const Wilder = () => {
  return (
    <div>
      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_12_Miles.jpeg"}
        />
        <figcaption>
          Miles Madonna (Class of 2024), <em>French Broad River</em> (detail).
          <br />
          Handweaving, 48" x 68", wool, alpaca, mohair, indigo.
        </figcaption>
      </figure>
      <p>
        This image is a snapshot from one of the thirteen blankets woven by
        undergraduate students at Warren Wilson College for The Blue Ridge
        Blanket Project, a collaboration with local nonprofit organization Local
        Cloth.
        <sup>
          <a href="#annotation-1">1</a>
        </sup>{" "}
        Warren Wilson College is a small liberal arts college in the Blue Ridge
        Mountains, just east of Asheville, North Carolina, US. Students in the
        fiber arts program learn about material through growing and processing,
        through making and histories, and through projects like these blankets
        that connect them into their local communities and the people and places
        around them.{" "}
      </p>

      <p>
        This blanket is named for the ancient river that runs through Asheville.
        The French Broad River is one of the oldest rivers in the world. Some
        say the second oldest, others dispute this claim but still hold it in
        the top five. Whichever ranking it really holds, you can feel its wisdom
        rushing along its banks. The Cherokee called it Tahkee-os-tee, or
        “racing waters,” as it was the place they raced their dugout canoes.{" "}
      </p>

      <p>
        Like a river, this blanket project is a gathering place. It draws
        strength and energy from the people, plants, animals, and organizations
        it comes into contact with. A confluence of many tributaries creates the
        locally made cloth. The list below acknowledges the many people coming
        together with their individual passions to make something bigger than
        themselves and create the storytelling of this blanket.
      </p>

      <ul className="bulleted">
        <li>
          <em>The animals:</em> Fiber from angora goats, sheep, and alpaca from
          fourteen farms in the region has become the star of the blankets. The
          softness is a tactile feeling that wakes up your senses and can invoke
          all the cozy feelings of curling up with a good book and mug of tea.
        </li>
        <li>
          <em>The farmers:</em> These people give care to keep the animals alive
          and steward the land in which they live.{" "}
        </li>
        <li>
          <em>The mill:</em> Two Roots Fiber Mill, in Greeneville, Tennessee,
          took time in their backed-up production line to give attention to this
          project. They make sure the area has a fiber mill to keep production
          happening locally.{" "}
        </li>
        <li>
          <em>The dye plants:</em> In this blanket the blue comes from indigo.
          In others it was black walnut, or madder root, or weld stalks. These
          are all dye plants that grow well locally and give a wonderful color
          palette for the full collection of blankets. The weld was grown in the
          natural dye garden at the college garden and harvested by
          students—examples of work activities that occur on the fiber arts
          crew.
          <sup>
            <a href="#annotation-2">2</a>
          </sup>{" "}
        </li>
        <li>
          <em>The dyers:</em> Meghan Gibbons and I started a natural dye study
          group many moons ago as a place to look at where the overlap between
          medicinal plants and dye plants converges. The group is under
          different leadership today, but still going strong through a community
          of learners at Local Cloth.
        </li>
        <li>
          <em>Heather Powers:</em> MA in Critical Craft Studies, Class of 2021,
          and lead consultant on this project, gave expertise in the way of
          time, ideas, and experience.{" "}
        </li>
        <li>
          <em>The weavers:</em> Three students—Miles Madonna, Matilda Law, and
          Daniel Baron—pulled warp and weft together to create the lovely twill
          patterns that make up the blanket collection. They all learned to
          weave on the fiber arts crew at Warren Wilson College, finding
          themselves on the loom literally and in every figurative sense
          possible through this project and many others.{" "}
        </li>
        <li>
          <em>The loom:</em> The simple machine, which the fiber arts crew has
          named Mor Mor Mia (a grandmother, Morgan’s grandmother), pulled warp
          and weft together in a web called <em>The French Broad</em>.
        </li>
        <li>
          <em>Me:</em> I took the dyed yarns and creatively put them together
          into thirteen twill designs for this project. I enthusiastically
          signed on to this collaboration because of the historical importance
          of handwoven blankets to this region and to the college.
          <sup>
            <a href="#annotation-3">3</a>
          </sup>{" "}
        </li>
        <li>
          <em>The land:</em> All the places locally that this blanket touched,
          including our college campus, in Swannanoa. The Swannanoa River runs
          right through the middle of campus, then into the French Broad.
        </li>
      </ul>

      <p>
        Through the short but impactful time I have been inspired by Namita and
        all the students and faculty of the MA in Critical Craft Studies, I have
        affirmed that the ways in which we are working at Warren Wilson College
        is needed and desired. I have found acknowledgment of the ecosystem of
        engagement with materials and the learning that happens adjacent to this
        way of working. I have also come to understand that craft is more than
        making and the object, and that histories will always run deeper than at
        first glance. Because of this influence, I now have an additional lens
        with which I teach my students. I encourage them to think deeper about
        any material, beyond where it comes from and what you might do with it.
        Who else has spent time engaged with this material, and what was their
        story? Similarly, as took place with this specific project, the MA in
        Critical Craft Studies has been a gathering place. I love to think about
        the many tributaries of knowledge that have spread around the world
        which will keep this program flowing beyond.
      </p>
      <hr />

      <cite>
        <sup id="annotation-1">1.</sup> Local Cloth is a nonprofit based out of
        Asheville, North Carolina, with a mission to support and sustain the
        regional fiber and textile community of producers and professionals
        within the field through education and collaborative projects.
      </cite>
      <cite>
        <sup id="annotation-2">2.</sup> Warren Wilson College is one of nine
        work colleges in the US, giving undergraduate students a holistic
        experiential education combining work, community engagement, and
        academics. Within the work program, students integrate their academic
        interests by working weekly on a “crew” as part of this experience.
        These work assignments support college operations, foster collaborative
        learning, give students hands-on opportunity to explore vocational
        interests, and develop workplace competencies. These crews also become
        family, the cultural richness that adds to their undergraduate studies.
        The fiber arts crew supports our academic craft studio program and
        provides opportunities to deepen craft learning and materials
        exploration.
      </cite>
      <cite>
        <sup id="annotation-3">3.</sup> When I revived the weaving program at
        Warren Wilson College, in 2009, stories of a previous weaving program
        began to come to light. Stories and cloth that were hidden in the
        library archives, and with people who knew bits and pieces of this
        history, began to be woven back together. The college had a thriving
        weaving program during the 1920 to 1960s, under the name Dorland-Bell
        Looms and, later, Warren Wilson Crafts. Local weavers, many of whom
        themselves had children at the Dorland-Bell School, began to teach local
        Appalachian patterns to the students. Some of these mountain coverlet
        weavers would exchange one of their handwoven blankets for a year’s
        tuition to the prestigious Dorland-Bell School. The value of this
        knowledge was known and acknowledged, and it’s a beautiful history that
        drives my work with the next generation of weavers at the college.
      </cite>
    </div>
  );
};

export default Wilder;
