import { NavLink } from "react-router-dom";

const LignelWiggers = () => {
  return (
    <div>
      <p>
        The MACR program, from its inception in 2017, generated all sorts of
        printed matter and online communication: occasional promotional posters,
        postcards, and advertisements, but the most substantial are student
        publications tied to coursework in the Materials Lab (Mat Lab) thread, a
        course that encourages applied learning through the process of creating
        a publication. Publishing—from start to finish—is invariably
        collaborative. In these cases, it involved ongoing conversations behind
        the scenes between Ben Lignel, who designed the program-generated
        materials, and Namita Wiggers, who built the framework in which these
        could exist, and between Ben and the students themselves through the
        work itself. Each publication served multiple goals: it documented
        curiosity and research in a given year, and invested students with the
        responsibilities of producing and sharing knowledge, on the terms they
        themselves set. The program-generated design work pivoted on
        intentionality: through the process of production, and through the ways
        in which design conveys and connects in visual, textual, and physical
        forms.{" "}
      </p>

      <p>
        The four recorded conversations that fed into the following text echo
        the way we—Ben and Namita—collaborate at a distance. For more than 10
        years, from a friendship seeded by the intermittent mailing out of
        printed matter from Paris toward Portland, to books, conferences,
        research projects, and this program, the ways in which collaboration can
        bridge distances was a key aspect in the development and implementation
        of the MACR program.{" "}
      </p>

      <p>
        Talking about the design work felt strange at first. The pieces are
        responsive to specific needs, such as a promotional piece or a
        cohort-led publication. We asked ourselves before, during, and after
        this conversation: how do these individual pieces reflect and reveal the
        thinking behind the program itself? How did the materials shape and
        convey how design and teaching were inextricably intertwined? What is
        the story here to tell?{" "}
      </p>
      <p>
        In many ways, our hesitation mirrors the challenges of speaking about
        craft. That which is too familiar, too much a part of daily work, often
        resists analysis. In editing these conversations, we decided to show
        this collaborative process, as much as we wanted to explain the thinking
        that went into five different projects: an early promotional poster, the
        2020, ’21, and ’22 printed publications, and the final online project
        that you are now looking at. The results show, we hope, how our thinking
        over a distance works—and the connection between teaching and design
        work, from 2020 to the program’s final publication.
      </p>
      <p>Ben and Namita</p>
      <p>*</p>
      <p>
        <em>
          The following text is culled from conversations that took place
          remotely and in person on February 1, February 16, and March 6, 2023.
          They have been edited for clarity and coherence.{" "}
        </em>
      </p>

      <h2>The Poster</h2>

      <p>
        <span className="speaker">Ben Lignel:</span> So let’s start with the
        first piece we worked on together for the program: the poster. One of
        the things this is exciting for is the fact that it captured an event or
        a moment in the residency, a moment that was powerful for everyone.
        Because Lisa’s questions workshop was a moment of epiphany. Suddenly it
        felt like this was the end of her workshop and everything that she’d
        done to that moment converged to allow people to very quickly produce
        these [hundred] questions …
      </p>

      <p>
        <span className="speaker">Namita Gupta Wiggers:</span> … collectively.
        She had prepared [the students] by that point to be ready to engage in
        the exercise. By this stage, she’s a scribe, she’s only writing things
        down: she [had] created a space for everybody else to engage and
        participate without hierarchy as much as possible.
      </p>

      <p>
        People were surprised by how much was coming out of this sort of simple
        prompt, you know, within [what felt like] a few minutes. As it got
        longer and longer, and I think I appreciated that she did this on these
        large sheets of paper that together created this long, long banner:
        something that could have been [written in] a notebook became a flag or
        a manifesto. Mike needed to stand on the stool above an organ to hang
        it, because it’s so long. There’s like two meters and a half of
        questions: an enormous piece of paper that suddenly fell, for me, within
        a long history of public-facing placards, proclamations, or letters of
        grievance …
      </p>

      <p>
        That's interesting. My mind went to how different it felt from the way
        things get posted up in business contexts because that’s more of the
        context that I’ve seen these tools used: big giant posters and stickers.
        And markers. There’s a lot of ideation that I’ve been a part of in
        business contexts. But we never stuck them together to make a giant
        scroll. Or [let them] sit there for people to engage over time as they
        continue [to inhabit] the space. For me, it was about subverting those
        business tools.
      </p>

      <p>
        <span className="speaker">BL:</span> Yeah, I can see that as well. I
        think my mind went to public placards because of the adversarial nature
        of these placards … There is something to be said about being an image
        within an image or a text within text. This is a form of imbrication
        that we used a few times over the next few years to show people
        something that we have been looking at in class before we show it to
        them. We employed the same strategy in <em>Mapping Craft</em>, our first
        publication, and again in our second. By doing that, we’re acknowledging
        the fact that whatever we’re producing in that classroom is for us, but
        also geared toward a bigger audience from the get-go. These 100
        Questions are questions that we’re addressing to the world … from the
        perimeter of the classroom.
      </p>
      <figure>
        <img
          src={
            process.env.PUBLIC_URL + "/img/notaretreat/B_N_11.1_ponge_et_al.jpg"
          }
        />
        <figcaption>
          Three examples over the years of image of text reproduced within an
          image. From left to right:{" "}
          <em>MA in Critical and Historical Craft Studies</em> poster, October
          2018; spread from <em>Mapping Craft</em>, July 2020, showing the essay
          “The Footnote,” by Ben Lignel and matt lambert; and a postcard
          reproducing the advertisement placed in the South Carolina Gazette in
          1750 by John “Quash” Williams. This last is part of the Black Craft
          Digital Archive series featured in <em>to: Craft</em>, the second
          student publication, June 2021.
        </figcaption>
      </figure>

      <p>
        <span className="speaker">NGW:</span> I agree. And, Ben, you know, it’s
        interesting what you’re saying about activating: I think that was a
        really, really important part of it for us. We had conversations about
        showing research … a certain way. That poster explains that it was a
        collective process. And there’s something about it being about people
        coming together and interacting. It was an experiential thing for me
        that we demonstrated.{" "}
      </p>

      <p>
        But let’s talk about the design of the poster for a minute. I think the
        poster was your idea. You were, like, there is something here, I want to
        do something with this. And I think I remember saying, well, we need
        something to send out to schools. You responded to it in a visceral,
        physical sort of way. There was something else you were responding to.
        What was that about?
      </p>

      <p>
        <span className="speaker">BL:</span> I don’t know. As I mentioned
        earlier, I liked the conceptual art move of taking a picture of
        something which is already a piece of paper. These gimmicks make you
        become double sighted somehow. I also wanted to share with people who
        receive this a sense of the extravagance—that list, again, is two meters
        tall! In the early stages, we discussed having a poster that was
        actually super long. Making it bigger would have allowed us to turn the
        event of opening the envelope into performance. That would have been
        great. Instead, what we produced was something which was manageable, and
        thank God for that because people started putting it up in their offices
        and school studios. It lends itself to that because of those questions.
        Because there’s so many ways you can read this and come back to it: for
        people who are invested in the world of craft, seeing those questions
        would have been a call to action or a call to thinking or a call to
        researching. That is why showing Mike in the sort of fumbling act of
        putting up [the 100 Questions] was exciting: it said “This is something
        that happened. Someone stood up on a stool and then put it up over the
        organ” and there’s a nice way that this sheet of paper is sort of
        draping over the organ in a ridiculous sort of way. Because it could
        have been much neater, as you said earlier. Instead, some of the details
        in the design point to the joyful, experimental feeling that permeated
        the program at the beginning. This situation—like many others—was the
        result of your careful staging—a staging meant for each of us, faculty
        and students, to do our thing with the understanding that something
        would come out of it. The poster, in the end, was as much a call to
        action for other people as it was a reminder for us of that first
        residency, and of the questions we were going to live by … The actual
        format and choice of Comic Sans font or the fake Riso feel of it were a
        no-brainer …
      </p>

      <p>
        <span className="speaker">NGW:</span> Why did you want that? I recall us
        having conversations specifically about Riso. Before you gave me any
        kind of a draft I remember you saying something about wanting it to feel
        like early, early Macintosh paste-up.
      </p>

      <p>
        <span className="speaker">BL:</span> This goes back to this idea that a
        certain visual vocabulary points to conditions of work and conditions of
        dissemination that are specific to a time and to a place.{" "}
      </p>

      <p>
        <span className="speaker">NGW:</span> This came up also in the zine
        workshops with Faythe Levine [in January 2022]. She talked about being
        at a Kinko’s and how you would take over the Kinko’s at night and people
        would be in there cutting and pasting and making posters.{" "}
      </p>

      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_11.2_Poster.jpg"}
        />
        <figcaption>
          <em>M.A. in Critical and Historical Craft Studies</em> poster, October
          2018. Four-panel accordion fold, 9.75” x 26”, offset printed. Photo:
          Scott Wiggers
        </figcaption>
      </figure>

      <p>
        <span className="speaker">BL:</span> These aesthetics that we’re
        describing—whether they’re from the 80s or 90s and whether they come
        from the conceptual art world or the later zine movement—are interesting
        contexts of what they stand in contrast to. I mean, there is a neatness
        to the American corporate communication that we’re trying to avoid … the
        poster, with its minimal color range and the graininess of the
        poor-quality image, point in a different direction.
        <sup>
          <a href="#annotation-1">1</a>
        </sup>{" "}
        And I think this is also what the graphic designer, the in-house graphic
        designer at Warren Wilson College, reacted to. It’s interesting that he
        could both understand the moves that [we] were making, or identify them,
        but not see their points. And so it succeeded in that way. I think it
        made for a very welcoming, accessible piece of graphic design that
        didn’t feel too corporate or that it was selling something too much.
      </p>

      <p>
        <span className="speaker">NGW:</span> Totally. When I received the email
        [it] was so clear how upset he was. What you didn’t know is that there
        was a style guide that had been created the previous year. It had a
        color palette and a corporate identity structure for WWC that offered
        ways to present content that were actually less about selling Appalachia
        in a nostalgic way but still about selling the campus in terms of the
        natural beauty. I knew that what we were doing was going to stand out in
        the field, that it would cause some conversation. It would catch
        attention. I wanted it to be the poster people stole off the bulletin
        boards—which they did—and I replaced some for people because it got
        stolen. I knew where it was going to go, so for me the dissemination
        factor worked in the way you were talking about mail art: going from
        individual to individual and then eventually potentially getting
        collected as ephemera and maybe even going into museums. I was thinking
        of mail art as this poster was going to go to faculty members. They were
        going to either put it on their door with other posters that look like
        what I just described, or they were going to put it on their wall or
        bulletin board.{" "}
      </p>

      <p>
        I didn’t share Morgan’s email and my response with you. I thought rather
        than emailing him back I would take a friendly colleague approach and so
        I called him. When he answered, I said, “Hey, I gathered from your mail
        that you <em>love</em> the poster. Let’s talk about this. Let’s have a
        conversation.” And it turned out to be the right strategy to defuse a
        situation. I talked about the graininess of the photos. And I mentioned
        Xeroxes and photocopies and Riso and stuff like that. And I said, you
        know, we’re speaking to a particular kind of maker who’s in these
        programs, who’s going to recognize that, and it will be evocative.{" "}
      </p>

      <p>
        <span className="speaker">BL:</span> I think it’s funny in hindsight how
        so many things about this make sense. One of them that we have mentioned
        is that the fact that you and I met over posters, the posters that I
        sent to you when you were chief curator and director of the Museum of
        Contemporary Craft, in Portland, meant that this was a language that we
        shared—a point of origin in our friendship. That immediately made it
        easy for us to consider the poster as a means of communication.
      </p>

      <p>
        Back then, it had been a way of promoting my work as a jeweler and
        making connections with people whose work I knew from afar that I
        thought was relevant and interesting. And so you started keeping them
        but there was no connection yet. I love the idea of sending something
        and [asking] for nothing in return. There’s something very powerful
        about this gesture of giving, which does create a reciprocal duty but a
        very slight one, a very mild one. And so it made space for something to
        happen after, which was, you know, us meeting and then becoming friends
        and you sort of telling me how you found these posters to be enigmatic.
        I do like to produce things that do not make sense immediately. I think
        this is present in most of the material I designed for the program, and
        that gets us back to the Comic Sans font. That choice has to do with
        which fonts are considered legitimate and credible for institutional
        communication: Comic Sans is not one of them. The Filipino graphic
        designer Clara Balaguer, whose ideas we studied later in class,
        <sup>
          <a href="#annotation-2">2</a>
        </sup>{" "}
        discusses how in the Philippines using Comic Sans is a way to actually
        create a sense of authenticity in countercultural circles: official
        documents and professionally designed com favor Arial and Helvetica.
        Something similar is happening here: using a font that doesn’t feel
        credible to most people who work in institutions makes it appealing to
        us. Because this is how the programming was from the beginning, how you
        set it up to be a program that was going to attend to and challenge
        conventions and cannons.
      </p>
      <p>
        <span className="speaker">NGW:</span> This is something that I also paid
        attention to at the museum. Color is important. The typeface is
        important. These kinds of decisions are all about creating. Taking
        things to the nth degree is not creating content and then handing it
        over to get turned out into a particular style. We got the chance, the
        poster signaled room to play by compromising a bit and showing what
        could happen with it, we demonstrated success from that.
      </p>

      <p>
        <span className="speaker">BL:</span> I don’t think we ever looked back
        and I certainly never felt that there was anyone watching over my
        shoulder telling me “you should do this this way.” And, I mean, you’ve
        been incredibly, incredibly sort of open and you’ve given me a lot of
        leeway to do stuff. And this exchange with Morgan is the only time when
        I felt we were accountable to someone else.
      </p>

      <p>
        <span className="speaker">NGW:</span> Remember that I didn’t share with
        you the note from Morgan until I had resolved the situation? I had to
        establish a certain trust with him. I was able to give historical
        contexts. Morgan didn’t know that I taught two semesters of the history
        of graphic design at Portland State University. He didn’t know that I
        knew design history and could speak to that. From that trust we
        developed an understanding that the things that the program was
        producing <em>from</em> the program could be my mine to manage, and the
        things that the college produced to represent the program amongst other
        College programs they could produce on their own. But, yeah, you got
        freedom because I put myself in a position where I could pull those
        aside and let you run.
      </p>

      <p>
        <span className="speaker">BL:</span> That’s what I think I meant, you
        know, it felt from that point onward that you were holding at bay
        whoever was trying to intrude, and that we could play. This [was]
        nowhere truer than for the first publication, which was produced under
        these conditions: inventing things, trying things out with multiple
        mapping and navigation systems which were really complex and very
        exciting to work with. And you trusted us to the end, without seeing a
        number of things until late in the process. I am just saying that I
        really appreciate how much freedom I had to try different things. As a
        result, each of the four publications we produced occupies a different
        graphic-design world. The sort of connotations they carry in terms of
        production methods, or design, adhere to different forms of credibility
        that are not really compatible. And I love that.{" "}
      </p>

      <p>
        <span className="speaker">NGW:</span> I love the way you described that
        each of them has different credibility and legitimacy and they operate
        in different worlds in different ways. And yet they all speak to the
        program and at the same time there’s so much that brings them together.
        But there’s also so much in how the form changes that was really
        important to me. We talked about this and whether to make all the
        publications look like part of a series or not, and we decided not to.
        That was a big decision. That had implications for me in terms of what I
        wanted the program to be: that it was not canonical and it was not going
        to fit into a particular mold. It was going to have this kind of
        flexibility and fluidity, and that comes through in those forms really
        beautifully.
      </p>

      <h2>Mapping Craft</h2>

      <p>
        <span className="speaker">BL:</span> We were thinking at the end of last
        conversation about ways to frame and present the three publications that
        we have produced to date … I’ll start by saying that Mat Lab was sort of
        turned into channels for publication, focusing on two things: first, to
        put the publication together and to do so in a way that would be
        relevant to the students. And then, second, to think about what it means
        to occupy the seat of an editor, and to make the choices that come with
        that position, thinking in particular about the relationship that exists
        between an editorial project and a “format.” In those terms, there was a
        big desire from the cohort of 2020 to do something that would speak the
        language of legitimacy within the academic world …
      </p>

      <p>
        <span className="speaker">NGW:</span> Before we go into mapping all of
        them, I want to pause on the students for a second and say that they not
        only were part of shaping what the format was and the content and focus,
        but they also were contributors and have by-lines in each publication:
        their involvement is multifaceted.
      </p>

      <p>
        <span className="speaker">BL:</span> For sure! To clarify: the whole
        cohort was tasked with identifying collectively what they wanted this
        publication to do and then two of the students would be hired—one from
        each year, ideally—to serve as part of the editorial team, as editorial
        assistants. The project took place over the last two semesters: during
        semester three, the class would work on defining an editorial goal, and
        then in semester four a smaller group of students and I took care of
        producing it: doing editorial feedback, copy editing, designing things,
        and then fabricating. The two assistant positions were made possible,
        each year, by specific scholarships—funds given by private individuals
        to support the program’s publication project.
      </p>

      <p>
        <span className="speaker">NGW:</span> The students serving as co-editors
        with you … What was their role in terms of design, structure, format,
        and things like that?
      </p>

      <p>
        <span className="speaker">BL:</span> Let’s start from the <em>goal</em>.
        Once the first cohort decided that they would be really interested in
        producing something that they could show at CAA (College Art
        Association), or plonk on some editor’s desk and say “I’m a scholar” …
        that led us to decide on a format that mirrored reader-friendly,
        medium-sized, handheld, scholarly publications printed in a pared-down
        color palette.
        <sup>
          <a href="#annotation-3">3</a>
        </sup>{" "}
        Our focus is on mapping complexity, making the richness of sources
        visible to the reader, celebrating the complexities of the connections
        that they’re making, the resources they’re looking at. They were
        interested in credibility and legitimacy, so I designed to that. Both
        you, and matty, were key to that information design process.
      </p>

      <p>
        <span className="speaker">NGW:</span> What’s interesting is that the
        students very much wanted a more academic-looking publication, whose
        components signal rigor for a certain kind of scholarship. That first
        class definitely wanted to be engaged in that community: they expressed
        a really strong desire to be, for lack of a better word, taken seriously
        by the academic community.
      </p>

      <p>
        <span className="speaker">BL:</span> Well, and would it be okay to say
        that <em>we</em> also wanted to be taken seriously?!
      </p>

      <p>
        <span className="speaker">NGW:</span> Is that something that you felt?{" "}
      </p>

      <p>
        <span className="speaker">BL:</span> Well … this is a 260-page
        publication. It has five different sorts of navigation systems: a
        glossary, three sets of maps, a table of contents, and a keywords
        apparatus. And the theme was “This is how we meet.” When we came to the
        first residency, people were saying during initial introductions that
        “I’ve been waiting for this program for 10 years.” And I think this
        publication, academic as it seemed, also really paid tribute to the
        field-building spirit that animated the program from the get-go. The
        notion that we’re recognizing one another across the room, and naming
        the people that we’re wanting to speak with, and making space for those
        people within the publication.
      </p>

      <p>
        There’s so many things that are part of the program, part of the way
        that you set up the program, part of the way that you’re thinking about
        making space for people that trickles down to graphic solutions, or
        editorial solution. For example: it was very important for the students
        to not just list teachers and classes and events in our program
        description, but also speak about those soft{" "}
        <em>moments of in-between</em>. This is where we were in a bus and going
        to the Cherokee village or they were doing a midnight swim in the river.
        This has to do with the sort of spirit that you set up for the program:
        a way of paying attention to the personal but also to what happens when
        the action is finished and—as the American documentary filmmaker
        Frederick Wiseman did—you keep the camera rolling. We’re thinking about
        learning as not just what happens during class, but everything that
        happens around it. And so the publication, to go back to it, worked
        toward two different goals. One of which was to look academic, and the
        other to feel that it was the product of people coming together and
        eating together and swimming together and sort of thinking about craft
        as a new field together. So, yeah, this publication is both very serious
        and dripping with emotions, you could say.
      </p>

      <p>
        <span className="speaker">NGW:</span> Yeah, so very much so. Talk more
        about the navigation systems, which you described as complex and
        layered. How did that come about?
      </p>

      <p>
        <span className="speaker">BL:</span> You know, I’m not sure that I’m
        able to answer the simple question of, “Where did this originate?” I
        know it was on your mind. I know it was on our mind—yours and
        mine—because we did a mapping exercise together … which was about
        sending the students out into Asheville and mapping the presence of
        craft vertically and horizontally in the city around a few blocks, an
        assignment that would prove very exciting. Not so much for what they
        actually saw, but for the way that they managed to relay that
        information and drew it for us in ways that [were] each time very
        different.
        <sup>
          <a href="#annotation-4">4</a>
        </sup>{" "}
      </p>
      <figure>
        <img
          src={
            process.env.PUBLIC_URL +
            "/img/notaretreat/B_N_11.3_Mapping_Craft_Asheville.jpg"
          }
        />
        <figcaption>
          Darrah Bowden, Matt Haugh, and Kat St. Aubin’s mapping of the presence
          of craft around the Asheville Art Museum, using a sedimental approach.
          Basement of the Center for Craft, January 2019. Photo: lydia see. This
          photo was used to create a set of postcards to promote the program.
        </figcaption>
      </figure>

      <p>
        They used a modeling system that they were interested in because it
        showed how the modeling system itself has an impact on what can be seen.
        And that, again, informs the way that we think about maps for this
        publication: indexes, tracking systems, classification systems, all the
        things that allow us to order knowledge contain their own sort of bias.
        And by multiplying the number of maps there was a hope that their
        overlap and the absurdity of their combinations would invite the reader
        to question the way that knowledge is organized and built.{" "}
      </p>

      <p>
        The most literal map in the publication is in the opening pages, led by
        you and me, which is laid over a color representation of the Swannanoa
        Valley, and tracks every single event, class, and workshop of faculty
        and guests that came to mentor and work with the students. A second map,
        on the next spread, was student-led and pays tribute to “in-between
        learning” and shows important events as experienced by the students—the
        places where they met someone or did something that isn’t in the
        syllabus but informed the way that they think about craft.{" "}
      </p>

      <p>
        <span className="speaker">NGW:</span> So what I love is how the second
        one talks about experience in a different way. Its categories are
        “observed, nourish, unlearn, immerse, dialogue, play, love, wander.”
        Craft is known for being about experiential education and I feel like
        this map, the double layering, recognizes who has been engaged and when
        in a historical context, but the second one allows for that same time
        period to be understood through a really different lens. There’s a third
        layer of mapping that happens then that is in introducing each of the
        individual essays. Can you speak to that a little bit?
      </p>

      <p>
        <span className="speaker">BL:</span> This second map was culled from the
        students’ response to an invitation the editorial team—Sam Rastatter,
        matt lambert, and, later, Heather Powers—sent to them to name and list
        events and places that had mattered to them over the last two years. We
        gathered these in one unified, collective map—this one—but also
        distributed the responses into smaller, individual maps that introduced
        each student contribution to the publication. We probably should have
        started the other way around! The three-map system points to the fact
        that there were things happening to all of them also happening to them
        in a singular way.
      </p>

      <p>
        <span className="speaker">NGW:</span> It’s like an Eames-influenced
        Powers of 10 approach—looking at time, place, and activities, and
        zooming in from the organizational level to the shared-experience level
        to the individual-experience level … I think is really compelling.
      </p>

      <p>
        <span className="speaker">BL:</span> The last map isn’t really a map.
        It’s a glossary of terms on the back cover of the book. The words are
        organized in alphabetical order as it would be in a glossary, but my
        choice of words favored improbable juxtapositions—
        <em>
          moccasin soles, motorcycle leather, old pebble, overshot coverlet
        </em>{" "}
        … What I wanted to do there is to provide readers with a truncated
        material culture of the program, with objects, materials that were
        present in the classroom, functioning like a teaser, making a weird sort
        of sense—differently than the syllabus maps or the personal events maps.
        I hoped that craft scholars would look at this and say, “Hmm, this is
        exciting.” I mean, there are <em>tusks</em> and <em>wedding gowns!</em>{" "}
        The person whose thinking influences me most for the back cover is
        George Perec and his endless, systematic semantic games … we’ve often
        read his work in class: his <em>Think/Classify</em> essay,
        <sup>
          <a href="#annotation-5">5</a>
        </sup>{" "}
        and<em> Life, a User’s Manual</em> for its unbelievable object-centric
        sociological reading of Paris in the 70s …
      </p>

      <p>
        <span className="speaker">NGW:</span> Yes, and also his{" "}
        <em>Attempt at Exhausting a Place in Paris</em>
        <sup>
          <a href="#annotation-6">6</a>
        </sup>{" "}
        … which you assigned in Mat Lab.
      </p>

      <p>
        <span className="speaker">BL:</span> I think what I’m trying to leverage
        here is the obvious through a self-aware limitation of a list.{" "}
        <em>An Attempt at Exhausting a Place in Paris</em> is actually a failure
        to describe anything that amounts to an event or a story. So does this
        list on the back of the book feel like an incredible, brutal shorthand
        for the thinking that happens inside the book. As a reader you sort of
        pick up on this: the ultimate filling-the-gap game—a game which makes
        you complicit to the interests of the authors and can only really be
        solved by looking inside the book. Like the maps, the glossary leverages
        the tension between a shorthand form and the totality it represents.
      </p>

      <p>
        <span className="speaker">NGW:</span> … and this points to the systems
        that have caused maps to come into being. If you look at the ways in
        which, and the reasons why, different colonial powers mapped the world,
        it’s clear how maps speak to the power structures differently if you
        look at English maps or Spanish maps, for example. All these layers are
        coming in play here, just not explicitly stated in the book itself.
      </p>

      <p>
        <span className="speaker">BL:</span> To expand on your point on the
        colonial structures, I think one of the early lectures you delivered
        during that first residency was “Looking for Craft in All the Wrong
        Places.” Your lecture raised questions, and invited the audience to
        think about what is a legitimate place or source for craft knowledge.
        That was on my mind as we put [together] this list of words for the back
        cover of our first publication: to not just look at things that are in
        museums, not just look at things that are in galleries, and to pay
        attention to moccasin soles, motorcycle leather, and wedding gowns.
      </p>

      <p>
        <span className="speaker">NGW:</span> … and <em>pillowcase</em> …
        between <em>paper bag</em> and <em>pith</em>. I was looking at them and
        I thought, okay, pillowcase. It sends me to an interview that Dara
        Bowden did with Archie Stewart, connected to kites. So to sit down and
        go, oh, okay, wait, this is about certain kinds of materials and how
        they work … it starts you thinking in a different way.{" "}
      </p>

      <p>
        The other thing that I’m remembering is that there’s some different
        kinds of text treatments that happen here. I’m thinking about the
        “conversation” between Linda Tuhiwai Smith and Francis Ponge. I wonder
        if you might speak about that.
      </p>
      <figure>
        <img
          src={
            process.env.PUBLIC_URL +
            "/img/notaretreat/B_N_11.4_Ponge_smith_spread.jpg"
          }
        />
        <figcaption>
          Spread from <em>Mapping Craft</em>, July 2020, showing the
          juxtaposition of (left) Ponge’s text and (right) Linda Tuhiwai
          Smith’s.
        </figcaption>
      </figure>
      <p>
        <span className="speaker">BL:</span> We mentioned earlier the fact that
        the subtitle of this publication is “This is how we meet” … so there’s
        many real conversations that happen in this publication and at least one
        manufactured encounter between the French poet Francis Ponge and the New
        Zealand scholar Linda Tuhiwai Smith, whose work has been central to your
        teaching in the program.
        <sup>
          <a href="#annotation-7">7</a>
        </sup>{" "}
        The students and I decided to pair them up in the publication following
        a Mat Lab class in which we put her call to invent a new language for
        decolonized scholarship in conversation with Francis Ponge’s call for
        the invention of new language that would do justice to the singular
        existence of <em>things</em>, 50 years earlier.
        <sup>
          <a href="#annotation-8">8</a>
        </sup>{" "}
        The design solution I found there was to actually feature their texts as
        open books, photographed side by side but separated by a gap, to
        visually indicate that this is a manufactured meet.
      </p>

      <p>
        <span className="speaker">NGW:</span> What I loved about this design is
        it makes you aware of the form of the book and the book as an object,
        right? Because you have to pause and go, wait a minute, what’s happening
        here? Once you figure out that the left pages are all Ponge’s and the
        right pages are all Tuhiwai Smith’s, it forces you to actually engage
        this object of the mapping craft. This book-as-an-object makes you
        physically aware of what it means to actually read, it pushes you back
        out of the space of the page.{" "}
      </p>

      <p>
        <span className="speaker">BL:</span> There’s something about this
        particular format, its reliance on something we’ve described in this
        conversation as an academic format. It’s sort of a de facto celebration
        of the printed page as the site of highest legitimacy, and there’s
        plenty of that in this book.{" "}
      </p>
      <h2>to: Craft</h2>
      <p>
        <span className="speaker">NGW:</span> What happens to place, experience,
        and community in these multiple kinds of publications? I am thinking of
        the postcards from the Class of 2021, that are about distribution, about
        reaching out, about connecting across space, across time.
      </p>

      <p>
        <span className="speaker">BL:</span> We had an assignment, a quick one,
        which was really lovely: I asked them to think about any form that could
        carry a message that we would send out. Amy Meisner’s response was to
        write on the leaves of a lettuce. And there was something exciting about
        the ephemerality of that vegetable—the fact that it was so fragile and
        yet could be turned into a gift … I think all the responses to that
        assignment courted the evanescent, the flickering: something that
        doesn’t really have much of a tangible form. Meanwhile, I was excited
        about sending something out as a way to measure the distance between two
        people and trying to bridge that distance. The postcards are doing these
        two things at the same time. They acknowledge the fact that you’re far
        away from someone else. But as it’s doing that, it’s crossing that
        bridge through gift-giving.{" "}
      </p>

      <p>
        <span className="speaker">NGW:</span> Was this something that you
        thought of introducing in 2021 because of the pandemic? We were strictly
        online and not co-locating anymore: How much of that influenced your
        interest in postcards that particular year?
      </p>

      <p>
        <span className="speaker">BL:</span> It did, for sure. There was
        something about communicating with others. And also, to put it very
        simply, to be nice to others. Postcards have a way of entering the
        privacy of someone else’s home, of being present to someone else in
        their own space … As we already said, Mat Lab was driven by the idea of
        finding a form that aligns with the ethos, the goal of a cohort’s
        publication. In this case, the students. They centered the ideas of
        gift-giving and of sharing. There is something very pithy and
        light-footed about the postcard format—it is a minimal gesture—that is
        very nice. It’s probable that the second cohort was at least as much
        responding to the conditions of isolation as it was responding to the
        first publication, which was for them a mountain that they didn’t want
        to climb.{" "}
      </p>
      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_11.5_Box.jpg"}
        />
        <figcaption>
          <em>to: Craft</em>, the MACR program’s second student publication,
          June 2021. Printed carboard box containing 39 digitally printed
          postcard, a set of stamps, and a folded Riso-printed foreword with an
          illustration by Alaskan artist Sara Tabbert.
        </figcaption>
      </figure>

      <p>
        The box is the size of a postcard and carries, on one of its longer but
        thinner edges, a quote by Hungarian mail artist Endre Tót, which says,
        “I write to you because I am here and you are there.” And that goes back
        to what we were just talking about with this idea that the postcard is a
        way to bridge a distance that it acknowledges. The second sort of thin
        side has a very brief description of the project:{" "}
      </p>

      <blockquote className="indented">
        <p>
          to: Craft contains: (1) a space of representation and discussion for
          communities or ideas that are absent from the craft canon, shaped as
          an invitation and a tool to do, experiment with, and appreciate craft
          and disseminate onward; (2) material that focuses on revising the
          craft canon toward inclusivity, by putting learning and making tools
          in the hands of craft-adjacent readerships. This content, and its
          emphasis on “doing,” celebrates the sensorial and the social in craft.
          It is framed as a hopeful endeavor that acknowledges the tough
          questions—of systemic bias in participation and representation—that
          stand behind it. June 2021 350 copies{" "}
        </p>
      </blockquote>

      <p>
        Meanwhile, the postcard offered many other possibilities that the
        heftier first publication did not. The back of postcards became a
        blackboard on which the students wrote commentaries and treaties,
        instructions, invitations, and short messages of benevolence. This
        set—because it is a “set”—invited internal repetitions and variations.
        Each of the four students had a postcard featuring a question from{" "}
        <NavLink to="/notaretreat/jarrett_wiggers">
          Lisa Jarrett’s workshop
        </NavLink>{" "}
        from the 2019 summer residency. We also had a series of stacks of books
        that they were reading and that amounted to a sort of portrait of the
        students—but differently than the previous year’s maps did. Finally, we
        extended an invitation to Tiffany Momon, program mentor, Teaching
        Fellow, and founder of the Black Craftspeople Digital Archive
        <sup>
          <a href="#annotation-9">9</a>
        </sup>{" "}
        to participate in the publication. And she produced a series of six
        postcards, each of which was pointing to a particular place or object
        that bore testament to the presence of Black craftspeople in the South.
        This was a way to draw the recipients’ attention to the important work
        she is doing. Using a postcard to do so somehow feels aligned with the
        fragile evidence—newspaper clippings, old census—she is basing her
        research on. And I thought that was lovely.
      </p>

      <p>
        <span className="speaker">NGW:</span> I’m watching you as you opened the
        box … and there was paper stuffed in there to make sure everything
        stayed and didn’t get banged up and jostled. Can you talk about the
        different pieces?
      </p>

      <p>
        <span className="speaker">BL:</span> So there are a few things that were
        added into the box: 39 postcards, a Riso-printed introduction carrying
        an illustration by Sara Tabbert, an artist from Alaska. And then we had
        a series of six small black stamps with the following simple Primo Levi
        quote on them: “If not now, when?”{" "}
      </p>

      <p>
        One of the key things we discussed with the students is to consider the
        publication as a wider extended network of activities. That would
        include “onward publishing”: the idea that you would send something to
        someone who would then send it onward to their own people. The idea was
        to rope in the recipient as a co-publisher, or co-distributor. I really
        wanted the students to be aware of this idea that the publication is a
        thing that moves, and depending on the form it has it moves differently.
        Sometimes it ends up on the coffee table. Sometimes it ends up on the
        shelf. Sometimes it ends up on the fridge and sometimes it sort of ends
        up in the post office again. And that is the very beautiful thing that
        we’re trying to play with.{" "}
      </p>

      <p>
        <span className="speaker">NGW:</span> Bean Gilsdorf, one of the program
        mentors, is purging her postcard collection right now and actually has
        been sending these out recently. She posted on Facebook that it was very
        challenging to let them go …{" "}
      </p>

      <p>
        <span className="speaker">BL:</span> We were thinking about dispersal,
        dissemination, and transmission as part of the same project in Mat Lab.
        I love the idea that once you open your kit, and as soon as you get one
        postcard out, you start to disperse something that will never be whole
        again. And there’s something very beautiful about putting recipients in
        the position of being re-publishers, and that need to deplete a resource
        by sending it to other people. This implies a choice between preserving
        documentation whole or sharing a resource in pieces.{" "}
      </p>

      <p>
        <span className="speaker">NGW:</span> Bean shared, too, that she felt
        she could let them go in a way because of the website. Can you speak a
        bit to how the website functions?
      </p>

      <p>
        <span className="speaker">BL:</span> There is something super symbolic
        about slicing the adhesive tape or removing the band around the
        postcards: these are one-time irreversible things that accompany
        physical mail-outs, along with obliteration … But we were aware of the
        fact that not everyone was going to receive this. We printed only 350
        copies, so we wanted to be sure that the project was both documented and
        accessible to people who knew about the program but might not receive a
        box set. And so front and back pictures of each one of the postcards are
        available on the website, together with a brief contextualization.
      </p>

      <p>
        <span className="speaker">NGW:</span> There are differences between the
        physical box set and this publication online. This postcard set has
        extended texts that are available online only. There’s also a specific
        order for the postcards online—a very concrete structure—whereas once
        you undo that paper ribbon, you release the postcards from their
        structure.
      </p>

      <figure>
        <img
          src={
            process.env.PUBLIC_URL +
            "/img/notaretreat/B_N_11.6_to_craft_website.jpg"
          }
        />
        <figcaption>
          Screengrab from the online version of <em>to: Craft</em>, the MACR
          program’s second student publication, June 2021. This page features a
          contribution from Heather K. Powers.
        </figcaption>
      </figure>

      <p>
        <span className="speaker">BL:</span> The additional texts are essays and
        lists of further readings. This program has been consistently very
        generous about publicly sharing resources and extending the syllabi
        toward people who might be interested in some of these topics. This has
        to do, in this case, with the fact that one of the key topics of this
        second publication is the presence of Black craft. It’s part of the
        questions that were raised in the Lisa Jarrett workshop, and in your own
        decolonizing methodologies discussions. It is the focus of Mellanee
        Goodman’s work, and of Tiffany Momon’s Black Craftspeople Digital
        Archive (BCDA). And so we’re collectively invested in spreading
        resources, to make sure that the traumatic misrepresentation that “there
        were no Black craftspeople in North Carolina,” as students were once
        told by an external source, for example, will not be heard again.{" "}
      </p>

      <p>
        <span className="speaker">NGW:</span> Connected to that, can we talk
        about one postcard in particular? Mellanee’s blank one?
      </p>

      <figure>
        <img
          src={
            process.env.PUBLIC_URL +
            "/img/notaretreat/B_N_11.7_mellanee_Postcards.jpg"
          }
        />
        <figcaption>
          Three of the postcards produced by Mellanee Goodman for{" "}
          <em>to: Craft</em>, the MACR program’s second student publication,
          June 2021. (Left to right) <em>Transmission of Knowledge part 1</em>,
          showing Lucindy Lawrence Jurdon, circa 1937, at the spinning wheel her
          enslaved mother used before her;{" "}
          <em>Transmission of Knowledge part 2</em>, illustrating the account
          made by Elizabeth Hyde Bostume of seeing Black women coming back from
          the field after the civil war;{" "}
          <em>Transmission of Knowledge part 3</em>, featuring three Black
          students of the Louisville, Kentucky, Hancock Street Chapel’s sewing
          school, run by the Presbyterian Church in the late 19th century.
        </figcaption>
      </figure>

      <p>
        <span className="speaker">BL:</span> Mellanee, who served as editor for
        this publication, produced three postcards, and they are all titled{" "}
        <em>Transmission of Knowledge</em>. As part of her research on
        documenting the presence of Black craft in the South, she found a
        testimony by Elizabeth Hyde Bostume, a white contraband camp missionary
        from South Carolina who, at the end of the Civil War, wrote:{" "}
      </p>

      <blockquote className="indented">
        <p>
          “It was not an unusual thing to meet a woman coming from the field,
          where she had been hoeing cotton, with a small bucket or cup on her
          head, and a hoe over her shoulder, contentedly smoking a pipe and
          briskly knitting as she strode along. I have seen, added to all these,
          a baby strapped to her back.”
        </p>
      </blockquote>

      <p>
        Of course, no image exists of the event. In discussion with Mellanee, we
        were trying to figure out how to deal with that and decided to
        acknowledge this systemic problem by captioning a blank postcard. I
        think the reason why this works is because the description is so vivid:
        you can imagine it in your head. In fact, you <em>need</em> to and can
        only imagine it in your head. This imaginative work ties back with some
        of the work that we’ve done in Mat Lab on the scholarship of Saidiya
        Hartman
        <sup>
          <a href="#annotation-10">10</a>
        </sup>{" "}
        and Epeli Hau’Ofa,
        <sup>
          <a href="#annotation-11">11</a>
        </sup>{" "}
        both of whom have presented imagination as a legitimate and necessary
        tool in reparative history-writing. The visual inspiration of a blank
        card for me, meanwhile, came from a friend’s house, where he’d collected
        captions to old photographs and only exhibited the captions. I thought
        it was super generative, as a series of non-images cannot exist without
        the viewer’s imagining how to fill them up.
      </p>

      <p>
        <span className="speaker">NGW:</span> What excited me so much about this
        particular one, too, is the way that it creates a physical presence
        where there is no “properly” documented physical presence, and I’m using
        the word properly in quotes to reflect how white supremacist structures
        operate and what they deny. Mellanee and you have taken someone who was
        ghostly and given her a presence without showing her; you’re bringing
        her body and her skills back into view. I found this very exciting,
        particularly in conjunction with Tiffany’s BCDA postcards. I love the
        way that this pushes back on what we have been taught is the right way
        to do history, using only tangible evidence. By introducing this in our
        program, we’re actually challenging what is evidence, how to engage it,
        and imagining research possibilities in a really powerful way.
      </p>

      <p>
        <span className="speaker">BL:</span> And riffing on what you just said
        about bringing her skills back into view: based on the description, we
        sort of know that she’s going to be sewing, she’s going to be mending.
        And the opening line of the text that inspired the card that says “It’s
        not an unusual thing” opens many possibilities that Mellanee went on to
        analyze and to document and to speculate [on] in her Practicum.{" "}
      </p>

      <p>
        <span className="speaker">NGW:</span> Let’s talk about the typeface. And
        the reason I think it’s important is because I think one of the things
        that we wanted to teach the students [is] that publications are not just
        about producing content.{" "}
      </p>

      <p>
        <span className="speaker">BL:</span> Yes, you are right. We wanted to be
        very deliberate and intentional about everything that we use and the
        people that we work with. It made sense to us to work with{" "}
        <a href="https://www.ecoenclose.com/" target="_blank" rel="noreferrer">
          EcoEnclose
        </a>
        , a packaging company that used soy-based inks; to commission stamps
        from a company—the{" "}
        <a
          href="https://theportlandstampcompany.com/"
          target="_blank"
          rel="noreferrer"
        >
          Portland Stamp Company
        </a>
        —that reflects on the meaning of stamps today; and to ask{" "}
        <a href="https://www.outletpdx.com/" target="_blank" rel="noreferrer">
          Outlet
        </a>
        , a small-sized artisanal outfit, to produce our folded Riso foreword.{" "}
      </p>

      <p>
        And then finally, coming to the fonts: one of the places where the
        contribution of women and people of color has traditionally been quite
        invisible is typeface creation. And so we decided to use only fonts
        created by either women or people of color—which is what we did, with a
        couple of exceptions, leaning on the following two databases:{" "}
        <a
          href="https://www.design-research.be/by-womxn/"
          target="_blank"
          rel="noreferrer"
        >
          https://www.design-research.be/by-womxn/
        </a>{" "}
        and{" "}
        <a
          href="https://library.typographica.org/black-type-designers-foundry-owners"
          target="_blank"
          rel="noreferrer"
        >
          https://library.typographica.org/black-type-designers-foundry-owners
        </a>
        . These were a great starting point. Because we were producing 39
        different postcards, there was a possibility to showcase this work in 39
        different ways. We credited each designer on each card, and we refer
        back to the BIPOC typeface designer database on the colophon so that
        others can support these designers.{" "}
      </p>

      <figure>
        <img
          src={
            process.env.PUBLIC_URL +
            "/img/notaretreat/B_N_11.8_amy_Postcard.jpg"
          }
        />
        <figcaption>
          A postcard produced by Amy Meissner for <em>to: Craft</em>, the MACR
          program’s second student publication, June 2021. The question,
          inspired by Lisa Jarrett’s “100 Questions workshop” from the summer of
          2019, is set in Bayard, a font design by Tré Seals, a Black designer
          working in Washington, DC.
        </figcaption>
      </figure>

      <p>
        <span className="speaker">NGW:</span> Is there any other postcard or
        element of the postcards that we didn’t touch on that you wanted to talk
        about?
      </p>

      <p>
        <span className="speaker">BL:</span> Yes—your own! It features a shirt
        that you embroidered over the confinement period of the pandemic, in
        which the dates of your loved ones getting a first and then the second
        shot are shown as small Band-Aids [stitched] in gold thread onto the
        white cotton shirt. And so the postcard shows meandering blue lines
        passing time until vaccinations were available. The postcard focuses on
        one of these Band-Aids, which is embroidered on this white shirt. It is
        a powerful way for a simple picture to refer back to this very long,
        elastic sort of timeline—that we have since referred to as that sort of
        “black hole of time”. People have blanked out these two years, in many
        ways. And this is craft, marking the time, and marking the time as
        something that you also do whilst you’re teaching is something that you
        do.
        <sup>
          <a href="#annotation-12">12</a>
        </sup>{" "}
      </p>

      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_11.9_Namita.jpg"}
        />
        <figcaption>
          Namita Wiggers’s contribution for <em>to: Craft</em>, the MACR
          program’s second student publication, June 2021. The picture shows{" "}
          <em>Taking a Line for a Walk</em>, a cotton shirt embroidered with
          black and blue cotton thread and gold and silver metallic threads
          between March 2020 and May 2021.
        </figcaption>
      </figure>

      <p>
        <span className="speaker">BL:</span> This postcard is the only one to
        clearly reference pandemic confinement. I like that it also references
        something that is very much part of the classroom: the fact that a
        number of the students and faculty will embroider, knit, or use their
        hands as we conduct our classes. On a very simple, other level, this has
        made for an ongoing presence of crafting in the classroom. But it also
        signals that different sorts of learning are being not just accepted but
        celebrated. People sometimes need to do something with their hands to be
        able to focus.{" "}
      </p>
      <p>
        It’s worth mentioning in this context the research conducted by Phoenix
        Booth—from the 2020 cohort—which has been influential on other students
        [and] made me understand better the relationship between crafting and
        coping. Their Practicum focused on the role of craft within therapeutic
        activities, specifically when dealing with trauma … Since then, I have
        understood differently the presence of the hands just outside the lower
        edge of the Zoom screen, as we all dealt with isolation …{" "}
      </p>

      <p>
        <span className="speaker">NGW:</span> Yes! This was the year I sent
        laser-cut looms by mail to everybody, and we learned how to use those
        together online. Going back to my postcard: before we knew if this was
        going to end in any way, shape, or form, I was thinking about “taking
        the line for a walk,” an exercise that Paul Klee gave in the Bauhaus, I
        believe. The blue meandering line that covers the shirt echoes this idea
        of taking a line for a walk while you’re waiting, but then also
        doodling. I used to get in so much trouble with my mom because I would
        doodle all over my homework or all over my notebooks. She felt that this
        was a sign of my not paying attention. And it actually wasn’t that, it
        was actually a sign that I was paying very close attention. I think you
        and I’ve talked about my habit of writing everything down that’s
        happening as a drawing exercise, a line drawing exercise. That doodling
        fills up time but also, as you said, <em>marks</em> time. Some people
        watching our students knitting might assume that they’re not paying
        attention. They would be wrong, and it was important to me to create
        space for other kinds of ways of learning to be in the room.{" "}
      </p>
      <h2>A Joyful Feast</h2>
      <p>
        <span className="speaker">BL:</span> The zine—the program’s third
        end-of-year publication in 2022—was the outcome of a different
        production setup than the previous two. I was off for the fall semester,
        and Sara Clugage took over Mat Lab—and the publication project—until it
        was passed on to me, as planned, as a design project. She was interested
        in getting the students to think about the economy of production and
        consumption of a publication. She also discussed how the form of a
        publication somehow reflects particular conditions in which it’s been
        produced, leaning on a text by Ann Komaromi on Samizdat production.
        <sup>
          <a href="#annotation-13">13</a>
        </sup>{" "}
        Samizdat are a cheap, machine-typed copy of a text produced from the 50s
        to the 90s in Russia, and which were defined by the mode of production,
        which is confidential, homemade, and shared by a clandestine mode of
        distribution. The text argues that the form reflects—and will become
        durably associated with—the oppositional political stance of its
        authors, their fragility a reflection of the precarious production
        conditions in which they were made. Following a similar logic, the zine
        was understood by students as a reflection of the particular conditions
        of isolation in which it was produced. This cohort wanted something that
        clearly centered confinement and the emotional needs that the pandemic
        generated. It really was about sharing a meal together, and that’s part
        of the title of that publication:{" "}
        <em>A Joyful Feast. Craft in Moments of Pause</em>.
      </p>
      <p>
        <span className="speaker">NGW:</span> The other part of Sara’s teaching
        is connected <NavLink to="/notaretreat/clugage">to foods</NavLink>,
        meals, and conviviality...{" "}
      </p>

      <p>
        <span className="speaker">BL:</span> You have made space for
        conviviality in the program in a very intentional way. Spending time
        together was seen as central to building companionship and collegiality
        between faculty and students. Your cultural background and inclinations,
        and my passion for food, made the practice of being together a
        relatively simple and highly present thing.{" "}
      </p>

      <p>
        The third publication, for which Kate Hawes served as student editor,
        was conceived of as a table of offerings and as a very generous, very
        open space where a number of people who had marked the students were
        invited to pitch in. The invitation was not just to consider food, but
        also the notion of “pause”—that we should be mindful not to work all the
        time, but also make sure that we tend to one another or to ourselves …
        to nourish one another physically and also intellectually … To take a
        break and to see whether craft or how craft was present in both moments
        of activity and moments of breaks.
      </p>

      <p>
        <span className="speaker">NGW:</span> There’s also a cultural
        familiarity with the zine from student experiences in the US. The zine
        format can also be framed in terms of punk culture, DIY communities,
        and, of course, Riot Grrrl. We talked about the accessible and familiar
        presence of Kinko’s and photocopy shops. We have a population of
        students who is very familiar with this format and has been for years.
      </p>

      <p>
        <span className="speaker">BL:</span> A few things could be said to make
        the link between “stance” and form a little clearer: this publication
        doesn’t have a table of contents. It doesn’t have pagination. If the
        first publication was an exercise in over-mapping, this one is quite the
        opposite. It feels very loose: you wade your way through the
        publication, not knowing where you’re going to end up. There’s also a
        series of “asides,” short-form texts that describe “missed connections”:
        a groovy grab bag of research ideas that haven’t quite found a form yet,
        based on personal ads from Craigslist and other free publications in the
        US. They are one of the aspects of this publication that I like most. So
        I designed them as if there were personal ads: they have that quality
        of, you know, the quickly written and quickly consumed. That is very
        nice to do. There are perhaps 50 of them spread out and scattered
        throughout the publication, and announced in early pages as major
        events. I borrowed this strategy of announcing a page from a Monty
        Python publication where they keep on announcing “page 72” as if it was
        the Holy Grail. And then of course when you turn to page 72, the only
        thing it has is the text “page 72.” I love the self-aware absurdity of
        the publication constantly communicating about itself ...{" "}
      </p>
      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_11.10_spread.jpg"}
        />
        <figcaption>
          A spread from <em>A Joyful Feast—Craft in Moments of Pause</em>, the
          third student publication, September 2022. Riso printing on matte
          paper, 36 pages, 7” x 10” (folded). The spread features an example of
          “missed connections” at lower right, a title set in potato font, and
          one of Joni Van Bockel’s illustrations.
        </figcaption>
      </figure>

      <p>
        For the design, I was briefed by two of the students—Kate Hawes and
        Joanna Weiss—and by Sara, who showed me, among other things, an image
        that captured the idea of feast in a particular way: an ancient Roman
        mosaic floor showing the leftovers from a meal—leaves, picked bones,
        empty shellfish. The good move that we made here is to decide to hire
        Joni Van Bockel, an ex-student originally from this cohort who elected
        to take a leave of absence from the program, to develop a series of
        illustrations for the zine. We arrived together at the idea that small
        critters and creatures would populate this publication’s pages ... A
        spider in high heels ended up being the mascot of the publication: it
        steals some lines of text on one page, smokes a cigarette on another.
        That brought a whole side of humanity and playfulness to the
        publication, which worked well with the imperfect messiness of Riso
        printing. Every title was hand-drawn, trying to do justice to whatever
        was being discussed in the piece. I also made a potato-font system: this
        faulty form of mark-making spoke the language of low-tech reproduction
        and was coherent with the food theme of the publication … This, like
        many other details in the publication, fails repeatedly to achieve a
        perfect reproduction.
      </p>

      <p>
        The idea was that the students would understand how making a publication
        invites a set of choices, that it is never about dumping text onto a
        page, and it is always about choosing how each page needs to look for{" "}
        <em>that</em> text. In other words, choosing a tight, hand-held format
        for the first one, postcards for the second, a smudgy Riso-printed
        magazine format for the third, and a hyperlinked digital archive for the
        fourth are very deliberate choices that have to do with the goal that
        the students set themselves.
      </p>

      <p>
        The first one wanted to establish academic credibility. The second was a
        reaction against that—and expresses a wanting to bring into the craft
        fold people who are usually not part of that craft history. The third
        was a reflection or reaction to confinement and the emotional toll it
        took: it turned the publication into an invitation to come and be
        together, feast together, eat together.{" "}
      </p>

      <p>
        You actively promoted this freedom. And I remember a conversation we had
        during the first residency, asking ourselves, “will it be okay if these
        publications do not look the same?” And I remember your response: “we
        could do that, but that’s more work. Are you okay with that?”
      </p>

      <p>
        <span className="speaker">NGW:</span> Yes, and although we understood
        the beauty and simpler aspects of serialization, we didn’t want that.
        Letting every publication be different allowed for the students to
        really go from concept to finished form, and really have room to express
        knowledge in a way that matched where they were in time and space. It
        made for a different kind of teaching experience, and a lot more work
        for you!
      </p>
      <h2>The MACR Papers</h2>
      <p>
        <span className="speaker">NGW:</span> When the provost cancelled our
        program, I remember you encouraging me to see this as an opportunity.
        The final one, the one where this conversation will be hosted, follows
        the same approach: from the very beginning, you said if we are doing
        something different, we should think about what had happened with Black
        Mountain College, and the question of documenting pedagogical experiment
        as it happens. You asked, “What if our final publication is something
        that actually looks back on the five years of the program?” Which
        sounded great, um, until we launched into the longest, protracted ending
        of a job situation ever (shared laughter).
      </p>

      <p>
        I do remember us having some debate and discussion about how many people
        to invite and I wanted to invite everyone and then realized that there
        probably are some people who didn’t need to be invited. And you also
        pointed out that there’s only so much we can actually realistically
        accomplish. We still have ended up with 20-some contributions.
      </p>

      <p>
        <span className="speaker">BL:</span> Should we invite everyone? This is
        a really interesting editorial issue. To be open, and to be fair to
        everyone, is very much what you’re about. But I also have defended in
        Mat Lab what we said a moment ago: an editorial project is a choice. We
        wanted to be deliberate about the sort of people who we felt would be
        invested in critically reflecting on what they’d done in this program.
        And we ended up sending our prospective contributors a
        Miranda-July-and-Harold-Fletcher-type series of playful prompts that set
        no limits to what they could do. I personally was moved by this idea
        that I encountered in the work of feminist scholar Françoise Colin: to
        produce “a heritage without a testament.”
        <sup>
          <a href="#annotation-14">14</a>
        </sup>{" "}
        And you were very clear about this as well: you didn’t want this
        publication to be a “how to remake” this program. You didn’t want it to
        be a roadmap to future program directors. But you wanted it to reflect
        on and give a glimpse of what <em>did</em> happen. And that is very much
        what we have.
      </p>
      <figure>
        <img
          src={
            process.env.PUBLIC_URL +
            "/img/notaretreat/B_N_11.11_reading_joyful2.jpg"
          }
        />
        <figcaption>
          Students from the last two cohorts reading the third publication
          during the summer 2022 residency. (Left to right) Miriam Devlin,
          Joanna Weiss, Kae Lorentz, Kate Hawes, Beryl Perrron-Feller and Jill
          DiMassimo. Photo: Ben Lignel
        </figcaption>
      </figure>
      <p>
        <span className="speaker">NGW:</span> Yes, and I think we’ve done good
        work of opening the space for lots of different contributions. It’s been
        hard for me to write and finish this publication because it’s hard to
        feel that the project is ending. You know and I know it doesn’t end here
        and now because all of us will go off and do other things, and many of
        us will stay connected. But I feel there was a lot more work that we
        could have done. There’s just way much more to do.{" "}
      </p>

      <p>
        And about my really, really not wanting it to function as an
        instructional guide: this was something that came up early in the
        program. In the fall of 2018, right after we’d launched the program, I
        co-led a session at the Shared Ground symposium on{" "}
        <em>unsettling coloniality</em> with L. Vinebaum, who taught a workshop
        in the program the following January.
        <sup>
          <a href="#annotation-15">15</a>
        </sup>{" "}
        After the closing reception, Bernie Herman pulled me aside and said that
        I needed to give people a clear idea of what to do, what steps to take.
        That they needed to understand how to make change. And I thought to
        myself: no. There isn’t a checklist where people can mark things off, no
        box to tick that says you’ve done it. It’s a process, and people need to
        work to change and understand the conditions where they are. I’m glad
        that it’s not going to be that kind of publication. It is open and
        people can meander through it and find something for themselves from any
        of the edited publications here. It’s not didactic. It’s not
        prescriptive. I feel that these publications answer what Bernie was
        pointing to by demonstrating the work itself.{" "}
      </p>

      <p>
        Let’s return to this question about “culmination.” The publication marks
        an end to the 2023 cohort’s two years of coursework. But this and none
        of these publications ends at graduation and the final closure. The
        publications are distributed. They’re sold; they are resold. They are
        gifted to other people. Including this website we’re finishing. That’s
        going to be the trace that most people will know and understand as the
        program …
      </p>

      <p>
        <span className="speaker">BL:</span> Someone could just consume them for
        the work that [they contain]. But my sense is that as time passes and as
        we get farther and farther away from the moment of production, these
        publications will stand as testament to conditions of their production,
        as opposed to testament to specific thinking. Future readers will
        understand them as a product of a very particular environment that
        allowed them to exist. They are, you know, they are the product of this
        program, and I don’t think they could have been the product of any other
        program.{" "}
      </p>

      <p>
        Just wanted to also acknowledge the fact that these are publications
        that are tied to classroom activities. To a large extent, the production
        of these publications, even though done with a readership in mind, is
        really about thinking as we’re doing. And so it is a means for the
        students to articulate their own position, articulate their own views,
        understand how they fit in conversations. They are developing their own
        knowledge through these publications, before they’re developing anyone
        else’s, and only showing readers a very small part of much wider
        research that spans their Practicum, the publication, course work, and
        many, many pages of unpublished material. Mellanee’s postcards are a
        good example of that. Looking at the publication, she’s got three
        postcards to show for her research. But you and I know she has a book in
        her from her Practicum. Many of them do.{" "}
      </p>

      <hr />

      <cite>
        <sup id="annotation-1">1.</sup> See Hito Steyerl, “In Defense of the
        Poor Image,” e-flux journal 10 (November 2009), retrieved May 10, 2023,{" "}
        <a
          href="https://www.e-flux.com/journal/10/61362/in-defense-of-the-poor-image/"
          target="_blank"
          rel="noreferrer"
        >
          https://www.e-flux.com/journal/10/61362/in-defense-of-the-poor-image/
        </a>
        . Both Sara Clugage and alejandro acierto taught this essay in the
        program.
      </cite>
      <cite>
        <sup id="annotation-2">2.</sup> See, in particular, Amber Newman, “Troll
        Palayan: Clara Balaguer on Design, Decolonization, and Trolling
        Duterte,” in <em>Publishing as Practice</em> (Los Angeles: Inventory
        Press and Ulises, 2021).
      </cite>
      <cite>
        <sup id="annotation-3">3.</sup> The publication’s main colors were black
        and blue—the latter to evoke, throughout, architectural plans, and plant
        the notion of a pedagogical blueprint in the reader’s mind.
      </cite>
      <cite>
        <sup id="annotation-4">4.</sup> Program coordinator Dani Burke helped us
        identify three areas in downtown Asheville. Group one (Mike Hatch, matt
        lambert, and Sam Rastetter) examined a specific block on which several
        craft-connected lifestyle stores were located, including East Fork
        Pottery’s first downtown sales location and an antique mall. This group
        identified different kinds of craft in stores and re-arranged the sides
        of each block into a dinner party that reflected their analysis of
        gender and identity in each store. Group two (Phoenix Booth, Nick
        Falduto, and Sarah Kelly) visited a section of Eagle Street, the
        historically Black neighborhood undergoing rapid gentrification. This
        group recognized spaces that ranged from a long-standing barber shop to
        a place selling Himalayan salt, but found their interactions with craft
        to be fleeting and momentary. To match this, they shared their findings
        in the form of a Prezi presentation. Group three (Darrah Bowden, Matt
        Haugh, and Kat St. Aubin) examined one of the tourist-focused blocks
        stretching from the now-removed Vance Memorial, past the Asheville Art
        Museum, and down to a café across from the Aloft Hotel. This area
        included galleries and fine craft shops—and felt, to the group, like a
        façade for the city. They chose to represent their findings in this
        image that shows the flatness of a city’s presentation of craft for
        consumption and red-lining via a sedimentary examination that revealed
        Eagle Street’s location behind this public façade.
      </cite>
      <cite>
        <sup id="annotation-5">5.</sup> Georges Perec, “Think/Classify,” in{" "}
        <em>Species of Spaces and Other Pieces</em> (London: Penguin
        Twentieth-Century Classics, 1997).
      </cite>
      <cite>
        <sup id="annotation-6">6.</sup> Georges Perec,{" "}
        <em>An Attempt at Exhausting a Place in Paris</em> (Cambridge: Wakefield
        Press, 2010).
      </cite>
      <cite>
        <sup id="annotation-7">7.</sup> Linda Tuhiwai Smith,{" "}
        <em>Decolonizing Methodologies: Research and Indigenous Peoples</em>{" "}
        (London and New York: Zed Books, 1999).
      </cite>
      <cite>
        <sup id="annotation-8">8.</sup> Francis Ponge, “My Creative Method,”
        trans. Beverley Bie Brahic, originally published in full in{" "}
        <em>Sonofabook</em> (London: CB Editions, Spring 2015).
      </cite>
      <cite>
        <sup id="annotation-9">9.</sup> See{" "}
        <a
          href="https://blackcraftspeople.org"
          target="_blank"
          rel="noreferrer"
        >
          https://blackcraftspeople.org
        </a>
        .
      </cite>
      <cite>
        <sup id="annotation-10">10.</sup> Saidiya Hartman, “Venus in Two Acts,”{" "}
        <em>Small Axe</em> 26 (June 2028): 1–14.
      </cite>
      <cite>
        <sup id="annotation-11">11.</sup> Epeli Hau’Ofa, “Pasts to Remember,” in{" "}
        <em>We Are the Ocean: Selected Works</em> (Honolulu: University of
        Hawai’i Press, 2008), 60–79.
      </cite>
      <cite>
        <sup id="annotation-12">12.</sup> Steven Kurutz, “What We Learned from a
        Year of Crafting,” <em>The New York Times</em>, May 13, 2021, retrieved
        May 10, 2023,{" "}
        <a
          href="https://www.nytimes.com/2021/05/13/style/craft-boom.html"
          target="_blank"
          rel="noreferrer"
        >
          https://www.nytimes.com/2021/05/13/style/craft-boom.html
        </a>
        . The shirt was featured in an article in the <em>New York Times</em>,
        and exhibited in <em>Community Spread: How We Faced a Pandemic</em>, at
        the Wing Luke Museum of Asian Pacific American Experience, Seattle,
        Washington, May 2021–May 2022.
      </cite>
      <cite>
        <sup id="annotation-13">13.</sup> Ann Komaromi, “The Material Existence
        of Soviet Samizdat,” <em>Slavic Review</em> 63 no. 3 (Autumn 2004):
        597–618.
      </cite>
      <cite>
        <sup id="annotation-14">14.</sup> Françoise Colin,{" "}
        <em>Un Héritage sans Testament</em> (Montréal: Éditions du Remue-Ménage,
        2020).
      </cite>
      <cite>
        <sup id="annotation-15">15.</sup> To learn about the 2018 Shared Ground
        symposium, co-organized by Bard College, The Center for Craft, and
        Museum of Arts and Design, NYC, visit{" "}
        <a
          href="https://www.centerforcraft.org/research-initiatives/shared-ground#conference-recap"
          target="_blank"
          rel="noreferrer"
        >
          https://www.centerforcraft.org/research-initiatives/shared-ground#conference-recap
        </a>
        . For a direct link to the session with L. Vinebaum and Namita Gupta
        Wiggers, visit{" "}
        <a
          href="https://www.youtube.com/watch?v=AUGI7tbE3fI"
          target="_blank"
          rel="noreferrer"
        >
          https://www.youtube.com/watch?v=AUGI7tbE3fI
        </a>
        . To download the “unsettling coloniality: a critical and radical
        fiber/textile bibliography,” created by Aram Han Sifuentes, L. Vinebaum,
        and Namita Gupta Wiggers, visit:{" "}
        <a
          href="http://www.criticalcraftforum.com/unsettling-coloniality-a-critical-and-radical-fibertextile-bibliography"
          target="_blank"
          rel="noreferrer"
        >
          http://www.criticalcraftforum.com/unsettling-coloniality-a-critical-and-radical-fibertextile-bibliography
        </a>
        . All links retrieved May 10, 2023.
      </cite>
    </div>
  );
};

export default LignelWiggers;
