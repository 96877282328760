const Aufhebung = () => {
  return (
    <div>
      <p>
        “The broken vessel is not transformed into ‘art’ because of the
        destruction of function, but rather that it remains within the realm of
        craft while being ascribed conceptual meaning. The German term{" "}
        <em>aufhebung</em> relates to Hegelian philosophy, describing ‘the
        process by which the conflict between two opposed or contrasting things
        [...] is resolved by the emergence of a new idea, which both preserves
        and transcends them,’ becomes useful in understanding the duality of
        destruction and creation (‘<em>Aufhebung</em>’). Translated as sublation
        in English, the term articulates the process by which the vessel’s
        previous form is negated, while still maintaining and preserving an
        element of the previous state within the new synthesis (‘Sublate’).”
        <sup>
          <a href="#annotation-1">1</a>
        </sup>
      </p>
      <h2>Further Readings</h2>
      <ul className="bibliography">
        <li>
          Wallace, Lucia Ilene. “Drop My Heart like an Urn; Dissolve My Skin
          like It’s Clay: Dichotomies of Destruction & Creation; the Material &
          the Intangible.” MA thesis, Ontario College of Art & Design
          University, 2021.
        </li>
      </ul>
      <hr />
      <cite>
        <sup id="annotation-1">1.</sup> Lucia Ilene Wallace, “Drop My Heart like
        an Urn; Dissolve My Skin like It’s Clay: Dichotomies of Destruction &
        Creation; the Material & the Intangible” (MA thesis, Ontario College of
        Art & Design University, 2021), 11.
      </cite>
    </div>
  );
};

export default Aufhebung;
