import React from "react";

const EditorNote = () => {
  return (
    <div>
      <blockquote>
        <strong>Black Workers</strong> <br />
        The bees work
        <br />
        Their work is taken from them. <br />
        We are like the bees&mdash; <br />
        But it won't last <br />
        Forever. <br />
        &mdash;Langston Hughes
      </blockquote>

      <p>
        Our inspiration for this publication begins with the rich and compelling
        scholarship about Black craftwork and Black craftspeople in America
        generated by the students and alumni of the Master of Arts in Critical
        Craft Studies (MACR) program at Warren Wilson College. As students in
        the MACR program, we saw innovative thought, writing, and enactive
        research around Black craft that we believe can stand as a body of work.{" "}
        <em>Reclaiming the Center: Making Way for Black Craft</em>{" "}
        <a href="#citation-1">
          <sup>1</sup>
        </a>{" "}
        reflects on the learning environment and pedagogical design of the MACR
        program, and celebrates the thinking that precedes and proceeds from it.
        Within this publication, we gathered a series of short essays,
        interviews, and illustrations centering Black craftwork and Black
        craftspeople, engaging students, faculty, and visiting scholars to draw
        forth a conversation of voices and experiences.
      </p>

      <p>
        In researching Black craft histories, these contributors use expansive
        research methods to subvert the colonizing structures of
        institutionalized archives and conventional data-retrieval systems that
        often erase the presence of Black craftspeople. Such methods might
        result in picking up clay on-site and adding water in hand to understand
        an old practice, mining oral histories, or using modern tools to
        re-create historical furniture. Within this publication, we have paired
        contributions into discrete sections to highlight both the ways these
        scholars have addressed the complexities encountered when researching
        Black craft, and the ensuing scholarship that emerged from these ways of
        thinking.
      </p>

      <p>
        To that end, we have organized these contributions into five sections
        called Approach, Absence, Presence, Materials, and Making. The first
        section, Approach, sets the stage for the study of Black craft in and
        around the MACR program, with interviews of the program's founder,
        Namita Gupta Wiggers, and History and Theory 1 professor Dr. Tiffany
        Momon. In the subsequent four sections, we have included writing,
        reflections, and photographs from scholars organized by their processes:
        rethinking archival conventions, mapping unconsidered connections,
        exploring the role of potential and imagination, and using making as a
        methodology.
      </p>

      <p>
        In assembling this compilation of work, we are endeavoring to re-surface
        lost histories and push back on the white-biased archives through a lens
        of Black craft. The critical craft scholars—teachers, thinkers, and
        makers—in this publication bring light to archival silences perpetuated
        by anti-Blackness and use craft as a way to examine lived experiences of
        makers known and no longer known. To put this writing and thought
        together is to recognize the overlapping and interwoven threads of the
        histories of Black craft and craftspeople, histories which clearly and
        absolutely need to be brought to the forefront of consciousness in craft
        as a whole. We hope the work within this publication will encourage
        other craft scholars to consider these approaches, research this
        important aspect of craft studies, and continue the growing scholarship
        on Black craft history.
      </p>
      <hr />
      <cite>
        <sup id="citation-1">1.</sup> Title influenced by{" "}
        <em>Feminist Theory: From Margin to Center</em>, by bell hooks.
      </cite>
    </div>
  );
};

export default EditorNote;
