const Meissner = () => {
  return (
    <div>
      <figure>
        <div className="three-by-two">
          <img
            src={
              process.env.PUBLIC_URL +
              "/img/notaretreat/B_N_2_NeedleThreading-1.jpg"
            }
            alt="Threading a needle 1"
          />
          <img
            src={
              process.env.PUBLIC_URL +
              "/img/notaretreat/B_N_2_NeedleThreading-2.jpg"
            }
            alt="Threading a needle 2"
          />
          <img
            src={
              process.env.PUBLIC_URL +
              "/img/notaretreat/B_N_2_NeedleThreading-3.jpg"
            }
            alt="Threading a needle 3"
          />
          <img
            src={
              process.env.PUBLIC_URL +
              "/img/notaretreat/B_N_2_NeedleThreading-4.jpg"
            }
            alt="Threading a needle 4"
          />
          <img
            src={
              process.env.PUBLIC_URL +
              "/img/notaretreat/B_N_2_NeedleThreading-5.jpg"
            }
            alt="Threading a needle 5"
          />
          <img
            src={
              process.env.PUBLIC_URL +
              "/img/notaretreat/B_N_2_NeedleThreading-6.jpg"
            }
            alt="Threading a needle 6"
          />
        </div>
        <figcaption>
          Fig.1-6: (1) How to thread a needle. (2) Fold the yarn over the needle
          eye. (3) Slip it off and pinch it tightly between your fingers. (4)
          While pinched, meet the folded fibers to the eye. (5) Push the fibers
          through. (6) Magic and smart. Photos courtesy of Amy Meissner.
        </figcaption>
      </figure>

      <p>
        Learn to thread a needle. Not the sucked-on-acrylic-yarn way, not the
        frayed-strands-of-cotton-floss way, but the magic, smart way: fold the
        yarn over the needle edge, slip it off, and pinch hard between finger
        pads, then force this dent of fibers through the eye. Learn from your
        mother how materials bend, how when you touch materials and tools, they
        also touch you. Learn how your hands move without your mind telling them
        to. Also magic.
      </p>

      <p>
        Learn you’ll have to sew with the blue plastic needle for now; you’d
        rather use the needle your mother flashes beside you: slim, narrow,
        quick. Learn these needles become lost, but it’s heroic to search on all
        fours with her under the coffee table with spread fingers, especially if
        you’re the one to find it first. Once, you found the silver needle
        upright, there, alongside an older <em>forgotten</em> needle burrowed
        into the carpet, waiting for a foot. Learn you’ll spend a lot of time
        losing, finding, and threading needles. Learn how a needle dropped on
        kitchen linoleum bounces and pings. Easier to find.
      </p>

      <p>
        Learn to love the jar of buttons you have permission to dump and sort by
        size, then by color, then by size again. Learn this cascade of
        potential. Learn to palm a loose button from Heartz’s Fabric store. Get
        caught and promise you’ll never steal anything ever again.
      </p>

      <p>
        Learn your mother’s golden bird scissors are serious business. They
        only, ONLY, rest on the table in front of you, never in your lap, never
        between the two of you on the couch. Learn the story of the embroidering
        woman in the Swedish farm kitchen, someone your mother knew—maybe an
        aunt or a neighbor–—who left the window bench to refill her coffee, then
        returned to the thin cushion where someone had left a pair of golden
        bird scissors. Learn how she sat on them because they weren’t on the
        table, and because of this mistake they sliced her bottom.{" "}
      </p>

      <blockquote className="indented">
        <p>
          <em>Did she need a doctor? </em>
          <br />
          This wasn’t part of the story.
        </p>
      </blockquote>

      <p>
        Learn, years later, about the Shinto belief that objects contain spirits
        who act out when discarded or improperly cared for. But for now, it’s
        enough to know the golden screw that is this bird’s clever eye is
        watching you, while the rest of its blade-beak and handle-legs slowly
        spin around it.
      </p>

      <p>
        Learn, at seven, how 5/8 of an inch is an important fraction; visualize
        it in the form of a seam allowance on a tissue pattern, then on cloth’s
        cut edge. Learn clothing patterns are exciting to choose in the fabric
        store, maddening to unfold (worse to re-fold and re-insert into the
        original envelope), and result in garments looking … <em>homemade</em>.
        Learn fitting a body is harder than pushing the pedal of your mother’s
        Viking Husqvarna to the floor and sewing as fast as you can. Learn the
        latter takes minutes to learn, the former, decades.
      </p>

      <p>
        Learn to roll thread with your index finger and thumb to make a strong
        knot. Learn, at seventeen while beading wedding gowns during a high
        school internship in a tiny shop, that doubled thread in the messiest,
        thickest knot will hold those fake pearls. Maybe forever. Learn this
        because all the beadwork your boss’s mother-in-law completes will have
        to be redone since her knots are too fine and slip through the satin
        weave. After her husband drives her home from these twice-a-week visits
        and the dresses are hoisted from the table, pearls slide from her
        single-thread strands and bounce (and ping) off the concrete floor.
        Learn it’s now your job to repair the mother-in-law’s beadwork in her
        absence. This is your free labor on top of her free labor. Learn you
        need to keep this a secret from the mother-in-law, but at seventeen this
        is difficult because you feel superior.{" "}
      </p>

      <blockquote className="indented">
        <p>
          <em>You. Just. Can’t. Keep. Quiet. About. It. </em>
        </p>
      </blockquote>

      <p>
        Learn now the mother-in-law has hurt feelings and won’t be helping in
        the shop for a while. Learn then that people in their 70s deserve to
        feel needed and valued.
      </p>

      <p>
        Learn there isn’t <em>one</em> way to sew on plastic pearls, or alter a
        dress, or repair a seam, or finish a garment on the inside, or make a
        pattern, or fit a dress, or sew on a label. Learn about occupation,
        expectation, efficiency, failure. Learn there are many ways to care for
        humans, more than one way to feel shame.
      </p>

      <p>Learn ripping out seams is 50% of the sewing process.</p>

      <p>
        Learn a 3/8-inch seam allowance equals a centimeter, and at 22, you’ve
        moved to Canada so now you finally learn the metric system and
        understand what your European mother living in the United States has
        been complaining about all this time. A 5/8-inch seam allowance is for
        home sewers, and since you’ve entered factory production you may never
        sew that way again. Learn to call yourself a “sample sewer” or a “sample
        cutter,” not a “seamstress,” and then explain to friends outside the
        fashion industry what these things mean without using the word
        “hierarchy.” Eventually, you’ll call yourself a “patternmaker,” then a
        “designer,” and use the word “promotion” with friends, which sounds
        better. You don’t mention how much you miss the sewing.
      </p>

      <p>
        Learn that in Canada, they don’t call it “seam ripping,” they call it
        “unpicking.” Probably because this also sounds better.
      </p>

      <p>
        Learn about getting paid under the table because you don’t have work
        authorization in another country. Learn about the precarity of this
        along with co-workers from Vietnam and India and Laos and mainland
        China. Learn how the garment cutter from Hong Kong has status, though:
        gender, profession, place of origin, authorization paperwork, attitude.
        Learn when everyone calls out “<em>Sifu!</em>” as he swaggers in, it
        means “Master” in Cantonese. Learn when he smacks the stack of spread
        fabric on the long table with the wooden yardstick it’s to force the
        fibers to settle before he begins his cut.
      </p>

      <p>Learn about bounced paychecks. </p>

      <p>
        Learn right away there is no need to tiptoe to your boss about this (a
        33-year-old woman with zero business experience, in way over her head)
        and apologize for the inconvenience, learn her surprise is feigned,
        learn she knew when she wrote those checks they would bounce, learn her
        tears are real, but not real. Learn the garment cutter’s paychecks also
        bounce. Learn from him how to run an industrial cutting machine even
        though it’s not his job to teach you, in fact teaching you jeopardizes
        his own position.{" "}
      </p>

      <p>
        Learn you never want to be a 33-year-old woman with zero business
        experience, in way over her head.
      </p>

      <p>
        Learn to expect a bounced paycheck. Learn to phone the bank and ask if
        there’s enough money in the account on payday, then slip out the door
        with your sweaty check. Learn the sick secret of draining the account
        first before the others. Learn this is the privilege of language, of
        whiteness, of country of origin, of (finally) work authorization, of the
        mobility of the patternmaker in heels standing at a table spinning
        tagboard slopers and curved rulers instead of tethered to a sewing
        machine beside a pile of unfinished garments.
      </p>

      <p>
        Learn to quit. <br />
        Learn to get a new job. <br />
        Learn to get a different new job.
        <br />
      </p>

      <p>
        Learn to drape a silk <em>peau de soie</em> wedding gown on a mannequin.
        Learn to bone a corset. Learn to cut a silk crepe bias slip dress. Learn
        to hand baste. Learn to sew a French seam. Learn to hand appliqué lace.
        Learn to ease a sleeve cap. Learn to steam wool. Learn to fit the
        client’s body, learn to fit her mind. Learn how a custom-made corset
        hidden inside a gown can shrink a 30-inch waist to a 26. Learn to not
        hesitate when cutting into the $500-per-meter silk charmeuse printed
        with giant peacocks. Learn—when your boss whispers, “If it’s not the
        last piece of this fabric in the world, just cut the fucking fabric”—to
        cut the fucking fabric.
      </p>

      <p>
        Learn someone’s money will always buy more fabric. Learn hesitation
        leads to fear. Learn to save silk scraps and secret them home. Learn
        pulling from the trash isn’t stealing.
      </p>

      <p>
        Learn from the tailor in the atelier how if you accidentally drop
        scissors on the floor this means somewhere in the world a tailor has
        died.
      </p>

      <p>
        Learn a few clients assume you and your boss are having an affair
        because you are only 26, 27, 28, 29 and can’t possibly have the skill
        set to cut, sew, fit, finish all these custom-made wedding gowns,
        especially in the month of June. Learn he never corrects this
        assumption. Learn grooming and mentoring aren’t the same, but each takes
        years. Learn you don’t have the language for this, and you won’t for
        almost three decades. Learn some part of you still blames yourself for
        not understanding what was happening and for not knowing how to leave
        sooner.{" "}
      </p>

      <p>
        Learn to quit a career you loved, have a different career, raise
        children, sit on latent skills. Never unlearn the metric system even
        though you’ve returned to the United States.
      </p>

      <p>
        Learn your children are quick with needles. Learn to give them sharp
        tools. Hammers. Boxes of nails. Learn to pull a needle out of someone’s
        bare heel. Learn to patch the torn knees of insulated snow pants. Learn
        to darn holes in expensive wool kid’s socks. Learn to launder and repair
        rotten-smelling winter gloves. Learn to extend cuffs on sleeves and hems
        when they get too short. Learn children will come to you with holes for
        the mending pile. Learn five-year-olds deserve to feel needed and
        valued. Mend their things invisibly to prove you still know how.
      </p>

      <p>
        Learn to stop thinking about who you could have been had you not left
        the career you loved. Learn to adapt, sew differently, for different
        reasons, for different people.
      </p>

      <p>
        Learn (suddenly) you don’t know the difference between Art and Craft.{" "}
      </p>

      <blockquote className="indented">
        <p>
          <em>Why don’t you know this? </em>
        </p>
      </blockquote>

      <p>
        Realize this gap while listening to some quilters squabble over museums
        and galleries, how quilts should be just as important as paintings, but
        never touch on how we are taught skills, or who teaches us, or how, or
        what we teach others. Learn they want to talk Art. Learn you want to
        talk materials and tools and technique and why and how and who and where
        and when this business between Art versus Craft all started anyway.
        Learn you are sick of hierarchies.
      </p>

      <p>
        Learn academic books are expensive when Googling “craft scholars,” so
        you buy used. Assume you’ll learn from an authoritative voice in print,
        but learn instead from a voice penciled into the margins:
      </p>

      <blockquote className="indented">
        <p>
          <em>
            Hilariously overthought.
            <br />
            Hahahahaha, $, status, social hierarchy, are you kidding?
            <br />
            Please define.
            <br />
            Does craft want to be accepted/validated, as modern art has, or is
            it comfortable living in opposition to?
            <br />
            Is he using the subordination/inferiority/devaluation of craft as a
            social commentary, pointing out that we devalue
            context/relationship, usefulness, texture/materiality, skill,
            pastoral, amateur?
            <br />
            Please define.
            <br />
            Please define.
            <br />
            Really? That’s it?
            <br />
            Is this his goal?
            <br />
            Interesting premises but how did this book lead us there?
          </em>
        </p>
      </blockquote>

      <p>
        Learn, after finishing this craft scholar’s book, you’re still unsure
        how to define Craft but have discovered many pencil drawings of pots on
        the blank end pages. Learn you’d rather learn from the book’s previous
        owner who fills margins and throws pots. Learn you’re interested in
        finding more of these voices who make, question, and define or re-define
        craft, while occupying unnoticed spaces, determined.
      </p>

      <p>
        (Learn, at 49, you were accepted into a graduate program where such a
        thing could happen).
      </p>

      <p>
        Learn the value of your own craft history: needles, thread, sewing,
        cutting, repairing, re-making, bending/feeling/recognizing the limits
        and potentiality of materials. Learn the value of your own voice, of
        questioning the authoritative voice in books, of asking who gets to
        decide what goes there and for whom. Learn how to read a book with a
        pencil in your hand.{" "}
      </p>

      <p>
        Remember first learnings: when you touched tools and materials, and how
        they touched you back.
      </p>

      <p>Epilogue</p>

      <p>
        Learn, a few years later, as a visiting artist teaching the Craft of
        Repair to English Language Learners how nervous you feel as a teacher.
        Learn high school students, no matter what country they’re from, will
        act like it’s cool, but not cool, to present answers to your assigned
        group questions:{" "}
      </p>

      <blockquote className="indented">
        <p>
          <em>
            Describe a time when you saw something being repaired. If you’ve
            never seen someone repairing, describe how you wish you could have
            repaired something. What is the difference between a woven and a
            knit fabric? How is each made? What are the differences between a
            needle, a pin, and a safety pin? When might you use each tool?
          </em>
        </p>
      </blockquote>

      <p>
        Learn from two Ukrainian boys in all-black, who haven’t been in the
        United States very long, that someone taught them pins are talismans.
        One touches his collar:{" "}
        <em>
          They keep you safe from evil when you hide them in your clothing
        </em>
        .{" "}
      </p>

      <p>
        Learn, when teaching, it’s difficult to recover when someone else’s raw
        history catches in your throat. Learn, later that night, as you feel a
        scratch across your cheek when pulling off your sweater, that you’ve
        forgotten the classroom pin you burrowed into its neckline. A lame
        solidarity.
      </p>

      <p>
        Learn you need to alter the curriculum because these students want to
        “feel silk” once you explained it comes from a worm. Learn to let their
        curiosity lead and bring in every piece of silk from your studio,
        including scraps saved from the trash decades earlier. Learn how
        squeamish they become—even boys in all-black—when you pass around three
        intact silk cocoons, and they feel the rattle of dried worms inside.
        Cool, but not cool.
      </p>

      <p>
        Learn they <em>really</em> want to know how to sew on buttons, so you
        haul in the jar and spill buttons on a table for them to paw, sort,
        choose. Learn you secretly wish they’d steal some because you have so
        many.
      </p>

      <p>
        Learn when students suck on the end of their thread and aim for the
        needle in midair with one eye closed, there is an important starting
        point to teach, something magic and smart.
      </p>

      <blockquote className="indented">
        <p>
          <em>
            Fold the thread over the needle edge, slip it off, pinch it hard,
            then push the dented fibers through the eye. <br />
            Yes, this will work. <br />
            I promise.
            <br />
            Try again.
          </em>
        </p>
      </blockquote>

      <p>
        Teach them how materials bend, how if they do the same thing over and
        over, eventually their fingers and hands will move without their minds
        telling them to. Teach them to notice when the tools and materials touch
        them back.
      </p>

      <p>Learn to teach them this.</p>

      <h2>Further Readings</h2>
      <ul className="bibliography">
        <li>
          Alison, Jane.{" "}
          <em>Meander, Spiral, Explode: Design and Pattern in Narrative</em>.
          New York: Catapult, 2019.
        </li>
        <li>
          Puig de La Bellacasa, Maria.{" "}
          <em>Matters of Care: Speculative Ethics in More Than Human Worlds</em>
          . Minneapolis: University of Minnesota Press, 2017.
        </li>
        <li>
          Shor, Ira, and Paulo Freire.{" "}
          <em>
            A Pedagogy for Liberation: Dialogues on Transforming Education
          </em>
          . London: MacMillan Education Group, 1987.
        </li>
      </ul>
      <hr />
    </div>
  );
};

export default Meissner;
