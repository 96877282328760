import { NavLink } from "react-router-dom";

const Clugage = () => {
  return (
    <div>
      <p>
        Mention any kind of making to someone in the MACR program and they’ll
        try to find a way to claim it as a craft. “Craft” here is a loose and
        inclusive word, applicable to any practice that involves time,
        attention, commitment, and skill. The generosity of the term extends to
        craft cocktails and the writer’s craft, crafting community and crafting
        character. Thinking broadly about craft allows us to bring in people and
        ways of making that have historically been excluded from craft studies.
        When we see craft in something, we’ve spotted a practice that requires
        attention, autonomous decision-making, and the exercise of technique
        that has been learned over time. There are plenty of tried-and-true
        craft places in this program: we talk about woodshops, forges, looms,
        potter’s wheels, and hot shops. But we’ve learned still more about the
        cultures and skills we find in those locations by adding analogous
        cultures from other workshops, including the{" "}
        <NavLink to="/notaretreat/razdan">kitchen</NavLink>. In this program, we
        have whipped up whole menus of skills, investigations, and meditations
        by cooking together.{" "}
      </p>
      <blockquote className="indented">
        <p>
          <em>
            We make dinner. Jessie procures a bunch of local fish and some
            fragrant banana leaves that are so enormous Ben decides to wash them
            in the shower. After giving us a glassblowing lesson in the hot
            shop, Mike fires up the outdoor furnace and starts heating some
            glass for cooking. We experiment with making steam packets, banana
            leaves overlapped around little stacks of delicate fish. How many
            leaf layers? Should the fish be skin-side up or down? (Up.) With
            packets assembled and laid out on a steel table, Mike and Jen gather
            glass on pontils and let it glop down on the fish. It steams and
            hisses. The first packet burns a bit but we make adjustments to the
            timing. They work out a system of keeping the rapidly cooling glass
            moving over the packets to ensure even coverage—no one knows what
            this new culinary technique is yet but we start calling it
            “mopping.”{" "}
          </em>
        </p>
      </blockquote>
      <p>
        When we claim the kitchen as a site for craft practice, we attribute to
        the cook the same kind of skill that we attribute to the potter or the
        weaver—deft manual manipulation, deep familiarity with materials, and a
        capacity for creative response. In her examination of contemporary home
        cooks in the US, Amy Trubek notes that cooking, when approached as the
        acquisition of embodied knowledge, is a craft: “cooking with craft can
        involve the acquisition of a skillful practice learned by repetition and
        the education of attention.”
        <sup>
          <a href="#annotation-1">1</a>
        </sup>{" "}
        In the MACR program, we have learned through the practical, active work
        of cooking. That work has been bolstered by reading and thinking about
        what we eat and how we exist in relationship to food—a relationship that
        shifts as we consume and create.{" "}
      </p>
      <blockquote className="indented">
        <p>
          <em>
            Two teams made caponata, a dish with many “traditional” variations
            that originates from southern Italy. The teams had recipes of
            varying specificity and one of those recipes was in Italian. Each
            team judged what ingredients to add (soy sauce?) or leave out
            (capers, definitely) to make their own synthetic recipe. What
            ingredients would agree with each other? How soft should the
            eggplant be once sautéed? They tried it out. When both versions were
            ready, a distinguished panel of judges (Tom and Jen) critiqued the
            dishes according to the relative sweetness of the agrodolce, the
            size and shape of the chopped ingredients, the texture of the
            eggplant, and the overall vibe. Once a verdict was rendered, both
            teams had to pack their knives and go (upstairs to bed).
          </em>
        </p>
      </blockquote>
      <p>
        When we say we learn by cooking, we mean more than learning technique,
        we mean <em>ideas</em>. In the language of the Western epistemological
        tradition, we could say that cooking involves the combination of thought
        and feeling. It is an intentional practice that aims to accomplish a
        desired goal with appropriate execution of skill, and it requires both
        theory and practice. The cook has to observe and respond to their
        materials with conscious deliberation, making adjustments and thinking
        ahead to next steps. This kind of “thoughtful practice” has been
        articulated by a number of writers, from John Dewey to Glenn Adamson.
        Adamson’s <em>Thinking Through Craft</em> opens up the question of craft
        as a thoughtful practice by turning to contemporary artists who make
        “conceptual” or “abstract” work with craft materials. His interpretation
        of these artists through a craft lens draws attention to their
        handicraft methods, destabilizing the balance between headwork and
        handwork. This shift allows us to think about handwork as cognitive, to
        “elevate” it to a conceptual level. Here, the focus is on the
        integration of our mental work with manual work through two parts of our
        bodies, our heads and our hands. It’s a necessary move in healing the
        mind/body divide of Western thought, even if it is not a sufficient one.
        Surely there is more to our bodies than just heads and hands?
      </p>
      <blockquote className="indented">
        <p>
          <em>
            We raise our glasses. We’re tucked inside the garden cabin on the
            Warren Wilson College farm on a warm evening, and everyone has been
            given a glass with a bit of peachy-mauve jelly at the bottom. (Ben
            serves from two trays and cheekily asks guests if they would like
            “vegetarian or Norwegian,” even though all the jelly is tomato
            flavored.) Shannon and I top the jelly with smoked vodka. All
            assembled, Kat raises her glass to lead the toast, arm extended far
            above her head—and freezes. We all stand in silence, our own arms
            raised high and expectant, thinking this can’t be right, can it? But
            it lasts so long our minds wander. We start thinking everywhere.
          </em>
        </p>
      </blockquote>
      <p>
        By refusing the definition of manual work as non-mental work, craft
        thinkers upset a long history of the mind/body divide that goes as far
        back in Western thought as Plato. In this tradition, the mind inquires
        into eternal truths (from a distance) while the body practices living
        (close at hand). The purity of mental contemplation is held above the
        messy, driving appetites of transient life. In a taxonomy that has
        haunted us ever since, Plato used these mind/body categories in{" "}
        <em>Republic</em> to differentiate between, on the one hand, art and
        governance, and, on the other hand, trades or crafts. In Plato’s craft
        practice, we moderate our bodily sensations through reason, gaining by
        virtue of the combination a virtuoso skill—a “knack” for doing
        something. This kind of knowledge is valuable to society but not to be
        confused with the higher knowledge held by philosophers or rulers. As
        food scholar Lisa M. Heldke comments on knowledge in Plato’s
        formulation, “Bodily knowledge in general, if such an expression is not
        simply a misnomer, can only be a lower, craft-like form of knowledge,
        subservient to the genuine knowledge of the rational soul.”
        <sup>
          <a href="#annotation-2">2</a>
        </sup>{" "}
        Importantly, Plato’s model citizens are highly specialized, trained to
        do one thing and discarding the rest of human activities as too much
        distraction. This means that those with bodily knowledge are forever
        under the authority of those with rational knowledge. In{" "}
        <em>Republic</em>, a cook cooks, and a governor governs. The potential
        for class struggle gleams from the cracks in this social structure. C.
        L. R. James, though he found much to admire in ancient Greece’s direct
        democracy,
        <sup>
          <a href="#annotation-3">3</a>
        </sup>{" "}
        often called for proletariat revolution with the adage “every cook can
        govern”
        <sup>
          <a href="#annotation-4">4</a>
        </sup>{" "}
        —every worker, even the most forgotten and powerless, can participate in
        statecraft.{" "}
      </p>
      <blockquote className="indented">
        <p>
          <em>
            Over Zoom, we make the most common dish in the world: grain and
            sauce. Everyone gets a list of proposed sauce ingredients in several
            categories and a rough schedule for adding them to a skillet.
            Alliums first (garlic, onion, shallot) in fat (olive oil, vegetable
            oil, butter). Then cured meats. Spices next, to bloom. Any pastes or
            milks, then a deglazing agent. Fresh meats or vegetables to finish,
            then apply the sauce to your cooked grain of choice. As we cook, we
            hold our pots up to the camera and describe our decision-making
            processes, what we know about our ingredients, and what we don’t
            know.
          </em>
        </p>
      </blockquote>
      <p>
        In cooking, our appetites and our reason inform each other. Should any
        of Plato’s governors have taken to the kitchen, they might have found
        cooks know quite well how to mix elements harmoniously. For us as
        individuals, as well as for the body politic of Plato’s{" "}
        <em>Republic</em>, it is our task to moderate and govern ourselves. We
        rule our appetites through reason. By the Enlightenment era, David Hume
        had sensed a problem with this dynamic and suggested inverting the
        terms: in <em>Treatise on Human Understanding</em>, he claimed that it
        isn’t reason that rules the appetites but the appetites that rule
        reason. He reasoned that we cannot start from reason alone, or we risk
        thinking of ourselves as set apart from the world—on the contrary, we
        need to experience the world in order to know it. Even though we start
        our investigations down in the world, rational thought is still, for
        Hume, the highest purpose of human life; the direction of knowledge has
        changed but the hierarchy remains the same.
      </p>
      <p>
        One of the issues with separating thought from practice, even if the
        terms are inverted, is that the capacity for thought has not been
        historically apportioned to all human beings equally. Hume, like other
        Western philosophers, devised a definition of “human” that was
        explicitly racialized, reserving reason and speech (headwork) for
        European people alone. In his essay “Of National Characters,” he claims
        that in Jamaica, for example, a Black man may be considered learned but
        in fact he only parrots speech.
        <sup>
          <a href="#annotation-5">5</a>
        </sup>{" "}
        Zakiyyah Iman Jackson, analyzing this notorious passage, describes the
        degrees of humanity meted out by race: “For Hume, in the case of African
        rationality, it was either deficient or negligible. Therefore, the
        humanity of the Negro ‘species of men’ was acknowledged, but in a
        hierarchical and taxonomical frame.”
        <sup>
          <a href="#annotation-6">6</a>
        </sup>{" "}
        It is clear from Western intellectual history, from Plato to Hume, that
        the way we have considered what it is to be human has been influenced by
        the transatlantic slave trade and the expropriation of non-European
        people as laborers. Those who have been forced to labor with their hands
        have been imagined, by European thinkers, to not be capable of thought.{" "}
      </p>
      <blockquote className="indented">
        <p>
          <em>
            We read a story by Italo Calvino in which two tourists in Mexico,
            the narrator and his wife, only relate to each other through sharing
            meals. In “Under the Jaguar Sun,” the two characters consume
            everything: chiles en nogada, history, culture, Mexico itself, and
            each other. Their appetites are awakened by new tastes and
            experiences. They do not seem to question their right to have those
            experiences, although the reader might. We make chiles en nogada and
            talk about the tourist gaze. When we, in Black Mountain, North
            Carolina, study a recipe made famous in Puebla, Mexico, what do we
            learn? What do we consume?{" "}
          </em>
        </p>
      </blockquote>
      <p>
        What to do, then, with this brutal history bearing us down under its
        weight? It is not enough to simply flip the hierarchy between head and
        hand. It may not yet be enough to integrate the head and hand into
        “thoughtful practice.” The language of this craft-like way of knowing
        preserves the frame of the structure it claims to dismantle: head and
        hand, still conceived of as separate locations, simply meet midway. And
        even if the hierarchy between head and hand begins to seem less clear
        through craft work, the head and hand are still set firmly above the
        abdominal wall. Our guts, our bowels: the complex and messy humanity in
        our lower bodies that remain abstracted from other kinds of craft work
        come sharply into focus through cooking. All of our philosophical
        efforts to separate thinking from doing seem less central to our human
        experience when we think from the position of the stomach.{" "}
      </p>
      <p>
        This has important implications for how we think of manual work when it
        is kitchen work or service work—while craft criticism has long
        “elevated” the handwork of professional potters and weavers to an art
        form, it has had less to say about the handwork of line cooks and
        domestic servants. That elision is at least partly a consequence of a
        long history of Western thought that sets both head and hand against the
        lower body and assigns that kind of bodily knowledge along lines of race
        and class. Kyla Wazana Tompkins, tracking the history of food thinking
        in US history, explains: “The idea of puritanism and the American life
        of letters rests on the absenting of the lower body, which is projected
        onto people of color and working-class people, who then, of course, as
        the American middle class emerges, become the people doing food labor.”
        <sup>
          <a href="#annotation-7">7</a>
        </sup>{" "}
        When we consider cooking as a craft practice, we value working
        cooks—people working in restaurants, operating street carts, and
        managing school cafeterias—as thinkers.
      </p>

      <blockquote className="indented">
        <p>
          <em>
            We go to the farmers market. We are all buying ingredients for a
            dinner that has yet to be conceptualized, and so each of us needs to
            think about what looks good to us but also what looks good to the
            rest of the group—this is going to have to come together somehow. In
            this iterative task, we will have to respond to how the cook before
            us has added to or altered the dish with our own improvisation.
            While at the market, we ask the vendors about their work. Do they
            own the farm they’re representing? Do they work at the farm as well
            as at the market? What is it like to make what they make? Jill asks
            a cheesemonger about his process, and he says cheesemaking is mostly
            doing dishes.
          </em>
        </p>
      </blockquote>

      <p>
        In cooking, it is impossible to preserve a hierarchy of our reason and
        appetites—we think and we taste, we stir and we consider and we taste
        again. We craft things and then we absorb that material into our own
        bodies, or we give it to others who absorb it into their own. It becomes
        difficult to separate the making subject from the object it makes or the
        other people who use it. In many craft practices, close (physically
        close as well as attentively close) observation and familiarity gained
        over time diminishes the distance between subject and object. Cooking
        collapses that distance entirely. When we cook, we get to “know” a food,
        the way a dough stretches or a pepper slips out of its charred skin.
        When we eat, we learn to enjoy some foods over time, with familiarity.
        We know to avoid other foods that don’t agree with us. Anthropologist
        and food scholar Annemarie Mol writes: “Here is the lesson for{" "}
        <em>knowing</em>: engaging perceptively with an object, while at the
        same time valuing it, may change the subject.”
        <sup>
          <a href="#annotation-8">8</a>
        </sup>{" "}
        The goal of food-making is to make the objects that will become our own
        bodies, changing us materially as well as perceptually. As humans, we
        know the world through incorporating the world into our selves.{" "}
      </p>

      <blockquote className="indented">
        <p>
          <em>
            On our last night of a January residency, we finally unwrap the pork
            loins that we set to cure on the first night of residency. They have
            been rubbed with salt and spices and a little coffee and tightly
            trussed in clean napkins, then left to swing in front of an open
            window in a stairwell (the stairwell has become, intentionally and
            regrettably, freezing cold). Ben cuts slices and hands them out to
            taste. We are all separate people, always to some extent unknown to
            each other. But eating the same thing shifts the boundary between
            us. The people who cured the pork loins made something, but now we
            are all making ourselves with what they made. Our disconnection,
            which has been dissolving over time, is now dissolving in our
            stomachs.
          </em>
        </p>
      </blockquote>

      <p>
        Cooking doubles down on the lessons and possibilities of craft practice;
        it makes us work with and for our own bodies, together. In the MACR
        program, we have valued cooking as a way to think and a way to be with
        each other. We value things and the people who make them—especially
        those people who have not had their value recognized. By moving our
        focus on handwork not just up to the head but down to the stomach, we
        embrace our appetites and the knowledge they can bring us. Cooking
        teaches us what cooks know: we live together in the world, and to know
        the world is to be changed by it.{" "}
      </p>

      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_7.1_shooting.jpg"}
        />
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_7.2_Tasting.jpg"}
        />
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_7.3_laying.jpg"}
        />
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_7.4_closing.jpg"}
        />
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_7.5_eating.jpg"}
        />
        <figcaption>
          Figure 1-5. Scenes from the “shadow classes” conducted by Sara Clugage
          and Ben Lignel during the Summer 2022 and Spring 2023 residencies.
          Featured here: gestures for making jelly, testing Sicilian caponata
          ingredients, prepping for trout <em>au verre fondu</em>, encasing a
          pit-roasted pineapple in clay … and tasting one of the results in a
          faux-juried event. Photo: Ben Lignel
        </figcaption>
      </figure>

      <hr />

      <cite>
        <sup id="annotation-1">1.</sup> Amy B. Trubek,{" "}
        <em>Making Modern Meals: How Americans Cook Today</em> (Oakland:
        University of California Press, 2017), 156.
      </cite>

      <cite>
        <sup id="annotation-2">2.</sup> Lisa M. Heldke, “Foodmaking as a
        Thoughtful Practice,” in{" "}
        <em>Cooking, Eating, Thinking: Transformative Philosophies of Food</em>,
        ed. Deane W. Curtin and Lisa M. Heldke (Bloomington: Indiana University
        Press, 1992), 211.
      </cite>

      <cite>
        <sup id="annotation-3">3.</sup> C. L. R. James, “Every Cook Can Govern,”{" "}
        <em>Correspondence</em> 2, no. 12 (June 1956),{" "}
        <a
          href="https://www.marxists.org/archive/james-clr/works/1956/06/every-cook.htm"
          target="_blank"
          rel="noreferrer"
        >
          https://www.marxists.org/archive/james-clr/works/1956/06/every-cook.htm
        </a>
        .
      </cite>

      <cite>
        <sup id="annotation-4">4.</sup> Matthew Quest, “‘Every Cook Can Govern’:
        Direct Democracy, Workers’ Self Management & the Creative Foundations of
        C. L. R. James’ Political Thought,” <em>The CLR James Journal</em> 19,
        no. 1/2 (2013): 374.{" "}
        <a
          href="https://doi.org/10.5840/clrjames2013191/218"
          target="_blank"
          rel="noreferrer"
        >
          https://doi.org/10.5840/clrjames2013191/218
        </a>
        .
      </cite>

      <cite>
        <sup id="annotation-5">5.</sup> David Hume, “Of National Characters,” in{" "}
        <em>Essays, Moral and Political</em> (1758),{" "}
        <a
          href="https://davidhume.org/texts/empl1/nc"
          target="_blank"
          rel="noreferrer"
        >
          https://davidhume.org/texts/empl1/nc
        </a>
      </cite>

      <cite>
        <sup id="annotation-6">6.</sup> Zakiyyah Iman Jackson,{" "}
        <em>Becoming Human: Matter and Meaning in an Antiblack World</em> (New
        York: New York University Press, 2020), 24.{" "}
      </cite>

      <cite>
        <sup id="annotation-7">7.</sup> Katherine Snell, “An Interview with Kyla
        Wazana Tompkins,” <em>Columbia Journal of Literary Criticism</em> 13,
        no. 1 (2015), 53.
      </cite>

      <cite>
        <sup id="annotation-8">8.</sup> Annemarie Mol, <em>Eating in Theory</em>{" "}
        (Durham, NC: Duke University Press, 2021), 65.
      </cite>
    </div>
  );
};

export default Clugage;
