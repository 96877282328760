const Helgeson = () => {
  return (
    <div>
      <figure>
        <img
          src={
            process.env.PUBLIC_URL +
            "/img/notaretreat/B_N_16_Helgeson_WhatLightKnows.jpeg"
          }
        />
        <figcaption>
          Anna Helgeson, <em>Looking at Nothing aka What Light Knows</em>, 2023
          <br />
          Digital image
        </figcaption>
      </figure>

      <p>
        There was a time before nothing; before humans had a word or a symbol to
        represent absence. The form 0 (zero) starts to appear in Mesopotamia
        some 5,000 years ago In the seventh century AD, the Indian mathematician
        Brahmagupta conceived of the idea of zero having no value at all, a
        concept referred to as “sunya” (History 2014). Significantly, when
        Brahmagupta introduced the concept, it was not described as a terrifying
        void (a common conception of nothingness). Instead it was described as a
        welcome state: a release of ego, desires, and attachments.{" "}
      </p>

      <p>
        For five years the first and only Master’s of Art in Critical Craft
        Studies (MACR) thrived in the middle of nowhere (aka in Swannanoa, North
        Carolina, and in the virtual “nowhere” of online learning) while its
        students produced nothing, that is, no things: no objects to speak of.
        This one-of-a-kind program was not a program for makers, although many
        makers came. Students were not learning how to build better pots or
        weave complicated textiles—rather they were questioning, debating,
        thinking, writing, curating, and reading, all activities that produce no
        tangible objects but instead create ideas and deepen our conversations
        about craft.
      </p>

      <p>
        As this program full of people making nothing in the middle of nowhere
        prepares to disappear, it may seem that there is nothing to celebrate.
        However I suggest we instead celebrate nothing.
      </p>

      <p>
        In the words stitched together below I will make a case for the central
        role of nothing and nowhere in craft; how nothing is often overlooked
        but is essential to the form, activation, and subversive power of craft.{" "}
      </p>
      <h2>Craft Is Nothing</h2>
      <p>
        Many craft objects are designed to be nothing—placeholders, useful only
        because of what they hold space for. Clothing, vessels, shoes,
        furniture, and baskets are all examples of this.
      </p>

      <p>
        In <em>A Theory of Craft: Function and Aesthetic Expression</em>, Howard
        Risatti reminds us that many craft objects hold space for the body:
      </p>

      <blockquote className="indented">
        <p>
          Because craft objects are by their very nature intended to be
          physiologically functional, they are objects made for the body and
          bodily “actions”; therefore they must accommodate the body and be
          somatically oriented … if baskets are too large to hold in the hands
          in front of the body their girth must fit between hip and hand, the
          cover must echo the rectangular shape of the body, the armrest must
          accommodate the arm, and the seat and splat must accommodate the
          buttocks and back. Objects such as these must be somatically oriented
          in the way they materialize the space in and around the body, the
          space between the arm and hip, around the seated body, etc. In this
          way they must retain, in their form, what is essentially a negative
          imprint of the human body. (Risatti 2007)
        </p>
      </blockquote>

      <p>
        Clothing too needs to be physiologically functional and hold space for
        this negative imprint of the body. In order to create a fitted pair of
        pants a seamstress must first gain intimate knowledge of the wearer’s
        physical particulars—the girth of the thigh, roundness of the hip,
        length from crotch to ankle. When finished, these particulars form a
        negative shape of a body. The form of the garment is shaped by this
        void. When the clothing is not on the body (which is most of the time)
        the void is a placeholder, necessary for the garment to function.
      </p>

      <p>
        The void in this case acts as the memory of a specific human form and
        matters at least as much, if not more, than the thing itself.{" "}
      </p>
      <h2>Craft Is No Thing</h2>
      <p>
        When we encounter craft objects in the world, whether in a museum or a
        kitchen, we are often encouraged to see them as isolated, still things.
        Most museums display objects that are extracted from a meaningful
        network of other objects, people, places, and actions. Mainstream
        “western” thought is built on this idea of the isolated thing, the
        categorizable object.{" "}
      </p>

      <p>
        However, craft lives in networks and in action: pouring, covering,
        holding, scooping. The ceramic mug I use every morning for my first cup
        of coffee is functional because of the action it performs—moving coffee
        from the coffee pot to my mouth. If it could not perform this action it
        would be of no use and its significance in my life would alter. It is
        the motion, the relationship to my hand, my mouth, and my nourishment,
        that makes it a cup.{" "}
      </p>

      <p>
        In the first decade of the 21st century, a flurry of exhibitions and
        publications aligned craft and performance art, encouraging renewed
        conversations about crafts’ no-thing-ness. Exhibitions such as{" "}
        <em>Hand+MADE: The Performative Impulse in Art and Craft</em>, at the
        Contemporary Arts Museum Houston (2010); <em>Common Threads</em>, at the
        Illingworth Kerr Gallery, in Calgary, Alberta (2008); and{" "}
        <em>Gestures of Resistance</em>, at the Museum of Contemporary Craft, in
        Portland, Oregon (2010), all situate making, exchange, and interaction
        as the central focus.{" "}
      </p>

      <p>
        This shift from thing to action is clearly articulated by Nicole Burisch
        in "From Objects to Actions and Back Again: The Politics of
        Dematerialized Craft and Performance Documentation".
      </p>
      <blockquote className="indented">
        <p>
          … the works under consideration here rethink the central role of the
          crafted object to privilege performance or live actions, and provide
          an opportunity to think through the possibilities and consequences of
          a dematerialized craft practice. (Burisch 2016)
        </p>
      </blockquote>

      <p>
        A dematerialized craft practice also raises questions about who and what
        craft is for. As much as craft can exist in the act of using, it also
        exists as an act of making.
      </p>

      <p>
        Poet and potter M. C. Richards made it clear in her book{" "}
        <em>Centering: In Pottery, Poetry, and the Person</em> that the most
        significant impact a pot has is on its maker, not its user. The act of
        making was in many ways a spiritual act for Richards, a way to connect
        to the world, to the matter of our earth (clay), to a single moment
        demanding full attention.
      </p>

      <p>
        In L<em>ive Form: Women, Ceramics, and Community</em>, Jenni Sorkin
        states it this way:
      </p>

      <blockquote className="indented">
        <p>
          Richard’s conceptualization of ceramics as a fully communal and
          performance-based practice drives a wedge between the functional
          versus sculptural debates, in that she embraces functionalism in order
          to focus centrally on process and, in doing so, wrote an influential
          metaphysical treatise on pottery. (Sorkin 2016)
        </p>
      </blockquote>

      <p>
        If what matters amounts to nothing, no tangible object, how then do we
        value craft? This shift from thing to action, from product to process,
        and from isolated singularities to networks of possibility, has
        potentially radical implications in a society built on material
        capitalism. Standard modes of operation that value efficiency,
        perfection, and obsolescence cease to make sense.
      </p>

      <h2>Craft Is Nowhere</h2>
      <p>
        Utopian visions haunt contemporary craft. The term utopia is derived
        from the Greek <em>οὐ</em> (“not”) and <em>τόπος</em> (“place”)
        (Wikipedia n.d.), an impossibility, a place un-like any known place.
        Some of this haunting can be traced to the enthusiastic social reform
        embedded in the ideals of the Arts & Crafts movement during the late
        nineteenth century. William Morris and his co-conspirators conceived of
        craft as an antidote to industry. In his bizarre science fiction book{" "}
        <em>Notes from Nowhere</em>, Morris imagines twenty-second-century
        England as a socialist utopia. In this imaginary time and place there
        are no factories to make clothes, no coal mines to fuel machines.
        Anything people want they must make by hand and they do so willingly and
        with great care and contentment. In this fictional utopia there are no
        hierarchies among jobs, and all people, regardless of gender, race, or
        background, create what they want, when they want it.{" "}
      </p>

      <p>
        The idea that one could literally make their own version of reality,
        step outside the flow of time, outside the oppressions of “modern life,”
        became one of craft’s most appealing promises. This longing for nowhere
        has inspired the creation of myriad craftcentric communities, schools,
        and organizations including, famously: Charles Robert Ashbee’s Guild and
        School of Handicraft, founded in 1888, in Chipping Campden, England
        (Chipping Campden History Society n.d.); Elbert Hubbard’s Roycroft,
        founded in 1897, in the village of East Aurora, New York (Roycroft
        Campus n.d.); and Booker T. Washington’s Tuskegee Institute, founded in
        1881, in Macon County, Georgia (Tuskegee University n.d.). While the
        mission and desired outcomes for each of these institutions were
        distinct, they all placed the transformative power of craft, informed by
        the utopian ideas of the Arts & Crafts principles, at the center of
        their ideology.
      </p>

      <p>
        Another way to be nowhere is to be nowhere in particular, to embrace a
        transient way of life, thus avoiding the confines of property ownership
        and the pressures of participating in heteronormative institutions.
        Being “crafty,” or having the skills to make useful things for oneself
        so as to not be dependent on fixed institutions and commercial outlets,
        is essential to this way of life. The work of queer textile artist LJ
        Roberts calls attention to the DIY culture of transient queers in their
        work entitled{" "}
        <em>VanDykesTransDykesTransVanTransGrandmxDykesTransAmDentalDamDamn</em>{" "}
        (2014–2020). The work was inspired by a transient collective of lesbians
        and non-monogamous separatists who traveled around the United States in
        the late 1970s in their own tricked-out DIY conversion vans; many of
        them took on the surname Van Dyke.
      </p>

      <p>
        In an article entitled{" "}
        <em>Survival of the Knittest: Craft and Queer-Feminist Worldmaking</em>,
        written for the feminist journal MAI, Daniel Fountain describes the work
        this way:
      </p>
      <blockquote className="indented">
        <p>
          Within the work, Roberts pays homage to pioneering lesbian, queer, and
          transgender histories and themes of nomadism, landlessness, movement,
          and identity, by drawing influence from so-called conversion van
          culture.{" "}
        </p>

        <p>
          Their work serves as an important reminder of the role that crafting
          has for many members of the queer community—beyond heteronormative and
          cisnormative concepts of home and family. (Fountain 2021)
        </p>
      </blockquote>
      <p>
        In the case of the queer nomads represented in Roberts’s work, creating
        utopia was a matter of survival, a way to thrive in the margins and a
        testament to the subversive power of being nowhere.
      </p>

      <p>
        In a world where most “somewhere” and “somebodies” are creations of the
        oppressive fantasies of wealthy, straight, white men, it is indeed a joy
        to be part of a community crafting a future full of nothing and nowhere.{" "}
      </p>
      <h2>Works Cited</h2>
      <ul className="bibliography">
        <li>
          Burisch, Nicole. 2016. “From Objects to Actions and Back Again: The
          Politics of Dematerialized Craft and Performance Documentation.”{" "}
          <em>TEXTILE</em> 14 (1): 54–73.
        </li>
        <li>
          Chipping Campden History Society. n.d. “C R Ashbee and The Guild of
          Handicraft.” Accessed March 18, 2023.{" "}
          <a
            href="https://www.chippingcampdenhistory.org.uk/content/history/people-2/arts_artists_and_craftspeople/c_r_ashbee"
            target="_blank"
            rel="noreferrer"
          >
            https://www.chippingcampdenhistory.org.uk/content/history/people-2/arts_artists_and_craftspeople/c_r_ashbee
          </a>
          .
        </li>

        <li>
          Fountain, Daniel. 2021. "Survival of the Knittest: Craft and
          Queer-Feminist Worldmaking" MAI: Feminism & Visual Culture. December
          13, 2021. Accessed March 8, 2023.{" "}
          <a
            href="https://maifeminism.com/survival-of-the-knittest-craft-and-queer-feminist-worldmaking/"
            target="_blank"
            rel="noreferrer"
          >
            https://maifeminism.com/survival-of-the-knittest-craft-and-queer-feminist-worldmaking/
          </a>
          .
        </li>

        <li>
          History. 2014. “Who Invented the Zero?” January 22, 2014. Accessed
          February 7, 2023.{" "}
          <a
            href="https://www.history.com/news/who-invented-the-zero"
            target="_blank"
            rel="noreferrer"
          >
            https://www.history.com/news/who-invented-the-zero
          </a>
          .
        </li>

        <li>
          Risatti, Howard. 2007.{" "}
          <em>A Theory of Craft: Function and Aesthetic Expression</em>. Chapel
          Hill: The University of North Carolina Press.
        </li>

        <li>
          Roycroft Campus. n.d. “History.” Accessed March 18, 2023.{" "}
          <a
            href="https://www.roycroftcampuscorporation.com/learn/history/"
            target="_blank"
            rel="noreferrer"
          >
            https://www.roycroftcampuscorporation.com/learn/history/
          </a>
          .{" "}
        </li>

        <li>
          Sorkin, Jenni. 2016.{" "}
          <em>Live Form: Women, Ceramics, and Community</em>. Chicago:
          University of Chicago Press.
        </li>

        <li>
          Tuskegee University. n.d. “History of Tuskegee University.” Accessed
          March 18, 2023.{" "}
          <a
            href="https://www.tuskegee.edu/about-us/history-and-mission"
            target="_blank"
            rel="noreferrer"
          >
            https://www.tuskegee.edu/about-us/history-and-mission
          </a>
          .
        </li>

        <li>
          Wikipedia. n.d. “Utopia.” Accessed February 10, 2023.{" "}
          <a
            href="https://en.wikipedia.org/wiki/Utopia"
            target="_blank"
            rel="noreferrer"
          >
            https://en.wikipedia.org/wiki/Utopia
          </a>
          .
        </li>
      </ul>
      <h2>Further Readings</h2>
      <ul className="bibliography">
        <li>
          Adamson, Glenn. <em>Craft: An American History</em>. New York:
          Bloomsbury, 2021.
        </li>

        <li>
          Black, Anthea and Nicole Burisch.{" "}
          <em>The New Politics of the Handmade</em>. London: Bloomsbury Visual
          Arts, 2021.
        </li>

        <li>
          Chitham, Karl, Kolokesa Māhina-Tuai, and Damian Skinner.{" "}
          <em>
            Crafting Aoteara: A Cultural History of Making in New Zealand and
            the Wider Moana Oceania
          </em>
          . Wellington, New Zealand: Te Papa Press, 2019.
        </li>

        <li>
          Wikipedia. n.d.a. “Jacquard machine.” Accessed February 13, 2023.{" "}
          <a
            href="https://en.wikipedia.org/wiki/Jacquard_machine"
            target="_blank"
            rel="noreferrer"
          >
            https://en.wikipedia.org/wiki/Jacquard_machine
          </a>{" "}
        </li>
      </ul>
      <hr />
    </div>
  );
};

export default Helgeson;
