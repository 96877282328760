import { NavLink } from "react-router-dom";

const Fisher = () => {
  return (
    <div>
      <p>
        I thought about this question a lot when I taught for a semester for the
        MA in Critical Craft Studies program at Warren Wilson College. Indeed,
        it was a question built into the fabric of the program itself. I want to
        share a reflection with you, but at the end of this essay I want to hear
        from you. I want to know the names of writers and thinkers in the craft
        sphere whose work deserves greater readership and circulation. It is my
        contention that craft is a historiographically rich and exciting
        landscape, but one whose breadth and depth is yet to be fully reflected
        in the scholarship and popular writing that circulates most dominantly
        in craft discourse. For some writers and ideas, circulating outside the
        pull of a centrifugal force is the point—but for others, they desire a
        multivocal, reciprocal ecosystem of craft writing sustained by
        communities and institutions.{" "}
      </p>

      <p>
        In February 2023, I was lucky enough to walk through the First Americans
        Museum (FAM), in Oklahoma City, with Dr. heather ahtone, a revered
        scholar of Indigenous art and culture and the museum’s director of
        curatorial affairs. In an hour and a half listening to her, I learned
        more about Indigenous craft history in the United States than I had in
        the whole of my lifetime. In the weeks since, I have continued to learn
        from her books, which I picked up in the museum store. When Dr. ahtone
        left me after her generous tour, it was in front of a text placed at the
        collection galleries exit that asks visitors to consider the works and
        installations they have just experienced in light of who gets to write
        history. I stood in front of it for a long time, reading and re-reading
        it.{" "}
      </p>

      <p>
        Thanking those who pass it for their visit, the silkscreened notice
        states plainly:
      </p>

      <p>
        <em>History has more than one truth.</em>
      </p>

      <p>
        <em>
          More often than not, history is written by a dominant culture. Their
          stories become known as “truth.” Our First American truths consider
          our beliefs and our perspectives. They are often in contrast with what
          the majority of us were taught in school and see in popular culture.{" "}
        </em>
      </p>

      <p>
        <em>
          We share our truths to expand what is remembered as history. We hope
          your experiences here offer new insights and new perspectives … we
          honor you for your time and leave you with many blessings. We look
          forward to <NavLink to="/notaretreat/keith">your return</NavLink>.{" "}
        </em>
      </p>

      <p>
        At FAM, the collective stories of 39 distinctive tribal nations are
        shared. Only a few were actually indigenous to what is now known as the
        state of Oklahoma. Most were removed from their homelands across the
        contiguous United States, from as far away as what is now California and
        Florida and Pennsylvania. They were removed as part of a systematic
        erasure and genocide, and corralled in “Indian Territory.” Today, the
        museum serves as a rich center of art and education that highlights
        these 39 unique cultures and their contributions to the world—past and
        present—as well as the resilience of the First American nations in
        Oklahoma now. The interpretive text above struck me as particularly
        powerful, a stark lesson on the deliberate suppression and erasure of
        culture on the one hand, and yet a generous farewell that included a
        warm invitation to return.
      </p>

      <p>
        I have since thought often about the multiple truths of history, and
        which get platformed—and which do not. Four years ago, if you’d asked me
        about craft history—including histories and contemporary accounts of
        Indigenous makers in the United States—I would have had a hard time
        understanding what one might mean by “craft history.” It’s not that I
        didn’t know craft, per se. I grew up in Scotland, where my neighbors
        farmed, knitted, worked at textile factories, sewed, and blew glass,
        among many other professional and creative pursuits. However, even
        through three degrees in art history, I had never heard the term “studio
        craft” uttered. Nor had my university classes closely considered the
        processes, materials, or theories of any practice remotely resembling
        craft in any wider sense. But then, in 2019, I was offered my current
        job at the Museum of Fine Arts, in Boston, where I look after
        “contemporary decorative arts”—in other words, a collection of studio
        craft augmented by works by contemporary makers whose work exists far
        beyond that movement-based descriptor. Less than six months after I was
        hired, the pandemic began. Without access to the museum and the works in
        storage that I was meant to steward, I bought every book I could on
        modern and contemporary craft, and I studied. I also contacted everyone
        who had held my job at the museum before me, going back more than 50
        years, and asked them how they built their knowledge around craft.
        Shortly after that my brilliant colleague, Dr. Marina Tyquiengco, joined
        the MFA to steward contemporary Indigenous art and craft. Then, over the
        last year and a half I have traveled across the United States via
        Amtrak, visiting places of craft education and meeting with hundreds of
        makers in all 48 contiguous states for a project called{" "}
        <em>Craft Schools</em>.{" "}
      </p>

      <p>
        Craft history, I found, could be expansive. It could also be very
        narrow, or nowhere at all, depending on where one looked. My
        cross-country train journey turned up a polyphonic history of craft that
        spans cultures and aesthetics and motivations, as well as community
        centers, homes, accredited schools, reservations, and rural enclaves. In
        the hundreds of conversations on craft that I was fortunate enough to
        have, people located their own knowledge discovery in disparate people
        and places—family members, neighbors, high-school classrooms, and
        hedgerows. In academic and museum settings, I found that craft writers
        and historians often descend from a much more circumscribed set of
        sources.
      </p>

      <p>
        At one point there was a list on the Critical Craft Forum—an online
        forum founded in 2008 for peer exchange of scholarship and ideas in
        craft—that was open-source and highlighted names of great craft writers.
        It was a place to consult when folks were building tables of contents
        for edited books, sourcing review writers for magazines and journals,
        and showing new generations of students who and what to read in the
        craft sphere. (I have a list like it for excellent graphic designers,
        which I often share, too.) In the absence of this CCF register, which
        has been lost in the warren of the World Wide Web, I itched for the
        start of a new list.
      </p>

      <p>
        I am grateful to have read work by esteemed and rigorous craft writers,
        including Rachael Arauz, Jenni Sorkin, Namita Gupta Wiggers, Glenn
        Adamson, Shannon Stratton, Sara Clugage, Diana Greenwold, Elizabeth
        Essner, Jeannine Falino, Ned Cooke, and Elisabeth Agro. And I am excited
        about emerging names like my wonderful MFA colleague Kendall DeBoer and
        the former Museum of Arts and Design curator Angelik Vizcarrondo-Laboy.
        History can be transmitted powerfully by spoken word, too. Helen Drutt
        at Haystack talking about her career across six decades and Alison
        Croney Moses speaking on racial equity in craft stick with me, as does
        every studio visit I was lucky enough to have during the{" "}
        <em>Craft Schools</em> journeys.{" "}
      </p>

      <p>
        Despite its richness and broadness, craft history is still a nascent
        scholarly and museological field. If we are to cultivate a broad field
        of craft, to heed the FAM wall text in its provocation to expand what is
        remembered as history, we need to work hard to listen out for
        storytellers across time and place.{" "}
      </p>

      <figure>
        <img
          src={
            process.env.PUBLIC_URL +
            "/img/notaretreat/B_N_21_Michelle_dr_ahtone.JPG"
          }
        />
        <figcaption>
          Picture of Dr. heather ahtone’s book, <em>Okla Homma</em>, which is a
          companion to the permanent collection galleries at the First Americans
          Museum in Oklahoma City. Photo: Michelle Millar Fisher
        </figcaption>
      </figure>

      <p>
        In addition to the names above, I’m sharing six names below. I could
        share more, but I invite you to contribute in order that this list be as
        broad in its sources as possible. Add yourself or nominate a colleague
        or friend by putting their name and the title of a piece of writing on
        craft by them that particularly moved you (better still: hyperlink the
        work if you can). The point of this ever-growing list is to surface and
        applaud craft writers of all ages and stages, and to make them visible
        so that when articles and essays and writing opportunities are on offer,
        those that fill them are as diverse as the stories of craft we seek to
        listen to and tell.
      </p>

      <p>
        So, who or what have you read or written recently that has taken you
        beyond the canons and ideas with which you are comfortable and familiar?
        What writing have you found outside of institutions and figures granted
        dominant academic or social authority? Here are mine:
      </p>

      <blockquote className="indented negative-firstline">
        <p>
          <strong>dr. heather ahtone</strong> (Choctaw/Chickasaw Nation) is the
          director of curatorial affairs at the First Americans Museum, in
          Oklahoma City, Oklahoma. I loved reading her wall texts throughout
          FAM, and in particular her writing in these two books:{" "}
          <em>Okla Homma: Tribal Nations Gallery, First Americans Museum</em>{" "}
          and, most especially, <em>Winiko: Life of an Object</em> (written in
          conjunction with James Pepper Henry).
        </p>

        <p>
          I first got to know <strong>Chenoa Baker</strong>, currently the
          associate curator at Beacon Gallery, in Boston, as my intern a few
          summers ago at the MFA Boston. She was already a superb writer but she
          has since blossomed even further, participating in the Material
          Intelligence gatherings, as well as writing widely, including for{" "}
          <em>Boston Art Review</em>, <em>Burnaway</em> and{" "}
          <em>Sugarcane Magazine</em>.
        </p>

        <p>
          A PhD candidate in the Department of the History of Art & Architecture
          at the University of California, Santa Barbara,{" "}
          <strong>Graham Feyl</strong> was recommended to me through my
          colleague Kendall DeBoer (whose writing you should also take note of).
          A student of Jenni Sorkin, he’s currently writing on queer craft,
          including the work of Nicolas Moufarrege, whose exhibition at the
          Queens Museum, in 2019, was stupendously beautiful. He is currently
          part of the first cohort of writers for <em>American Art</em>{" "}
          journal’s Toward Equity in Publishing program.{" "}
        </p>

        <p>
          <strong>L Autumn Gnadinger</strong> teaches at Tyler School of Art in
          Philadelphia and writes for and edits Ruckus, an online journal
          focusing on art in the South and Midwest. I loved their recent essay,
          “Craft and Its Writing as Collectivized Outsider,” on the potential of
          a pluralistic understanding of craft as a provocative
          counter-narrative to institutionalized fine art histories.{" "}
        </p>

        <p>
          I was lucky enough to meet <strong>Kate Hawes</strong> during my time
          at Warren Wilson. Kate has been working with wood for over two decades
          and their writing practice is as fully formed as their making in
          furniture, spoons, and sculpture and their teaching work.{" "}
        </p>

        <p>
          A scholar who specializes in Native American and Aboriginal Australian
          art, <strong>Dr. Marina Tyquiengco</strong> was appointed as the
          inaugural Ellyn McColgan Assistant Curator of Native American Art at
          the MFA Boston. Her doctoral dissertation,{" "}
          <em>
            What Native Looks Like Now: Embodiment in Contemporary Indigenous
            Art, 1989–Present
          </em>
          , was a critical analysis of global contemporary indigenous artists,
          and at the MFA she is working with a range of artists, including those
          whose work responds to issues and ideas of craft.{" "}
        </p>
      </blockquote>
      <p>*</p>

      <p>
        Link to{" "}
        <a
          href="https://docs.google.com/document/d/1PIDt3G1HAUUzD8MH90ImlpjjhVTQhNIEChvmttMkVBs/edit?usp=sharing"
          target="_blank"
          rel="noreferrer"
        >
          Google spreadsheet
        </a>
        , where you can nominate writers.
      </p>

      <hr />
    </div>
  );
};

export default Fisher;
