import { NavLink } from "react-router-dom";

const Introduction = () => {
  return (
    <div>
      <p>
        <em>This is not a retreat.</em>
      </p>

      <p>
        The word retreat has multiple meanings—one of them clearly connects to a
        low-residency graduate program like the MA in Critical Craft Studies
        (MACR): a place to which one removes themselves for study,
        rehabilitation, thinking, making, or writing. Such places loom large in
        craft contexts and other histories: think Walden Pond, Santiniketan,
        Haystack Mountain School of Crafts, and, adjacent to these craft-centric
        spaces, the “ivory tower” of academic institutions. Being at a retreat
        is a privilege as one’s basic needs for food and shelter are taken care
        of by other people who enable participants to think, make, learn new or
        renewed skills or ideas, or work—a re-orientation through retreat.
        <sup>
          <a href="#annotation-1">1</a>
        </sup>{" "}
      </p>

      <p>
        The MA in Critical Craft Studies (2017–2023), launched by a small
        liberal arts college in Western North Carolina, followed a low-residency
        model, meaning that a two-week intensive initiated each semester’s work.
        Nestled within 1,100 acres that include a working forest, farm, and
        garden in the Swannanoa Valley, Warren Wilson College (WWC) contained
        elements of the quintessential rural craft retreat. The MACR program,
        which focused on craft history and theory, deliberately drew on elements
        of retreat frequently engaged in what has become known in recent years
        as “The Craft School Experience” in the US: international faculty drawn
        from multiple institutions, knowledge-bearers from outside of academia,
        communal housing and meals, and an intensely focused schedule.
        <sup>
          <a href="#annotation-2">2</a>
        </sup>{" "}
      </p>

      <p>
        Two-week-long residencies opened each semester of study, on site at WWC
        in the fall and in downtown Asheville in the winter. This, of course,
        all shifted online during the height of the pandemic, but the goal never
        changed. When on site in North Carolina, the aim was to break down
        rural/urban binaries, and to experience and examine craft in settings
        with multiple, strong local and regional connections to craft: “the
        Appalachian Mountains.” Working from this cultural region, we could
        engage concurrent and co-located Black, Indigenous, and settler craft
        histories, encompass city and mountain life, and hold in our minds the
        distribution of crafted objects and gestures across museums and
        galleries, historical villages, craft schools and guilds, gift shops,
        factories, and studios. In other words: this location near the
        mountains, far from being a <em>removal from</em> life and living is an{" "}
        <em>immersion in</em> how and where craft lives.
      </p>

      <p>
        <em>This is not a retreat.</em>
      </p>

      <p>
        Study in North Carolina was intended to be a model and catalyst for
        considering craft in local contexts, with the aim that students would
        extend their work toward studying where their feet touched the ground.
        The experiential model veered, by design, from customary academic
        structures and systems. What students brought into the program was both
        central to, and celebrated in, the classroom. That classroom was
        designed so learning would happen for everyone—students, educators, and
        visiting knowledge-bearers. The multi-disciplinary approach moved us
        across many fields and methods: we walked with anthropology and sensory
        ethnography, along Marxism and museum studies, and through phenomenology
        and historiography; we engaged with oral history methods and
        publications as practice; we hiked with the dean of land when on campus
        and mapped our own neighborhoods when online … all work toward building
        a field of craft studies.{" "}
      </p>

      <p>
        We are deeply saddened at the provost’s surprising decision to terminate
        the MACR program, and turned to a number of people within the community
        we cultivated to learn what they experienced through their engagement
        with the program, and how they seeded it onward. Our invitation included
        a series of questions inspired by the ways in which Miranda July, Thomas
        McEvilley, Yoko Ono, Lisa Jarrett, Jen Delos Reyes, Judith Leemann, and
        others practice and provoke inquiry and conversation. Moving
        sequentially, the first essays you will encounter provide a sense of
        what people bring into the program through life and lived experiences.
        <sup>
          <a href="#annotation-3">3</a>
        </sup>{" "}
        The contributions also speak to the responsibilities, vulnerabilities,
        and ambitions of teaching. <em>How</em> teaching takes place is as vital
        as <em>what</em> is being taught.
        <sup>
          <a href="#annotation-4">4</a>
        </sup>{" "}
        Next, we created a cluster of essays about the program as connected to
        Warren Wilson College—histories connected to making and place, with a
        discussion of the concept of <em>craftscape</em> as the guiding
        framework for the program and its community reach.
        <sup>
          <a href="#annotation-5">5</a>
        </sup>{" "}
        The concluding essays touch on hope, agency, visibility and presence,
        and death, the better to remind us of the work ahead of us.
        <sup>
          <a href="#annotation-6">6</a>
        </sup>{" "}
        This call to action, made on the doorstep of a closing program, is as
        melancholy as it is risk-embracing: “The passage of [time] marches us
        towards death but infuses every step with the urgent thrill of purpose.
        We are ourselves in those moments, answering the call of the material
        world, in that we are concerned with it in all the thickness of its
        meaning…”
        <sup>
          <a href="#annotation-7">7</a>
        </sup>{" "}
      </p>

      <p>
        To play with a second, military definition of the word “retreat,” this
        publication does not mark a weakening in the faculty and alums’
        commitment to the study of craft, or to its expanding community. It is
        only the end of <em>this</em> phase of the first program dedicated to
        critical craft studies. We, and those with whom we read, talked,
        debated, thought, ate, and swam in the river will continue, as you do,
        the forever work of “building new worlds.”
        <sup>
          <a href="#annotation-8">8</a>
        </sup>{" "}
      </p>
      <hr />

      <cite>
        <sup id="annotation-1">1.</sup> Here, we are thinking of Sara Ahmed’s
        discussion of how a philosopher can work because someone else is tending
        to his life and needs, from caring for children to preparing and serving
        meals, as well as discussion on finding your way, in{" "}
        <em>Queer Phenomenology: Orientations, Objects, Others</em> (Duke
        University Press, 2006), 1–16.
      </cite>
      <cite>
        <sup id="annotation-2">2.</sup> In recent years, select places known for
        weekend, week-long, or multi-week learning opportunities through
        craft-making have grouped themselves as “The Craft School Experience” in
        the US. This cluster of schools, which includes Arrowmont School of Arts
        and Crafts, Haystack Mountain School of Crafts, Penland School of Craft,
        Peters Valley School of Craft, and Pilchuck Glass School, represents
        only a small number of craft schools across the US. See{" "}
        <a
          href="http://www.craftschools.us/the-experience.html"
          target="_blank"
          rel="noreferrer"
        >
          http://www.craftschools.us/the-experience.html
        </a>
        , Retrieved May 28, 2023. Michelle Millar Fisher, Ronald C. and Anita L.
        Wornick Curator of Contemporary Decorative Arts within the Contemporary
        Art Department, Museum of Fine Arts Boston (MFAB), and core faculty in
        the MACR program, is organizing an exhibition and publication in
        conjunction with her own research on craft schools, scheduled to open at
        the MFAB in 2024. See also Namita G. Wiggers, “Back-to-school,” in Brian
        Gillis et al., <em>Discursive</em> (Eugene, OR: Jordan Schnitzer Museum
        of Art, 2018). For background on the program,{" "}
        <a
          href="https://www.instagram.com/macraftstudieswwc/"
          target="_blank"
          rel="noreferrer"
        >
          visit @macraftstudieswwc
        </a>{" "}
        on Instagram, and the websites{" "}
        <a
          href="https://www.macraftstudieswwc.com"
          target="_blank"
          rel="noreferrer"
        >
          www.macraftstudieswwc.com
        </a>{" "}
        and{" "}
        <a
          href="https://www.criticalcraftforum.com"
          target="_blank"
          rel="noreferrer"
        >
          www.criticalcraftforum.com
        </a>
        .
      </cite>
      <cite>
        <sup id="annotation-3">3.</sup> Amy Meissner’s essay,{" "}
        <NavLink to="/notaretreat/meissner">
          “But First, Learn This (A List),”
        </NavLink>{" "}
        launches this publication by bringing in the breadth and depth of an
        alum’s life and learning experiences, expanding her knowledge through
        ways in which the program helped her shape and articulate her own
        questions about craft.
      </cite>
      <cite>
        <sup id="annotation-4">4.</sup> Embodiment -- what it feels like to
        teach in academia and the difference in experience when teaching
        together with people of the global majority in a welcoming and embracing
        space—in{" "}
        <NavLink to="/notaretreat/jarrett_wiggers">
          “Teaching Questions: Lisa Jarrett and Namita Gupta Wiggers in
          Conversation”
        </NavLink>{" "}
        and alejandro acierto’s{" "}
        <NavLink to="/notaretreat/acierto">
          “In the After-Thought: Teaching Transparency and the Ongoing Practice
          of Freedom.”
        </NavLink>{" "}
        Judith Leemann extends the vulnerability of guiding and being immersed
        in an experience in{" "}
        <NavLink to="/notaretreat/leemann">
          “towards tending not knowing.”
        </NavLink>{" "}
        The importance of making as a means of understanding and communicating
        about craft comes through in Melissa Potter’s{" "}
        <NavLink to="/notaretreat/potter">
          “Pandemic Papermaking: Critical Craft,”
        </NavLink>{" "}
        and Sara Clugage’s{" "}
        <NavLink to="/notaretreat/clugage">
          “This Is Not a Food Studies Program,”
        </NavLink>{" "}
        and the bowl as an object and subject for examination and learning takes
        different directions in Mara Holt Skov’s{" "}
        <NavLink to="/notaretreat/skov">
          “Reflections on Teaching and Learning with the Bowl”
        </NavLink>{" "}
        and Ben Lignel’s{" "}
        <NavLink to="/notaretreat/lignel">
          “Teaching and Unlearning—Or How to Write Wall Texts: Lessons of
          Decolonial Thinking.”
        </NavLink>{" "}
        Research, collections, and connections—as well as forms of conveying
        knowledge—come through in Faythe Levine’s{" "}
        <NavLink to="/notaretreat/levine">
          “The Dotted Line (Desktop Version).”
        </NavLink>
      </cite>
      <cite>
        <sup id="annotation-5">5.</sup> A cluster of co-located essays in this
        publication engage WWC as a place and the program embedded within the
        institution in multiple ways; see Ben Lignel and Namita G. Wiggers,{" "}
        <NavLink to="/notaretreat/lignel_wiggers">
          “Designing the Plane as We Flew It—A Discussion on the MACR Program
          Publications”
        </NavLink>{" "}
        to understand the challenges of messaging an expansive view of craft
        from within an institution invested in a traditional notion of craft and
        Appalachian heritage and how publications operated to expand critical
        thinking and writing; Melanie Wilder’s{" "}
        <NavLink to="/notaretreat/wilder">“Weaving Community Together”</NavLink>{" "}
        for consideration of the layered and interwoven relationships between
        materials, commodities, and Indigenous and settler communities linked to
        undergraduate craft experiences at WWC; buried Indigenous and Black
        histories in the essay{" "}
        <NavLink to="/notaretreat/keith">
          “Emplaced Remembrance—Thinking about Gone Worlds Always Nearby,”
        </NavLink>{" "}
        by Jeff Keith, core faculty, MACR, and professor of global studies, WWC;
        and Anjula Razdan’s feature from <em>American Craft</em> on Namita G.
        Wiggers’s{" "}
        <NavLink to="/notaretreat/razdan">concept of “craftscape,”</NavLink> in
        process of connecting the undergraduate programs across the campus with
        land resources and the MACR program at the time of the program’s
        termination by WWC.
      </cite>
      <cite>
        <sup id="annotation-6">6.</sup> The contributions in this publication
        run a gamut of emotions and set forth a range of possible actions and
        responses that extend interactions and community into the future, from
        absence, in Anna Helgeson’s{" "}
        <NavLink to="/notaretreat/helgeson">
          “Craft, Nothing, and Nowhere,”
        </NavLink>{" "}
        and death, in Tom Martin’s essay{" "}
        <NavLink to="/notaretreat/martin">
          “What to Do When You Are Dead: Craft and Existential Anxiety,”
        </NavLink>{" "}
        to rethinking the power of questions in Kevin Murray’s{" "}
        <NavLink to="/notaretreat/murray">“Why the Question?”</NavLink>; from
        agency and the power of self-definition in Jen Delos Reyes’s{" "}
        <NavLink to="/notaretreat/delosreyes">
          “Future Desire Lines: Cultivating Paths of Resistance, Support, and
          Joy”
        </NavLink>{" "}
        to hope and change in Tiffany Momon’s{" "}
        <NavLink to="/notaretreat/momon">
          “People, Get Ready: Building New Worlds.”
        </NavLink>{" "}
        And finally the ongoing cultivation of community through mentoring via
        Alpesh Kantilal Patel’s{" "}
        <NavLink to="/notaretreat/patel">
          “Worldmaking with Colleagues,”
        </NavLink>{" "}
        and the collectivity of future knowledge making through Michelle Millar
        Fisher’s call to action and expansion of a field in{" "}
        <NavLink to="/notaretreat/fisher">
          “Who writes craft histories?”
        </NavLink>
      </cite>
      <cite>
        <sup id="annotation-7">7.</sup> Tom Martin, “towards tending not
        knowing,” <em>This Is Not a Retreat</em>, edited by Ben Lignel and
        Namita Wiggers. <em>The MACR Papers</em>, Swannanoa, NC: MA in Critical
        Craft Studies, 2023.
      </cite>
      <cite>
        <sup id="annotation-8">8.</sup> Tiffany Momon, “People, Get Ready:
        Building New Worlds,” <em>This Is Not a Retreat</em>, edited by Ben
        Lignel and Namita Wiggers. <em>The MACR Papers</em>, Swannanoa, NC: MA
        in Critical Craft Studies, 2023.
      </cite>
    </div>
  );
};

export default Introduction;
