import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

// prettier-ignore
const ArticleMenuItemWrapper = styled.div.attrs((props) => props)`
  padding: 1rem;
  a {
    display: flex;
    gap: 1.5rem;
    color: ${(props) => props.highlight};

    &:hover {
      text-decoration: none;
    }
  }
`;

// prettier-ignore
const ArticleOrder = styled.div.attrs((props) => props)`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  font-size: 0.9rem;
  border: 2px solid transparent;
  transform: rotate(45deg);
  border: 2px solid ${(props) => props.$highlight};
`;

// prettier-ignore
const ArticleOrderLabel = styled.span.attrs((props) => props)`
  display: block;
  transform: rotate(-45deg);
  color: ${(props) => props.$highlight};
`;

const ArticleAuthor = styled.div`
  font-family: var(--sans-serif);
`;

const ArticleTitle = styled.div`
  font-family: var(--condensed-copy);
  text-transform: none;
`;

const ArticleMenuItem = ({ article, to, highlight, onClick }) => {
  const { id, order, authorShort, title } = article;

  const handleClick = () => {
    if (!onClick) return;
    onClick();
  };

  return (
    <ArticleMenuItemWrapper highlight={highlight}>
      <NavLink to={to} onClick={handleClick}>
        <ArticleOrder highlight={highlight}>
          <ArticleOrderLabel highlight={highlight}>{order}</ArticleOrderLabel>
        </ArticleOrder>
        <div>
          {authorShort && <ArticleAuthor>{authorShort}</ArticleAuthor>}
          <ArticleTitle>{title}</ArticleTitle>
        </div>
      </NavLink>
    </ArticleMenuItemWrapper>
  );
};

export default ArticleMenuItem;
