import { NavLink } from "react-router-dom";

const Murray = () => {
  return (
    <div>
      <p>
        Soon after arriving at Swannanoa, I had my first taste of the MA in
        Critical Craft Studies. The walls were decked with sheets on which
        questions about craft had been carefully written by hand. They were the
        result of{" "}
        <NavLink to="/notaretreat/jarrett_wiggers">
          Lisa Jarrett’s 100 Questions exercise
        </NavLink>
        . It was exciting to feel a vista of learning opening up.{" "}
      </p>

      <p>
        A question can create a path. I’d been long taken with the Zapotec story
        “walking we ask questions.” Questions are not only individual thoughts,
        questions can also be collective endeavors.{" "}
      </p>

      <p>
        So as one journey concludes, I wanted to try and find a way forward
        through the unfolding series of questions.
      </p>

      <p>Let’s begin.</p>

      <p>What question should we be asking ourselves? </p>

      <p>I stumble.</p>

      <p>
        Why are we asking questions? Why do we make a space for what we don’t
        yet know? Why do we stop moving, not to rest, but to envisage
        alternative paths? Is it a sign of doubt and lack of resolve?{" "}
      </p>

      <p>I recover.</p>

      <p>
        Can questioning be a political act in itself? Do questions always need
        an answer? Does questioning without answer become mindless, like the
        obstinate child who knows no other way of conversing? Why do you ask?
        Who benefits?{" "}
      </p>

      <p>
        Instead, can questions be sequential steps in a journey? How does one
        question lead to another? Is it a matter of finding the unquestioned
        assumption underlying the initial question? Why do we want to question
        the preconceptions of a question?{" "}
      </p>

      <p>We set forth.</p>

      <p>
        What are the questions we might ask about craft? Is the act of posing a
        question about craft in effect contrary to its authentic value as an
        object in the world? Aren’t objects in a way answers to our questions,
        such as “What is real?,” “Where can I put precious stories so they can
        become everyday companions?,” “How can I give permanent substance to a
        fleeting emotion like love?” Is knowing the question that an object
        answers a way of giving it value?
      </p>

      <p>We rest.</p>

      <p>
        What of the less cerebral dimension of craft? Doesn’t our haptic
        experience of the object, through touch, in particular, exist
        independently of the question?{" "}
      </p>

      <p>
        What if the question were simply an ornament? Isn’t it meaningful that
        the question mark itself is curved? Is the question a bend in the path,
        a flourish of the hand?
      </p>

      <p>It’s time to go.</p>

      <p>
        What distinguishes craft from art? What difference does it make that the
        work is an object in the world, rather than a representation? What is it
        about its “thingness” that makes it craft? Does its singularity as an
        object give it a rare value? Does the non-fungible token (collectable
        digital version) then grant art an equivalent singularity? Or does value
        lie in the vulnerability of an object, which may be lost or broken, in
        the world? Does the preciousness of the heirloom that is carefully
        passed down through generations depend on its vulnerability, like a
        flickering candle?
      </p>

      <p>Up we climb!</p>

      <p>
        Who is craft for? How can craft be egalitarian when the prices of
        handmade products are much higher than mass-manufactured equivalents? Is
        craft essentially a pursuit of the rich, who use it to distinguish their
        sensibilities from the uneducated classes that are beholden to
        mass-produced consumerism? Is it some compensation that these elite
        tastes help sustain the crafts through patronage?{" "}
      </p>

      <p>I find an impasse.</p>

      <p>
        Is craft essentially for Westerners? Is it a product of German
        Romanticism, which classified cultures according to their unique
        creative activities? Is it underpinned by a capitalist mind view that
        attributes value exclusively to the commodity? Does the relatively
        sacred nature of the object in Indigenous, African, and Asian cultures
        render it invisible within the museum’s understanding of craft? Why are
        there so few African voices?
      </p>

      <p>I listen.</p>

      <p>✿</p>

      <p>
        I’ll forever think of the glades around the Warren Wilson campus as
        spaces for questions. Like the do-si-do at Swannanoa Gathering square
        dances, the questions animated dizzying conversations. Hopefully, they
        will continue to provide points where we can cross paths in the future.{" "}
      </p>
      <figure>
        <img
          src={
            process.env.PUBLIC_URL +
            "/img/notaretreat/B_N_18_Kevin_Jarrett Workshop.jpg"
          }
        />
        <figcaption>
          Linda Sandino, Heather K. Powers, Lisa Jarrett on the Warren Wilson
          campus during the One Hundred Questions Workshop led by Lisa Jarrett,
          July, 2019. Photo: Namita Gupta Wiggers
        </figcaption>
      </figure>

      <h2>Further Readings</h2>
      <ul className="bibliography">
        <li>
          "Walking: We Ask Questions."{" "}
          <a
            href="http://www.narconews.com/Issue35/article1124.html"
            target="_blank"
            rel="noreferrer"
          >
            http://www.narconews.com/Issue35/article1124.html
          </a>
          .
        </li>
      </ul>
      <hr />
    </div>
  );
};

export default Murray;
