const Leemann = () => {
  return (
    <div>
      <figure>
        <img
          src={
            process.env.PUBLIC_URL +
            "/img/notaretreat/B_N_5_Blueprint_variant.jpg"
          }
        />
        <figcaption>
          Judith Leemann, <em>array</em>, 2023, pencil on paper
        </figcaption>
      </figure>

      <p>
        i.
        <br />
        Dream of an aquatic sanctuary, a large hall with dozens of standing
        pools, each with a different kind of fish. Someone has come in the
        night, pulled the plug on every pool. Pool walls collapsed, floor
        soaked, fish gasping. There are faucets in the hall, plenty of clean
        water at hand. But this clean water, this new water, cannot save the
        fish.{" "}
      </p>
      <p>The new water is not the old water.</p>

      <p>
        The old water was thick—with time, with a balance slowly bred, with the
        volatile chemistry of its cleanness long gone. The old water had been
        conditioned to support these exact living fish.
      </p>

      <p>
        The dream, some 20 years old, vivid still.
        <br />
        The perverse cruelty of acting like all water is the same.
      </p>

      <p>
        When a million-liter saltwater aquarium at the center of a Berlin hotel
        breaks in the early hours of December 16, 2022, shooting fish and water
        into winter streets, this dream comes rushing back.
      </p>

      <p>
        And two days earlier, moving too quickly, tossing what I think is an
        empty milk carton into the trash, I paint a breaching white whale onto
        the side of the stove.
      </p>

      <p>
        I text my friend, <em>everything is spilling</em>.
      </p>

      <p>
        ii.
        <br />I am not always teaching art, but I am always teaching{" "}
        <em>as an artist</em>, by which I mean working with the material at
        hand, attending to its particular affordances, its patternings. Can I be
        as curious in the classroom as in the studio, ask myself the same kinds
        of questions: what if I place this under, next to, behind that? Move the
        parts around to produce a question, not a lesson.
      </p>

      <p>
        I am brought as a guest teacher into a classroom of craft scholars.
        We’ve spent the morning in side-by-side close reading of objects I
        brought with me. Someone, Kat perhaps, stops us as we are about to begin
        the next activity:{" "}
        <em>Can we take a moment to acknowledge what just happened?</em>{" "}
        Something passes through the room. I see it move them, move through
        them. They will refer to this again later, and each time I will pretend
        I know what they are talking about. The truth is that, to this day, I
        have no idea what happened.{" "}
      </p>

      <p>
        I am outside of their shared experience, even as I understand that what
        I was bringing into the room, how I was playing with the structure of
        time and attention, object, task, and relation may have set the
        conditions for this thing to take place.{" "}
      </p>

      <p>
        I recognize that something like this often enough enters the rooms where
        I teach. But I do not see myself as the author of it. Understand it as
        something that sometimes rides in on the back of what I think I am
        teaching, then drops down and makes its own way. I do know that it helps
        to keep my own curiosity at the center of my choices, as a way to
        mitigate the impulse to produce certain kinds of lessons <em>for</em>{" "}
        anyone else.
      </p>

      <p>
        That which matters most to me in teaching cannot (perhaps, must not) be
        aimed for head on, be measured directly. It is the brightness of eye
        that matters, the learned creature re-seating itself in its inherent,
        irrevocable, resurrected curiosity.{" "}
      </p>

      <p>
        iii.
        <br />
        In their book <em>The Dawn of Everything</em>, the Davids Graeber and
        Wengrow talk of the “play farming” that characterizes early agriculture.
        A kind of low-stakes participating in, experimenting with, and tending
        of that which already grew wild. And where was such play farming done?
        Usually in flood zones, where the coming and going of water was a
        reliable condition.
        <sup>
          <a href="#annotation-1">1</a>
        </sup>
      </p>

      <p>
        What shifts if you assume that learning is already fully present, if you
        do not try to produce it or to increase its fruits?
      </p>

      <p>
        Margaret Mead argued eloquently against steering people toward
        blueprinted goals, even the ones we agree would be good for us. “A
        screwdriver is not seriously affected when, in an emergency, we use it
        as a wedge; and a hammer’s outlook on life is not affected because we
        sometimes use its handle as a simple lever. But in social manipulation
        our tools are people, and people learn, and they acquire habits which
        are more subtle and pervasive than the tricks which the blueprinter
        teaches them.”
        <sup>
          <a href="#annotation-2">2</a>
        </sup>{" "}
      </p>

      <p>
        It is too easy to read Mead as saying simply beware of manipulating
        others as they will sense it and resist. When she says “subtle and
        pervasive” she points us toward noticing the second-order effects of
        having people go along with the plans you make for them. She isn’t
        talking about the student who rejects school and fails out but rather
        the ongoing background conditions acquired by the student who passes
        successfully through their schooling.
      </p>

      <p>
        How not to act as an agent of the arrangement that is formal schooling?
        How neither to just go passive and let the prevailing habits of that
        arrangement go unchallenged? How to enact instead a speculative
        choreography across the space and time of the classroom in which an
        improvised oscillation of occupying and evacuating the role of teacher
        brings into legibility the background conditions which we then can
        study? Together.
      </p>

      <p>
        Said another way, can I pull the plug on myself as teacher? Can I spill?
      </p>

      <p>
        iv.
        <br />
        In 2005, at a work-in-progress show, the performance group Goat Island,
        after two decades of making work together, announced that their current
        work would incorporate an additional constraint: that of lastness.
      </p>

      <p>
        I sat in the audience with tears streaming down my face. Just a month
        before, my grandfather, faced with the choice to extend his life half a
        year but with daily dialysis, also chose lastness. Took his time to make
        sure my grandmother could forgive him the choice, and chose not to last.
      </p>

      <p>
        v.
        <br />
        What is the one word that can’t be included in the riddle?
      </p>

      <p>
        vi.
        <br />I am reading an essay by Bayo Akomolafe in which appears the
        sentence: <em>It doesn’t even have to last</em>.
        <sup>
          <a href="#annotation-3">3</a>
        </sup>{" "}
        I text it to my friend to reassure her that the fleeting moments of
        liveliness in her classroom of college seniors, pandemic-numb still,
        count for something even if they do not last.
      </p>

      <p>
        And this week as I am metabolizing the fact that I can no longer hold up
        a thing I have been holding up, she sends it back to me:{" "}
        <em>It doesn’t even have to last</em>.
      </p>

      <p>
        Something moves through in the night, letting water out of tanks.
        Relentless.{" "}
      </p>

      <p>
        I said it to another friend once, when a program she had tended was
        being shuttered: <em>There are many ways to be remembered</em>. What I
        wanted to say to her is that the failure of a form is only that, a
        failure of the form’s lasting. As the form fails, as what it contained
        spills again into formlessness, we can and should grieve. But we could
        also keep our eye on the form that is this spilling.{" "}
      </p>

      <p>
        vii.
        <br />
        Before me now, an array of objects, a closed set, a table for heads to
        bend over, for hands to rearrange. To learn with objects. A radical
        side-by-side making of meaning together, moving via thing into relation.
      </p>
      <p>
        You cannot place relation as a subject in front of a class when the
        thinking of the room is still thing-thinking. The mind of the room will
        do what it thinks it is charged with doing and will grasp that relation
        as a thing and will within minutes by that handling have made it a
        thing. The logic through which our hands reach has everything to do with
        what they find on landing.
      </p>

      <p>
        Instead summon into the room two things, a decoy thing and a friend for
        that thing. These things must matter, but not too much. Decoy thing and
        thing’s friend can then be pointed to by person body marked as teacher
        and some question voiced. It matters that the bodies gather around the
        objects of study, matters that the eyes inside the head atop the spine
        incline down to look. The posture of study, the posture of{" "}
        <em>what is this</em>, is equally the posture of rolling out dough, the
        posture of changing a diaper, the posture of chiseling at a workbench.
      </p>

      <p>
        So if it’s not the posture that guarantees relational thinking, though
        it may incline us toward it, what are the marks of such thinking?
      </p>

      <p>
        A staying with. A refusal to break perceiver and perceived so cleanly in
        two. An engagement of indirect procedures and oblique angles of
        approach. A refusal to take up the assurances of distance.
      </p>

      <p>She called that carved box her co-teacher and she meant it.</p>

      <p>
        viii.
        <br />
        If not by taking our distance, then by what means do we confirm a thing,
        verify a thing? How do we ensure we are not imagining things?
      </p>

      <p>
        What takes the place of distancing is a body confirming its knowing in
        the very act of doing, inside the doing, by the repetition of that
        doing. This has everything to do with the observation one of you made:{" "}
        <em>Isn’t it interesting that all of us here are makers?</em>
      </p>

      <p>
        From inside a practice, you build the capacity to register and rerun a
        line, and in each rerunning you become able to discern more. Your answer
        to the question <em>what is happening?</em> becomes granular, it begins
        to exhibit the grammar inherent to the object/place/dynamic you are
        studying. When you try to describe it with a grammar false to it, you
        feel a deep no and pivot without a fight.
      </p>

      <p>
        What if, from inside the rerun lines of your teaching practice, you
        found that the things that matter to you to teach are the unassessables?
        The inevaluatables?{" "}
      </p>

      <p>
        What if you came to suspect there are things we shouldn’t know that we
        know? Things so potent they must always remain speculative.
      </p>

      <p>
        What if you refrained from breaking the seed open to test its
        quickening?{" "}
      </p>

      <p>
        What if you committed to teaching only lessons whose ripening point is
        just beyond the institution’s reach?
      </p>

      <p>
        ix.
        <br />
        In an online talk given April 13, 2022, the art historian Darby English
        renders a heartful classroom in which his art history students are
        dissuaded from approaching the objects of their study via{" "}
        <em>knowing</em>.
      </p>

      <p>From my notes, these his words in fragments:</p>

      <blockquote className="indented">
        <p>exact and incomplete yet inconclusive object</p>

        <p>in knowing I am not with the work at all</p>

        <p>by applying what I know I refuse its demand that I do work</p>

        <p>in side-by-side looking I cannot keep my object still</p>

        <p>a rich and difficult withness</p>

        <p>
          how do I have to become to be with this thing?
          <sup>
            <a href="#annotation-4">4</a>
          </sup>{" "}
        </p>
      </blockquote>
      <p>
        x.
        <br />
        Restraint is called for. Humbly become whatever this thing you want to
        approach needs you to become. Pare back to the simple effort to near an
        object with words, to get under the skin of what otherwise wants to be
        performed in the room by way of assertion, by way of claim.
      </p>

      <p>And open in that effort a chasm, an impossibility.</p>

      <p>
        An unbroken commitment to encountering objects—not knowing them but
        drawing back enough to enter their logic and move with them—may be
        impossible. But in the side-by-side pressing into that impossibility, we
        discover affection for what we do not know. We learn to stand not
        knowing.
      </p>

      <p>
        This standing not knowing is physical, is epistemological. Is ours to
        claim, reclaim. Has the slippery beauty of being immaterial. The
        undeniable, consequential materiality of spills.
      </p>

      <p>
        The side of my stove has long since been cleaned. No more white whale
        breaching. How to measure the matter of tending on a winter morning to
        such a fleeting form, giving it its due. Plainly, I chose to see it.{" "}
      </p>

      <p>
        <em>It doesn’t even have to last</em>, writes Akomolafe.
      </p>

      <blockquote className="indented">
        <p>
          It doesn’t even have to last. Just a gathering. Just a glimmer of the
          ecstatic. Just a new word that undoes the shackles of loyalty. Just a
          sense that magic is not some fool’s errand amid more serious
          endeavors. Just a voice whispering back in the thick night.
          <sup>
            <a href="#annotation-5">5</a>
          </sup>{" "}
        </p>
      </blockquote>
      <hr />

      <cite>
        <sup id="annotation-1">1.</sup> David Graeber and David Wengrow,{" "}
        <em>The Dawn of Everything: A New History of Humanity</em> (Toronto:
        Signal, 2023), 400–401.
      </cite>
      <cite>
        <sup id="annotation-2">2.</sup> Gregory Bateson,{" "}
        <em>Steps to an Ecology of Mind</em> (Chicago: University of Chicago
        Press, 2016), 170.
      </cite>
      <cite>
        <sup id="annotation-3">3.</sup> Bayo Akomolafe, “Black Lives Matter, but
        to Whom? Why We Need a Politics of Exile in a Time of Troubling
        Stuckness (Part II),” Democracy & Belonging Forum (Democracy & Belonging
        Forum, January 30, 2023),{" "}
        <a
          href="https://www.democracyandbelongingforum.org/forum-blog/black-lives-matter-but-to-whom-part-2"
          target="_blank"
          rel="noreferrer"
        >
          https://www.democracyandbelongingforum.org/forum-blog/black-lives-matter-but-to-whom-part-2
        </a>
        .
      </cite>
      <cite>
        <sup id="annotation-4">4.</sup> Darby English, "Scheme and Otherness in
        Our Work with Art," presented on April 13, 2022, as the Raymond F. West
        Memorial Lecture at the Stanford Humanities Center, live via Zoom. See
        event announcement here:{" "}
        <a
          href="https://shc.stanford.edu/stanford-humanities-center/events/darby-english-scheme-and-otherness-our-work-art"
          target="_blank"
          rel="noreferrer"
        >
          https://shc.stanford.edu/stanford-humanities-center/events/darby-english-scheme-and-otherness-our-work-art
        </a>
        .
      </cite>
      <cite>
        <sup id="annotation-5">5.</sup> Akomolafe, Ibid.
      </cite>
    </div>
  );
};

export default Leemann;
