const Lifespan = () => {
  return (
    <div>
      <blockquote>
        <p>
          “One thousand trees have been planted in Nordmarka, a forest just
          outside Oslo, which will supply paper for a special anthology of books
          to be printed in one hundred years’ time. Between now and then, one
          writer every year will contribute a text, with the writings held in
          trust, unpublished, until the year 2114. Tending the forest and
          ensuring its preservation for the one-hundred-year duration of the
          artwork finds a conceptual counterpoint in the invitation extended to
          each writer: to conceive and produce a work in the hope of finding a
          receptive reader in an unknown future.”
          <sup>
            <a href="#annotation-1">1</a>
          </sup>
          <br /> &mdash;Description of <em>Future Library</em>, by Katie
          Paterson
        </p>
      </blockquote>

      <p>
        “The Library resurrects a rapidly receding past for the future,
        dramatically rehearsing and preserving the very silence that [Leah]
        Marcus suggests must accompany and characterize any stable preservation
        effort.
        <sup>
          <a href="#annotation-2">2</a>
        </sup>
      </p>

      <p>
        Yet the temporalized space of the library is not simply a space of
        return, but also a space of deferral and anticipation. The{" "}
        <em>Library</em>’s archaic recuperation of the past is inseparable from
        its speculative projection into the future. Through the near-empty space
        of the <em>Library</em>, the time of the project becomes dramatically
        stretched beyond the lifespans of its initial creators: Paterson
        remarks, ‘[w]hen I had the idea for <em>Future Library</em> I knew
        instantly it would outlive me (and most of us alive today). It is
        important that I do not see it fully realized—it is a work conceived for
        an unknown, future generation.’ This space, then, is proleptically
        haunted by the future, paradoxically filled by both the absent presence
        of the books to come, and the gradual, inevitable disappearance of those
        texts’ mortal writers, selectors, and readers.”
        <sup>
          <a href="#annotation-3">3</a>
        </sup>
      </p>

      <h2>Further Readings</h2>
      <ul className="bibliography">
        <li>
          Benzon, Paul. “On Unpublishing: Fugitive Materiality and the Future of
          the Anthropocene.” In <em>Publishing as Artistic Practice</em>, edited
          by Annette Gilbert, 282–95. Berlin: Sternberg Press, 2016.
        </li>

        <li>
          Future Library. “<em>Future Library</em>, 2014¬2114.” Accessed
          February 16, 2023.{" "}
          <a
            href="https://www.futurelibrary.no"
            target="_blank"
            rel="noreferrer"
          >
            https://www.futurelibrary.no
          </a>
          .
        </li>
      </ul>

      <hr />
      <cite>
        <sup id="annotation-1">1.</sup> Future Library, “<em>Future Library</em>
        , 2014–2114,” accessed February 16, 2023, https://www.futurelibrary.no.
        Katie Paterson’s work <em>Future Library</em> concerns the contemplation
        of time and the environment on a grand scale.
      </cite>

      <cite>
        <sup id="annotation-2">2.</sup> Paul Benzon refers to Leah Marcus’s
        article on the silence of archives versus the noisiness of publishing in
        the digital era. Paul Benzon, “On Unpublishing: Fugitive Materiality and
        the Future of the Anthropocene,” in{" "}
        <em>Publishing as Artistic Practice</em>, ed. Annette Gilbert (Berlin:
        Sternberg Press, 2016), 289–90.
      </cite>
      <cite>
        <sup id="annotation-3">3.</sup> Benzon, “On Unpublishing.”
      </cite>
    </div>
  );
};

export default Lifespan;
