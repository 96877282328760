const Weiss = () => {
  return (
    <div>
      <figure>
        <img
          src={
            process.env.PUBLIC_URL +
            "/img/dimassimo-weiss/J_J_5.1_1_COLLAGE_BL_1.png"
          }
        />
        <figcaption>
          Connie Porter, <em>Addy: A Heart Full of Hope</em>, (Middleton, WI:
          American Girl Publishing, 2020), 110–111. This text, published by
          American Girl Publishing, was made available to download for free in
          the summer of 2020 as part of the company’s commitment to racial
          equity. This excerpt was originally published in{" "}
          <em>Changes for Addy: A Winter Story</em>. Downloaded April 25, 2023.{" "}
          <a
            href="https://assets.contentstack.io/v3/assets/blte5bbb46adcc8c61b/blt1e726d107b427f37/620d20dc6662ca0a6f617764/addy-a-heart-full-of-hope.pdf"
            target="_blank"
            rel="noreferrer"
          >
            https://assets.contentstack.io/v3/assets/.../.../.../addy-a-heart-full-of-hope.pdf
          </a>
          .
        </figcaption>
      </figure>

      <p>
        From their uses as currency to jewelry and adornment, cowrie shells are
        a consistent piece of Black material culture and a symbol of the African
        diaspora. These shells are often found in colonial archeological sites
        in America, although they are native to the Indo-Pacific Ocean. Enslaved
        people could carry these small shells with them from Africa, worn on the
        body or in the hair, or even held in the hand as a small piece of home.
        <sup>
          <a href="#annotation-1">1</a>
        </sup>{" "}
        A cowrie shell becomes the symbol of freedom for Addy Walker, a young
        Black girl in the American Girl book series whose story occurs near the
        end of the Civil War. When Addy and her mother Ruth self-emancipate,
        Ruth gives her daughter a cowrie shell that once belonged to Addy’s
        great-grandmother and namesake, Aduke. In this book for young audiences,
        readers of all ages find a bit of Addy’s sensory experience to see and
        even imagine feeling. An illustration of the cowrie shell sits next to
        its description with easily recognizable similes: a domed top as smooth
        as soap, and a ridged underside like the teeth of a comb. For Addy’s
        family, the literal holding of this cowrie shell has been an act of
        cultural maintenance for generations. This sensory engagement is
        something the reader can hold onto alongside Addy and imagine the weight
        and texture of the shell in their own hand as well.
      </p>

      <figure>
        <img
          src={
            process.env.PUBLIC_URL +
            "/img/dimassimo-weiss/J_J_5.1_1_COLLAGE_BL_2.png"
          }
        />
        <figcaption>
          (Left) Connie Porter, <em>Meet Addy: An American Girl</em> (Middleton,
          WI: American Girl Publishing, 1993), 39-40. Downloaded April 18, 2023.{" "}
          <a
            href="https://assets.contentstack.io/v3/assets/blte5bbb46adcc8c61b/blt50751ddf6b599cf5/620d21324ae5ae6845c6b3d6/Addy_meet.pdf"
            target="_blank"
            rel="noreferrer"
          >
            https://assets.contentstack.io/v3/assets/.../.../Addy_meet.pdf
          </a>{" "}
          (Right) vanessa german, <em>Parade to the Baptism</em>, 2013. Mixed
          media. The Westmoreland Museum of American Art. Image courtesy Kaela
          Speicher for Concept Art Gallery.
        </figcaption>
      </figure>

      <p>
        As a diasporic relic existing alongside its holder, this cowrie shell is
        a piece of family legacy kept safe and handed down alongside an oral
        tradition. This shell was a piece of home for Addy’s great-grandmother;
        for Addy herself, it is a tangible reminder of her family’s history, a
        link that connects her directly to Aduke, and a hope for a more
        promising future. From this point in the series onward, Addy wears the
        cowrie shell as a necklace laced onto a shoestring of her brother’s,
        keeping her family and her ancestors close to her heart even when they
        are separated.
        <sup>
          <a href="#annotation-2">2</a>
        </sup>{" "}
      </p>

      <figure>
        <img
          src={
            process.env.PUBLIC_URL +
            "/img/dimassimo-weiss/J_J_5.1_1_COLLAGE_BL_3.png"
          }
        />
        <figcaption>
          (Left) vanessa german, <em>Untitled (Power Figure)</em>, 2015. Mixed
          media. Concept Art Gallery. Image courtesy Kaela Speicher for Concept
          Art Gallery. (Right) Connie Porter,{" "}
          <em>Meet Addy: An American Girl</em> (Middleton, WI: American Girl
          Publishing, 1993), 40-41.
        </figcaption>
      </figure>

      <p>
        Addy’s cowrie shell is a vessel for movement and resistance: a handheld
        reminder of Aduke’s forced transplantation in the Atlantic slave trade,
        shared in the midst of the Walker family’s self-emancipation to
        Philadelphia. In vanessa german’s mixed media works, cowrie shells are
        also present as one of many elements adorning her power figure
        sculptures, which are themselves shaped by themes of movement and
        resistance. Between the ships carefully balanced on their heads, the
        shoes they carry, and the skateboards they glide on, these figures are
        brimming with the potential of sailing, walking, skating forward. German
        alludes to this movement in a poetic and ever-mounting tally of
        materials alongside her sculptures. Notable especially in the piece{" "}
        <em>
          2 ships passing in the night, or i take my soul with me everywhere i
          go, thank you
        </em>
        , German lists the ocean and “slavery on an everyday basis as made real
        and present by the reality of white supremacy,” followed by her
        repetitions of the words “the journey.”{" "}
        <sup>
          <a href="#annotation-3">3</a>
        </sup>{" "}
        While cowrie shells are absent in this list, the Atlantic slave trade
        which carried them across the ocean is overwhelmingly present.
      </p>

      <figure>
        <img
          src={
            process.env.PUBLIC_URL +
            "/img/dimassimo-weiss/J_J_5.1_1_COLLAGE_BL_4.png"
          }
        />
        <figcaption>
          (Left) Connie Porter, <em>Meet Addy: An American Girl</em> (Middleton,
          WI: American Girl Publishing, 1993), 41. (Right) vanessa german,{" "}
          <em>Toaster</em>, 2011. Found glass bottle, hand wrought beads,
          fabric, earth, string, thread, hair, mantle bird, electric outlet,
          cowrie, buttons, spark plugs, old doll parts, plaster, wood, wood
          paint, vintage match books, found toaster, framed slave ownership
          photograph of Delilah, found jewelry, found carved wooden banana, and
          fabric. Carnegie Museum of Art. Image courtesy Kaela Speicher for
          Concept Art Gallery.
        </figcaption>
      </figure>

      <p>
        As pieces of diasporic material culture, cowrie shells are imbued with
        deeply rooted histories; through German’s work, they are also reinforced
        and reinscribed with new significance. Often German fashions her figures
        with the shells as their mouths, echoing the shape of their eyes and
        drawing the viewer to look upon the shining white of these features, set
        in the midst of the deep matte black of the face. The cowrie shell
        mouths of these figures are slightly open, as if through parted lips
        they are “speaking themselves into existence.”
        <sup>
          <a href="#annotation-4">4</a>
        </sup>{" "}
        This concept of self-determination is rooted in the material itself:
        German admires the cowrie shell because it comes from “a creature that
        makes its home by secreting material from its own body.”
        <sup>
          <a href="#annotation-5">5</a>
        </sup>{" "}
        Like this little creature, vanessa german has carved out a place for
        herself and her art using the materials she has at hand, and her
        sculptures also seem to be in vessels and bodies of their own making.
        Draped with an assortment of accumulated objects, the power figures
        might appear burdened and weighed down by their layered skirts of shells
        and found items. At the same time, they are resplendent and lavished
        with abundance, with a shell as a mouthpiece and a relic of cultural
        consistency, to call upon and honor African traditions.
      </p>

      <figure>
        <img
          src={
            process.env.PUBLIC_URL +
            "/img/dimassimo-weiss/J_J_5.1_1_COLLAGE_BL_5.png"
          }
        />
        <figcaption>
          (Left) vanessa german,{" "}
          <em>
            2 ships passing in the night, or i take my soul with me everywhere i
            go, thank you
          </em>
          , 2014. Mixed media. The Westmoreland Museum of American Art. Image
          courtesy Kaela Speicher for Concept Art Gallery. (Right) Connie
          Porter, <em>Meet Addy: An American Girl</em> (Middleton, WI: American
          Girl Publishing, 1993), 59.
        </figcaption>
      </figure>

      <h2>Bibliography</h2>
      <ul className="bibliography">
        <li>
          “Collection: Parade to the Baptism, Vanessa German.” The Westmoreland
          Museum of Art, accessed February 10, 2023.{" "}
          <a href="https://collection.thewestmoreland.org/objects-1/info/7711?sort=0">
            https://collection.thewestmoreland.org/objects-1/info/7711?sort=0
          </a>
          .
        </li>

        <li>
          “Collection: Vanessa German, Toaster.” Carnegie Museum of Art.
          Accessed February 10, 2023.{" "}
          <a href="https://collection.cmoa.org/objects/476b28a2-83f8-4da0-a011-37799c6fd9e3">
            https://collection.cmoa.org/objects/476b28a2-83f8-4da0-a011-37799c6fd9e3
          </a>
          .
        </li>

        <li>
          German, Vanessa. “i take my soul with me everywhere i go.”{" "}
          <em>The Georgia Review</em>, Summer 2016. Accessed August 26, 2021.{" "}
          <a href="https://thegeorgiareview.com/posts/i-take-my-soul-with-me-everywhere-i-go/#site-header">
            https://thegeorgiareview.com/posts/i-take-my-soul-with-me-everywhere-i-go/#site-header
          </a>
          .
        </li>

        <li>
          Heath, Barbara J. “Cowrie Shells, Global Trade, and Local Exchange:
          Piecing Together the Evidence for Colonial Virginia.”{" "}
          <em>Historical Archaeology</em> 50, no. 2 (2016): 17–46. Accessed
          August 26, 2021.{" "}
          <a href="http://www.jstor.org/stable/24757075">
            http://www.jstor.org/stable/24757075
          </a>
          .
        </li>

        <li>
          Jones, Tracy. “Vanessa German Commits ‘A Violence to the Lie.’”{" "}
          <em>The Microscopic Giant</em>, January 17, 2019.{" "}
          <a href="http://themicrogiant.com/vanessa-german-commits-a-violence-to-the-lie/">
            http://themicrogiant.com/vanessa-german-commits-a-violence-to-the-lie/
          </a>
          .
        </li>

        <li>
          Pearce, Laurie Elisabeth. “The Cowrie Shell in Virginia: A Critical
          Evaluation of Potential Archaeological Significance.” Master’s thesis,
          The College of William and Mary, 1992.{" "}
          <a href="https://dx.doi.org/doi:10.21220/s2-e12a-8115">
            https://dx.doi.org/doi:10.21220/s2-e12a-8115
          </a>
          .
        </li>

        <li>
          Porter, Connie. <em>Addy: A Heart Full of Hope</em>. Middleton, WI:
          American Girl Publishing, 2020.
        </li>

        <li>
          Porter, Connie. <em>Meet Addy</em>. Middleton, WI: American Girl
          Publishing, 1993.
        </li>
      </ul>
      <hr />
      <cite>
        <sup id="annotation-1">1.</sup> Barbara J. Heath, “Cowrie Shells, Global
        Trade, and Local Exchange: Piecing Together the Evidence for Colonial
        Virginia,” <em>Historical Archaeology</em> 50, no. 2 (2016): 17,
        accessed August 26, 2021,{" "}
        <a
          href="http://www.jstor.org/stable/24757075"
          target="_blank"
          rel="noreferrer"
        >
          http://www.jstor.org/stable/24757075
        </a>
        .
      </cite>

      <cite>
        <sup id="annotation-2">2.</sup> Connie Porter,{" "}
        <em>Meet Addy: An American Girl</em> (Middleton, WI: American Girl
        Publishing, 1993), 39–41.
      </cite>

      <cite>
        <sup id="annotation-3">3.</sup> vanessa german, “i take my soul with me
        everywhere i go,” <em>The Georgia Review</em>, Summer 2016, accessed
        August 26, 2021,{" "}
        <a
          href="https://thegeorgiareview.com/posts/i-take-my-soul-with-me-everywhere-i-go/#site-header"
          target="_blank"
          rel="noreferrer"
        >
          https://thegeorgiareview.com/posts/i-take-my-soul-with-me-everywhere-i-go/#site-header
        </a>
        .
      </cite>

      <cite>
        <sup id="annotation-4">4.</sup> Tracy Jones, “Vanessa German Commits ‘A
        Violence to the Lie,’” <em>The Microscopic Giant</em>, January 17, 2019,{" "}
        <a
          href="http://themicrogiant.com/vanessa-german-commits-a-violence-to-the-lie/"
          target="_blank"
          rel="noreferrer"
        >
          http://themicrogiant.com/vanessa-german-commits-a-violence-to-the-lie/
        </a>
        .
      </cite>

      <cite>
        <sup id="annotation-5">5.</sup> Jones, “Vanessa German Commits ‘A
        Violence to the Lie.’”
      </cite>
    </div>
  );
};

export default Weiss;
