const Foreword = () => {
  return (
    <div>
      <h2 className="center">
        From Archive to Zombie: Crafting an Encyclopedia
      </h2>
      <p>
        In elementary school, research assignments meant turning to the set of
        cream-and-brown World Book encyclopedias in my parents' living room and
        getting lost for a while. For example, if I needed to write about
        Hungary, I'd dutifully flip to <em>Hungary</em> and copy down the
        population, geographical features, and gross domestic product, but then
        I'd reach the part about culture and, wait, what the heck is{" "}
        <em>goulash</em>? From there, I'd read about <em>paprika</em>, dive into
        the <em>spice trade</em>, hopscotch to <em>Vasco da Gama</em>, cruise
        through the section on <em>Portugal</em>, and end up looking at photos
        of <em>Iberian foxes</em>. By charting a course through multiple
        volumes, I learned the nerdy pleasures of gathering nuggets of
        knowledge, making my own tenuous associations, and feeding my curiosity.
        My school report might suffer, but these self-guided journeys felt
        exhilarating and world-expanding.
      </p>

      <p>
        I haven't touched a printed encyclopedia for decades; for better or
        worse, the internet has become the first place I turn to for
        information. Now, thinking of a set of instantly out-of-date books as a
        single repository of truth is inconceivable. And yet, I still think the
        encyclopedia format has its uses as a tantalizing way to induce action
        and sensation by prompting its readership to flip, skim, think, and
        wonder.
      </p>

      <h2 className="center">Structure</h2>

      <p>
        When I thought about creating a publication for my degree in Critical
        Craft Studies (MACR) that encapsulated my research and experience in the
        program, I knew I wanted to portray craft studies as always moving
        forward. My effort would contain definitions and examples but would not
        end there. Even though MACR itself is ending prematurely, what was
        made—ideas and writing and objects and connections—will remain on the
        move, weaving its way through craft discourse at large. I did not want
        to create a headstone to mark its demise, with facts and figures forever
        engraved in granite. At the same time, I was eager to utilize the
        strengths of publications—familiarity, readability, and printability—to
        communicate. How would I combine these desires to showcase specificity
        through text yet create a sense that craft studies is always evolving?
      </p>

      <p>
        An encyclopedia seemed the right format to echo the program: sometimes
        cohesive, planned, and linear, and other times spontaneous and
        experimental. Recording craft and craft-adjacent bits and pieces (that
        relate in the end, because everything does) would ensure that what was
        taught by MACR and what I got out of it is not lost. I could also
        process my complicated feelings about the end of the program through the
        publication structure, directly addressing loss through entry text or
        hinting at transformative action with the entry titles.
      </p>

      <h2 className="center">Concept</h2>

      <p>
        A good encyclopedia, like MACR, creates more questions than answers.
        Andrew Clark argues that the 18<sup>th</sup>-century{" "}
        <em>Encyclopédie</em>, helmed by Denis Diderot and created by over 100
        contributors during the Enlightenment, was always being renewed.
      </p>

      <blockquote className="indented">
        <p>
          <em>The Encyclopédie</em> reconfigures the idea of a compendiums
          signifying process by turning passive definitions into active or
          interactive articles whose resistance to the whole paradoxically (in
          the context of a "reference" work) requires the reader continually to
          question his relation to knowledge, closure and universal truth.
          <sup>
            <a href="#annotation-1">1</a>
          </sup>
        </p>
      </blockquote>

      <p>
        Diderot's decision to hold space for his readers lets them move from
        accepting the words before them to questioning why those words were
        used. The <em>Encyclopédie</em> codifies a curated and edited point of
        view but is also a document that comes alive when entries join each
        other in conversation.
      </p>

      <p>
        Clark believes Diderot created "a horizontal model of critique and
        interaction" by approving writing that was free to cause trouble with
        other entries or be self-reflexive.
        <sup>
          <a href="#annotation-2">2</a>
        </sup>{" "}
        I found echoes of this approach in artist Sara Cwynar's{" "}
        <em>Kitsch Encyclopedia</em>, a reflection on kitsch imagery that
        constitutes a world overlaying the real world, cleverly pulled from
        found images and the writing of Milan Kundera, Roland Barthes, and Jean
        Baudrillard. Her selections can be read or viewed at face value but also
        inspire additional movement throughout as the reader compares,
        reassesses, and questions Cwynar's choices. This process makes a
        bricolage of impressions and implications; it creates, as the kids call
        it, a whole vibe. The motivating concepts behind these encyclopedias
        became the model for my own.
      </p>

      <h2 className="center">Organization</h2>

      <p>
        The <em>Encyclopedia of Craft Studies (Abridged)</em>, or ECS-A,
        includes entries that roughly follow the lines of investigation
        experienced in the program: tackling higher-level conceptual
        explorations; learning how craft objects operate in this space; and
        forming actions and reactions based on what was learned. I selected 31
        entry words to present a multiplicity of ways to think about endings and
        beginnings. Like in the <em>Encyclopédie</em>, entries orbit their topic
        at different distances, and may be literal, poetic, visual,
        autobiographical, analytical, anecdotal, or seemingly nonsensical. While
        the relationships between entries may seem inscrutable, knowing the
        rules of the game&mdash;that connections <em>might</em>{" "}
        exist&mdash;encourages the kind of critical evaluation and speculation
        that is part of MACR's pedagogy which will continue beyond the programs
        closure.
      </p>

      <h2 className="center">Connections</h2>

      <p>
        This should probably be called the{" "}
        <em>Encyclopedia of Rena's Craft Studies</em> since I am the crucible
        for its contents. However, without the voices of those analyzing,
        tending to, and making craft history, this publication would be far less
        rich. I included new writing from past and present MACR community
        members, relevant reading from the syllabi, outside reading I discovered
        and enjoyed, and commissions by craft artists whose work addresses death
        and rebirth, loss, and absence.
      </p>

      <p>
        From its inception, the ECS-A was meant to be grounded in the history of
        MACR and yet contribute new meaning to the field of craft. Taken as a
        whole, it turned into a kind of self-portrait. If it generates feelings
        of sadness, outrage, surprise, glee, or confusion, then you will feel
        some of what I feel. Hopefully it is also fun to browse! Skip around the
        entries and feel free to enjoy wherever your journey takes you.
      </p>

      <h2 className="center">Bibliography</h2>

      <ul className="bibliography">
        <li>
          Clark, Andrew H. "The Parts of the Encyclopedia." <em>Shark</em> 4
          (Summer 2002): 165–77.
        </li>
      </ul>

      <hr />

      <cite>
        <sup id="annotation-1">1.</sup> Andrew H. Clark, "The Parts of the
        Encyclopedia," <em>Shark</em> 4 (Summer 2002): 171.
      </cite>

      <cite>
        <sup id="annotation-2">2.</sup> Clark, 175. "In the{" "}
        <em>Encyclopédie</em>, the various levels of the text exist at much
        closer and greater distances: closer, an actual definition acts as
        commentary or polemic; at a greater distance, a cross-reference to
        another volume might undermine the definition in the volume being read."
      </cite>
    </div>
  );
};

export default Foreword;
