export const KEYWORD_MAP = [
  [
    "a",
    [
      {
        id: "absence",
        name: "absence",
      },
      {
        id: "aesthetics",
        name: "aesthetics",
      },
      {
        id: "agency",
        name: "agency",
      },
      {
        id: "anthropology",
        name: "anthropology",
      },
      {
        id: "antiracism",
        name: "anti-racism",
      },
      {
        id: "appalachia",
        name: "appalachia",
      },
      {
        id: "apprenticeship",
        name: "apprenticeship",
      },
      {
        id: "archives",
        name: "archives",
      },
      {
        id: "askingquestions",
        name: "asking questions",
      },
    ],
  ],
  [
    "b",
    [
      {
        id: "basketweaving",
        name: "basket weaving",
      },
      {
        id: "bias",
        name: "bias",
      },
      {
        id: "bibliography",
        name: "bibliography",
      },
      {
        id: "biography",
        name: "biography",
      },
      {
        id: "blackcraftspeople",
        name: "black craftspeople",
      },
      {
        id: "blackfeminism",
        name: "black feminism",
      },
      {
        id: "blackgirlhood",
        name: "black girlhood",
      },
      {
        id: "blackhistory",
        name: "black history",
      },
      {
        id: "blackwomen",
        name: "black women",
      },
    ],
  ],
  [
    "c",
    [
      {
        id: "ceramic",
        name: "ceramic",
      },
      {
        id: "chairs",
        name: "chairs",
      },
      {
        id: "chance",
        name: "chance",
      },
      {
        id: "chapter",
        name: "chapter",
      },
      {
        id: "commentary",
        name: "commentary",
      },
      {
        id: "community",
        name: "community",
      },
      {
        id: "conversation",
        name: "conversation",
      },
      {
        id: "coursework",
        name: "coursework",
      },
      {
        id: "craftpractices",
        name: "craft practices",
      },
      {
        id: "craftstudies",
        name: "craft studies",
      },
      {
        id: "craftwayspresenters",
        name: "craft ways (presenters)",
      },
      {
        id: "craftscape",
        name: "craftscape",
      },
      {
        id: "criticalracetheory",
        name: "critical race theory",
      },
      {
        id: "criticism",
        name: "criticism",
      },
      {
        id: "curation",
        name: "curation",
      },
    ],
  ],
  [
    "d",
    [
      {
        id: "dance",
        name: "dance",
      },
      {
        id: "death",
        name: "death",
      },
      {
        id: "decolonization",
        name: "decolonization",
      },
      {
        id: "destruction",
        name: "destruction",
      },
      {
        id: "diaspora",
        name: "diaspora",
      },
      {
        id: "digitalcommunication",
        name: "digital communication",
      },
      {
        id: "dye",
        name: "dye",
      },
    ],
  ],
  [
    "e",
    [
      {
        id: "ecology",
        name: "ecology",
      },
      {
        id: "education",
        name: "education",
      },
      {
        id: "embodiedknowledge",
        name: "embodied knowledge",
      },
      {
        id: "encounters",
        name: "encounters",
      },
      {
        id: "enslavement",
        name: "enslavement",
      },
      {
        id: "erasure",
        name: "erasure",
      },
      {
        id: "essay",
        name: "essay",
      },
      {
        id: "ethnography",
        name: "ethnography",
      },
      {
        id: "excerpt",
        name: "excerpt",
      },
    ],
  ],
  [
    "f",
    [
      {
        id: "faith",
        name: "faith",
      },
      {
        id: "fieldwork",
        name: "fieldwork",
      },
      {
        id: "future",
        name: "future",
      },
    ],
  ],
  ["g", []],
  [
    "h",
    [
      {
        id: "healing",
        name: "healing",
      },
      {
        id: "heritage",
        name: "heritage",
      },
    ],
  ],
  [
    "i",
    [
      {
        id: "identity",
        name: "identity",
      },
      {
        id: "imaginedfutures",
        name: "imagined futures",
      },
      {
        id: "indigenousknowledge",
        name: "indigenous knowledge",
      },
      {
        id: "indigenousstudies",
        name: "indigenous studies",
      },
      {
        id: "interdisciplinarity",
        name: "interdisciplinarity",
      },
      {
        id: "interview",
        name: "interview",
      },
      {
        id: "introduction",
        name: "introduction",
      },
      {
        id: "invitation",
        name: "invitation",
      },
    ],
  ],
  ["j", []],
  [
    "k",
    [
      {
        id: "knowledgeclassification",
        name: "knowledge classification",
      },
    ],
  ],
  [
    "l",
    [
      {
        id: "labor",
        name: "labor",
      },
      {
        id: "land",
        name: "land",
      },
      {
        id: "learningwithobjects",
        name: "learning with objects",
      },
    ],
  ],
  [
    "m",
    [
      {
        id: "macrprogram",
        name: "macr program",
      },
      {
        id: "makingspace",
        name: "making space",
      },
      {
        id: "mapping",
        name: "mapping",
      },
      {
        id: "materialknowledge",
        name: "material knowledge",
      },
      {
        id: "materiality",
        name: "materiality",
      },
      {
        id: "memory",
        name: "memory",
      },
      {
        id: "methodology",
        name: "methodology",
      },
      {
        id: "movement",
        name: "movement",
      },
      {
        id: "museumlabels",
        name: "museum labels",
      },
    ],
  ],
  [
    "n",
    [
      {
        id: "newwriting",
        name: "new writing",
      },
      {
        id: "newwritingalumni",
        name: "new writing (alumni)",
      },
      {
        id: "newwritingcontributor",
        name: "new writing (contributor)",
      },
      {
        id: "newwritingfaculty",
        name: "new writing (faculty)",
      },
      {
        id: "newwritingstudent",
        name: "new writing (student)",
      },
    ],
  ],
  [
    "o",
    [
      {
        id: "objectlessness",
        name: "objectlessness",
      },
      {
        id: "oralhistory",
        name: "oral history",
      },
    ],
  ],
  [
    "p",
    [
      {
        id: "pedagogy",
        name: "pedagogy",
      },
      {
        id: "performance",
        name: "performance",
      },
      {
        id: "phenomenology",
        name: "phenomenology",
      },
      {
        id: "philosophy",
        name: "philosophy",
      },
      {
        id: "place",
        name: "place",
      },
      {
        id: "poetry",
        name: "poetry",
      },
      {
        id: "publichistory",
        name: "public history",
      },
    ],
  ],
  [
    "q",
    [
      {
        id: "queerlife",
        name: "queer life",
      },
      {
        id: "questions",
        name: "questions",
      },
    ],
  ],
  [
    "r",
    [
      {
        id: "renewal",
        name: "renewal",
      },
      {
        id: "repair",
        name: "repair",
      },
      {
        id: "reprint",
        name: "reprint",
      },
      {
        id: "resistance",
        name: "resistance",
      },
    ],
  ],
  [
    "s",
    [
      {
        id: "selfknowledge",
        name: "self-knowledge",
      },
      {
        id: "selfpublishing",
        name: "self-publishing",
      },
      {
        id: "skillacquisition",
        name: "skill acquisition",
      },
      {
        id: "socialjustice",
        name: "social justice",
      },
      {
        id: "somatics",
        name: "somatics",
      },
      {
        id: "supportnetworks",
        name: "support networks",
      },
    ],
  ],
  [
    "t",
    [
      {
        id: "teachingpractice",
        name: "teaching practice",
      },
      {
        id: "transcience",
        name: "transcience",
      },
      {
        id: "translation",
        name: "translation",
      },
    ],
  ],
  ["u", []],
  [
    "v",
    [
      {
        id: "visualessay",
        name: "visual essay",
      },
    ],
  ],
  [
    "w",
    [
      {
        id: "weaving",
        name: "weaving",
      },
      {
        id: "woodworking",
        name: "woodworking",
      },
      {
        id: "worldmaking",
        name: "world making",
      },
      {
        id: "writing",
        name: "writing",
      },
    ],
  ],
  ["x", []],
  ["y", []],
  ["z", []],
];
