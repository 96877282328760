const Skov = () => {
  return (
    <div>
      <p>
        The bowl is a primal vessel, arguably the oldest object type that humans
        ever made for themselves from the muddy banks of ancient rivers where
        the clays for crafting vessels were found. In every culture across the
        world we have created bowls to store, carry, serve, present, and
        display. They represent a maker’s talent, material beauty, aesthetic
        preferences, humble earthiness, and often great wealth. From the
        practical to the poetic, bowls hold these qualities in a complex and
        delicate balance.
      </p>

      <p>
        We all know and love at least one bowl. So, for the first brief writing
        assignment in our Summer 2021 residency, the first of what I knew would
        be an intense year of acclimating to the MACR program, I invited each
        student to bring along a friend. A bowl. <em>Their</em> bowl. They would
        tell a curatorial story about this bowl in the simplified form of a
        museum label. And in four short days, they would have a taste of
        everything that our program would demand of them over the next two
        years. They would look closely at craft objects. Employ new
        methodologies. Write for context. Draft, revise, and polish their words.
        Plumb objects for deeper meaning. And share what they learned with their
        new community, a group of craft enthusiasts and thinkers scattered
        across the country. Despite the distance between us imposed by pandemic
        teaching and learning, our bowls would bring us together.
      </p>

      <p>
        To ground our museum-label writing, we looked at a specific exhibition,{" "}
        <em>Object Focus: The Bowl</em>, a past project from our program
        director, Namita Gupta Wiggers, that still resonates today, even 10
        years after it opened at Portland’s Museum of Contemporary Craft (see{" "}
        <a
          href="https://objectfocusbowl.tumblr.com/"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        ). This exhibition began in a traditional way, with a display of bowls
        from the museum’s permanent collection and borrowed from individual
        collectors. But Namita’s approach expanded the exhibition beyond the
        museum walls into digital space using the Tumblr image-sharing platform.
        Another radical move was to crowdsource a wide range of diverse voices
        to tell their own stories about bowls of their choosing on Tumblr. This
        was not only groundmaking from a technical perspective, it also
        demonstrated Namita’s willingness to cede some curatorial power and
        trust her community to interpret each bowl for themselves. It modeled
        how a digital platform could be used to invite the public to engage
        actively with a museum exhibition, which is often a passive and rigidly
        designed experience. The exhibition had a rich online life as new essays
        were added daily. Our class contributions essentially extended the
        conversation out ten years, if only in concept.
      </p>

      <p>
        The merging of an object type born in ancient times with future-leaning
        exhibition strategies in <em>Object Focus: The Bowl</em> was a reminder
        to students that they would also need to be ready to use new (even
        not-yet-invented) methods and tools for reaching their audiences. As
        more experiences are moving to digital spaces and craft objects are
        being accessed through digital means, the interpretation of those
        objects is increasingly important. If we are going to move craft and
        critical thinking about craft forward in a digitally mediated world,
        then learning how to bring physical things to life is more essential
        than ever.
      </p>

      <p>
        I can now see clearly that this assignment about a vessel was a vessel,
        too—a place into which each person could pour their passions for craft
        and the materiality of objects and to delight in singular expressions of
        beauty. It was a place to get to know each other and to contemplate what
        it is we love about objects. It was a place to rest (if only for a
        moment) before jumping into the rigorous work each student came to do in
        our program.
      </p>

      <h2 className="center cursive">Reflections and Lessons</h2>

      <p className="center cursive">
        1.
        <br />
        There are countless stories to tell about every bowl, but we all need to
        choose one promising direction and focus on that. (Replace “bowl” with
        “life” if you like.)
      </p>

      <p className="center cursive">
        2.
        <br />
        The irony of immersing new students in a remote Zoom-based Materials Lab
        class without sharing the sensual experience of actual materials was not
        lost on any of us.
      </p>

      <p className="center cursive">
        3.
        <br />
        Humans are not going to stop making bowls and (long after you and I are
        gone) they are not going to stop wanting to talk about them and
        interpret them for others. It’s what we <em>do</em>.
      </p>

      <p className="center cursive">
        4.
        <br />
        If you are going to be inventing the future of anything, it’s helpful to
        bring along some old friends.
      </p>

      <hr />
    </div>
  );
};

export default Skov;
