import React from "react";
import Bio from "../Bio";
import { Helmet } from "react-helmet";
import { BIOS } from "../../data/bios";

const Bios = () => {
  return (
    <>
      <Helmet>
        <title>The MACR Papers | Bios</title>
        <link rel="canonical" href="https://www.themacrpapers.com/bios" />
      </Helmet>
      <div className="pane scrollable">
        <h2 className="page-title">Bios</h2>
        <div className="bio-grid">
          {BIOS.sort((a, b) => {
            if (a.id < b.id) return -1;
            if (a.id > b.id) return 1;
            return 0;
          }).map((bio, i) => (
            <Bio key={i} bio={bio} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Bios;
