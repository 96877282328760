const Potter = () => {
  return (
    <div>
      <p>
        I was invited by Namita Gupta Wiggers to dream up a virtual workshop for
        the MA in Critical Craft candidates during the early days of the
        pandemic in 2020. I was living in a Chicago apartment, unclear whether
        the laundry room was safe, and locked out of my papermaking studio. I
        wondered, what did it mean to be making art in this time of pandemic and
        climate crisis? What could the students and I collectively and
        individually make with what we had, and how could that heighten our
        awareness of our distinct locations—from Alaska to North Carolina?
        Joined by Mellanee Goodman, Phoebe Kuo, Amy Meissner, Heather Powers
        (all Class of 2021); Joni Van Bockel, Shannon Donohue, Kat Gordon,
        Laurin Guthrie, Lexie Harvey, Kate Hawes, Maru Lopez, Kae Lorentz,
        Colleen Ramsay Hoesch (all Class of 2022), we each created a papermaking
        studio in our respective lockdown environment. I shared with them a
        process I had designed using bare-bones equipment for making paper,
        using water and a mason jar with agitated recycled paper and egg
        cartons. Students immediately got to work with passion, humor, and
        excitement. The outcomes were broad and wonderful: old film canisters
        and baskets were transformed into sculptural forms made of paper, dog
        hair and fallen lichen became inclusions in the paper. I learned from
        the pandemic teaching community about Padlet, an online gallery and post
        board, and put it to work as a live-time critique space. Students posted
        their interpretations and gave critical feedback to one another,
        responding to readings about material feminism and climate emergency
        through their artistic interpretations. Though I have returned to an
        in-person classroom—still my first choice—I am deeply affected by the
        generous and formative pandemic pedagogy we were all forced to create in
        record time. When my campus closed down, I was adrift. Through the
        Pandemic Papermaking experiment, I regained my faith in our collective
        agreement to make and think through this time together no matter where
        we are, and in what circumstance.
      </p>

      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_6.1_MACCKG.jpg"}
        />
      </figure>

      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_6.2_MACCPK2.jpg"}
        />
      </figure>

      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_6.3_MACCAM.jpg"}
        />
      </figure>

      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_6.4_MACCKH.jpg"}
        />
      </figure>

      <figure>
        <img
          src={
            process.env.PUBLIC_URL + "/img/notaretreat/B_N_6.5_MACCPaperLog.jpg"
          }
        />
      </figure>

      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_6.6_MACCCH.jpg"}
        />
      </figure>

      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_6.7_MACCA.jpg"}
        />
      </figure>

      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_6.8_MACCLH.jpg"}
        />
      </figure>

      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_6.9_MACCML.jpg"}
        />
      </figure>

      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_6.10_MACCKL.jpg"}
        />
      </figure>

      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_6.11_MACCHP.jpg"}
        />
      </figure>

      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_6.12_MACCMG.jpg"}
        />
      </figure>

      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_6.13_MACCHP2.jpg"}
        />
      </figure>

      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_6.14_MACCJV.jpg"}
        />
      </figure>
      <br />
    </div>
  );
};

export default Potter;
