import React, { useState } from "react";
import Menu from "./components/Menu";
import MobileMenu from "./components/MobileMenu";
import ScrollTop from "./components/ScrollTop";
import { Outlet } from "react-router";
import { NavLink } from "react-router-dom";
import { ReactComponent as Logo } from "./assets/logo.svg";
import { useMediaQuery } from "react-responsive";

function App({ onMenuToggle }) {
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });

  const toggleMenu = () => {
    setOpen(!open);
    onMenuToggle();
  };

  return (
    <>
      <ScrollTop />
      {isMobile ? <MobileMenu open={open} onClose={toggleMenu} /> : null}
      <div className="grid">
        <div className="menus">
          <NavLink
            className="logo"
            to="/"
            style={{ display: "block", width: "120px" }}
          >
            <Logo />
          </NavLink>

          {isMobile ? (
            <>
              <button
                type="button"
                className="mobile-menu__toggle"
                onClick={toggleMenu}
              >
                <div></div>
                <div></div>
                <div></div>
              </button>
            </>
          ) : (
            <>
              <ul className="top-menu">
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>
                <li>
                  <NavLink to="/keywords">Keywords</NavLink>
                </li>
                <li>
                  <NavLink to="/bios">Bios</NavLink>
                </li>
              </ul>
              <Menu />
            </>
          )}
          <div className="menus-backdrop"></div>
        </div>
        <Outlet />
      </div>
    </>
  );
}

export default App;
