import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { PUBLICATIONS, COLORS } from "../../publications";
import ArticleMenuItem from "../ArticleMenuItem";
import Classnames from "classnames";

const PublicationNav = ({ publication, onClick }) => {
  const [open, setOpen] = useState(false);

  const toggleClasses = Classnames("mobile-menu__publication-toggle", {
    "mobile-menu__publication-toggle--open": open,
  });

  const handleNavClick = () => {
    setOpen(false);
    onClick();
  };

  const toggleArticles = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  return (
    <>
      <div
        className="mobile-menu__publication"
        style={{ backgroundColor: publication.accent }}
      >
        <NavLink onClick={handleNavClick} to={`${publication.id}`}>
          <div>{publication.author}</div>
          <div>{publication?.name}</div>
        </NavLink>
        <button className={toggleClasses} onClick={toggleArticles}>
          <div className="mobile-menu__caret"></div>
        </button>
      </div>
      {open && (
        <ul>
          {publication.articles.map((a, i) => (
            <li key={i}>
              <ArticleMenuItem
                article={a}
                to={`/${a.pubId}/${a.id}`}
                highlight={COLORS[a.pubId]}
                onClick={handleNavClick}
              />
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

const MobileMenu = ({ open, onClose }) => {
  const menuClasses = Classnames("mobile-menu", { "mobile-menu--open": open });

  const handleClick = () => {
    onClose();
  };

  return (
    <div className={menuClasses}>
      <div className="mobile-menu__header">
        <button
          onClick={handleClick}
          type="button"
          className="mobile-menu__close"
        >
          <div></div>
          <div></div>
        </button>
      </div>
      <ul className="top-menu">
        <li>
          <NavLink to="/" onClick={handleClick}>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink to="/keywords" onClick={handleClick}>
            Keywords
          </NavLink>
        </li>
        <li>
          <NavLink to="/bios" onClick={handleClick}>
            Bios
          </NavLink>
        </li>
      </ul>
      {PUBLICATIONS.map((pub, i) => {
        return (
          <PublicationNav key={i} publication={pub} onClick={handleClick} />
        );
      })}
    </div>
  );
};

export default MobileMenu;
