const Momon = () => {
  return (
    <div>
      <p>
        Something happened between the first time I taught a workshop in the
        MACR program, in January 2021, and the last time I taught a workshop in
        the MACR program, in January 2023. The first experience was via Zoom,
        with all of virtual learning’s clunkiness and disjointedness. It felt
        more like a regular academic Zoom lecture. I found nothing particularly
        special about it at the time. The last experience was in person, and it
        was a fitting end to what had been, for me, a trip beyond my comfort
        zones into a space where I felt like I could truly experience what
        learning and collaborating could be. Although it was just two years
        between the first and last experiences, looking back, I see how
        transformational those two years were.{" "}
      </p>
      <p>
        I have never considered myself to be a traditional historian or
        professor. My foray into the history field came through my love of Black
        people and Black stories. I gladly abandoned a career in corporate
        America, barely working my way up the corporate lattice from the
        isolation of my cubicle, to go back to college, earning a BA in African
        and African American Studies and an MA and PhD in Public History. It was
        during those years of earning my MA and PhD that my studies became
        fixated on Black people and the power of place. I wanted to see past
        generations of Black people on the landscape. I wanted to walk where
        they walked and touch the ground from which they came and to which they
        would return. I wanted to feel them. Throughout my subsequent career, I
        have tried to incorporate this sensory experience as much as possible.
        What I realized was that within these sensory experiences, I was
        dreaming. I was envisioning past lives and the way those lives
        intersected with mine.
      </p>
      <p>
        I first met Namita Wiggers in June 2020. The subject line of our first
        email exchange read “Hoping to connect re: a student doing work that
        intersects with yours.” As I look over that email all this time later, a
        few keywords stand out to me—<em>build, connect, future,</em> and{" "}
        <em>craft</em>. It was those words that drew me into the program, and
        those words became my sustenance. Those words became my reason for
        returning to the program, even when my life was overwhelming due to the
        pandemic; the intense growth of my research project, the Black
        Craftspeople Digital Archive; and the uncertainty in my academic career.
        What I anticipated in joining this program was the building of new
        worlds, and I did not want to miss my chance.{" "}
      </p>
      <h2>Build</h2>
      <p>
        The first year I co-taught the History and Theory 1 summer course as
        part of the MACR Teaching Fellowship, I created a soundtrack for the
        class. The song for our first session was “People Get Ready,” by Curtis
        Mayfield and The Impressions. The lyrics of the first verse are as
        follows:{" "}
      </p>

      <blockquote>
        <p>
          People get ready
          <br />
          There’s a train a-coming
          <br />
          You don’t need no baggage
          <br />
          You just get on board
          <br />
          All you need is faith
          <br />
          To hear the diesels humming
          <br />
          Don’t need no ticket
          <br />
          You just thank the Lord
        </p>
      </blockquote>

      <p>
        Recorded in 1965 at the height of the civil rights movement, “People Get
        Ready” is a song about preparing for the afterlife, imbued with social
        commentary as only Curtis Mayfield could do. It is a song that tells you
        everything yet tells you nothing, as it leaves so many possible
        questions about your voyage unanswered. All barriers to entry are
        removed. The song demands that you rely on faith.
      </p>
      <p>
        My goal in that course was to use music to encourage students to think
        about what I hoped would happen in the class—that we would challenge and
        change the systems that limited us, that we would move forward on faith,
        and that our end result would be something more than we could have
        imagined at the outset. When I created the course’s soundtrack, I had
        not considered just how much it was a commentary on where I was in my
        career and life. I had just joined a cohort of scholars in a field
        different from my own to build something new. I was intimidated. I was
        stepping into something new for which I did not feel prepared. Despite
        my apprehensions, I relied on faith, I got on board, and got ready to
        build.{" "}
      </p>
      <h2>Connect</h2>
      <p>
        In January 2023, MACR students Jill DiMassimo and Joanna Weiss
        interviewed me for the MACR student publication and described me as a
        “connecting thread.” Jill and Joanna pulled on this thread and described
        to me what they saw as my thread, beautifully unraveling and providing a
        foundation for other threads—students, thoughts, and ideas—to connect to
        different lines of inquiry and thought. When asked how I felt about it,
        I did not know what to say. I had never pictured myself that way before.
        I was just here because I had not wanted to miss my chance.{" "}
      </p>
      <p>
        In this program, I had the good fortune of teaching and mentoring
        graduate students. The two students that I have worked most closely
        with, Mellanee Goodman and Jill DiMassimo, pulled me out of what felt
        like a dark hole and reignited a fire in me that had gone dim. They both
        reminded me of the delight of researching, the joy of connecting thought
        and theory, and dreaming. I admired their curiosity and watched in
        wonder as they worked through their Practicum projects. Both students
        asked questions that felt so deep and intense that I often wondered what
        my role was in the mentor/mentee relationship. I learned as much from
        them as I hope they learned from me.{" "}
      </p>
      <h2>Future</h2>
      <p>
        One thought that Namita Wiggers and I often exchanged was that just
        because the world had always done something one way does not mean that
        we must continue to do that something in that one way. Working with the
        MACR program allowed me to dream in a way that I had never done before
        in academia. I suddenly became obsessed with the future. What did I want
        the field to become? How could I bring about significant change? How
        could I challenge systems? How could I incorporate the principles of the
        MACR program into my pedagogical practice? Into public history practice?{" "}
      </p>

      <figure>
        <img
          src={
            process.env.PUBLIC_URL +
            "/img/notaretreat/B_N_15_come_let_us_build.jpg"
          }
        />
        <figcaption>
          Fig. 1. Danny Lyon and Mark Suckle,{" "}
          <em>Come Let Us Build a New World Together</em>, 1963, poster. Danny
          Lyon took the photograph during a protest organized by the Student
          Nonviolent Coordinating Committee (SNCC) against a whites-only
          swimming pool in Cairo, Illinois. Mark Suckle designed the poster.
        </figcaption>
      </figure>

      <p>
        I printed out the picture above, folded it, and carried it with me.
        Congressman John Lewis, pictured on the left, is among the people I
        quote the most. The words on the image read “Come let us build a new
        world together.” When I think about what I want that world to look like,
        it is a world that both appreciates and celebrates Blackness. As someone
        who leads a Black digital humanities project, whose public history
        practice is centered on Black lives, and someone who works in the
        museums and historic sites field, I want a world where Black-centered
        exhibitions are the norm. It is a world where Black curators, exhibit
        designers, registrars, collections managers, scholars, and the like are
        able to function in spaces absent of anti-Blackness. It is a world where
        scholars like me are not told that our exhibitions are “too much
        history.” It is a world unlike any that we have seen before. As I dream
        of this future new world, I am reminded of Audre Lorde’s essay, “The
        Master’s Tools Will Never Dismantle the Master’s House.”
      </p>
      <h2>Craft</h2>
      <p>
        I approach my craft study through the lens of the decorative arts. The
        outlines of craft production in the decorative arts always bothered me
        because the field focuses so much on connoisseurship and aesthetics.
        While some may attempt to deny it, the decorative arts field is rather
        small, older, and predominantly white. There is a new group of young
        scholars that I will refer to here as decorative arts rebels who are not
        interested in doing things the same old way. However, getting to this
        point has been challenging because the field’s gatekeepers are slow to
        change. I often get the sense that they are waiting for this moment that
        we are in to pass.{" "}
      </p>
      <p>
        Approaching the study of the decorative arts through the MACR lens has
        added to my understanding of that field. Namita Wiggers’s coining of the
        phrase “craftscape” changed my way of thinking. It was the word that I
        knew I needed, yet did not know existed. Wiggers defines the craftscape
        as “a way of reframing craft not just as commodity objects that are
        sold, collected, or exhibited, but as social objects that are inscribed
        with histories and narratives that can tell us something about the
        world.”
        <sup>
          <a href="#annotation-1">1</a>
        </sup>{" "}
        What I have experienced in the decorative arts field is resistance,
        sometimes hostile, to sharing the truth that Black makers contributed to
        objects. Resistance to the reality that if a white craftsperson enslaved
        Black makers in their shop and those Black makers contributed to the
        creation of objects, we cannot solely attribute objects to the white
        enslaver craftsperson. The responses to me and this work have ranged,
        and continue to range, from hostility to anger. I have been told that
        the work I do questions everything, that it is a slippery slope, that I
        cannot prove what I claim, to even downright bizarre statements that
        just because a white cabinetmaker enslaved Black cabinetmakers this does
        not necessarily imply that Black cabinetmakers contributed to any
        objects. I am never surprised by the mental exercises people will take
        to justify their anti-Blackness.{" "}
      </p>
      <p>
        The idea of the craftscape appealed to me because it was an opportunity
        to see the many hands involved in production. It provided a way to
        examine the histories of materials or, as biologist David Haskell has
        coined, “the second life of objects,” or exploring the many lives of
        materials from their conception to their end. The outlines of craft
        production in the decorative arts are clear to me, but approaching the
        study of the decorative arts through the lens of the MACR program has
        provided me with a new set of tools to aid in the destruction of racist
        and oppressive systems that hold interpretation, board rooms, museum
        employees, and more back from reaching their full inclusive potential.{" "}
      </p>
      <h2>Conclusion</h2>
      <p>
        With the closure of the MACR program, I cannot help but dream of the
        what if. What if the program was given a full chance to grow? The
        complete ability to thrive? A full opportunity to succeed? I used to
        wonder if the program was ahead of its time. After reflection, I realize
        that it was actually right on time. It saved me. The MACR program was
        what many of us—students, faculty, and workshop leaders—needed to find
        our place and position ourselves for our voyage. We now possess the
        tools to build an anti-racist present and future. We heard the train’s
        whistle and the diesel’s humming. We are ready to board.{" "}
      </p>
      <hr />
      <cite>
        <sup id="annotation-1">1.</sup> Anjula Razdan, “Unearthing the
        Craftscape,” <em>American Craft Magazine</em>, May 27, 2021,{" "}
        <a
          href="https://www.craftcouncil.org/magazine/article/unearthing-craftscape"
          target="_blank"
          rel="noreferrer"
        >
          https://www.craftcouncil.org/magazine/article/unearthing-craftscape
        </a>
        .
      </cite>
    </div>
  );
};

export default Momon;
