import Foreword from "./foreword";
import Acknowledgments from "./acknowledgments";
import Epigraph from "./epigraph";
import Ancestry from "./ancestry";
import Aufhebung from "./aufhebung";
import Coalesce from "./coalesce";
import Ecology from "./ecology";
import Educate from "./educate";
import Enchant from "./enchant";
import Evolve from "./evolve";
import Ghost from "./ghost";
import Grave from "./grave";
import Legacy from "./legacy";
import Lifespan from "./lifespan";
import Liminal from "./liminal";
import Magic from "./magic";
import Map from "./map";
import Memorial from "./memorial";
import Offering from "./offering";
import Palimpsest from "./palimpsest";
import Portrait from "./portrait";
import Predict from "./predict";
import Publication from "./publication";
import Queer from "./queer";
import Reframe from "./reframe";
import Renewal from "./renewal";
import Repair from "./repair";
import Resolve from "./resolve";
import Souvenir from "./souvenir";
import Speculate from "./speculate";
import Subvert from "./subvert";
import Talisman from "./talisman";
import Zombie from "./zombie";
import Eulogy from "./eulogy";
import Archive from "./archive";

const publication = {
  pubId: "tom",
  id: "tom",
  author: "Tom",
  authorFull: "Rena Tom",
  name: "Encyclopedia of Craft Studies (Abridged)",
  articles: [
    {
      pubId: "tom",
      id: "foreword",
      order: 1,
      title: "Foreword",
      author: "Rena Tom",
      authorShort: "Tom",
      tags: [
        "new writing (student)",
        "introduction",
        "interdisciplinarity",
        "knowledge classification",
      ],
      citation: `Tom, Rena. "Foreword," Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/foreword",
      downloadUrl: `tom/Rena_1_Foreword.pdf`,
      component: <Foreword />,
    },
    {
      pubId: "tom",
      id: "acknowledgments",
      order: 2,
      title: "Acknowledgments",
      author: "Rena Tom",
      authorShort: "Tom",
      tags: ["community", "macr program", "support networks"],
      citation: `Tom, Rena. "Acknowledgments," Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/acknowledgments",
      downloadUrl: `tom/Rena_2_Acknowledgments.pdf`,
      component: <Acknowledgments />,
    },
    {
      pubId: "tom",
      id: "epigraph",
      order: 3,
      title: "Epigraph",
      author: "Rena Tom",
      authorShort: "Tom",
      tags: ["reprint", "excerpt", "future", "renewal"],
      citation: `Tom, Rena. “Epigraph,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/epigraph ",
      downloadUrl: `tom/Rena_3_Epigraph.pdf`,
      component: <Epigraph />,
    },
    {
      pubId: "tom",
      id: "ancestry",
      order: 4,
      title: "Ancestry",
      author: "Rena Tom",
      authorShort: "Tom",
      tags: [
        "reprint",
        "visual essay",
        "anthropology",
        "community",
        "heritage",
        "memory",
        "methodology",
      ],
      citation: `Tom, Rena. “Ancestry,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/ancestry",
      downloadUrl: `tom/Rena_4_Ancestry.pdf`,
      component: <Ancestry />,
    },
    {
      pubId: "tom",
      id: "archive",
      order: 5,
      title: "Archive",
      author: "Namita Gupta Wiggers",
      authorShort: "Wiggers",
      tags: [
        "new writing (faculty)",
        "essay",
        "appalachia",
        "black history",
        "community",
        "craft studies",
        "craftscape",
        "erasure",
        "heritage",
        "indigenous knowledge",
        "memory",
        "methodology",
        "making space",
      ],
      citation: `Wiggers, Namita Gupta. “Archive,” Encyclopedia of Craft Studies (Abridged),, edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7  `,
      url: "https://www.theMACRPapers.com/tom/archive",
      downloadUrl: `tom/Rena_5_Archive.pdf`,
      component: <Archive />,
    },
    {
      pubId: "tom",
      id: "aufhebung",
      order: 6,
      title: "Aufhebung",
      author: "Lucia Ilene Wallace",
      authorShort: "Wallace",
      tags: [
        "reprint",
        "excerpt",
        "absence",
        "ceramic",
        "destruction",
        "objectlessness",
        "renewal",
      ],
      citation: `Wallace, Lucia Ilene. “Aufhebung,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/aufhebung",
      downloadUrl: `tom/Rena_6_Aufhebung.pdf`,
      component: <Aufhebung />,
    },
    {
      pubId: "tom",
      id: "coalesce",
      order: 7,
      title: "Coalesce",
      author: "Rena Tom",
      authorShort: "Tom",
      tags: [
        "new writing (student)",
        "visual essay",
        "community",
        "future",
        "support networks",
      ],
      citation: `Tom, Rena. “Coalesce,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/coalesce",
      downloadUrl: `tom/Rena_7_Coalesce.pdf`,
      component: <Coalesce />,
    },
    {
      pubId: "tom",
      id: "ecology",
      order: 8,
      title: "Ecology",
      author: "Anna Lowenhaupt Tsing",
      authorShort: "Tsing",
      tags: ["reprint", "excerpt", "death", "destruction", "ecology", "future"],
      citation: `Tsing, Anna Lowenhaupt. “Ecology,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/ecology",
      downloadUrl: `tom/Rena_8_Ecology.pdf`,
      component: <Ecology />,
    },
    {
      pubId: "tom",
      id: "educate",
      order: 9,
      title: "Educate",
      author: "Rena Tom",
      authorShort: "Tom",
      tags: [
        "new writing (student)",
        "visual essay",
        "community",
        "craft studies",
        "pedagogy",
        "support networks",
      ],
      citation: `Tom, Rena. “Educate,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/educate",
      downloadUrl: `tom/Rena_9_Educate.pdf`,
      component: <Educate />,
    },
    {
      pubId: "tom",
      id: "enchant",
      order: 10,
      title: "Enchant",
      author: "Karen Wilson Baptist",
      authorShort: "Baptist",
      tags: [
        "reprint",
        "excerpt",
        "absence",
        "memory",
        "philosophy",
        "transcience",
      ],
      citation: `Baptist, Karen Wilson. “Enchant,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/enchant",
      downloadUrl: `tom/Rena_10_Enchant.pdf`,
      component: <Enchant />,
    },
    {
      pubId: "tom",
      id: "evolve",
      order: 11,
      title: "Evolve",
      author: "Rena Tom",
      authorShort: "Tom",
      tags: [
        "new writing (student)",
        "visual essay",
        "absence",
        "curation",
        "knowledge classification",
      ],
      citation: `Tom, Rena. “Evolve,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/evolve",
      downloadUrl: `tom/Rena_11_Evolve.pdf`,
      component: <Evolve />,
    },
    {
      pubId: "tom",
      id: "ghost",
      order: 12,
      title: "Ghost",
      author: "Sara Clugage",
      authorShort: "Clugage",
      tags: [
        "new writing (faculty)",
        "essay",
        "absence",
        "craft studies",
        "future",
        "materiality",
      ],
      citation: `Clugage, Sara. “Ghost,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/ghost",
      downloadUrl: `tom/Rena_12_Ghost.pdf`,
      component: <Ghost />,
    },
    {
      pubId: "tom",
      id: "grave",
      order: 13,
      title: "Grave",
      author: "Jarrett M. Drake",
      authorShort: "Drake",
      tags: ["reprint", "excerpt", "archives", "community", "death", "future"],
      citation: `Drake, Jarrett M. “Grave,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/grave",
      downloadUrl: `tom/Rena_13_Grave.pdf`,
      component: <Grave />,
    },
    {
      pubId: "tom",
      id: "legacy",
      order: 14,
      title: "Legacy",
      author: "Rena Tom",
      authorShort: "Tom",
      tags: [
        "coursework",
        "visual essay",
        "absence",
        "craft practices",
        "heritage",
        "mapping",
      ],
      citation: `Tom, Rena. “Legacy,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/legacy",
      downloadUrl: `tom/Rena_14_Legacy.pdf`,
      component: <Legacy />,
    },
    {
      pubId: "tom",
      id: "lifespan",
      order: 15,
      title: "Lifespan",
      author: "Paul Benzon and Future Library",
      authorShort: "Benzon & Future Library",
      tags: [
        "reprint",
        "excerpt",
        "ecology",
        "future",
        "heritage",
        "self-publishing",
        "writing",
      ],
      citation: `Benzon, Paul and Future Library. “Lifespan,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/lifespan",
      downloadUrl: `tom/Rena_15_Lifespan.pdf`,
      component: <Lifespan />,
    },
    {
      pubId: "tom",
      id: "liminal",
      order: 16,
      title: "Liminal",
      author: "Alex Singh",
      authorShort: "Singh",
      tags: [
        "reprint",
        "excerpt",
        "interdisciplinarity",
        "philosophy",
        "weaving",
      ],
      citation: `Singh, Alex. “Liminal,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/liminal",
      downloadUrl: `tom/Rena_16_Liminal.pdf`,
      component: <Liminal />,
    },
    {
      pubId: "tom",
      id: "magic",
      order: 17,
      title: "Magic",
      author: "Rena Tom",
      authorShort: "Tom",
      tags: [
        "new writing (student)",
        "visual essay",
        "absence",
        "aesthetics",
        "embodied knowledge",
        "philosophy",
        "transcience",
      ],
      citation: `Tom, Rena. “Magic,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/magic",
      downloadUrl: `tom/Rena_17_Magic.pdf`,
      component: <Magic />,
    },
    {
      pubId: "tom",
      id: "map",
      order: 18,
      title: "Map",
      author: "Rena Tom",
      authorShort: "Tom",
      tags: [
        "coursework",
        "visual essay",
        "community",
        "craft studies",
        "knowledge classification",
        "mapping",
        "resistance",
      ],
      citation: `Tom, Rena. “Map,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/map",
      downloadUrl: `tom/Rena_18_Map.pdf`,
      component: <Map />,
    },
    {
      pubId: "tom",
      id: "memorial",
      order: 19,
      title: "Memorial",
      author: "Rena Tom",
      authorShort: "Tom",
      tags: [
        "new writing (student)",
        "visual essay",
        "ecology",
        "memory",
        "transcience",
      ],
      citation: `Tom, Rena. “Memorial,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/memorial",
      downloadUrl: `tom/Rena_19_Memorial.pdf`,
      component: <Memorial />,
    },
    {
      pubId: "tom",
      id: "offering",
      order: 20,
      title: "Offering",
      author: "Phoebe Cheng",
      authorShort: "Cheng",
      tags: [
        "reprint",
        "essay",
        "aesthetics",
        "anthropology",
        "community",
        "heritage",
        "transcience",
      ],
      citation: `Cheng, Phoebe. “Offering,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/offering",
      downloadUrl: `tom/Rena_20_Offering.pdf`,
      component: <Offering />,
    },
    {
      pubId: "tom",
      id: "palimpsest",
      order: 21,
      title: "Palimpsest",
      author: "Indira Allegra",
      authorShort: "Allegra",
      tags: [
        "new writing (contributor)",
        "essay",
        "labor",
        "transcience",
        "weaving",
      ],
      citation: `Allegra, Indira. “Palimpsest,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/palimpsest",
      downloadUrl: `tom/Rena_21_Palimpsest.pdf`,
      component: <Palimpsest />,
    },
    {
      pubId: "tom",
      id: "portrait",
      order: 22,
      title: "Portrait",
      author: "Rena Tom",
      authorShort: "Tom",
      tags: [
        "new writing (student)",
        "visual essay",
        "community",
        "support networks",
      ],
      citation: `Tom, Rena. “Portrait,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/portrait",
      downloadUrl: `tom/Rena_22_Portrait.pdf`,
      component: <Portrait />,
    },
    {
      pubId: "tom",
      id: "predict",
      order: 23,
      title: "Predict",
      author: "Andres Payan Estrada",
      authorShort: "Estrada",
      tags: [
        "new writing (faculty)",
        "craft ways (presenters)",
        "visual essay",
        "aesthetics",
        "craft practices",
        "future",
      ],
      citation: `Estrada, Andres Payan. “Predict,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/predict",
      downloadUrl: `tom/Rena_23_Predict.pdf`,
      component: <Predict />,
    },
    {
      pubId: "tom",
      id: "publication",
      order: 24,
      title: "Publication",
      author: "Ben Lignel",
      authorShort: "Lignel",
      tags: [
        "new writing (faculty)",
        "essay",
        "community",
        "future",
        "philosophy",
      ],
      citation: `Lignel, Ben. “The Letter R,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/publication",
      downloadUrl: `tom/Rena_24_Publication.pdf`,
      component: <Publication />,
    },
    {
      pubId: "tom",
      id: "queer",
      order: 25,
      title: "Queer",
      author: "matt lambert",
      authorShort: "lambert",
      tags: [
        "craft ways (presenters)",
        "new writing (alumni)",
        "essay",
        "community",
        "future",
        "memory",
        "queer life",
      ],
      citation: `lambert, matt. “Queer Companion,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/queer",
      downloadUrl: `tom/Rena_25_Queer.pdf`,
      component: <Queer />,
    },
    {
      pubId: "tom",
      id: "reframe",
      order: 26,
      title: "Reframe",
      author: "Rena Tom",
      authorShort: "Tom",
      tags: ["coursework", "essay", "aesthetics", "ethnography"],
      citation: `Tom, Rena. “Reframe,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/reframe",
      downloadUrl: `tom/Rena_26_Reframe.pdf`,
      component: <Reframe />,
    },
    {
      pubId: "tom",
      id: "renewal",
      order: 27,
      title: "Renewal",
      author: "Brian Potter",
      authorShort: "Potter",
      tags: [
        "reprint",
        "essay",
        "craft studies",
        "destruction",
        "heritage",
        "repair",
      ],
      citation: `Potter, Brian. “Renewal,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/renewal",
      downloadUrl: `tom/Rena_27_Renewal.pdf`,
      component: <Renewal />,
    },
    {
      pubId: "tom",
      id: "repair",
      order: 28,
      title: "Repair",
      author: "Miriam Devlin",
      authorShort: "Devlin",
      tags: [
        "new writing (student)",
        "essay",
        "aesthetics",
        "future",
        "heritage",
        "renewal",
        "repair",
      ],
      citation: `Devlin, Miriam. “Repair,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/repair",
      downloadUrl: `tom/Rena_28_Repair.pdf`,
      component: <Repair />,
    },
    {
      pubId: "tom",
      id: "resolve",
      order: 29,
      title: "Resolve",
      author: "Jill DiMassimo",
      authorShort: "DiMassimo",
      tags: [
        "new writing (student)",
        "essay",
        "absence",
        "aesthetics",
        "heritage",
      ],
      citation: `DiMassimo, Jill. “Resolve,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/resolve",
      downloadUrl: `tom/Rena_29_Resolve.pdf`,
      component: <Resolve />,
    },
    {
      pubId: "tom",
      id: "souvenir",
      order: 30,
      title: "Souvenir",
      author: "Rena Tom",
      authorShort: "Tom",
      tags: [
        "new writing (student)",
        "essay",
        "absence",
        "community",
        "future",
        "memory",
      ],
      citation: `Tom, Rena. “Souvenir,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/souvenir",
      downloadUrl: `tom/Rena_30_Souvenir.pdf`,
      component: <Souvenir />,
    },
    {
      pubId: "tom",
      id: "speculate",
      order: 31,
      title: "Speculate",
      author: "Tom Martin",
      authorShort: "Martin",
      tags: [
        "new writing (faculty)",
        "essay",
        "absence",
        "future",
        "phenomenology",
        "philosophy",
      ],
      citation: `Martin, Tom. “Speculate”, Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/speculate",
      downloadUrl: `tom/Rena_31_Speculate.pdf`,
      component: <Speculate />,
    },
    {
      pubId: "tom",
      id: "subvert",
      order: 32,
      title: "Subvert",
      author: "Rena Tom",
      authorShort: "Tom",
      tags: [
        "new writing (student)",
        "essay",
        "craft studies",
        "philosophy",
        "resistance",
      ],
      citation: `Tom, Rena. “Subvert,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/subvert",
      downloadUrl: `tom/Rena_32_Subvert.pdf`,
      component: <Subvert />,
    },
    {
      pubId: "tom",
      id: "talisman",
      order: 33,
      title: "Talisman",
      author: "Kasey Smith",
      authorShort: "Smith",
      tags: [
        "new writing (contributor)",
        "essay",
        "craft practices",
        "ecology",
        "future",
        "heritage",
        "renewal",
      ],
      citation: `Smith, Kasey. “Talisman,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/talisman",
      downloadUrl: `tom/Rena_33_Talisman.pdf`,
      component: <Talisman />,
    },
    {
      pubId: "tom",
      id: "zombie",
      order: 34,
      title: "Zombie",
      author: "Greg Garrett",
      authorShort: "Garrett",
      tags: ["reprint", "excerpt", "aesthetics", "renewal"],
      citation: `Garrett, Greg. “Zombie,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/zombie",
      downloadUrl: `tom/Rena_34_Zombie.pdf`,
      component: <Zombie />,
    },
    {
      pubId: "tom",
      id: "eulogy",
      order: 35,
      title: "Eulogy",
      author: "Rena Tom",
      authorShort: "Tom",
      tags: [
        "new writing (student)",
        "essay",
        "absence",
        "future",
        "memory",
        "pedagogy",
        "renewal",
      ],
      citation: `Tom, Rena. “Eulogy,” Encyclopedia of Craft Studies (Abridged), edited by Rena Tom. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-8-7 `,
      url: "https://www.theMACRPapers.com/tom/eulogy",
      downloadUrl: `tom/Rena_35_Eulogy.pdf`,
      component: <Eulogy />,
    },
  ],
  accent: "#009129",
  firstArticle: "foreword",
};

export default publication;
