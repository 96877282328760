import React from "react";
import Book from "../../assets/book.gif";

const Download = ({ url }) => {
  return (
    <a
      className="download-link"
      href={`${process.env.PUBLIC_URL}/pdfs/${url}` || "#"}
      download
    >
      <img src={Book} alt="Book Icon" title="Download this article as a PDF" />
    </a>
  );
};

export default Download;
