import { NavLink } from "react-router-dom";

const JarrettWiggers = () => {
  return (
    <div>
      <p>
        Lisa Jarrett and I met as participants on a panel. We made immediate
        plans to meet and have been talking ever since. We both live in and work
        from Portland, Oregon, which makes it easy to speak in an unhurried way.
        It’s a luxury in comparison to time-contingent meetings via online
        platforms with most faculty and students in the program who are
        scattered across the US and abroad. Our friendship grows from multiple
        shared interests, building programs and platforms being one of them.
      </p>
      <p>
        Lisa’s engagement with the program is visible through the workshops she
        led to kick-off every Summer Residency from 2018–2022. What we hope this
        conversation will reveal, however, is how her role and teaching put the
        four core ideas from which the MA in Critical Craft Studies (MACR)
        pivots into action. To use Warren Wilson College’s own educational
        priorities, this conversation demonstrates the application of those four
        ideas through teaching.
        <sup>
          <a href="#annotation-1">1</a>
        </sup>{" "}
        We edited two recorded conversations here to give a sense of{" "}
        <em>how </em>as much as <em>what</em> took place.
      </p>
      <p>The four ideas at the program’s core are:</p>
      <ul className="bulleted">
        <li>yes, and</li>
        <li>make space</li>
        <li>questions</li>
        <li>
          which craft?
          <sup>
            <a href="#annotation-2">2</a>
          </sup>
        </li>
      </ul>
      <p>
        A former student who also does improv introduced me to the idea of “yes,
        and.” One person puts an idea into the active space. Another person
        picks up and connects with that idea (yes), then expands on that idea
        (and). Regardless if they agree or disagree, the exercise is about
        building something with each other through responsive listening.
        <sup>
          <a href="#annotation-3">3</a>
        </sup>{" "}
      </p>
      <p>
        Make space is about intentionally and actively leaving room for new
        ideas, different ways of thinking, sharing floor time to enable more
        people to enter a conversation. For a program focused on history and
        theory, this can be particularly challenging in how it pushes against
        assumptions about what knowledge must be present, or, in art historical
        terms, against a cannon.{" "}
      </p>
      <p>
        It is a slower way of working that involves stopping and starting to
        answer questions to ensure that everyone is able to be present and able
        to find ways to participate.
      </p>
      <p>
        Questions, as Lisa notes in her bio, are her medium. In order to make
        space for different kinds of knowledge, people need to carefully
        consider what questions are, how they work, and what they can shift.
        What comes through in the following conversation, I believe, is how the
        100 Questions is a list, but a special kind of list that reflects a
        myriad of preparatory exercises, shifts in thinking, and active
        engagement while unsettling expectations about how knowledge comes into
        view.{" "}
      </p>
      <p>
        “Which craft” is, perhaps, my favorite one to say out loud; it’s cheeky
        and brings smiles. One aim for the question “which craft” is
        sidestepping the art-versus-craft binary. It is about unsettling
        coloniality by opening space for many more possibilities for craft
        discourse. In our program, students have introduced wide-ranging topics
        for study: American Girl dolls, makeup, kites, factory work, kitchen
        tools, loss, Black craftspeople, the space of the studio, repair, and
        much more.{" "}
      </p>
      <p>
        Lisa’s role was not that of a craft historian; it was to set a stage
        where the students were prepared for a different kind of investigatory
        work regardless of which craft they chose to study.{" "}
      </p>
      <p>
        What is not as evident in this conversation is the impact Lisa’s
        workshops had on faculty. I’ve invited a lot of educators into this
        program; we all taught together. That meant collaboratively reviewing
        and workshopping syllabi, as well as sitting in on each other’s classes
        during residencies. Academic educators are not accustomed to teaching in
        front of, let alone with, one another. I am leery of naming what methods
        and disciplinary approaches Lisa brought in because it feels
        antithetical to the thoughtfully choreographed and fluid way in which
        she teaches. Faculty and students were affected by her approach; it
        shaped particular conditions for learning and reminded all of the
        possibilities of teaching.{" "}
      </p>
      <p>—Namita Gupta Wiggers</p>
      <p>*</p>
      <h2>Questions as Medium</h2>
      <p>
        <span className="speaker">Namita Wiggers:</span> Questions were on my
        mind from the beginning of this role at Warren Wilson College, and we’d
        had several conversations about questions and pedagogy by the time I
        started work in July 2017. In my job talk I mapped four foundational
        elements at the core of my proposed program: <em>yes, and</em>, which
        comes from improv techniques of building your response on the last thing
        someone introduced into the space; <em>make space</em>, which comes from
        activity and equity work; <em>questions</em>, which is how research and
        understanding begins; and <em>which craft?</em>, which is a cheeky way
        of introducing that craft is huge as a topic, and a reminder that each
        kind of craft requires a different approach.
      </p>
      <p>
        We’d already talked about how questions are your medium, as Amanda Leigh
        Evans put it, so I am sure I went into the job interview having already
        talked with you about what I wanted to do with the program, about what
        the possibilities were for your involvement, too. Because you are
        full-time faculty at Portland State University, my intention was not for
        you to have sustained relationships throughout the year, but rather to
        bring learning out during summer residencies, to create a space for
        learning in a different kind of way. Do you recall?
      </p>
      <p>
        <span className="speaker">Lisa Jarrett:</span> I think once you were
        really thinking about what it would mean to build this program, if my
        memory serves me correctly, you and I had a conversation about questions
        and talked about what that really could mean. I had done this
        workshop—sort of a question-based workshop approach like at Warren
        Wilson—with a summer group at the now closed Oregon College of Art and
        Craft, and at Columbia River Correctional Institution. And I think that
        you and I probably talked about what it could mean to start a cohort, an
        entire program, by thinking about this, by thinking about approaching
        the practice of learning through questions.
      </p>
      <p>
        We talked about how all the faculty would be present in all the
        workshops and classes. And how everything was unfolding; it was being
        designed as it was unfolding. At that early point, even you weren’t
        certain of courses and schedules. At that point we didn’t know what was
        really going to happen in real time. In terms of core faculty, I’d met
        Ben Lignel in Portland once, and I didn’t meet Linda Sandino until we
        arrived at Eco Dorm (the living quarter in which both faculty and
        students roomed during residency) on the WWC Campus. She and I met,
        chatted a bit in a classroom, and then that first evening we all went to
        the workshop together.{" "}
      </p>
      <p>
        <span className="speaker">NGW:</span> Yes, and while your workshop
        connected directly to Ben’s Material Lab course, it was key to Linda’s
        Research Methods Lab as well, which we will talk about more later. I
        can’t recall if we talked about the way that JP Reuer started the MFA in
        Applied Craft and Design with a design-build project.
        <sup>
          <a href="#annotation-4">4</a>
        </sup>{" "}
        Students would arrive at the start of the semester and build something
        together for a prison or school or library. Relationship building was
        focused on the student cohort, not on sustained engagement with the
        place receiving the building project. Although the project focused
        primarily on developing community amongst the cohort, not with external
        community members, I did appreciate how it started off the whole two
        years of their graduate experience with a shared work experience toward
        collaboratively and physically constructing something. It gave the
        students a chance to understand everybody’s skills and individual
        specializations. You came to understand which person really knew how to
        use a drill or a sewing machine, and that so-and-so has never used these
        tools but can project manage. The program was about <em>applied</em>{" "}
        craft and design, and this opening project built cohesion at the
        beginning through recognition of what each person brought into the space
        and through learning to build collectivity.
      </p>
      <p>
        And the thing I remember wanting to work on with you was questions. I
        want students to be able to ask different questions. I didn’t want them
        to ask questions the same way they always ask, or that we see asked over
        and over. I remember we were wondering: could we teach them to start an
        applied research program by thinking about questions? Could we then lead
        them to understand how certain questions lead to researchable questions,
        and other questions are really great questions, but they’re not going to
        lead you down into a research project? How do you build a cohort quickly
        in the context of a low-residency program? They’re intensives, these
        residencies, and we needed people to be able to get to that deep
        questioning and deep thinking sooner than later without making them feel
        that pressure. We wondered, too, about how we really might get people to
        open up and start being honest about what they know and what they don’t
        know.{" "}
      </p>
      <p>
        <span className="speaker">LJ:</span> I was excited about this next step
        for you, and then one thing kind of led to another and I think you
        called me and were like, “Hey, what would you think about trying that
        question workshop out here as the way to sort of launch the entire
        residency experience? How far can we take our experimental pedagogy and
        things that we’ve been doing out in the world, and apply them to a more
        institution—a degree-based program—and see what happens?”
      </p>
      <p>
        And it turned out to be wonderful. You wanted a way to bring the
        students to a place of inquiry that wasn’t so reliant on the way
        traditional programs have functioned. As an artist, I approach things a
        little bit differently than a historian. We were so excited about
        potential content and how to make this not just about art versus craft
        but how to really think about, as you put it, what it would be like to
        teach the next generation of our peers. That just really, really struck
        me as a beautiful approach. That’s what I remember. I remember us having
        long, winding conversations that one day led to the invitation of, “what
        do you think?”—and my thinking, “I want to try this.”
      </p>
      <p>
        Now, for me, that’s a very exciting place. I think that’s why this
        really worked, because I don’t need to have the security of knowing
        exactly what’s going to happen to be comfortable or for planning. You
        know that’s my personality and approach, and partly because I had a
        grasp on what this kind of workshop model meant to me. Also, the other
        thing that you did programmatically, Namita, was that you had us all
        give talks, so the students could hopefully understand the faculty’s
        positionality to the program, and each person’s positionality to
        research more broadly. So there wasn’t any confusion there and I think
        that that was a real strength, too. It’s really interesting to me how
        many things in that first year in 2018 we thought could work, and then
        they did. Obviously, there were things that changed, with refinements
        and deep reflection. It gave students an opportunity to see us as, quote
        unquote, “professionals.”
      </p>
      <p>
        I also think that there’s something really interesting in your deciding
        to pick an artist—who teaches and is a professor—to start this whole
        program. This could just upset across the board—whether you’re student
        or faculty—any expectation that you think you may have had. Because it’s
        not common for the artist to be the person beginning anything in the
        context of research. We’re the subject of research. So I think there
        were a couple of things happening there.{" "}
      </p>
      <p>
        <span className="speaker">NGW:</span> I deliberately wanted you, as an
        artist, to start us off because I knew that it would be about opening
        possibilities. You weren’t teaching content. You were guiding inquiry.
        Those are really different things, and I wanted that to be how the
        program began. Where it’s not about teaching that “you need to know
        these things in order to be a craft historian,” but more that you need
        to have strategies to be able to do research work to be a good craft
        historian. And that’s different.
      </p>
      <p>
        <span className="speaker">LJ:</span> What I loved about it is that I
        don’t think it was confusing to the students. Ever. I don’t think that
        they were confused. They did not think I was a craft historian. (shared
        laughter){" "}
      </p>
      <h2>
        100 Questions Workshop
        <sup>
          <a href="#annotation-5">5</a>
        </sup>{" "}
      </h2>
      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_3.1_Poster.jpg"}
        />
        <figcaption>
          <em>M.A. in Critical and Historical Craft Studies</em> poster, October
          2018. <br />
          Four-panel accordion fold, 9.75” x 26”, offset printed.
        </figcaption>
      </figure>
      <p>
        <span className="speaker">NGW:</span> What is the 100 Questions Workshop
        about? You’ve shifted how the exercise works and the various exercises
        and readings preceding it each time. We’ve also talked about distinct
        differences in experiences and responses between the cohorts.{" "}
      </p>
      <p>
        To give context, there was one cohort in year one (Class of 2020), and
        the workshop happened in summer 2018. In year two, there were two
        cohorts, but you only worked with the incoming group (Class of 2021). In
        2020, year three, we were in COVID and online, and you worked with both
        cohorts together (Classes of 2021 and 2022). In year four, 2021, was the{" "}
        <em>Craft Ways: Tending to Craft</em> symposium we co-organized with the
        Center for Craft; because the 2021 students were graduating, you worked
        primarily with the 2022 and 2023 cohorts.
        <sup>
          <a href="#annotation-6">6</a>
        </sup>{" "}
        And then in year five, 2022, we were in person on the Warren Wilson
        College campus and in Eco Dorm again, but because of the College’s
        cancellation of the program, you worked only with the 2023 cohort, this
        time for the second year in a row. You’ve kicked off every year for five
        years.{" "}
      </p>
      <span className="speaker">LJ:</span> That is crazy. You kind of just blew
      my mind. Holy shit. Yes.
      <p>
        So people ask me if this is a kaleidoscope conversation.
        <sup>
          <a href="#annotation-7">7</a>
        </sup>{" "}
        It’s related but it’s distinct. In the workshop conversation we have to
        have had a shared experience, usually around a reading and then some
        sort of specific reading-related prompt.{" "}
      </p>
      <p>
        The point of the 100 Questions is to exhaust students. That matters.
        That’s why I say it’s different than just collective conversation. I’m
        interested in people exhausting the most obvious questions and then for
        it to change and start to get a little bit harder. We do this
        collectively and, without even asking, people start to realize that they
        can refine and respond to a question that a person added to the
        conversation list 30 questions back. They are thinking about it, realize
        that they have a question that relates to it, and add it to the list.
        What I think about in the design, in terms of my own participation, is
        that I’m the scribe. I’m not telling you what to ask. In my experience
        as an artist, I would say that the first thing isn’t always the thing,
        it’s the beginning of the thing. This is true when I look back at my
        practice, and any major directional shift within it. I play with this
        exercise on my own. I play with it with groups.
      </p>
      <p>
        What I think is most effective in a group setting like a class is that
        there is an existing condition of willingness and desire to be there. It
        matters that we all signed up to be here on this day, and we think we
        all know why, and as it turns out maybe for completely different
        reasons, and those reasons could be incredibly positive to me.{" "}
      </p>
      <p>
        The exercise is also a way to de-emphasize the proprietariness of taking
        a position because, at its base, the exercise also suggests that sharing
        your thinking matters. To me, it’s really important to understand what
        somebody is trying to communicate. This is a shift away from agreeing
        with what we’re trying to communicate about. I think we live in a world
        now where people tell us what to read. I don’t think that’s always
        useful. You can have a question and share a question and then see that
        oppositional questions were part of this conversation. And that
        opposition turns out to be important to making sure your question is
        really valid. You kind of have to know what the other side of the coin
        is. It helps your thinking.{" "}
      </p>
      <p>
        The other thing that the workshop brings in is a shift in performativity
        and whiteness. It’s amazing what you’re capable of when you take away
        the things that show you that you’re not considered capable. That can
        happen when you as the “leader” take your voice out. In my role as
        scribe I’m not telling you where there’s no value, I am not telling you
        what’s right. There’s no pressure to perform with this exercise. You
        don’t have to say anything or anything specific, and that tends to keep
        people feeling able to do everything. It brings people along in the
        process.{" "}
      </p>
      <p>
        And it’s more like a game when you make it lighter, when a sentence is a
        question and not a statement. Then we’re just recording a conversation.
        And someone else is writing it down so you don’t have to worry about
        retaining it. Someone else is doing that work for you. And it’s not
        going to be pretty, it’s going to be personal, fun, and I try to write
        fast and catch all the things. Everything is going to be visible as a
        record that we’re creating collectively with few parameters and
        frameworks showing through. It can be an archive that is as messy as
        archives really are.{" "}
      </p>
      <h2>
        Performance But Not Performative: Understanding Labor in Generating the
        100 Questions List
      </h2>
      <p>
        <span className="speaker">LJ:</span> My role is the scribe, so my body
        is in a much different role and position than when I’m typically
        teaching. If I’m lecturing, if I’m leading a discussion, then I’m
        facilitating something. My body, my eyes, the way everything is directed
        is different than when I’m writing something down—when I am the one
        accepting the instruction even though it’s my instruction. You know,
        it’d be very easy to have someone else write. But that doesn’t help me
        decenter myself or the qualities of how hierarchy functions in a
        classroom. I can change my positionality by changing the role that I
        assign myself, and I think that questioning can be the same, it can
        shift one’s positionality to one’s research topic.
      </p>
      <p>
        <span className="speaker">NGW:</span> Let’s pause on this moment of you
        being the scribe because one of the things that has struck me, Lisa, is
        that this is a distinct shift that is not something that happens at any
        other moment in the series of your workshops. I think of the experience
        in each of the years as a series of mini workshops of which 100
        Questions is a part. I’m thinking about your body; you’re looking down,
        you’re writing, and you’re not making eye contact with anyone. That’s
        the only moment in the whole series of engagements where you are not
        looking at everyone, where you are not conversational and dialogical.
        Even when the students are doing other exercises to get to that place of
        opening up to working through 100 Questions, they’re looking at each
        other and they’re looking at you. They’re looking at you for cues.
        They’re not looking down and isolating themselves in the way you do in
        that scribe moment. I’m curious about your thoughts in terms of the
        performance of it, the pedagogical direction that you are leading
        people, and your self-awareness of how you’re leading people, what
        you’re aiming toward. That distinct physical shift in your engagement
        does something. Can you explain that more?
      </p>
      <p>
        <span className="speaker">LJ:</span> I’m always thinking about what
        happens, what our expectations are for what’s <em>supposed</em> to
        happen in a classroom. And in this case people, like you said, look to
        the teacher or the educator—to whoever it is—for a cue. And often the
        cue that you’re looking for is some sort of affirmation that what you’ve
        offered, what you’ve presented and shared, is on the right track. But in
        the context of the workshop where I’m the scribe, simply by having to
        write and listen that way, you—the participant—can’t get any cues from
        me other than vocal cues. I’ve never done this in a situation where
        people would be sitting, like, in rows or something. There’s a roundness
        of some sort to the arrangement of the room.{" "}
      </p>
      <p>
        I think the other thing that it makes visible is the labor involved
        before and during the 100 Questions exercise. It takes actual labor, you
        know. What happens when I’m scribing? I’m always trying to catch up,
        right? At some point it’s, like, oh, my goodness, they’re speaking
        faster than I can write, or I can’t spell a word and my handwriting is
        all over the place and the paper is big and it starts to take up
        physical space. And I’m trying to keep up with it. So there’s a way in
        which it’s a performance, but it’s not performative.
      </p>
      <p>
        So to get back to your question, what is it about changing my body’s
        relationship to the room that shifts the experience? I think it’s that
        it’s just not what you expect to have happen, you know? That you expect
        that labor to be done by somebody else, like that labor should be, in
        theory, too good for the teacher to do. In reality, that’s the exact
        labor of doing real research. You are the one working for this—in this
        case, me. It just feels right. And then no one else has to do anything.
        They can just use their brain and think. Everyone gets to participate in
        that way.
      </p>
      <p>
        <span className="speaker">NGW:</span> And everyone walks away feeling
        like a contributor, like they’ve been part of something. It’s making me
        think about a moment when one of our students shared a frustration that
        she would contribute things to discussions in someone else’s classes,
        and her comments would seem to fall short or other people wouldn’t pick
        up on them. It made her feel like she wasn’t “getting” the material.
        Something about the lack of response or engagement from the professor,
        and then from classmates, made her feel like what she’d lobbed out there
        fell like a dead weight. That educator led people through a particular
        way of reading and aimed toward an understanding of what they wanted
        them to get out of material. And that’s not what your workshops feel
        like. This makes me think about participation, labor, and teaching. I
        remember giving a tour in the early 90s, when I led museum education at
        Blaffer Gallery, to a group of three- and four-year-olds. We’re walking
        around and looking at narrative paintings, and there’s so many ways a
        three- and a four-year-old can talk about storytelling and color and
        what’s happening. They can make things up and there’s no wrong answer
        because it builds from what they see. And about halfway through the
        tour, one of the kids said, “Wow, you don’t know very much. Maybe we
        should give the tour.” I said, “Oh, darlin’, you are giving the tour.
        You are.”
      </p>
      <p>
        It was a beautiful moment because they didn’t even realize that they
        were doing the work themselves; I was listening and poking and prodding.
        And this is a really different pedagogical strategy than “I have an
        essay by XYZ person and I need you to understand this from this essay.
        And if you read it and what you bring up follows my train of thought to
        where I want to lead to, then you are contributing. If not, then your
        comment falls by the wayside and I zone in on the comment that leads you
        to what I want you to understand.” Totally different pedagogical
        strategies.
      </p>
      <p>
        <span className="speaker">LJ:</span> Yeah, because it tends to lead to
        somebody feeling left out because often when you contribute something
        that isn’t supporting the direction that the facilitator hopes you’ll
        go, you’re left with the impression that what you’ve offered is wrong,
        which is usually not the case. It may just not be the focal point of
        that conversation but it’s not necessarily that you’re wrong, and being
        made to feel wrong as a regular habit is not healthy in terms of helping
        people become who they are hoping to be.
      </p>
      <p>
        Or in the words that you’ve said about this program itself, you know, we
        are creating colleagues and participating in creating colleagues you
        want to be working with and collaborating with in the future. If they’re
        wrong and everybody else is wrong, then who is right? Where, then, is
        the interesting conversation to be had? I don’t want people to feel that
        way. Alienated. I hope that they don’t.{" "}
      </p>
      <p>
        There are a lot of things in the way that I frame teaching now that come
        from the ways in which the classroom hasn’t worked for me, even though I
        keep staying in it. Right? Like, I stay in classrooms. I love learning.
        I like places that honor learning, and that’s what classrooms are. They
        worked better, like you said, when I was, like, four or five when it
        was, like, “So today we’re going to play with these physical things.
        We’re going to read some stories. Everyone’s going to get a nap and a
        snack and that’s going to be kind of a lot for today. So tomorrow, we
        might go through some letters.” I mean, they just seem to go to all of
        the places: physically moving, listening, being entertained and amused,
        resting, feeding oneself. Those seem to be life lessons happening in a
        classroom. And it seems like it gradually goes from that to just the
        content of the thing I assigned you to read. And to be honest with you,
        if it’s really just about understanding something to be right, you don’t
        need to be in a room with me to do that. That’s just a reading list.
      </p>
      <h2>The Writing on the Wall</h2>
      <p>
        <span className="speaker">NGW:</span> Have we ever discussed the impact
        of having that sheet of questions up on the wall for the rest of that
        first residency after you left? In that first residency, literally every
        faculty member coming in saw it hanging on the wall and remarked on how
        amazing the questions were—and then proceeded to bring the students’
        questions into their second day’s lecture. That was the year where
        everybody came, presented a talk to the cohort, and that evening
        returned to their rooms to revise their workshops because they felt that
        they needed to raise their game to meet the students where they were,
        which was not where they had thought they were going to be. The students
        made them work harder. It was there, the list was physically there, and
        resonated deeply with people.
      </p>
      <figure>
        <img
          src={
            process.env.PUBLIC_URL +
            "/img/notaretreat/B_N_3.2_Hanging_the_question.jpg"
          }
        />
        <figcaption>
          Lisa Jarrett and students from the 2020 cohort hanging the “100
          Questions” onto the wall of the Ecodorm after the final session,
          Warren Wilson College, Summer 2018. (Left to right) matt lambert, Nick
          Falduto, Michael Hatch, Lisa Jarrett, Matt Haugh. Photo: Ben Lignel
        </figcaption>
      </figure>
      <p>
        Ezra Shales pulled two questions into his recap of our field trip up to
        Cherokee. And, of course, Glenn Adamson was captivated by number 54,
        “Can craft save America?” and wanted to use it as the title of his then
        forthcoming and now published book.
        <sup>
          <a href="#annotation-8">8</a>
        </sup>{" "}
        The 100 Questions is not a list, though, it’s a collaboratively produced
        artwork. You and I worked together to develop a citation that named
        everyone who worked on it—every student and faculty member—and then we
        gave him permission to cite it in that form. It ended up as the title of
        a chapter in his book, and later I used it as the title for an article,
        as you know, in <em>Metropolis</em>, in 2021.
        <sup>
          <a href="#annotation-9">9</a>
        </sup>{" "}
      </p>
      <p>
        <span className="speaker">LJ:</span> Was I possessive when someone
        wanted a single question for a title for something? Yes. And my response
        was, yes, you can use it, but you need to acknowledge the labor. We did
        work. If you want to use it for something, you’re going to give us
        credit. All of us. You can’t take all the thunder, even if this seems
        like imaginary thunder. I care. It came out of our cohort of students,
        and the context of this program and the context of me. That’s exactly
        where it comes from.
      </p>
      <h2>Micro-Field Trips</h2>
      <p>
        <span className="speaker">NGW:</span> Let’s talk about some of the other
        things that happened during your workshops before we talk about the
        readings you worked through with the students. You did a series of
        exercises with them, things that they had to do in pairs or groups, and
        in different ways you pushed them to think about things by moving their
        bodies, moving themselves, moving around the campus in different ways.
        Every workshop started with a reading and ended with a variation on your
        100 Questions Workshop project, and there were micro-lessons, or
        micro-looking, I think you called it.
      </p>
      <p>
        <span className="speaker">LJ:</span> The micro-field trip approach is
        from Avalon Kalin, a former Portland State University Art + Social
        Practice MFA student. Knowing that the students were going to have a
        very heavy load of reading-based research, I was interested in bringing
        participatory and experiential learning in. I wanted them, in my mind,
        to come toward anything that they were exploring with a sense that came
        from a direct experience and place. That’s what they were going to
        essentially be doing with interviewing shortly after my workshop and
        during the semester. They were going to begin learning the specifics of
        interviewing and were going to work closely with Linda Sandino, core
        faculty, on actually practicing interviewing in her Research Methods Lab
        course. That’s participatory research in a different way. But there’s a
        way in which you can work in that the conversation that you have with
        the person is just as valuable as all the reading. If you don’t know who
        you are in relationship to what you’re trying to ask, you’re at a
        disadvantage. And, to me, the way that you know who you are, how you
        respond to something, is by putting yourself in a situation that is
        maybe a little left of what you would normally do, and realizing how you
        come and how you show up in that situation.
      </p>
      <p>
        We were all, in theory, at a site that we pseudo recognized, a college
        campus. There’s land at Warren Wilson College; there are animals and
        things there that we think we know. But, as it turns out, we don’t know
        everything about things we walk by all the time. It’s hard to point to
        the thing you do all the time or the way you think all the time. Like
        close looking, right? Stopping and staring, talking to inanimate
        objects, getting to know one another, creating something from what’s
        there because that’s literally what you’re going to be doing as a
        researcher. It’s not that you’re finding something that didn’t exist.
        It’s that you’re doing something different with what is already present.
        Many times. And then having a conversation about that. It means talking
        about what we now know that we didn’t know before, right? So the
        micro-field trip is about that, about getting people to pay attention to
        paying attention.{" "}
      </p>
      <p>
        For me, this participatory approach is of primary value because I always
        go back to how learning occurs. I learned the best by doing. I can
        highlight and read and all those things, but I can’t recall them later.
        But if I have learned something with my body, I have a couple of
        different kinds of memory to access when I go to think about it later.
        It’s basically the same thing that we’ve talked about privately about
        how you take notes. I always have noticed taking notes for you to be a
        way that you listen, that it’s less about being able to go back to it
        and more about helping you be really present to the moment.
      </p>
      <p>
        <span className="speaker">NGW:</span> Totally, because it’s the action,
        it’s the physicality of it. I use a fountain pen because I really like
        to feel that pen move across the paper. For me, the physical mark-making
        allows me to process what I’m hearing, and note-taking allows me to, as
        you said, be present and physically engaged in what I’m doing.
      </p>
      <h2>Questions as an Embodied Practice</h2>
      <p>
        <span className="speaker">NGW:</span> Lisa, I also remember us talking
        about the practice of asking questions itself and the question of
        questions as embodied practice. And this came through in the first
        session—remember how students had visceral, emotional responses when you
        shared your own work in that first residency?
      </p>
      <p>
        <span className="speaker">LJ:</span> There was crying, and I was really
        surprised because the presentation that I put together for the class—I
        am looking at it now—is really what became the film that I shared with
        everybody as part of the second cohort’s final publication, “How Do You
        Draw the Light from Sapphires,” in{" "}
        <a
          href="https://www.macraftstudieswwc.com/publication2021/inquiry-as-practice"
          target="_blank"
          rel="noreferrer"
        >
          <em>To: Craft</em>
        </a>
        . It was a presentation intended to give them a context for my work and
        model how you change the meaning of something simply by contextualizing
        it differently.
        <sup>
          <a href="#annotation-10">10</a>
        </sup>{" "}
      </p>
      <figure>
        <img
          src={
            process.env.PUBLIC_URL + "/img/notaretreat/B_N_3.3_lisa_tocraft.jpg"
          }
        />
        <figcaption>
          Lisa Jarrett, <em>#34 – Inquiry as Practice</em> postcard, from
          Goodman, Mellanee, Benjamin Lignel, Amy Meissner, Heather K. Powers,
          and Joni Van Bockel (eds.),{" "}
          <em>To: Craft - MA in Critical Craft Studies</em>, Vol. 2, 2019–2021
          (Swannanoa: Warren Wilson College, 2021).{" "}
          <a
            href="https://www.macraftstudieswwc.com/publication2021/inquiry-as-practice"
            target="_blank"
            rel="noreferrer"
          >
            https://www.macraftstudieswwc.com/publication2021/inquiry-as-practice
          </a>
        </figcaption>
      </figure>
      <p>
        In this case I was using a series of questions to recontextualize a
        photograph—the same photograph—over and over again. At the end of that
        some of the students in that class asked if I’d done a film of this. It
        was highly personal. And I think <em>that</em> was what was relevant for
        some folks—they understood that there could be a deeply personal link to
        their research approach. That scholarly inquiry didn’t have to be some
        esoteric statement from jump. It could literally start with a photograph
        of your grandmother, and then the subsequent questions you might ask
        drill down, and you keep drilling down, drilling down until you really
        get to one that is a researchable topic.
      </p>
      <p>
        <span className="speaker">NGW:</span> For me, this is where craft comes
        into view in another way. Craft is about embodied experiences{" "}
        <em>and</em> research is an embodied experience. I don’t know how in
        this brown body it <em>cannot</em> be an embodied experience.
      </p>
      <p>
        <span className="speaker">LJ:</span> Can you talk a little bit more
        about that?
      </p>
      <p>
        <span className="speaker">NGW:</span> In most spaces that I operate in
        professionally, until very recently, I am one or one of just a few
        people who are not white. I work in white spaces. I work in museums, I
        work in academia, I work in craft organizations. These have been white
        spaces, and so my being here in those spaces disrupts so much. I disrupt
        the notion of what constitutes “American.” I disrupt the idea of “The
        West.” My body being present doesn’t allow for nice, neat, tidy, pat
        responses because I am physically in the room.
      </p>
      <p>
        <span className="speaker">LJ:</span> As you said, there are things that
        the people in the room are not looking at, and some are choosing not to
        look at them. I can call attention to them just by being in the room
        because I don’t look like everyone else in the room.{" "}
      </p>
      <p>
        I talked about the 100 Questions Workshops in the form of an essay that
        accompanied the postcard we just talked about for <em>To: Craft</em>,
        the progam’s 2021 publication. In terms of bringing something in, this
        exercise offers an option where your approach—my approach—does not need
        to be validated by any other standard other than my own. If I draw a
        line in terms of a pedagogical approach from the beginning, from reading
        through the workshop exercises to the context of scribing the 100
        Questions, everyone can bring something that is not already there. And
        it’s because of the difference of positionality toward the cannon as a
        whole, but also to education in terms of what we think of as the status
        quo way of educating folks in master’s programs. And I think it is like
        this for you, too. It’s not radical to me, it’s just survival.{" "}
      </p>
      <p>
        But survival is also radical, right? When you’re in a non-white body, in
        my case as a Black woman, and your case as a South Asian woman, both of
        whom were raised in the United States and are American, even though
        that’s not really a way I would describe myself, culturally, this is
        where I am. I’m bound to the history of this place, and I’m part of it.
        I think that’s true for you, too? I think it’s really interesting that
        we have come to similar conclusions about what is valuable for everybody
        based on who we are and how we come to the table.
      </p>
      <p>
        I think that we are uniquely positioned to give people different kinds
        of permission.
      </p>
      <p>
        <span className="speaker">NGW:</span> Yes, this is what being director
        allows, right? To give permission to everyone—to faculty, to students,
        and to myself. When I invited Tiffany Momon to teach History and Theory
        I in the fall of 2021, she was concerned that she didn’t know craft
        history. I said, “Wait a minute. You know how to trace enslaved
        craftspeople who’ve had no recognition and whose names, for all intents
        and purposes, have been lost along the way. You find ways to name them
        by using public records and public research. You identify human beings
        and give them presence. I mean, what craft history do you need to
        learn?” I asked her to teach what she wanted to know in school, what she
        wished she had learned.{" "}
      </p>
      <p>
        For me, it’s about permission and also understanding that there are
        different starting points. This came up with Mellanee Goodman’s work
        (Class of 2021), for example. We talked about how to write footnotes to
        address those readers who need to boost their own knowledge, about how
        backtracking to get <em>them</em> up to speed is a distraction. We
        discussed how she could remain focused on her research in the body of
        her Practicum project and answer their questions through citations and
        footnotes. My being director let me say, no, she doesn’t need to go back
        and add context. In this program, we’re starting from the assumption
        that enslaved makers have been erased and that history has to be
        evidenced differently than how we were taught. That is the assumption. I
        won’t make a student go back to prove that.{" "}
      </p>
      <p>
        Because she doesn’t need to prove that with tools that fail. Lisa, I
        bring this up because you set the stage with questions and workshops. It
        helps me reframe things for students and faculty. We’re starting from a
        different place. Jill DiMassimo (Class of 2023) struggled with this a
        bit at first, about how far back she needed to go to situate herself. I
        asked her if she’d rather write a paper about how museums and history
        have erased colonial-era potter Thomas Commeraw’s Blackness, or would
        she rather spend her pages telling me about the things she’s learned
        that nobody else is looking at that put{" "}
        <NavLink to="/dimassimo_weiss/dimassimo">
          Commeraw’s work and life
        </NavLink>{" "}
        in terms of a different context in terms of place, labor history, and
        freedom. Because I would rather read about that than rehash, confirm,
        and “prove” systemic racism. In the end, she’s written a paper that
        engages Tiffany’s approach with other Black scholars in an ethical and
        ground-breaking way instead of re-proving racism.
      </p>
      <p>
        Let’s not start from those other places. Let’s start from experience and
        embodied knowledge. Let’s look for what needs to be seen from what is
        right in front of us. This is what your starting each residency with
        questions brought into view, Lisa. This makes a huge difference.{" "}
      </p>
      <p>
        This makes me think about embodied interactions in your sessions and one
        of my favorite things to come out of that first workshop: the
        performance piece that Mike Hatch (Class of 2020), Kelly Malec Kosak
        (withdrew), and Sydney Maresca (withdrew) did in the cow pasture. We
        were all standing above the pasture looking down from a designated
        viewing spot, complete with signage for anyone visiting the campus. And
        we started calling it the balconized view. I think Ben introduced the
        term as a reference to where royalty sat in European theaters.
      </p>
      <p>
        <span className="speaker">LJ:</span> Yes, yes, and yes!{" "}
      </p>
      <p>
        <span className="speaker">NGW:</span> That became a shorthand for the
        two years that cohort of students were in the program because I could
        say, “You’re taking a balconized view of your subject. Don’t give it to
        me from a distance. Get into it and get into the middle of it. Don’t
        talk from up on high and look down at it. Get into it. Get in there.”{" "}
      </p>
      <p>
        <span className="speaker">LJ:</span> Well, that’s a beautiful point to
        mark. We can develop shared language that has to do with our experience,
        our direct inner experience, and then figure out how to use that there.
        You don’t have to start back at the beginning. Let’s assume that we’re
        building on something instead of just a reassertion of the colonizer and
        not to have to keep telling them that they’re colonizing and need to
        prove that people were erased. That one is sneaky in the context of
        academia. This is why academia is sneaky and why you have to be
        slippery, because academia will convince you that you don’t know and
        that in order to even begin to say anything you have to prove what you
        do know. I’m sorry, fucking research has been done. Just fucking jump
        from there. Why am I having to do that? You know, take Mellanee’s work,
        for example. Why should she have to do that, or Jill have to do that,
        even in a white body, you know?{" "}
      </p>
      <h2>Power and Permission</h2>
      <p>
        <span className="speaker">LJ:</span> I threw out the rules for what was
        okay a long time ago, and when I did that, everything became possible.
        My work got better; everything got better. When I shifted and thought,
        “wait a minute, whose rules?” There are no rules. There are no rules.{" "}
      </p>
      <p>
        Tradition is not lost on me. Being validated by an institution is always
        present, especially when you exist in institutions long enough to learn
        to convince other people that you have validity in traditional contexts.
        I’m the one making the rule here, which means I’m participating in the
        conversation in a way I have historically not been represented. I think
        that this is how to help bring the people coming up behind you. That is
        the thing that matters more than anything else. Because change is slow.
        It’s not going to change in my lifetime. But I can change it for the
        person behind me because I’m a decision maker. I’m a gatekeeper. Whether
        I want to be or not, I am.
      </p>
      <p>
        <span className="speaker">NGW:</span> In terms of participating in a
        conversation that has not represented you, there has to be agency to
        shift things, pedagogically speaking, to talk about and through
        dialogue, exchange, reciprocity, and learning. But in truth, Lisa, it’s
        about shaping and leading something in a way that isn’t about domination
        in the way that people tend to think and talk about leadership. It’s
        leadership through modeling via inquiry, through listening, through
        other things.
      </p>
      <p>
        <span className="speaker">LJ:</span> There are pre-conditions in the
        classroom. You’re in charge as the teacher, you have all the power and
        even if you say I’m giving the power away, you’re not, right? It’s an
        impossibility for someone enrolled in a program. So the most that I have
        found that I can do is to participate differently with my students
        because I’m also not saying that I have nothing to offer you. I have a
        lot to offer my students. I just don’t need to cram it down your throat
        and most of what I offer you is that you are a valid resource. Getting
        you to believe that and then do something is where my pedagogy shows up.{" "}
      </p>
      <p>
        A lot of my teaching in this program has allowed me to <em>be</em> in
        the way that I relate to my other teaching responsibilities at Portland
        State University. It’s also different because of the structure of the
        thing. And, honestly, because of you being the person at the helm,
        there’s a lot I’m not having to navigate being the version of myself I
        have to be in certain settings. When I’m working with you there are
        things that are just not there. I’ve never thought about them while
        teaching in your program. They’re just not there.{" "}
      </p>
      <p>
        I hadn’t really thought about it that way. It’s occurring to me now.
        It’s a good thing. It’s a very good thing. But I think that there’s a
        bigger picture when I look at the implications of that. It means that
        what this program makes possible is different from other programs
        because it’s different from other programs fundamentally. Does that make
        sense?{" "}
      </p>
      <p>
        <em>NGW:</em> I think that comes through for me in where I’ve had
        challenges at times with racist remarks, power dynamics, and a lack of
        institutional support along the way and where I have not. And you and I
        would never have some challenges that I’ve had with other people.
        Because those are not going to be things that will come up in those
        ways.{" "}
      </p>
      <p>
        <em>LJ:</em> It doesn’t mean that we might not see things differently
        with a difference of opinion. It just means that from jump it is not the
        same thing.
      </p>
      <p>
        <em>NGW:</em> It made me feel empowered when you brought Salman Rushdie
        into the space as the first reading that we dug into.
        <sup>
          <a href="#annotation-11">11</a>
        </sup>{" "}
        I don’t think we had talked about Rushdie and his impact on me, and
        while I knew this was the reading you’d selected, because we discussed
        it and it was shared with everyone in advance, I didn’t anticipate my
        emotional response to engaging it in the classroom. To being in a space
        where it’s a program that I am developing with colleagues, and my
        colleague brings this in to kick us off. I mean, wow. Unexpected,
        unexpected. It was an amazing moment of visibility without it having to
        be delineated as that.{" "}
      </p>
      <p>
        The first reading laid the groundwork for the way that we work together
        as colleagues in addition to working with our students. That reading was
        Salman Rushdie’s “Step Across This Line.”
        <sup>
          <a href="#annotation-12">12</a>
        </sup>{" "}
        Tell me about that choice.
      </p>
      <p>
        <em>LJ:</em> I didn’t pick a Black woman on purpose for the first
        reading. That’s too much of what is expected. I did in subsequent years.
        In the second year I brought in Audre Lorde and James Baldwin. But I
        consciously decided not to start with the thing others would expect from
        me with the first year. Because what happens is we too often get
        pigeonholed into a place of, “of course, that’s where she starts.”
      </p>
      <p>
        Now. Nobody would blink an eye if it was just like the most rote or
        run-of-the-mill essay, right? Nobody would question anything.{" "}
      </p>
      <p>
        I’m aware of my relative ambiguity in the context of spaces like this.
        What are you? Who are you? How old are you? What have you done, anyway?
        Have I ever heard of you? What have you done that’s important? Why do
        you matter?{" "}
      </p>
      <p>
        And I think that that is part of what I thought was really compelling
        about teaching in the program. I thought, “this is wonderfully
        interesting. This is exciting!”
      </p>
      <p>
        <span className="speaker">NGW:</span> Why?
      </p>
      <p>
        <span className="speaker">LJ:</span> Well, because you aren’t somebody I
        had to convince, and honestly that made it so I didn’t have to convince
        anybody else. I was not worried about my credentials mattering to you
        because you’d already decided. You’re the first woman of color I’ve
        worked for and it was like somebody finally at least fucking understood
        where I was coming from.{" "}
      </p>
      <p>
        I just didn’t realize what it would feel like, to be quite honest. That
        sounds naive, but it’s completely real. This program is a place where I
        felt immediately that I could already always be myself. Like I was
        bringing my full self to these places. All the parts of me. I’m bringing
        in the readings; these are my personal readings. I had a real
        willingness to be very open in the context of the setting of this
        program. And that’s because of you. I think a lot about the things that
        exist that have, quote unquote, “given us permission to do the work that
        we do,” or give us a sense of permission that it’s valid. And that’s
        present here, too. I respond to that, generally.
      </p>
      <h2>How Dare He? I Dare You.</h2>
      <p>
        <span className="speaker">NGW:</span> Let’s touch on the question of
        permission by talking about how the reading went with that first group
        on the first night of the program. When I looked at that final 100
        Questions list from this first cohort, there was energy. There was a
        sense of fearlessness in finding what they wanted to understand and
        wanted to connect out to craft. I feel it.{" "}
      </p>
      <p>
        And it brings to mind the students’ reactions to the Rushdie reading. I
        remember Kat St. Aubin asking what gives him the right to string all
        these things that don’t go together. Remember her asking “how dare he”?{" "}
      </p>
      <p>
        <span className="speaker">LJ:</span> Yes! How dare he? It came up in our
        question list later! And it became almost a joke, like, how dare you? In
        a beautiful way, it seemed to be a place where everybody found their
        ability to give themselves permission, because the question of “how dare
        you” comes from a lecture that starts with talking to us about
        frontiers. There’s so much here that you can be contrary about from the
        beginning. He has searched what the first frontier was, and he asserts
        that it’s about the presence of life on Earth. And then he starts
        talking to us about birds. And I mean, this is all within the first two
        pages. “How dare he?” is right!
      </p>
      <p>
        Honestly, whether you like the work or not, for me, is almost
        irrelevant. I like it, and I know that’s why I teach it. I don’t care if
        you like it. I care if you can analyze it. Whether you like it is not
        the same thing as can you track what’s happening. Can you track the
        international sort of knowledge that he’s bringing to this and what it
        means to have been raised in Bombay, for all intents and purposes, and
        educated in England and imagining all the stories that you bring, as the
        years have gone by. And you really can’t fuck with his credentials. Do
        you know what I’m saying? I was careful about that. He is an
        accomplished and internationally recognized person. The lecture was
        given at Yale. All of the accolades are there. There’s a way this is a
        teaching technique, to bring in this lecture. Slippery, be slippery,
        slippery.
      </p>
      <p>
        <span className="speaker">NGW:</span> Say more about that.
      </p>
      <p>
        <span className="speaker">LJ:</span> I think in the context of teaching
        in PWIs—predominately white institutions—and in the context of wanting
        to talk about the content that I want to talk about as an educator, I
        don’t want to be too easy to pin down. I don’t. I think that that’s the
        freedom, especially in the context of teaching and co-teaching. I don’t
        even know if that’s what we really did in my workshops, but I was
        teaching with other colleagues in a room whose positionalities I don’t
        really know or understand, but I am willing to guess that it’s not mine.{" "}
      </p>
      <p>
        In other words, my presumptions about Linda, whether they were right or
        wrong, were that I didn’t expect her to agree with me. I trusted that
        she was an educated human being because you picked her, but still I’m,
        like, you’re still an older white woman from the UK. Like, period. Ben
        is a Frenchman. I’m a Black woman. You’re an Indian woman. We are not
        going to see shit the same. Period. And we’re not going to read things
        the same. Do you know what I mean? Like the things that are going to
        stand out to me are not going to be the same that stand out to Linda or
        Ben, although you and I tend to have more similarities in our readings.
        We have to have enough of a shared experience to be able to talk and
        laugh. And I think these things matter, it’s not something you expect
        but, at the end of the day, I’m always trying to do a couple of things
        at once. You can’t really knock what I put on the table—but you might
        not like that I put it there.{" "}
      </p>
      <p>
        <span className="speaker">NGW:</span> There’s also another layer to what
        you are saying that ties to the workshop: you may not like what I bring
        into view.{" "}
      </p>
      <p>
        As you said, the four of us come from different types of backgrounds. It
        brings something different into view to make this slippery move you
        made. It could have been so many other kinds of things, but the choices
        Rushdie made cause different kinds of conversations to emerge. And I
        think what ended up happening is that the joke of “how dare you” became
        “how dare anyone” became “I dare you to really go for your research.”
      </p>
      <p>
        <span className="speaker">LJ:</span> I dare you to play. I. Dare. You.{" "}
      </p>
      <h2>Modelling a Problem</h2>
      <p>
        <span className="speaker">LJ:</span> Let’s talk about that first evening
        and a point of engagement happened, a conflict moment in that very first
        day when Linda didn’t like the reading, if you recall.
      </p>
      <p>
        <span className="speaker">NGW:</span> I do recall that Linda didn’t like
        the reading. That was a really pivotal moment for so many reasons. There
        was a reason you selected that reading, and I am also curious about how
        you remember that classroom moment.
      </p>
      <p>
        <span className="speaker">LJ:</span> The reason that essay stood out to
        me is something that I wanted to introduce to the students. If we’re
        talking about this from a pedagogical perspective, I always pick
        something that I’m interested in personally, a reading that has been
        useful to my own research. And then I look for a bridge between that and
        the content I’m trying to introduce to students, and in this case you
        and I had so many conversations about getting these students to a place
        of asking really good questions. I thought about who I think of reading
        myself.
      </p>
      <p>
        In addition, I like a bit of storytelling. So to me, this is a reading
        that starts us with a grand story before it takes us into the themes
        about society. Questions about the moment in time that we’re in are
        here; this was written shortly after September 11. I was quite taken
        with this essay. It wasn’t new to me, but I hadn’t taught it before. And
        I remember I was trying to model this notion of using questions to draw
        people out in the workshop, to get us to another place. I was, like,
        let’s see where we get to, let’s dig into this text together in a room
        in a circle with no desks, no PowerPoint. Print out your reading, come
        here, and let’s talk about it.
      </p>
      <p>
        I think I was exuding such enthusiasm for this reading that it would
        have been strange for the students to come at that with a counter sort
        of point. But it wasn’t hard for Linda. And I think that that’s what was
        important because the students are going to go along with you, right?
        She’s not my student, she’s my senior, you know? And she didn’t like the
        essay.{" "}
      </p>
      <p>
        Now, I thought that was interesting for a lot of reasons. I wondered,
        was she critical of him as a storyteller or the really wide array of
        information that he brings to the table, or was it my analysis of the
        reading? To this day, I’m not quite sure. She was a very pleasant
        colleague, but it also seemed like what was in her mind was the value of
        resistance in that moment.{" "}
      </p>
      <p>
        Now, what the resistance was and how we handled it are two totally
        different things. And when I say “we,” I’m thinking about you and me and
        Ben and Linda, and the students observing how we dealt with conflict or
        disagreement. Because there was no fighting and there was no anger.
        There was just the expression of “I don’t actually like this.” I can’t
        remember if it was taste or if it was content. Let’s go back and listen
        to it. We were reading something, we were digging into the essay, and
        she had an expression on her face that conveyed an emotional response
        that was not positive. And so I think one of us looked at her and said
        something to the effect of, “Linda, did you have something you wanted to
        bring up?” And she said something like, “I just don’t care for this
        writing and writer.”
      </p>
      <p>
        <span className="speaker">NGW:</span> It was such a pivotal moment
        because, as you said, it could have gone in a number of different
        directions.{" "}
      </p>
      <p>
        LJ: That happened as we were trying to figure out how to talk about what
        we were doing. I just want to point to the value of the archive, which
        is something we have seen a lot of our students really gravitate toward.
        Building archives that don’t exist, or understanding how to even begin
        to engage in laying the groundwork for one. Five years later, it is
        almost next to impossible for us to remember the specifics of that day
        and we can listen again. It’s so much of what we saw come out. This is
        really what we’re talking about right now—how we’re experiencing trying
        to recall this conversation and the context of what the program became
        more broadly.
      </p>
      <p>
        <span className="speaker">NGW:</span> Say more. You are pointing to how
        to make space …{" "}
      </p>
      <p>
        <span className="speaker">LJ:</span> We’re literally modeling the
        problem of how to do it. You have to have this work and have to do this
        work because you have to make it exist. And you have to find the people
        to have the conversations with. It’s all valid. I do want to remember
        why she didn’t like it and I don’t quite remember. But what I will say
        is that I’m not surprised. I wasn’t paying all that much attention to
        her until that moment. I just remember having that feeling of, like, oh,
        my god, it’s very strange. You know, I’m attentive to everybody in a
        room when I’m teaching, like aware of them. I’m aware of reactions, even
        if I’m not acknowledging them. I think the interaction set the tone for
        a really great, rigorous kind of way that classes happened moving
        forward, whether we liked it (the reading) or didn’t. We could have
        differences of opinion in real time. It wasn’t the typical “after class
        I’m going to let you know that I didn’t love this part. But I didn’t
        want to interrupt you.” You know? There was nothing like that from jump.
        I digress.
      </p>
      <p>
        <span className="speaker">NGW:</span> I don’t think you’re digressing at
        all. I think that this was a pivotal moment. Look, I fully recognize
        that I asked people to come into a teaching mode that is atypical.
        Whether a workshop or a class, it’s certainly not the typical model for
        an academic program. I ask people who come in to teach differently, to
        potentially teach material that’s new—as you said, this was the first
        time you’ve taught this in this way. And then, in addition to that, I’m
        asking people to teach in front of each other, which is an extremely
        vulnerable position to put people in. I recognized all of that and I
        knew that would be hard and I wasn’t sure what might happen with it.{" "}
      </p>
      <p>
        What could have happened is the afterward side conversation, as you
        mentioned, or it could have turned into what I experienced in graduate
        school, which was the full-out intellectual battle in public of taking
        each other down. Intellectual sparring between the two professors. And
        that didn’t happen. And in some ways, I think that our preparatory work
        when we did our introductions earlier that afternoon and came up with
        the gestures for bringing people into the conversations set the stage.{" "}
        <em>How</em> we talked about what kinds of conversations we wanted to
        have set the stage. Remember when we went around the room and people
        shared why they were there, what they wanted to get out the program, and
        what they wanted this experience to be? We talked about respect and
        vulnerability in asking hard questions, and we came up with gestures
        right then and there. We walked into that classroom after our first
        dinner together having already set a stage to the students and each
        other that this was a place where there could be disagreement, that it
        could be a space like that. But all this is to say, Lisa, I recognize
        that I do ask a lot of faculty when they come into our program that is
        not the same kind of ask that they would get in another program.{" "}
      </p>
      <figure>
        <img
          src={
            process.env.PUBLIC_URL + "/img/notaretreat/B_N_3.4_Two-gestures.png"
          }
        />
        <figcaption>
          To create a space for honest and open conversation, Namita Wiggers
          introduced ways to expand dialogue and exchange. The Class of 2020
          developed these gestures to express these concepts. XPlane creative
          director Tim May created these drawings as part of a Craftscape at
          Warren Wilson College brainstorming session in 2021.
        </figcaption>
      </figure>
      <p>
        <span className="speaker">LJ:</span> All of those things, yes. But from
        the perspective of somebody who is used to a traditional sort of
        classroom, I am literally the antithesis of that. I did not pick a
        scholarly reading. I did not approach it in a scholarly way in teaching
        the reading. I didn’t give any of the things that somebody trained in a
        different way would have done to introduce any text to a group of
        master’s students, and it’s because that’s not my business, and it’s not
        my background.{" "}
      </p>
      <p>
        The thing that it did establish—the moment with Linda—is what it is like
        to work together and disagree as colleagues in the room with our
        students. I think a real point in us talking about that moment is not so
        much what happened—our personal feelings—but what it established as sort
        of a tenet for how this program could function even within disagreement
        as part of the discourse. I think that’s the valuable part.{" "}
      </p>
      <p>
        <span className="speaker">NGW:</span> Yes, that’s exactly what I felt,
        too.
      </p>
      <p>
        Something Ben pointed out recently is that there are many presences in
        the room. Ben was in the room. He shared that often he feels very much
        like the Frenchman, especially during certain kinds of conversations
        that are quite different from the kinds of conversations he has in
        Europe or in different European contexts. He pointed out that there are
        other dynamics of otherness in that learning space. I think he describes
        it as “we play ourselves to the cast we meet.”{" "}
      </p>
      <p>
        <span className="speaker">LJ:</span> I think what I’m hearing you say is
        that Ben’s saying that Linda’s is not the only international perspective
        in the room. And there’s this funny moment I heard in listening to the
        recording where Ben is actually the one who points us to something about
        the way the English language is structured. I don’t recall the
        specifics, but I remember thinking while listening, “Oh, God, we can’t
        even read our own damn language, but the Frenchman can!”
      </p>
      <p>
        <span className="speaker">NGW:</span> His way of questioning brings
        something different in.
      </p>
      <h2>What Is a Scholarly Reading?</h2>
      <p>
        <span className="speaker">NGW:</span> I want you to say more about
        academic scholarship from your perspective, as an artist. I’m flipping
        back and forth through my notes here because it’s making me think about
        when we’ve talked about academia and teaching models, and what makes
        something a scholarly reading and what might exclude something from that
        category.
      </p>
      <p>
        <span className="speaker">LJ:</span> Wow, yes. And I think that we could
        attach this all the way back to the choice of Rushdie’s essay as the
        introductory text for the first day of the first workshop of the
        program.
        <sup>
          <a href="#annotation-13">13</a>
        </sup>{" "}
        To me, that is a non-scholarly piece of writing, even though there’s a
        lot of scholarly sorts of endeavors and a demonstration of scholarly
        knowledge evidenced through it. It’s really a commentary that’s very
        much informed by the events happening in the world at the moment. And
        this is one of the things that was raised about the direction of the
        conversation by Linda. What’s dangerous about this? What’s that? Where’s
        that? What’s the danger part here? And the answer, in short, is
        essentialism. But it’s really funny because, to me, the essay itself is
        actually the antithesis of an essentialist perspective. It’s simply
        saying look at all of the different places: personal, private, public,
        local, national. We—humanity—continue to struggle around certain ideas.
        And you can’t take the wind out of the sails from that if you read the
        piece.
      </p>
      <p>
        I do think the piece was a struggle for her, as well, because it doesn’t
        present scholarly research. He’s a fiction writer, a novelist, a person
        [who] makes stories for a living. So everything about that depends on
        the sort of ways in which people read. Somebody with a ton of experience
        like you or Ben is going to maybe see all of those things, whereas the
        class participants on our very first day in graduate school may not come
        to it with that level of, like, nuance of reading, but they can still
        relate to the same things. So you have a way where there’s a commonality
        of shared experience that doesn’t alienate people who bring more
        experience to the table and it actually invites the questions that
        people might have at the table who have less experience. But we’re still
        circling something we can at least have some touchstone of understanding
        with, and I think that is what I like about that piece. It’s in two
        parts; it actually invites us as readers to think about those
        relationships. It’s embedded in the speech itself.{" "}
      </p>
      <p>
        <span className="speaker">NGW:</span> So I’m still struggling, though,
        Lisa, because I totally hear where you’re going with bringing it in as
        something that introduces and invites in. There is an invitational
        aspect to how you shared it and partly the way that you read it—sections
        of it. It’s also partly because there’s things in it that people know,
        that are familiar. So I’m going to play a poking game. So does that
        mean, in your view, that if you brought in a scholarly article that it
        would be something unfamiliar? Where is that line for you? Because for
        me, to be honest, when I read Salman Rushdie I do find that there are a
        lot of scholarly aspects to it in the sense that the lists that he makes
        are constructed. There is a consciousness in the writing; he’s not just
        chatting. He’s deliberately putting certain kinds of things together
        with intention, and to me that intentionality is what I associate with
        scholarly writing as something where somebody is very aware of what
        words they’re saying, when, and how that content creates meaning or
        opens space for engagement. But I’m still stuck, then, on what you would
        think would be more scholarly than, or scholarly as opposed to, Rushdie.
        Do you know what I’m trying to get at?{" "}
      </p>
      <p>
        <span className="speaker">LJ:</span> I think it’s actually kind of
        funny, I’m going to have to go back to how that work would be
        categorized. If I would go look that work up in a library, I would find
        it under literature, where there don’t need to be footnotes. Do you know
        what I’m saying? It’s not particularly an essay that I would call a
        fiction essay; it’s not a short story. But it’s using storytelling as
        sort of the delivery. If we were doing literary analysis, which we are
        not, there are ways in which we would explore that further. So of course
        it’s scholarly, but I also don’t think for a minute that a, quote
        unquote, “scholar” would agree with that. It’s not a peer-reviewed
        journal entry, in other words. It doesn’t follow the sort of formula of
        how “scholarly” things are written and it doesn’t even tell us the point
        it’s trying to make. There’s no abstract. I’m using a very narrow and
        limited or expansive definition of scholarly to make my point. So then
        this essay expands possibilities; like you said, it does a lot of the
        work of something scholarly. And so if the goal of the program is to
        expand the way we think about craft and its relationship to art, life,
        all of the things, it seems important to be able to do that and discern
        that. And it seemed important to me at the time to be able, as an
        artist, to bring something in that could model that, where that could be
        laid bare by the thing we were exploring with a first-hand experience of
        the thing instead of me telling you it.{" "}
      </p>
      <p>
        So, when we were in conversation with that first group of students, I
        notice in relistening to the recording how many times I have to restate
        the same question to get the level of specificity necessary to be
        counted as an answer. What’s missing? First, there’s silence. Well,
        what’s not there? Then there’s a general reply. Okay, so I ask, “but
        what is that?” “What is that?” And then, “oh, it’s this.” And so, to me,
        that also just affirms my suspicion that a scholarly thing tells you
        more often than not what to think in relationship to the conclusion
        that’s presented. Works like this (by Rushdie) don’t ask you to have any
        particular position, but they’re giving you the kind of information that
        is inherently going to inform or convey a position. Scholarly articles,
        I would say, are trying to maintain this position of objectivity as
        though that’s a possible place, as though that’s real. There’s an
        assertion of realness and an attempt to continue to redefine realness by
        status quo definitions of what a scholarly piece of writing is. And this
        is inherently fictitious. It’s a fiction, right? And so I’m interested
        in saying, like, “okay, well, then what does the fiction say?” Maybe
        that’s more real.
      </p>
      <p>
        <span className="speaker">NGW:</span> Can you share a bit about why you
        changed the readings for each residency cohort? What did it mean for you
        to bring in those personal stories?{" "}
      </p>
      <p>
        <span className="speaker">LJ:</span> I’m always intuitive with the way I
        prepare for teaching and the way that I teach. It’s the way teaching
        works for me. But there’s also an exactitude that’s present at the same
        time; so being flexible and fluid and not trying to solve every problem
        before it arises, and also knowing very clearly the thing I’m being
        flexible around. Those things matter, right? So selecting different
        texts to ground each cohort is part of that pedagogical approach.
      </p>
      <p>
        <span className="speaker">NGW:</span> Yes, yes! It’s an approach we both
        use!
      </p>
      <p>
        LJ: So the reading is fixed, but the way I want to navigate it and use
        it is going to be contingent on the people in the room, the time of day,
        you know, how I feel, how they feel. And with that I just thought that
        “Step Across This Line” demonstrated everything that you were describing
        you wanted the program to do in a way that the other readings fit into
        but were not an example <em>of</em>. I needed the reading to do that
        work.
      </p>
      <p>
        <span className="speaker">NGW:</span> So then why the Audre Lorde and
        James Baldwin discussion in year two, for the Class of 2021?
        <sup>
          <a href="#annotation-14">14</a>
        </sup>{" "}
      </p>
      <p>
        <span className="speaker">LJ:</span> The Audre Lorde and James Baldwin
        piece I chose because it was a real conversation.
        <sup>
          <a href="#annotation-15">15</a>
        </sup>{" "}
        And I know that the students were thinking about having conversations
        with folks. We've got two queer Black people who are trying to work
        things out in a conversation. Both of them feel that the other person
        doesn’t understand their position; they want and need each other to own
        up to it. And if I remember correctly as the story goes, they spoke for
        hours. I thought that it exemplified something important for the kinds
        of conversations students could potentially be having with the people
        they were going to be interviewing, and I liked that it put two
        different positionalities of Blackness on the table. To avoid any
        notions of essentialism while Lorde is really specifically calling
        Baldwin out on an essentialist viewpoint in certain ways.
      </p>
      <p>
        And then the Toni Morrison reading—the transcription of the 1977
        Portland State University talk.
        <sup>
          <a href="#annotation-16">16</a>
        </sup>{" "}
        And it goes back to the Rushdie essay in a way, brings in the timeliness
        of reading it in that moment. The Morrison piece felt like it had been
        written yesterday, because of the advice she was giving to scholars,
        Black scholars, Black learners, Black researchers, about where not to
        get caught up in the traps. It just seemed like a directive. It’s a
        speech I wish I had been sitting in the audience for. I never met her,
        and I think so much about what it means to encounter somebody only
        through their work. So that’s why I included that reading: content and
        modeling. Something I don’t have to do because the writing does part of
        the work for me.
      </p>
      <p>
        <span className="speaker">NGW:</span> The next year COVID hit. We were
        all isolated and we’ve talked a little already about [how] we were all
        trying to figure it out. You brought in a short story from Octavia
        Butler.
        <sup>
          <a href="#annotation-17">17</a>
        </sup>{" "}
        Do you remember why?
      </p>
      <p>
        <span className="speaker">LJ:</span> Well, a few reasons. Octavia is
        another near and dear one. We were in this moment where we were living a
        science fiction story as far as most of us were concerned. And she hated
        writing short stories; she didn’t enjoy them as a format.{" "}
        <em>Speech Sounds</em> is really a story about losing one’s voice, about
        losing the very thing that is crucial to your identity on the planet.
        It’s also about the inability to read. I think it was ultimately
        poignant and potent. I was reading a lot of her novels at the time and I
        was, like, I can’t bring in all of this work. What can I bring in? It
        led me to thinking more about the other essays in that book. There are
        places for wild imagination in Octavia Butler’s work that draw out
        things that are there. But it’s not that direct, it’s not that didactic,
        and they’re just this wild imagining. And she’s a person who against all
        odds did what she did. She became a writer. Nothing should have allowed
        that to happen based on the circumstances of her life. And I love that,
        too. I love what it takes to really stay in this work, and I need for
        the people that I work with in an educational capacity to understand how
        valuable that tenacity is to doing one’s work. It’s a process. You’re
        always doing the work. You don’t get a degree and now you know you’ve
        done it. There’s a sadness to that essay. But there’s also a realness.
        There’s violence, there’s sex, there’s kids, there are adults. Death,
        murder, injustice. Everything. Somehow in this very short story that
        leaves you at the end of it feeling like there’s possibility, and the
        possibility of course is in the children.{" "}
      </p>
      <p>
        And “The Book of Martha,” in 2021, in connection to the symposium; that
        was a scary one.
        <sup>
          <a href="#annotation-18">18</a>
        </sup>{" "}
        I was worried about using that one because it so directly uses a
        Christian-based idea of God. And it’s not about believing in
        Christianity, not that there would be anything wrong with that, but that
        wouldn’t be something I would teach with. I was a little nervous about
        that, though, that it would be misinterpreted. But it ended up being
        this really wonderful thing because it’s really about recognizing
        possibilities within yourself and about not needing so much external
        validation. But in order to get that validation from ourselves sometimes
        we had to go through an intermediary version of ourselves, which is kind
        of how I thought about that. Where shyness is shit, like in the story.
      </p>
      <p>
        <span className="speaker">NGW:</span> And we ended with bell hooks in
        July 2022.
        <sup>
          <a href="#annotation-19">19</a>
        </sup>{" "}
        This reading happened with a group that you met for the second time—so
        that’s one unusual thing. This is a group that met you online with the
        symposium and then they got to meet you in person this past summer.{" "}
      </p>
      <p>
        <span className="speaker">LJ:</span> With hooks it’s about the notion of
        theory as liberatory practice; it talks about the status quo as being
        very confining. And what that essay is doing is actually offering
        something quite the opposite. And there’s a huge degree of vulnerability
        when she talks about her relationship to some of her mentors. I think
        that’s huge. And I really like how accessible her writing is. The words
        are just so easy. It’s not overly complex for unnecessary reasons, so I
        think it’s easy to read, but I think that what she’s saying is maybe not
        as easy to digest and I just love how she shows up on the page. And I
        love thinking about what we do as a possibility for liberatory practice.
        Something that can liberate—if not you, then others.
      </p>
      <p>
        <span className="speaker">NGW:</span> I think it tied in so beautifully
        with this year being their second year, the last year of the program,
        thinking about it in terms of what the possibilities are for the
        program, or what they can do with what they’re learning. I think it set
        that up beautifully.
      </p>
      <h2>Variations on 100 Questions</h2>
      <p>
        <span className="speaker">NGW:</span> We’ve talked about the first
        cohort, who were in the program from 2018–2020. Let’s talk about your
        variations on the exercise with the remaining cohorts. What did you
        shift and what did you learn and understand about the exercise and the
        groups?
      </p>
      <p>
        The second class (Class of 2021) was a really different group. I
        remember that you went through the same exercises with that much smaller
        second-year group of four students. And when the 100 Questions list
        happened, Shannon Stratton and Linda Sandino, who were core faculty with
        me, and Kevin Murray, who was teaching a workshop, were there with you.
        Educators outnumbered the students.{" "}
      </p>
      <figure>
        <img
          src={
            process.env.PUBLIC_URL + "/img/notaretreat/B_N_3.5_questions.jpg"
          }
        />
        <figcaption>
          Spread showing the 101 questions arrived at during the Fall 2019
          residency workshop with Lisa Jarrett. matt lambert, Benjamin Lignel,
          Heather K. Powers, and Sam Rastatter (eds.),{" "}
          <em>Mapping Craft – This is how we meet</em> (Swannanoa: Warren Wilson
          College, 2020). |{" "}
          <a
            href="https://www.macraftstudieswwc.com/publication2020"
            target="_blank"
            rel="noreferrer"
          >
            https://www.macraftstudieswwc.com/publication2020
          </a>
        </figcaption>
      </figure>
      <p>
        Look at the first 15 questions and how different they are. “Why are we
        here? Am I in the right place? Am I the right person? … ”
      </p>
      <p>
        <span className="speaker">LJ:</span> It was very apparent that with the
        second year (Class of 2021) there was a timidity and uncertainty, as
        opposed to the previous cohort from the Class of 2020. The first-year
        cohort, I would venture to say, was ready to take a turn at expanding
        questions while the second-year cohort are still trying to figure out
        “what is my path?” It shows up in the way that they ask questions. And
        people knew the first list in the second cohort. In terms of year two
        (2019), I did notice that the faculty had not really warmed up to me
        although you were there. They seemed very confused by me initially as
        well, but then Shannon Stratton got into it; she had questions.
      </p>
      <p>
        <span className="speaker">NGW:</span> I remember being aware of [the
        first] 15 questions, about “why am I here,” from the cohort so I started
        poking, circling back to earlier questions and asking things to be
        provocative. Somebody would ask a question and I’d ask a question to
        join back and push the conversation. I was trying to get them to play
        and to push each other.
      </p>
      <p>
        <span className="speaker">LJ:</span> There were a few things happening
        and I couldn’t tell if they were regulating content. The way we engaged
        with the content was different than we had in the first year, and it
        seemed like we weren’t all together. The first times with them, it was
        me and them with Shannon Stratton and Linda Sandino, so there was no
        you, and there was no Ben. The dynamism—something that happens among
        teams—wasn’t there. There was none of the spark. To go back to what I
        was trying to say earlier, honestly, you being in a room matters.{" "}
      </p>
      <p>
        <span className="speaker">NGW:</span> Then in 2020, the next year, with
        a pandemic going on it just became a really different experience. We had
        to do the residency online. It was hard. I think that was something
        different with this group, the Class of 2022. We were learning if this
        could work online—the program, I mean. You never met this cohort; in
        fact, we all met for the first and only time at graduation in 2022.
      </p>
      <p>
        <span className="speaker">LJ:</span> There was dynamism, but I don’t
        think it’s a good comparison between year three (2020) and the previous
        two because it’s so totally different. It was durational and people
        added to the list throughout the residency, even after I’d left, so the
        list is super long and [it] maps accumulation. Also, I can look at the
        history and figure out who posted which question. I would never do that
        because to me the value of this exercise is that once you say a
        question, it’s just on the collection list and a part of all the
        questions around it.{" "}
      </p>
      <p>
        <span className="speaker">NGW:</span> It was a really hard and strange
        time for everyone in July 2020. We’re learning what online time felt
        like. Residency was supposed to be in person for reasons. And we
        couldn’t be in person.{" "}
      </p>
      <p>
        <span className="speaker">LJ:</span> I felt it. Personally, I felt more
        disconnected from those students than I had any others in this workshop.
        It was still pretty early in the pandemic, like month four of teaching
        on Zoom. You know, I’ve done a lot of stuff since then on Zoom: one-day
        pop-ins, our lectures, this, that, and the other, but I felt more of
        people in Zoom at that time. This was the only way I talked to people at
        that time.{" "}
      </p>
      <p>
        I feel like the list was interesting and generative, and you can see
        that a lot was still being figured out by everybody. Nobody wanted to be
        doing this like this. Nobody wanted to be in Zoom school. But I never
        got to know any of them. I really couldn’t tell you how many of those
        folks think and work.{" "}
      </p>
      <p>
        What happens when a major wrench gets thrown into how you have
        approached something? I am holding a question: would it have been better
        to really imagine something entirely different? I don’t know if I was
        capable of doing so considering the summer that we were in, but looking
        back on this list, it does leave me with that question.{" "}
      </p>
      <p>
        <span className="speaker">NGW:</span> Which brings us to the Class of
        2023, who started in the summer of 2021, when we listened to Adrian
        Piper’s lecture and you read Octavia Butler’s “Speech Sounds” to us.
        <sup>
          <a href="#annotation-20">20</a>
        </sup>{" "}
        So this was online, too, but this was different, and you had two cohorts
        and faculty together in Zoom.{" "}
      </p>
      <p>
        <span className="speaker">LJ:</span> Yes, and there was a specific
        connection to Craft Ways: Tending to Craft, a virtual symposium where
        people (students) were having to tend to their own questions over the
        course of that experience, but both the first- and second-year cohorts,
        so I think things were again really different from the first and second
        years of the program. So there’s newness, certainly. And then there’s
        personality.{" "}
      </p>
      <p>
        And that idea of tending. Funny that you bring that up in this moment
        because I was starting to think about the symposium, you know, which is
        almost the bookend in a way. I don’t know what else to say, but tending
        became something we pointed to directly in the planning stages of that
        symposium.
        <sup>
          <a href="#annotation-21">21</a>
        </sup>{" "}
        There were double questions: how is this going to work in the context of
        a conference during a pandemic, but then also, how do we tend to craft?
        What does it mean to tend, to care for something? I think the conclusion
        we all came to was time. It takes time.{" "}
      </p>
      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_3.6_Crafways.jpg"}
        />
        <figcaption>
          Promotional image for Craft Ways 2021: Tending to Craft.
        </figcaption>
      </figure>
      <p>
        <span className="speaker">NGW:</span> Yes, and time and what time meant
        shifted between Judith Leemann’s workshop, in which tending as a
        practice came into view, in January 2019, and Jen Delos Reyes’s
        workshop, in which the students put their ideas about tending into a
        programmatic model, in January 2020. Jen’s workshop helped clarify the
        Craft Ways concept with the Class of 2020 and 2021. Because of the
        pandemic, nothing was possible in 2020, and we could actually hold an
        online symposium with thoughtful intentionality in 2021. You were a part
        of our Working Group, so you were embedded in the Organizing Groups
        collaborative process with students and the Center for Craft.
        <sup>
          <a href="#annotation-22">22</a>
        </sup>{" "}
      </p>
      <p>
        <span className="speaker">LJ:</span> One of the things that to me felt
        really important was this notion of tending to craft, and all the
        different ways that you’ve sort of played with the word “craft” over the
        duration of your career. There’s your work on the craftscape, but here’s
        Craft Ways and tending to craft, right? All of these things are implied
        in how you talk about what it is <em>you</em> want to talk about in
        relationship to craft. It’s not just the what, it’s the how. And so for
        me, that <em>how</em> became really important in trying to provide a
        framework for that virtual symposium.{" "}
      </p>
      <p>
        What happens if people—in this case students—actually tend to questions
        of their own design throughout the whole thing? That’s something I’ve
        never tried to do before, and I <em>wanted</em> to do it, which
        therefore made it something exciting for me to keep thinking about.{" "}
      </p>
      <p>
        But that’s a very different thing than the collective generating of
        questions. It’s just another example in my mind of how using an
        inquiry-based approach to one’s way of listening, being, researching
        allows different possibilities. In the case of the Craft Ways symposium,
        it meant that each student was listening in a particular way in each
        presentation and session with their individual question in mind. It was
        guiding listening. It was way of saying “I’m curious about this” and
        listening for <em>that thing</em>. It meant adjusting, potentially in
        real time, to a nuance of <em>that thing</em> in someone’s session. Some
        discovered that the thing they <em>thought</em> they were interested in
        wasn’t really the thing. They had to figure out how else to say their
        question, to come at it from another way. I think that is what scares
        people about conferences. So if you enter thinking about a list of
        questions from other people connected to your question, then, and like,
        well, what isn’t? I think conferences with people reading papers are
        terrifying places. You know this. So through guided listening it was not
        about attending a symposium in an ambiguous and wide-open whatever-ness.
        Our students were present guiding discussions at the symposium and the
        graduation cohort was presenting their own research. So tending through
        questions went across everyone’s experiences—students, presenters,
        attendees. Everyone.{" "}
      </p>
      <h2>Changing What’s Possible</h2>
      <p>
        <span className="speaker">NGW:</span> I want to note that I could have
        started each semester by giving a lecture on “this is craft, this is
        what we’re going to do, this is how we’re going to do it. These are the
        pedagogical strategies we’re going to engage you in in order to open up
        space, blah, blah, blah … ”
      </p>
      <p>
        I could have done that. But that’s telling rather than letting somebody
        uncover it for themselves. There’s something different when you’ve got a
        group that’s interested in the arts and particular ways of making and
        expressing what’s going on in the world. It’s not math. It’s not the
        same as math. And there’s something about starting it out in a disarming
        way that is connected to critical thinking, that allows people to sit
        down and think. What I wanted it to do, and what I think it did, is that
        it opened possibilities for individual perspectives, for individual
        voice by bringing in other forms of storytelling and communication. To
        be able to explore something in a way that honored what every single one
        of those makers brought into the room already. It changes what kind of
        possibilities can go from there.{" "}
      </p>
      <p>
        <span className="speaker">LJ:</span> Yes, that! Changing what’s
        possible.
      </p>
      <h2>Tending: Questions vs. Statements</h2>
      <p>
        <span className="speaker">NGW:</span> There are different kinds of
        tending that we are circling around in this conversation. I am thinking
        of a question that Ben had that points to the applied aspect of this
        program’s pedagogical form: what behaviors or dispositions are we trying
        to encourage? Specifically, he’s pointing to making a distinction here
        between the workshop’s direct relevance to practicum and research and
        the wider benefit of adopting a questioning stance. And the latter is
        the bigger takeaway, it seems. And I’m not quite sure where to go with
        this question, because I feel like it’s pointing to a specific outcome.
        And I think it’s a “yes, and” for me.
      </p>
      <p>
        <span className="speaker">LJ:</span> Is he asking: what is the
        pedagogical value of using questions? It seems like he’s making a
        distinction between the workshop in the context of the program, i.e.,
        practicum and research, and then the wider benefit of this questioning
        stance. But to me those two things can’t really be separated. The
        practicum and research, at least for me, has always been so much related
        to the broader relationship to life and lived experience. And what
        questioning does is de-center the authority of a very specific kind of
        research that is often the most valued by the academy. Namely a status
        quo perspective from which you propose a question with the goal of
        answering it. You are going to pose the question, and sometimes you can
        pose it as a statement, and then you’re going to solve the problem,
        right?{" "}
      </p>
      <p>
        But that’s suggesting that it’s math. It’s solvable. And it’s not. So,
        to me, the bigger takeaway and the direct relevance to practicum and
        research is that it positions you as the researcher and the writer of
        the practicum differently than if you were to approach it from sort of a
        statement-based stance. It’s not the scientific method. So, yes, I agree
        with you that it’s a “yes, and.”
      </p>
      <p>
        <span className="speaker">NGW:</span> This makes me consider what it
        means to be a participatory researcher and to be embodied in the
        practice of research itself. We—you and I—are leading toward a
        questioning stance that is a bigger thing. You are the pivot. If you
        understand questions and this kind of research work as part of
        participatory and embodied learning, then you don’t—you can’t—forget
        that you are a part of what your work is and what you’re doing. That how
        you ask questions, how you frame a question, is as important as what you
        are trying to understand. How you frame a question reveals everything
        about your research and you. You are the pivot when you do your research
        from within rather than looking at something from outside. There’s
        something in what you said about the statement versus a question being
        math versus something else that implies distance.
      </p>
      <p>
        It’s about presence, too—what happens with the two of us in a room,
        reading a particular text, and what that does. It’s connected to the way
        that you and I practice: the way we live, the way we work, the way we
        research, and the way we inhabit academic space. A questioning stance
        can be something outside of yourself and it can also be about
        recognizing yourself as a part of it, too. And I think that latter one,
        that’s what we’re putting forward.{" "}
      </p>
      <p>
        <span className="speaker">LJ:</span> Yes, essentially, you’re part of
        the conundrum that you’re chasing as a researcher. I think another thing
        that this approach we are talking about does is that it helps avoid the
        dangers of confirmation bias, of asserting something that you want to be
        true and then looking for the things that bear that out.{" "}
      </p>
      <p>
        It’s like the difference between an informative interview and one that
        is less interesting, one where the interviewer is not anticipating what
        the interviewee is going to say. One where the interviewer is truly
        curious about what this person is going to say next. Do you really want
        to know, as opposed to what you think you bring to the conversation and
        assume that the answer will be? If you already know, then what’s the
        point of the work, right?{" "}
      </p>
      <p>
        I say this a lot to incoming graduate students [who] I’ve worked with
        over the years. The stance is humility. If you’re not teachable, then
        there’s no point in you being in a learning environment. If you’re at a
        place of refusal from jump, that’s fine, but then why are you here? It
        leads me to another question: are you just here to be told that what you
        already know is true? Or are you here to learn something about yourself
        you don’t even know it’s possible to discover? Those are two completely
        different positions toward learning and toward research. I think that’s
        another thing with questions that a sort of an inquiry-based stance
        allows. I was going to introduce the word positionality because it
        introduces what you mentioned a few minutes ago—the body’s relationship
        to the situation and circumstance.{" "}
      </p>
      <h2>Possibilities: What You Got from the Program</h2>
      <p>
        <span className="speaker">NGW:</span> What has the program been like for
        you? What does it do for you in terms of learning about yourself as an
        artist, as a teacher, as a writer, as a thinker, as a researcher, as
        whatever?
      </p>
      <p>
        <span className="speaker">LJ:</span> Well, first and foremost, it gives
        me a sense of hope that there are people out there, people who are
        interested in some of the same things that I have always felt interested
        in.
      </p>
      <p>
        And in terms of this program—I work in higher education. I teach
        full-time at a state university. I know programs, I build them. I
        participate in disseminating them. And this program is different, not
        just because it’s a low residency—that’s a model that’s existed for a
        long time—but because of the nature of what happens in those
        experiences. So from day one, from that first minute we’re all living
        together, we’re all eating together, we are planning our teaching
        together. The whole thing is about togetherness and really fostering
        relationships between people alongside relationships and affinities
        toward one’s work. That is something that many—although this is not true
        of the program that I happen to teach in now at Portland State
        University—programs don’t emphasize: those relationships between people
        at the programmatic level as valuable. Not in the same way. Networks are
        always externalized, somehow, and, for the students, your graduate
        cohort as you go through school is this place of bonding, but usually
        that bonding is just over the trauma of doing work together. Here, we
        are literally eating and breathing and thinking together and laughing
        together. Unpacking ideas together—students and faculty. The students of
        this program are nontraditional in the broadest sense of that term.
        We’ve got people that are at all stages of their careers doing the same
        kind of work we’re doing out there, who are looking to redirect or shift
        or shape their work in a way they haven’t been able to in the context of
        their professional lives. So for me, it was just incredible and remains
        incredible to participate in something like that, especially when I
        didn’t know that that was what was going to happen. I like surprises.
        Things about me: Lisa likes surprise, especially when they delight her.
        Lisa really likes things that foreground my personal life values. My art
        and my life are part of the same thing. They’re not separate, and my
        dissatisfaction, long and ongoing dissatisfaction with the absence of so
        many of these things, is with what hasn’t been there.
      </p>
      <p>
        I fell in love with the program because of its possibilities. It’s so
        rare—beyond the things that we build for ourselves—to encounter things
        that other people build that make you feel like what you do has value.
        And I think maybe that’s an underlying motivation for me, too, in terms
        of students.
      </p>
      <p>To leave us with a question: how does what you do have value? </p>
      <hr />
      <cite>
        <sup id="annotation-1">1.</sup> Mary Bates and Melissa Ray Davis, “From
        First Year Seminar to Capstone Project,” <em>Owl & Spade</em>, Warren
        Wilson College,{" "}
        <a
          href="https://www.warren-wilson.edu/after-wwc/owlspade/f2022/first-year-seminar/"
          target="_blank"
          rel="noreferrer"
        >
          https://www.warren-wilson.edu/after-wwc/owlspade/f2022/first-year-seminar/
        </a>
        , retrieved May 20, 2023.
      </cite>
      <cite>
        <sup id="annotation-2">2.</sup> See Namita G. Wiggers, “Unsettling as
        Action: Developing a Craft Studies Curriculum,” in the session
        “Unsettling Coloniality: Resources and Strategies for New Teaching
        Models and Pedagogies” (presentation, Shared Ground symposium, organized
        by Bard Graduate Center, the Center for Craft, and the Museum of Arts
        and Design, September 20–22, 2018),{" "}
        <a
          href="https://www.youtube.com/watch?v=hdy1bIxL9yw"
          target="_blank"
          rel="noreferrer"
        >
          https://www.youtube.com/watch?v=hdy1bIxL9yw
        </a>
        , retrieved May 19, 2023. This presentation was further developed and
        presented as “Which Craft Histories? Building Around Blindspots,” March
        21, 2019, Konstfack, Stockholm, Sweden.
      </cite>
      <cite>
        <sup id="annotation-3">3.</sup> Lauren Sinner, a former student of mine
        from the MFA in Applied Craft + Design, introduced me to this concept,
        which we applied along with other improv exercises to great success in
        2017 in the Theory of Objects course I taught for that program, and in
        Lauren’s workshop for MACR in January 2021. For more information about
        the MFA in AC+D, see{" "}
        <a
          href="https://pnca.willamette.edu/academics/graduate/acd"
          target="_blank"
          rel="noreferrer"
        >
          https://pnca.willamette.edu/academics/graduate/acd
        </a>
        , retrieved May 19, 2023.
      </cite>
      <cite>
        <sup id="annotation-4">4.</sup> Originally a joint program between
        Oregon College of Art + Craft and Pacific Northwest College of Art
        founded collaboratively in 2009, the program is now housed at
        PNCA/Willamette University following the closure of OCAC in 2019 and the
        merger between PNCA/WU.
      </cite>
      <cite>
        <sup id="annotation-5">5.</sup>{" "}
        <NavLink to="/notaretreat/lignel_wiggers">
          Ben Lignel turned the 100 Questions sheet into a poster, which we used
          to promote the program
        </NavLink>
        .
      </cite>
      <cite>
        <sup id="annotation-6">6.</sup> Craft Ways 2021: Tending to Craft, July
        22–24, 2021, virtual (online), Asheville: the Center for Craft and
        Warren Wilson College,{" "}
        <a
          href="https://www.centerforcraft.org/craft-ways-2021"
          target="_blank"
          rel="noreferrer"
        >
          https://www.centerforcraft.org/craft-ways-2021
        </a>
        , retrieved May 18, 2023. Craft Ways 2021: Tending to Craft was
        co-created by the MA in Critical Craft Studies program, Warren Wilson
        College, and the Center for Craft. The Organizing Group team included:
        Juliana Rowen Barton, Lola Clairmont, Lexie Harvey (Class of 2022),
        Berit Lavender, Heather Powers (Class of 2021), Jessie Shires, Namita
        Gupta Wiggers, and Marilyn Zapf. The Working Group, whose vision
        informed the fabric of the gathering, included: Julie Hollenbach, Lisa
        Jarrett, PJ Gubatina Policarpio, Jen Delos Reyes, and Emily Zilber.
      </cite>
      <cite>
        <sup id="annotation-7">7.</sup> See Imani Jacqueline Brown, “Truth as
        Theatrical Fiction,”{" "}
        <a
          href="https://imanijacquelinebrown.net/Truth-as-Theatrical-Fiction"
          target="_blank"
          rel="noreferrer"
        >
          https://imanijacquelinebrown.net/Truth-as-Theatrical-Fiction
        </a>
        , retrieved May 19, 2023.
      </cite>
      <cite>
        <sup id="annotation-8">8.</sup> Glenn Adamson,{" "}
        <em>Craft: An American History</em> (New York: Bloomsbury Publishing,
        2021).
      </cite>
      <cite>
        <sup id="annotation-9">9.</sup> Namita Gupta Wiggers, “Can Craft Save
        America?” Metropolis (June 18, 2021),{" "}
        <a
          href="https://metropolismag.com/viewpoints/can-craft-save-america/"
          target="_blank"
          rel="noreferrer"
        >
          https://metropolismag.com/viewpoints/can-craft-save-america/
        </a>
        , retrieved May 18, 2023.
      </cite>
      <cite>
        <sup id="annotation-10">10.</sup> Lisa Jarrett, “Inquiry as Practice:
        Notes on 100 Questions Workshop and How Do You Draw the Light from
        Sapphires,” in Mellanee Goodman, Benjamin Lignel, Amy Meissner, Heather
        K. Powers, and Joni Van Bockel (eds.), <em>To : Craft</em> (Asheville,
        NC: Warren Wilson College, 2021),{" "}
        <a
          href="https://www.macraftstudieswwc.com/publication2021"
          target="_blank"
          rel="noreferrer"
        >
          https://www.macraftstudieswwc.com/publication2021
        </a>
        , retrieved May 19, 2023.
      </cite>
      <cite>
        <sup id="annotation-11">11.</sup> Salman Rushdie, “Step Across This
        Line.” The Tanner Lectures on Human Values at Yale University, New
        Haven, CT, February 26, 2002.
      </cite>
      <cite>
        <sup id="annotation-12">12.</sup> Ibid.
      </cite>
      <cite>
        <sup id="annotation-13">13.</sup> Ibid.
      </cite>
      <cite>
        <sup id="annotation-14">14.</sup> James Baldwin and Audre Lorde,
        “Revolutionary Hope: A Conversation between James Baldwin and Audre
        Lorde,” <em>Essence</em> 15, no. 8 (December 1984): 72–74.
      </cite>
      <cite>
        <sup id="annotation-15">15.</sup> Ibid.
      </cite>
      <cite>
        <sup id="annotation-16">16.</sup> Toni Morrison, “A Humanist View,”
        Portland State University’s Oregon Public Speakers Collection: “Black
        Studies Center Public Dialogue. Pt. 2,” Portland State University, OR,
        May 30, 1975.
      </cite>
      <cite>
        <sup id="annotation-17">17.</sup> Octavia Butler, “Speech Sounds” and
        “Furor Scribendi,” in <em>Bloodchild and Other Stories</em> (New York:
        Seven Stories Press, 2005).
      </cite>
      <cite>
        <sup id="annotation-18">18.</sup> Adrian Piper, “What, Exactly, Is the
        Idea of Artistic Research?” (lecture, Lausanne Symposium, presented by
        the Federal Office of Culture, Switzerland, and Les Urbaines, Bern,
        Switzerland, December 6, 2015),{" "}
        <a href="https://youtu.be/tYZLg9-x3g4" target="_blank" rel="noreferrer">
          https://youtu.be/tYZLg9-x3g4
        </a>
        , retrieved May 18, 2023; Octavia Butler, “The Book of Martha,” “Furor
        Scribendi,” and “Positive Obsession,” in{" "}
        <em>Bloodchild and Other Stories</em>.
      </cite>
      <cite>
        <sup id="annotation-19">19.</sup> bell hooks,{" "}
        <em>Teaching to Transgress: Education as the Practice of Freedom</em>{" "}
        (New York: Routledge, 1994).
      </cite>
      <cite>
        <sup id="annotation-20">20.</sup> Piper, ibid., and Butler, “Speech
        Sounds,” ibid.
      </cite>
      <cite>
        <sup id="annotation-21">21.</sup> See Craft Ways 2021: Tending to Craft,{" "}
        <a
          href="https://www.centerforcraft.org/craft-ways-2021"
          target="_blank"
          rel="noreferrer"
        >
          https://www.centerforcraft.org/craft-ways-2021
        </a>
        , retrieved May 19, 2023.
      </cite>
      <cite>
        <sup id="annotation-22">22.</sup> Ibid.
      </cite>
    </div>
  );
};

export default JarrettWiggers;
