import Acierto from "./acierto";
import Clugage from "./clugage";
import JarrettWiggers from "./jarrett_wiggers";
import Leemann from "./leemann";
import Meissner from "./meissner";
import Potter from "./potter";
import Skov from "./skov";
import Lignel from "./lignel";
import Murray from "./murray";
import Patel from "./patel";
import DeLosReyes from "./delosreyes";
import Levine from "./levine";
import LignelWiggers from "./lignel_wiggers";
import Wilder from "./wilder";
import Keith from "./keith";
import Momon from "./momon";
import Helgeson from "./helgeson";
import Martin from "./martin";
import Fisher from "./fisher";
import Razdan from "./razdan";
import Introduction from "./introduction";

const publication = {
  id: "notaretreat",
  author: "Lignel & Wiggers",
  authorFull: "Benjamin Lignel & Namita Gupta Wiggers",
  name: "This is not a Retreat",
  articles: [
    {
      pubId: "notaretreat",
      id: "introduction",
      order: 1,
      title: "Introduction",
      author: "Ben Lignel and Namita Gupta Wiggers",
      authorShort: "Lignel & Wiggers",
      tags: [
        "new writing (faculty)",
        "introduction",
        "appalachia",
        "community",
        "craft studies",
        "craftscape",
        "imagined futures",
        "place",
        "teaching practice",
        "craft ways (presenters)",
      ],
      citation: `Lignel, Ben and Namita Gupta Wiggers. “Introduction,” This Is Not a Retreat, edited by Ben Lignel and Namita Wiggers. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, 2023. Copyright remains with the authors. 978-1-7351592-6-3 `,
      url: "https://www.theMACRPapers.com/notaretreat/introduction",
      downloadUrl: "notaretreat/Ben_Namita_1_intro.pdf",
      component: <Introduction />,
    },
    {
      pubId: "notaretreat",
      id: "meissner",
      order: 2,
      title: "But First, Learn This (A List)",
      author: "Amy Meissner",
      authorShort: "Meissner",
      tags: [
        "new writing (alumni)",
        "craft ways (presenters)",
        "essay",
        "apprenticeship",
        "labor",
        "self-knowledge",
        "skill acquisition",
      ],
      citation: `Meissner, Amy. “But First, Learn This (A List),” This Is Not a Retreat, edited by Ben Lignel and Namita Wiggers. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, 2023. Copyright remains with the author. ISBN 978-1-7351592-6-3 `,
      url: "https://www.theMACRPapers.com/notaretreat/meissner",
      downloadUrl: "notaretreat/Ben_Namita_2_Meissner.pdf",
      component: <Meissner />,
    },
    {
      pubId: "notaretreat",
      id: "jarrett_wiggers",
      order: 3,
      title:
        "Teaching Questions: Lisa Jarrett and Namita Gupta Wiggers in Conversation",
      author: "Lisa Jarrett and Namita Gupta Wiggers",
      authorShort: "Jarrett & Wiggers",
      tags: [
        "new writing (faculty)",
        "craft ways (presenters)",
        "interview",
        "bias",
        "black feminism",
        "community",
        "craftscape",
        "education",
        "macr program",
        "pedagogy",
        "asking questions",
        "making space",
      ],
      citation: `Jarrett, Lisa, and Namita Gupta Wiggers. “Teaching Questions: Lisa Jarrett and Namita Gupta Wiggers in Conversation,” This Is Not a Retreat, edited by Ben Lignel and Namita Wiggers. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, 2023. Copyright remains with the authors. ISBN 978-1-7351592-6-3 `,
      url: "https://www.theMACRPapers.com/notaretreat/jarrett_wiggers",
      downloadUrl: "notaretreat/Ben_Namita_3_jarrett.pdf",
      component: <JarrettWiggers />,
    },
    {
      pubId: "notaretreat",
      id: "acierto",
      order: 4,
      title:
        "In the After-Thought: Teaching Transparency and the Ongoing Practice of Freedom",
      author: "alejandro t. acierto",
      authorShort: "acierto",
      tags: [
        "new writing (faculty)",
        "essay",
        "bias",
        "diaspora",
        "digital communication",
        "pedagogy",
        "support networks",
        "asking questions",
        "making space",
      ],
      citation: `acierto, alejandro t.. “In the After-Thought: Teaching Transparency and the Ongoing Practice of Freedom,” This Is Not a Retreat, edited by Ben Lignel and Namita Wiggers. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, 2023. Copyright remains with the author. ISBN 978-1-7351592-6-3 `,
      url: "https://www.theMACRPapers.com/notaretreat/acierto",
      downloadUrl: "notaretreat/Ben_Namita_4_alejandro.pdf",
      component: <Acierto />,
    },
    {
      pubId: "notaretreat",
      id: "leemann",
      order: 5,
      title: "towards tending not knowing",
      author: "Judith Leemann",
      authorShort: "Leemann",
      tags: [
        "new writing (faculty)",
        "essay",
        "chance",
        "encounters",
        "learning with objects",
        "teaching practice",
        "asking questions",
      ],
      citation: `Leemann, Judith. “towards tending not knowing,” This Is Not a Retreat, edited by Ben Lignel and Namita Wiggers. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, 2023. Copyright remains with the author. ISBN 978-1-7351592-6-3 `,
      url: "https://www.theMACRPapers.com/notaretreat/leemann",
      downloadUrl: "notaretreat/Ben_Namita_5_Judith.pdf",
      component: <Leemann />,
    },
    {
      pubId: "notaretreat",
      id: "potter",
      order: 6,
      title: "Pandemic Papermaking: Critical Craft",
      author: "Melissa Potter",
      authorShort: "Potter",
      tags: [
        "new writing (faculty)",
        "commentary",
        "craft practices",
        "digital communication",
        "making space",
        "teaching practice",
      ],
      citation: `Potter, Melissa. “Pandemic Papermaking: Critical Craft,” This Is Not a Retreat, edited by Ben Lignel and Namita Wiggers. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, 2023. Copyright remains with the author. ISBN 978-1-7351592-6-3   `,
      url: "https://www.theMACRPapers.com/notaretreat/potter",
      downloadUrl: "notaretreat/Ben_Namita_6_Melissa.pdf",
      component: <Potter />,
    },
    {
      pubId: "notaretreat",
      id: "clugage",
      order: 7,
      title: "This Is Not a Food Studies Program",
      author: "Sara Clugage",
      authorShort: "Clugage",
      tags: [
        "new writing (faculty)",
        "essay",
        "bias",
        "community",
        "embodied knowledge",
        "skill acquisition",
      ],
      citation: `Clugage, Sara. “This Is Not a Food Studies Program,” This Is Not a Retreat, edited by Ben Lignel and Namita Wiggers. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, 2023. Copyright remains with the author. ISBN 978-1-7351592-6-3 `,
      url: "https://www.theMACRPapers.com/notaretreat/clugage",
      downloadUrl: "notaretreat/Ben_Namita_7_Sara.pdf",
      component: <Clugage />,
    },
    {
      pubId: "notaretreat",
      id: "skov",
      order: 8,
      title: "Reflections on Teaching and Learning with the Bowl",
      author: "Mara Holt Skov",
      authorShort: "Skov",
      tags: [
        "new writing (faculty)",
        "essay",
        "craft studies",
        "curation",
        "learning with objects",
        "museum labels",
      ],
      citation: `Skov, Mara Holt. “Reflections on Teaching and Learning with the Bowl,” This Is Not a Retreat, edited by Ben Lignel and Namita Wiggers. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, 2023. Copyright remains with the author. ISBN 978-1-7351592-6-3 `,
      url: "https://www.theMACRPapers.com/notaretreat/skov",
      downloadUrl: "notaretreat/Ben_Namita_8_Mara.pdf",
      component: <Skov />,
    },
    {
      pubId: "notaretreat",
      id: "lignel",
      order: 9,
      title:
        "Teaching and Unlearning—Or How to Write Wall Texts: Lessons of Decolonial Thinking",
      author: "Ben Lignel",
      authorShort: "Lignel",
      tags: [
        "new writing (faculty)",
        "essay",
        "bias",
        "indigenous knowledge",
        "knowledge classification",
        "learning with objects",
        "museum labels",
      ],
      citation: `Lignel, Ben. “Teaching and Unlearning—Or How to Write Wall Texts: Lessons of Decolonial Thinking,” This Is Not a Retreat, edited by Ben Lignel and Namita Wiggers. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, 2023. Copyright remains with the author. ISBN 978-1-7351592-6-3 `,
      url: "https://www.theMACRPapers.com/notaretreat/lignel",
      downloadUrl: "notaretreat/Ben_Namita_9_Ben.pdf",
      component: <Lignel />,
    },
    {
      pubId: "notaretreat",
      id: "levine",
      order: 10,
      title: "The Dotted Line (Desktop Version)",
      author: "Faythe Levine",
      authorShort: "Levine",
      tags: [
        "new writing (faculty)",
        "excerpt",
        "archives",
        "chance",
        "knowledge classification",
        "self-publishing",
        "writing",
      ],
      citation: `Levine, Faythe.“The Dotted Line (Desktop Version),” This Is Not a Retreat, edited by Ben Lignel and Namita Wiggers. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, 2023. Copyright remains with the author. ISBN 978-1-7351592-6-3  `,
      url: "https://www.theMACRPapers.com/notaretreat/levine",
      downloadUrl: "notaretreat/Ben_Namita_10_Faythe.pdf",
      component: <Levine />,
    },
    {
      pubId: "notaretreat",
      id: "lignel_wiggers",
      order: 11,
      title:
        "Designing the Plane as We Flew It—A Discussion on the MACR Program Publications",
      author: "Ben Lignel and Namita Gupta Wiggers",
      authorShort: "Lignel & Wiggers",
      tags: [
        "new writing (faculty)",
        "interview",
        "decolonization",
        "encounters",
        "macr program",
        "mapping",
        "pedagogy",
        "self-publishing",
        "asking questions",
      ],
      citation: `Lignel, Ben and Namita Gupta Wiggers. “Designing the Plane as We Flew It—A Discussion on the MACR Program Publications,” This Is Not a Retreat, edited by Ben Lignel and Namita Wiggers. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, 2023. Copyright remains with the authors. 978-1-7351592-6-3 `,
      url: "https://www.theMACRPapers.com/notaretreat/lignel_wiggers",
      downloadUrl: "notaretreat/Ben_Namita_11_Ben_Namita.pdf",
      component: <LignelWiggers />,
    },
    {
      pubId: "notaretreat",
      id: "wilder",
      order: 12,
      title: "Weaving Community Together",
      author: "Melanie Wilder",
      authorShort: "wilder",
      tags: [
        "new writing (faculty)",
        "essay",
        "community",
        "craft practices",
        "dye",
        "encounters",
        "mapping",
      ],
      citation: `Wilder, Melanie. “Weaving Community Together,” This Is Not a Retreat, edited by Ben Lignel and Namita Wiggers. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, 2023. Copyright remains with the author. ISBN 978-1-7351592-6-3  `,
      url: "https://www.theMACRPapers.com/notaretreat/wilder",
      downloadUrl: "notaretreat/Ben_Namita_12_Melanie.pdf",
      component: <Wilder />,
    },
    {
      pubId: "notaretreat",
      id: "keith",
      order: 13,
      title: "Emplaced Remembrance—Thinking about Gone Worlds Always Nearby",
      author: "Jeffrey A. Keith",
      authorShort: "Keith",
      tags: [
        "new writing (faculty)",
        "essay",
        "black feminism",
        "faith",
        "healing",
        "indigenous studies",
        "labor",
        "land",
        "oral history",
        "poetry",
        "memory",
      ],
      citation: `Keith, Jeffrey A.. “Emplaced Remembrance—Thinking about Gone Worlds Always Nearby,” This Is Not a Retreat, edited by Ben Lignel and Namita Wiggers. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, 2023. Copyright remains with the author. ISBN 978-1-7351592-6-3 `,
      url: "https://www.theMACRPapers.com/notaretreat/keith",
      downloadUrl: "notaretreat/Ben_Namita_13_Jeff.pdf",
      component: <Keith />,
    },
    {
      pubId: "notaretreat",
      id: "razdan",
      order: 14,
      title: "Unearthing the Craftscape",
      author: "Anjula Razdan",
      authorShort: "Razdan",
      tags: [
        "agency",
        "craftscape",
        "craft studies",
        "interdisciplinarity",
        "decolonization",
        "land",
        "methodology",
        "making space",
        "questions",
      ],
      citation: `Wiggers, Namita Gupta. “Introduction: Unearthing the Craftscape,” This Is Not a Retreat, edited by Ben Lignel and Namita Gupta Wiggers. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, 2023. Copyright remains with the authors. ISBN 978-1-7351592-6-3 `,
      url: "https://www.theMACRPapers.com/notaretreat/razdan",
      downloadUrl: "notaretreat/Ben_Namita_14_Craftscape.pdf",
      component: <Razdan />,
    },
    {
      pubId: "notaretreat",
      id: "momon",
      order: 15,
      title: "People, Get Ready: Building New Worlds",
      author: "Tiffany Momon",
      authorShort: "Momon",
      tags: [
        "new writing (faculty)",
        "craft ways (presenters)",
        "essay",
        "black craftspeople",
        "craftscape",
        "faith",
        "imagined futures",
        "pedagogy",
        "public history",
        "social justice",
        "world making",
      ],
      citation: `Momon, Tiffany. “People, Get Ready: Building New Worlds,” This Is Not a Retreat, edited by Ben Lignel and Namita Wiggers. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, 2023. Copyright remains with the author. ISBN 978-1-7351592-6-3  `,
      url: "https://www.theMACRPapers.com/notaretreat/momon",
      downloadUrl: "notaretreat/Ben_Namita_15_Tiffany.pdf",
      component: <Momon />,
    },

    {
      pubId: "notaretreat",
      id: "helgeson",
      order: 16,
      title: "Craft, Nothing, and Nowhere",
      author: "Anna Helgeson",
      authorShort: "Helgeson",
      tags: [
        "new writing (faculty)",
        "essay",
        "absence",
        "community",
        "craft practices",
        "imagined futures",
        "objectlessness",
        "performance",
        "queer life",
        "making space",
      ],
      citation: `Helgeson, Anna. “Craft, Nothing, and Nowhere,”This Is Not a Retreat, edited by Ben Lignel and Namita Wiggers. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, 2023. Copyright remains with the author. ISBN 978-1-7351592-6-3 `,
      url: "https://www.theMACRPapers.com/notaretreat/helgeson",
      downloadUrl: "notaretreat/Ben_Namita_16_Anna.pdf",
      component: <Helgeson />,
    },
    {
      pubId: "notaretreat",
      id: "martin",
      order: 17,
      title: "What to Do When You Are Dead: Craft and Existential Anxiety ",
      author: "Tom Martin",
      authorShort: "Martin",
      tags: [
        "new writing (faculty)",
        "essay",
        "death",
        "imagined futures",
        "phenomenology",
        "philosophy",
        "self-knowledge",
      ],
      citation: `Martin, Tom. “What to Do When You Are Dead: Craft and Existential Anxiety,” This Is Not a Retreat, edited by Ben Lignel and Namita Wiggers. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, 2023. Copyright remains with the author. ISBN 978-1-7351592-6-3 `,
      url: "https://www.theMACRPapers.com/notaretreat/martin",
      downloadUrl: "notaretreat/Ben_Namita_17_Tom.pdf",
      component: <Martin />,
    },
    {
      pubId: "notaretreat",
      id: "murray",
      order: 18,
      title: "Why the Question?",
      author: "Kevin Murray",
      authorShort: "Murray",
      tags: [
        "new writing (faculty)",
        "essay",
        "bias",
        "craft studies",
        "imagined futures",
        "methodology",
      ],
      citation: `Murray, Kevin. “Why the Question?,”This Is Not a Retreat, edited by Ben Lignel and Namita Wiggers. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, 2023. Copyright remains with the author. ISBN 978-1-7351592-6-3 `,
      url: "https://www.theMACRPapers.com/notaretreat/murray",
      downloadUrl: "notaretreat/Ben_Namita_18_Kevin.pdf",
      component: <Murray />,
    },
    {
      pubId: "notaretreat",
      id: "delosreyes",
      order: 19,
      title:
        "Future Desire Lines: Cultivating Paths of Resistance, Support, and Joy",
      author: "Jen Delos Reyes",
      authorShort: "Delos Reyes",
      tags: [
        "new writing (faculty)",
        "invitation",
        "bias",
        "biography",
        "community",
        "imagined futures",
        "making space",
        "writing",
      ],
      citation: `Delos Reyes, Jen. “Future Desire Lines: Cultivating Paths of Resistance, Support, and Joy,” This Is Not a Retreat, edited by Ben Lignel and Namita Wiggers. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, 2023. Copyright remains with the author. ISBN 978-1-7351592-6-3 `,
      url: "https://www.theMACRPapers/notaretreat/delosreyes",
      component: <DeLosReyes />,
      downloadUrl: "notaretreat/Ben_Namita_19_Jen.pdf",
    },
    {
      pubId: "notaretreat",
      id: "patel",
      order: 20,
      title: "Worldmaking with Colleagues",
      author: "Alpesh Kantilal Patel",
      authorShort: "Patel",
      tags: [
        "new writing (faculty)",
        "essay",
        "queer life",
        "social justice",
        "support networks",
        "world making",
      ],
      citation: `Patel, Alpesh Kantilal. “Worldmaking with Colleagues,” This Is Not a Retreat, edited by Ben Lignel and Namita Wiggers. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, 2023. Copyright remains with the author. ISBN 978-1-7351592-6-3 `,
      url: "https://www.theMACRPapers.com/notaretreat/patel",
      downloadUrl: "notaretreat/Ben_Namita_20_Alpesh.pdf",
      component: <Patel />,
    },
    {
      pubId: "notaretreat",
      id: "fisher",
      order: 21,
      title: "Who writes craft histories?",
      author: "Michelle Millar Fisher",
      authorShort: "Fisher",
      tags: [
        "new writing (faculty)",
        "invitation",
        "bias",
        "craft studies",
        "encounters",
        "indigenous knowledge",
        "writing",
      ],
      citation: `Fisher, Michelle Millar. “Who writes craft histories?,” This Is Not a Retreat, edited by Ben Lignel and Namita Wiggers. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, 2023. Copyright remains with the author. ISBN 978-1-7351592-6-3 `,
      url: "https://www.theMACRPapers.com/notaretreat/fisher",
      downloadUrl: "notaretreat/Ben_Namita_21_Michelle.pdf",
      component: <Fisher />,
    },
  ],
  accent: "#7830a6",
  firstArticle: "introduction",
};

export default publication;
