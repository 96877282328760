const Martin = () => {
  return (
    <div>
      <p>
        <sup>
          <a href="#annotation-1">1</a>
        </sup>{" "}
        Say you read the newspaper every day. You follow the political power
        struggles, the foreign armed conflicts, the constant handwringing about
        the state of the economy. Some days you keep thinking about the news as
        you drive to work, and some days you forget about it almost immediately.
      </p>

      <p>And then one day you die.</p>

      <p>
        What will the newspaper look like once you are dead? For the rest of us,
        the contents will remain the same. You may be gone, but the goings-on of
        the world are still relevant to <em>us</em>.
        <sup>
          <a href="#annotation-3">3</a>
        </sup>{" "}
        This is a little bit of a mind-bender because, until yesterday, the{" "}
        <em>us</em>
        <sup>
          <a href="#annotation-5">5</a>
        </sup>{" "}
        included you, and now it does not. That is true for everyone contained
        within the <em>us</em>. How can it still be <em>us</em>, then? Well,{" "}
        <em>us</em> was never about you, or me, or any one person. <em>Us</em>{" "}
        was this process that you got swept up in when you were born,
        <sup>
          <a href="#annotation-6">6</a>
        </sup>{" "}
        6 a set of minor worries, lesser joys, and banal preoccupations that you
        took part in as a matter of course, as a necessary part of being alive
        (you thought). Wars abroad; the stock market; celebrity gossip; these
        things never mattered to <em>you</em>, since they did not concern
        <sup>
          <a href="#annotation-7">7</a>
        </sup>{" "}
        you specifically. These things belonged to <em>us</em>, and when you
        died, <em>us</em> kept going without missing a beat.
      </p>

      <p>
        How much of your life is about you, and how much is devoted to{" "}
        <em>us?</em> You can tell how much of your life is about you by
        considering anything you do and asking yourself, “do I care
        <sup>
          <a href="#annotation-8">8</a>
        </sup>{" "}
        about this”? Not “care” like you care about the end a TV miniseries or
        the newest round of tax cuts, but really care. You cared about getting
        into a car accident and knocking out a molar when your jaw hit the
        doorframe. You cared about losing your virginity and you cared about
        wishing it were with somebody else. You cared in that moment in line at
        the grocery store after work, when the chain of thoughts about leaving
        the office late and never making a shopping list and sticking to your
        diet dissolved into a kind of formless anxious
        <sup>
          <a href="#annotation-10">10</a>
        </sup>{" "}
        grief. <em>I’m going to die</em>,
        <sup>
          <a href="#annotation-11">11</a>
        </sup>{" "}
        you understand in that moment.{" "}
        <em>
          I’ll never be this far from death again. And all this tedium will
          grind on without me.
        </em>
      </p>

      <p>
        These anxious moments are rarely revelatory, at least in the sense of
        providing a practical course of action. No next steps appear as you
        consider the meaninglessness of your routine in light of the
        inevitability of existential dissolution. If you are lucky, though, one
        idea may come into closer focus: that you are already dead, in a manner
        of speaking. If death is the end of everything that matters to you, then
        you may already be pretty close, bumping around through life answering
        to the collective demands of the <em>us</em>. If you are so distant from
        things that matter to you, how much of a <em>you</em> is there even to
        speak of?
      </p>

      <p>
        It is tempting to react in these moments of anxiety by considering
        <sup>
          <a href="#annotation-13">13</a>
        </sup>{" "}
        what matters to you and making a concrete plan
        <sup>
          <a href="#annotation-14">14</a>
        </sup>{" "}
        to pursue it. Maybe you would feel more yourself if you switched
        careers, you think. Maybe you would feel more authentically
        <sup>
          <a href="#annotation-15">15</a>
        </sup>{" "}
        yourself traveling the world alone or taking a new lover. And there may
        well be some truth to this, as those could be situations in which you
        would begin to attend to <em>you</em>, rather than the detached
        motivations of <em>us</em>.
      </p>

      <p>
        Yet there is a problem with this introspective strategy, namely that the
        realm of “considering” is already saturated with the expectations of the{" "}
        <em>us</em>. When you picture yourself backpacking across South Asia,
        are you imagining the sights and sounds and smells and the meanings they
        present? Probably not, since if you have not yet had these experiences,
        you cannot know whether and how they will matter to you. And if you
        expect that what matters to you lies in the unknown, why go backpacking,
        of all possible activities? There is plenty of unknown right on your
        doorstep. Get a job behind a meat counter at a supermarket instead, as
        there is plenty of unknown (to you) to experience there.
      </p>

      <p>
        Or perhaps you only wanted to go backpacking because you understood it
        to be what people do when in search of self-discovery. If you are
        picturing this trip as viewed through the lens of a movie camera, you
        are imagining yourself from the point of view of the <em>us</em>, rather
        than from your own point of view. In making plans to figure out what
        matters to you, you have slipped into a shared conception of how
        “finding what matters” looks, an established trope of self-discovery
        that paradoxically undermines your conception of yourself as an
        individual. The “finding yourself” script can be just another pre-formed
        identity that is available to take down off the shelf and inhabit,
        regardless of its relevance to <em>you</em>.
      </p>

      <p>
        So then how do you figure out what matters to you? The first step is to
        recognize that “figuring out” and “mattering to you” represent different
        categories of understanding.
        <sup>
          <a href="#annotation-16">16</a>
        </sup>{" "}
        When you needed a root canal, you did not “figure out” that the pain in
        your tooth mattered—it just did. It showed up in your mind fully loaded
        with mattering as a fundamental aspect of its existence. The mattering
        and the understanding of the thing happened exactly at once or, indeed,
        were the same phenomenon.
      </p>

      <p>
        You could attempt to “figure out”—in this case, make a list
        of—everything that matters, but you would end up with an absurd collage
        of bed bugs, hemorrhoids, wedding bands, your dislike of cilantro, and
        snow days, just off the top of your head. What ties these all together
        is that: 1) They are relevant to <em>you</em> and 2) Their relevance is
        immediate, rather than arriving through consideration. When you see a
        bedbug crawling across your hotel mattress, you do not need to think
        about whether it presents a problem. The bedbug and the problem are
        immediately
        <sup>
          <a href="#annotation-17">17</a>
        </sup>{" "}
        and entirely coterminous.
      </p>

      <p>
        Oddly enough, it is in finding that bedbug that you are really living.
        <sup>
          <a href="#annotation-18">18</a>
        </sup>{" "}
        Forget racing motorcycles or putting it all on black in Vegas or
        whatever else the phrase “really living” brings to mind. What defines
        our human understanding is our ability to project meaning into the
        world—the ability to see and feel and otherwise perceive things as they
        matter to <em>you</em>. In fact, the “world”
        <sup>
          <a href="#annotation-19">19</a>
        </sup>{" "}
        is just that, the sum of the meaningful objects that present themselves
        to us. You do not share the world with anyone else, since things
        <sup>
          <a href="#annotation-20">20</a>
        </sup>{" "}
        do not call out to everyone else in the way that they do to you. Maybe
        we share a similar reaction to that bedbug on the mattress, but no one
        else recognizes your slippers like you do, or your house keys, or your
        family photos. These things—<em>as those things</em>, rather than as
        mere “stuff”
        <sup>
          <a href="#annotation-22">22</a>
        </sup>{" "}
        —exist as they are for <em>you</em>, not for <em>us</em>.
      </p>

      <p>
        Some things in the world you inhabit do not even exist for other people.
        When I walk through the woods with my naturalist friend, I encounter
        trees and bushes and scraggly roots underfoot, but not much more. To
        her, there is a beech and a sycamore, and maybe a black walnut; shades
        of meaning attached to the things that matter for her. With this meaning
        comes the projection of future possibility
        <sup>
          <a href="#annotation-23">23</a>
        </sup>{" "}
        —the black walnut is almost ready to be tapped for syrup, a communing of
        self and world through skilled practice that brings forth yet more
        things laden with personal meaning. I can buy maple syrup at the store,
        but she can call black walnut syrup into existence through possibilities
        felt by her and not by me. I deal in stuff, and she deals in reality. I
        consume and she conjures; her call to the world
        <sup>
          <a href="#annotation-24">24</a>
        </sup>{" "}
        is answered by things that call back in return, and I merely take from
        the lifeless stuff I find before me.
      </p>

      <p>
        Begin here, then, if you want to know what matters to <em>you</em>: You
        will die. It is only in the finitude of existence that anything matters.
        “We are what matters to us”
        <sup>
          <a href="#annotation-25">25</a>
        </sup>{" "}
        —not just in that what matters to us shapes our self-conception, but in
        the sense that{" "}
        <em>
          what we are is defined by our ability to have things matter to us
        </em>
        . And each choice to attend to the mattering of things excludes other
        things that could also matter,
        <sup>
          <a href="#annotation-26">26</a>
        </sup>{" "}
        closing off opportunities to be the infinite versions of ourselves that
        the linearity of time does not allow. Time is a series of choices,
        forming a feeble line leading out from us at our birth and going in
        precisely one direction to the exclusion of all others. Our primary
        experience of time is that of possibility, of situations arising on the
        horizon of the possible and calling us toward them—this is what it means
        for something to matter. The secondary experience of time is distress,
        <sup>
          <a href="#annotation-27">27</a>
        </sup>{" "}
        as our path forward excludes the possibilities to either side. You
        cannot go back, and you cannot cease to go forward. The only other
        option would be to pretend that there is no end in sight, which would
        undermine the whole project of living by removing the weight of the
        alternative.
      </p>
      <p>So barreling ahead with life means:</p>

      <blockquote className="indented">
        <ol>
          <li>making difficult choices</li>
          <li>
            between things that matter <em>to you</em>
          </li>
          <li>
            in the face of <em>your</em> coming death
          </li>
        </ol>
      </blockquote>

      <p>
        As we saw earlier, “considering” these choices gets you nowhere but back
        into the claws of the <em>us</em>, that purgatory where nothing matters
        to anyone beca<em>us</em>e “anyone” does not entail a <em>you</em> for
        any individual person. To <em>us</em>, everyone dies (shrug) while to{" "}
        <em>you, you</em> will die (scream).
      </p>

      <p>
        Instead, recognize that if living is essentially the same as allowing
        things to matter to <em>us</em>, then the thing that matters most, that
        matters above all individual things that matter, is{" "}
        <em>mattering itself</em>. To illustrate this, consider how a kid who
        plays baseball in the park and squishes bugs and rides bikes and blows
        raspberries at his little sister leads a better life than a hypothetical
        child who sits on the sofa all day dosed with happiness-inducing drugs.
        The second child may be happier,
        <sup>
          <a href="#annotation-28">28</a>
        </sup>{" "}
        but is certainly worse off. For the second child, nothing matters; the
        heart-rending song of the world is muted rather than answered. The first
        child is called, and the second quelled. The first child does what we
        should do, and the second does what we usually do.
      </p>

      <p>
        See through the eyes of this first child, for whom the world is a
        landscape of fleeting possibility. Everything is steeped in meaning{" "}
        <em>for him</em>. His house is <em>his home</em>. His bike is{" "}
        <em>his ride</em>. Bugs afford squishing and seeing the insides of and
        grossing people out with. Baseball is infused with heroism, identity,
        and the overcoming of enemies. The long days of summer are limited, he
        knows, and as August sets in, the call of these panting sweating
        afternoons grows almost too loud to bear. His heart pounds in his throat
        as he races to the park on his hand-me-down BMX bike, aching to scream
        out in answer to the symphony of possibilities that speak{" "}
        <em>to him</em> and which peel away with the first leaves falling in
        autumn. In this way he knows death, and so he knows living. We, who sit
        at home reading the news and drinking wine and watching TV, endlessly
        holding our breath, have forgotten.
      </p>

      <p>
        You cannot be that child—it is unclear even if a child can be, anymore.
        But this midcentury idyll is only an illustrative example. Where is it
        that things matter for <em>you?</em>
        <sup>
          <a href="#annotation-29">29</a>
        </sup>{" "}
      </p>

      <figure>
        <img
          src={
            process.env.PUBLIC_URL +
            "/img/notaretreat/B_N_17_Tom_illustration.jpeg"
          }
        />
        <figcaption>
          <em>One Person’s Trash, Etc.</em> Image generated in March 2023 by the
          AI application Midjourney.com, using the following prompt: “A line
          drawing, two people examine a pile of wood scraps on the side of an
          urban street.”
        </figcaption>
      </figure>

      <p>
        I have a couple of friends who I cannot go for a walk with on trash day
        because I know I will get roped into helping them carry home some gross
        old hunk of wood. To my eye, these are tree stumps covered in dirt or
        construction off-cuts unearthed from some damp basement. But to my
        companions, this is cherry or maple, this is pressure-treated pine, and
        this is white oak of an appealing thickness that you never see in
        cabinets anymore. Things pop out to my woodworker friends, announcing
        themselves as species of wood or remnants of identifiable projects. But
        more pressingly, these treasures show up demanding action. When, I am
        asked, will we ever see such a bounty again if we do not stop and
        collect all these old cabinet doors? My protests about this stuff being
        useless are dismissed. This wood could become <em>anything</em>, I am
        firmly reminded. Somehow the grain makes it perfect for a table or a
        butcher’s block or for turning on a lathe. At this point, it becomes
        easier to haul a stack of boards a few blocks than it would be to argue.
      </p>

      <p>
        To these craftspeople, wood matters because it allows the exercise of
        our most fundamental human capability, marked by simultaneously
        transforming and being transformed by the social/material world. Certain
        piles of wood scrap coyly suggest to the knowing passer-by that they
        might offer more; a streak of color or a vintage aesthetic flourish hint
        that sanding this down will put you into conversation with an unusual
        and rewarding <em>thing</em>. Unfamiliar wood shows you how to work it,
        pushing you away at first then guiding your hands as you slowly become
        accustomed to how it wants to be touched. This is what matters, entering
        into this sensuous exchange. The wood does not “matter” in an economic
        or practical sense, as no sensible person goes into woodworking to make
        money. It matters because it reminds us of the true nature of the world,
        which is composed not of fixed meanings but of flowing streams of
        potential, potential to engage with and to be engaged by the things
        around us.
      </p>
      <p>
        My woodworker friends do not realize that they are obsessed with death,
        but they know that we may never again in our short lives walk by
        treasures like this on the side of the road and that we must therefore
        stop and scoop them up. My friends have become friendly enemies of time,
        the passage of which marches us toward death but infuses every step with
        the urgent thrill of purpose. We are ourselves in those moments,
        answering the call of the material world, in that we are concerned with
        it in all the thickness of its meaning; we are thereby fulfilled in our
        potential to be the animal that we are. Let your existential anxiety
        settle here, bringing into focus the being-for-<em>you</em> that the
        world offers moment by moment, in these moments that will never return.
      </p>
      <hr />

      <cite>
        <sup id="annotation-1">1.</sup> <div>This essay does two things.</div>
        <ol>
          <li>
            It interprets and illustrates a series of ideas from{" "}
            <em>Being and Time (BT)</em>, by Martin Heidegger,
            <sup style={{ position: "static" }}>
              <a href="#annotation-2">2</a>
            </sup>{" "}
            particularly Div. II. Up until the end, the essay is written mainly
            with Heideggerian ideas, sometimes using his own language. An
            endnote is provided in each instance where a term from Heidegger is
            used, or where a new word is substituted for one of his technical
            terms; other footnotes flesh out aspects of the larger project in{" "}
            <em>BT</em> where necessary. Where a term from the cited translation
            is contested or confusing, the original German appears in the
            footnote following the initials of the translators, abbreviated as
            MR.
          </li>
          <li>
            At the end, the essay implies an ethical warrant for craftwork based
            on Heidegger’s thinking in BT. While this contributes only a minor
            extension of his work, it does point to ideas that are not
            originally apparent in his writing.
          </li>
        </ol>
      </cite>

      <cite>
        <sup id="annotation-2">2.</sup>Heidegger, Martin.{" "}
        <em>Being and Time</em>, trans. J. Macquarrie and E. Robinson
        [Blackwell, 1927/1962.]
      </cite>

      <cite>
        <sup id="annotation-3">3.</sup> <em>Das Man</em>, translated by
        Macquarrie and Robinson as “the ‘they’” (164). <em>Das Man</em> is not
        other people in the sense of specific individuals (although it is always
        historically/factually situated), but rather a “great mass” (164) of
        others apparent to us in our commonly held ideas, dispositions, and
        orientation toward the world, and through which our conception of
        ourselves as individuals is hidden. Given our tendency to conflate our
        being with that of <em>Das Man</em>, it is perhaps better understood as
        “the <em>us</em>,” rather than “the they”; as Heidegger writes: “This
        Being-with-one-another dissolves one’s own <em>Dasein</em> completely
        into the kind of Being of ‘the Others,’ in such a way, indeed, that the
        Others, as distinguishable and explicit, vanish more and more.
        <sup style={{ position: "static" }}>
          <a href="#annotation-4">4</a>
        </sup>{" "}
        In this inconspicuousness and unascertainability, the real dictatorship
        of the ‘they’ is unfolded” (164).
      </cite>

      <cite>
        <sup id="annotation-4">4.</sup> The term <em>Dasein</em> is much
        discussed in Heidegger scholarship and is famously difficult to define.
        Here, I take it to refer to the prototypical human being, particularly
        in its unique capacity for engaging with the socio-material world.
      </cite>

      <cite>
        <sup id="annotation-5">5.</sup> The definite article is included here to
        mirror the construction of the phrase “<em>das Man</em>,” and to stress
        that “the us” is external to, and exclusive of, the individual.
      </cite>

      <cite>
        <sup id="annotation-6">6.</sup> We are “thrown” into a world that exists
        prior to our participation in it (§38, “Falling and Thrownness”,
        219–225). Thrownness is merely a consequence of the social nature of our
        existence; we could not be otherwise. We are prone, however, to
        completely forget ourselves in our preoccupation with the goings-on of{" "}
        <em>das Man</em>, thereby entering a state that Heidegger calls
        “fallenness” (220).
      </cite>

      <cite>
        <sup id="annotation-7">7.</sup> “Concern” (MR: <em>Besorgen</em>) is a
        technical term for Heidegger, referring to directing yourself toward
        that which matters to you (83). The use of the word in the main text
        here is meant to capture how one never sees oneself in such things—they
        are only ever abstractions, rather than immediate “concerns.” For the
        three examples given, the validity of this assumption varies between
        individuals, but presumably holds on average.
      </cite>

      <cite>
        <sup id="annotation-8">8.</sup> Care (MR: <em>Sorge</em>) and concern
        (MR: <em>Besorgen</em>) are closely connected for Heidegger, with care
        encompassing concern.{" "}
        <sup style={{ position: "static" }}>
          <a href="#annotation-9">9</a>
        </sup>{" "}
        Care is the overall orientation toward the meaningful world through
        which concern with particular objects and events is possible and is
        therefore a core feature of (if not coextensive with) the existence of{" "}
        <em>Dasein</em>: “Dasein’s being reveals itself as <em>care</em>”
        (original emphasis; 227). The switch in emphasis from concern to care at
        this point in the text is intended to move from a discussion of the
        objects and events that concern us (i.e., become our concerns) to how
        and why it is that we have concerns at all, a phenomenon explained by
        care.
      </cite>

      <cite>
        <sup id="annotation-9">9.</sup> See Blattner, William. “Care (
        <em>Sorge</em>),” in <em>The Cambridge Heidegger Lexicon</em>, M.
        Wrathall ed. (Cambridge University Press, 2021).
      </cite>

      <cite>
        <sup id="annotation-10">10.</sup> Anxiety (MR: <em>Angst</em>; the
        translators also suggest “dread,” “uneasiness,” and “malaise,” 227) is a
        negative affective state ultimately stemming from insufficient care
        about the world. It is through anxiety that we are prompted to discover
        our capacity for care, although Heidegger also warns that we may choose
        to turn away from care through “falling” into the collective perspective
        of <em>das Man</em> (234).
      </cite>

      <cite>
        <sup id="annotation-11">11.</sup> Death (MR: <em>Tod</em>) is a
        controversial topic among Heidegger scholars.
        <sup style={{ position: "static" }}>
          <a href="#annotation-12">12</a>
        </sup>{" "}
        The interpretation of the Heideggerian notion of death set out in this
        essay includes the conventional sense of the word (i.e., dying; human
        life ending) but also points to a broader set of circumstances under
        which <em>Dasein</em> can “end”: “Dasein … can end without authentically
        dying, though on the other hand, <em>qua</em> Dasein, it does not simply
        perish” (291). Since “Dasein’s being reveals itself as <em>care</em>”
        (original emphasis; 227), to stop caring is to cease to be{" "}
        <em>Dasein</em>, an “end” (death) for <em>Dasein</em>.
        Counterintuitively, it is possible to live through death as conceived of
        in this way, coming back to care and therefore to existence as{" "}
        <em>Dasein</em>—this choice is prompted by anxiety.
      </cite>

      <cite>
        <sup id="annotation-12">12.</sup> See Thomson, Iain. “Death and Demise
        in Being and Time,” in <em>The Cambridge Heidegger Lexicon</em>.
      </cite>

      <cite>
        <sup id="annotation-13">13.</sup> The term “considering” here is meant
        to imply <em>vorhandenheit</em>, the detached, rational perspective that
        reduces the complexity of socio-material meaning to the apparent
        qualities of objects when we step back from our primordial mode of
        encountering the physical world. By contrast, <em>zuhandenheit</em>{" "}
        aligns with concern, in which meaningful objects appear to us
        pre-reflexively as such rather than waiting for us to approach them from
        a theoretical perspective (§15, “The Being of the Entities Encountered
        in the Environment,” 95–102).
      </cite>

      <cite>
        <sup id="annotation-14">14.</sup> While engagement with the world is
        always future-oriented, it never involves “planning” in the rational,{" "}
        <em>vorhanden</em> sense of the word. Instead, the future orientation of
        understanding is marked by “projection” (185), through which the
        possibilities we understand/transpose from/onto the world appear as
        inseparable aspects of perception. At no point does this projective
        process have anything to do with explicit consideration or planning:
        “Projecting has nothing to do with comporting oneself towards a plan
        that has been thought out” (§31, “Being-there as Understanding,”
        182–188).
      </cite>

      <cite>
        <sup id="annotation-15">15.</sup> “Authenticity” (68) has a specific
        technical meaning for Heidegger. Rather than referring to being
        authentically ourselves—say, by expressing a unique style of dress or
        making decisions that align with our ethics or ambitions—Heidegger uses
        “authenticity” to point to the possibility of being authentically{" "}
        <em>Dasein</em>. As indicated in the endnotes on “care” (n. 8) and
        “death” (n. 11), to be <em>Dasein</em> fully is to embrace the finitude
        of existence that illuminates possibilities for meaningful action (and
        therefore fulfillment of individual potential, on an ontological level)
        in the socio-material world. One can thereby live authentically by
        coming to grips with death and more fully encountering existence, or
        live “inauthentically” by “falling” (n. 5) into the timeless perspective
        of <em>das Man</em>. In this second case, “[<em>Dasein</em>] itself is
        not; its Being has been taken away by the Others” (164).
      </cite>

      <cite>
        <sup id="annotation-16">16.</sup> <em>Vorhandenheit</em> vs.{" "}
        <em>zuhandenheit</em> (see n. 13).
      </cite>

      <cite>
        <sup id="annotation-17">17.</sup> “Immediately” in the dual sense of
        “happening right away” and “not mediated by rational contemplation;”
        pre-reflexively.
      </cite>

      <cite>
        <sup id="annotation-18">18.</sup> “Really living” as living
        authentically (see n. 15). This bedbug case is a thin example of
        authenticity, as it is not based on a substantial turning toward care.
        That said, these moments of shock or disgust do exemplify the immediate
        experience of <em>zuhandenheit</em>, in which the world is revealed as
        it matters <em>to you</em>. An entomologist may be able to take a
        distanced perspective on a bedbug in the laboratory (through{" "}
        <em>vorhandenheit</em>), but a hotel-goer normally reacts with an
        intuitive sense of terror as they project various bedbug-laden
        eventualities into a world that has been newly transformed by the
        appearance of this insect. Thus, key features of care are illuminated.
      </cite>

      <cite>
        <sup id="annotation-19">19.</sup> As in the notion of
        “being-in-the-world,” in which world and self exist in reciprocal
        relationship (see §12, “A preliminary sketch of Being-in-the-world, in
        terms of an orientation towards Being-in as such,” 78–86).
      </cite>

      <cite>
        <sup id="annotation-20">20.</sup> This use of the word “thing” to refer
        to something that appears in perception loaded with personal meaning is
        perhaps controversial. The term “thing” is difficult to pin down in
        Heidegger, particularly when considering his writing as a whole. In{" "}
        <em>BT</em>, the technical sense of the word “Thing” (indicated by the
        capitalization) refers to something vorhanden (see n. 13), in contrast
        to the zuhanden experience implied by the word in the present essay. By
        the later phases of his scholarship, however, Heidegger allows the word
        “thing” to take on more complex significance, referring to something
        that exists partly beyond—rather than merely outside—human practices.
        <sup style={{ position: "static" }}>
          <a href="#annotation-21">21</a>
        </sup>{" "}
        For a more complete discussion of this evolution, see James D. Reid,
        “Thing,” in <em>The Cambridge Heidegger Lexicon</em>.
      </cite>

      <cite>
        <sup id="annotation-21">21.</sup> See Heidegger, Martin. “The Thing.” In{" "}
        <em>Poetry, Language, Thought</em>, A. Hofstadter trans. (Harper
        Perennial Modern Thought, 1959/2013).
      </cite>

      <cite>
        <sup id="annotation-22">22.</sup> “Stuff” as what is revealed in the
        detached perspective of <em>vorhandenheit</em> (see n. 13; cf. “things,”
        n. 20).{" "}
      </cite>

      <cite>
        <sup id="annotation-23">23.</sup> The word “matter” here aligns with the
        notion of concern (see n. 8) but emphasizes how the contents of the
        world appear to us demanding concern, rather than having concern
        projected upon them. Thus, “this thing matters” and “I am concerned with
        this thing” are meant to have the same meaning here, with the latter
        underlining how the world calls to us when we are authentically disposed
        toward it (see next footnote, n. 24).
      </cite>

      <cite>
        <sup id="annotation-24">24.</sup> “
        <em>In conscience Dasein calls itself</em>… Ontologically, however, it
        is not enough to answer that Dasein is at the same time both the caller
        and the one to whom the appeal is made… The call comes <em>from</em> me
        and yet <em>from beyond me</em>” (original emphasis; 320). We are called
        by the world, but the world is not the factual, <em>vorhanden</em> world
        of science; it is the world that belongs to <em>Dasein</em>, the world
        which we are thrown into yet continually reconstitute in our
        understanding. We are therefore simultaneously called <em>by</em> the
        world and called <em>to</em> the world by our nature (§57, “Conscience
        as the Call of Care,” 319–325).
      </cite>

      <cite>
        <sup id="annotation-25">25.</sup> “We are what matters to us in our
        living; we are implicated in the world.” William D. Blattner,{" "}
        <em>Heidegger’s Being and Time: A Reader’s Guide</em> (London:
        Continuum, 2006), 12.
      </cite>

      <cite>
        <sup id="annotation-26">26.</sup> “When we say that Dasein is factically
        dying, we are saying at the same time that in its Being-towards-death
        Dasein has always decided itself in one way or another” (303). Calling
        from §51 (“Being-towards-death and the Everydayness of Dasein,” 296–304)
        here risks being somewhat misleading, however, the choice that Heidegger
        describes throughout this section is not an ontic (as opposed to
        ontological; i.e., factual/historical) choice between possible
        activities in life, but the choice to live authentically or
        inauthentically. The implied conclusion in this essay—which goes beyond
        what Heidegger claims—is that choice between activities at the level of
        the ontic entails a choice to authentically turn toward being, embracing
        meaning through being-toward-death instead of inauthentically falling
        toward <em>das Man</em> through our dismissal of death.
      </cite>

      <cite>
        <sup id="annotation-27">27.</sup> Given the supposition in the last
        endnote (n. 26), this distress becomes a necessary consequence of
        embracing anxiety. Anxiety is therefore not resolved in authenticity but
        accepted as a constant feature of being-toward-death.
      </cite>

      <cite>
        <sup id="annotation-28">28.</sup> Happiness is not thoroughly discussed
        in <em>BT</em> and is introduced here only to illustrate the distance
        between our everyday notion of happiness and the Heideggerian conception
        of living authentically; the former entails superficial pleasures, and
        the latter the satisfaction of our self-discovery <em>qua Dasein</em>.
      </cite>

      <cite>
        <sup id="annotation-29">29.</sup> The following paragraphs, which imply
        a specific ethical maxim, go beyond the overall framework for the
        possibility of ethics in <em>BT</em>. Heidegger does eventually make a
        call to action based on his ethical principles (see his remarks on
        education; 1954/1977), but never articulates an ethics of craft practice
        or craft learning, an omission I have never understood.
      </cite>
    </div>
  );
};

export default Martin;
