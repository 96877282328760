export const KEYWORD_ARTICLES = [
  {
    id: "absence",
    name: "absence",
    articles: [
      {
        pubId: "notaretreat",
        id: "helgeson",
        order: 16,
        author: "Anna Helgeson",
        authorShort: "Helgeson",
        title: "Craft, Nothing, and Nowhere",
      },
      {
        pubId: "tom",
        id: "aufhebung",
        order: 6,
        author: "Lucia Ilene Wallace",
        authorShort: "Wallace",
        title: "Aufhebung",
      },
      {
        pubId: "tom",
        id: "enchant",
        order: 10,
        author: "Karen Wilson Baptist",
        authorShort: "Baptist",
        title: "Enchant",
      },
      {
        pubId: "tom",
        id: "evolve",
        order: 11,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Evolve",
      },
      {
        pubId: "tom",
        id: "ghost",
        order: 12,
        author: "Sara Clugage",
        authorShort: "Clugage",
        title: "Ghost",
      },
      {
        pubId: "tom",
        id: "legacy",
        order: 14,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Legacy",
      },
      {
        pubId: "tom",
        id: "magic",
        order: 17,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Magic",
      },
      {
        pubId: "tom",
        id: "resolve",
        order: 29,
        author: "Jill DiMassimo",
        authorShort: "DiMassimo",
        title: "Resolve",
      },
      {
        pubId: "tom",
        id: "souvenir",
        order: 30,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Souvenir",
      },
      {
        pubId: "tom",
        id: "speculate",
        order: 31,
        author: "Tom Martin",
        authorShort: "Martin",
        title: "Speculate",
      },
      {
        pubId: "tom",
        id: "eulogy",
        order: 35,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Eulogy",
      },
    ],
  },
  {
    id: "aesthetics",
    name: "aesthetics",
    articles: [
      {
        pubId: "tom",
        id: "magic",
        order: 17,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Magic",
      },
      {
        pubId: "tom",
        id: "offering",
        order: 20,
        author: "Phoebe Cheng",
        authorShort: "Cheng",
        title: "Offering",
      },
      {
        pubId: "tom",
        id: "predict",
        order: 23,
        author: "Andres Payan Estrada",
        authorShort: "Estrada",
        title: "Predict",
      },
      {
        pubId: "tom",
        id: "reframe",
        order: 26,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Reframe",
      },
      {
        pubId: "tom",
        id: "repair",
        order: 28,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Repair",
      },
      {
        pubId: "tom",
        id: "resolve",
        order: 29,
        author: "Jill DiMassimo",
        authorShort: "DiMassimo",
        title: "Resolve",
      },
      {
        pubId: "tom",
        id: "zombie",
        order: 34,
        author: "Greg Garrett",
        authorShort: "Garrett",
        title: "Zombie",
      },
      {
        pubId: "wiltsie",
        id: "mitchell_wiltsie",
        order: 2,
        author: "W. J. T. Mitchell & Tina Wiltsie",
        authorShort: "Mitchell & Wiltsie",
        title:
          "From Metapictures to Metacraft? An Interview with W. J. T. Mitchell",
      },
    ],
  },
  {
    id: "agency",
    name: "agency",
    articles: [
      {
        pubId: "devlin",
        id: "adamson",
        order: 3,
        author: "Glenn Adamson",
        authorShort: "Adamson",
        title: "Material Intelligence",
      },
      {
        pubId: "devlin",
        id: "devlin",
        order: 4,
        author: "Trieste Devlin",
        authorShort: "Devlin",
        title: "On Frames",
      },
      {
        pubId: "notaretreat",
        id: "razdan",
        order: 14,
        author: "Anjula Razdan",
        authorShort: "Razdan",
        title: "Unearthing the Craftscape",
      },
      {
        pubId: "wiltsie",
        id: "mitchell_wiltsie",
        order: 2,
        author: "W. J. T. Mitchell & Tina Wiltsie",
        authorShort: "Mitchell & Wiltsie",
        title:
          "From Metapictures to Metacraft? An Interview with W. J. T. Mitchell",
      },
    ],
  },
  {
    id: "anthropology",
    name: "anthropology",
    articles: [
      {
        pubId: "devlin",
        id: "adamson",
        order: 3,
        author: "Glenn Adamson",
        authorShort: "Adamson",
        title: "Material Intelligence",
      },
      {
        pubId: "tom",
        id: "ancestry",
        order: 4,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Ancestry",
      },
      {
        pubId: "tom",
        id: "offering",
        order: 20,
        author: "Phoebe Cheng",
        authorShort: "Cheng",
        title: "Offering",
      },
      {
        pubId: "wiltsie",
        id: "gallery",
        order: 3,
        author: "Tina Wiltsie",
        authorShort: "Wiltsie",
        title: "Enduring Form: Objects Depicting Ceramics across Time",
      },
    ],
  },
  {
    id: "antiracism",
    name: "anti-racism",
    articles: [
      {
        pubId: "hand",
        id: "ahmed",
        order: 4,
        author: "Sara Ahmed",
        authorShort: "Ahmed",
        title: "A Phenomenology of Whiteness",
      },
      {
        pubId: "hand",
        id: "taylor",
        order: 6,
        author: "Keeanga-Yamahtta Taylor",
        authorShort: "Taylor",
        title: "Until Black Women Are Free, None of Us Will Be Free",
      },
    ],
  },
  {
    id: "appalachia",
    name: "appalachia",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "hatch",
        order: 3.1,
        author: "Michael Hatch",
        authorShort: "Hatch",
        title: "Redacted",
      },
      {
        pubId: "dimassimo_weiss",
        id: "goodman",
        order: 3.2,
        author: "Mellanee Goodman",
        authorShort: "Goodman",
        title: "Reflections on “In the Fray”",
      },
      {
        pubId: "dimassimo_weiss",
        id: "devlin",
        order: 6.1,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Exceptional, Typical, Chairs by Dick Poynor",
      },
      {
        pubId: "notaretreat",
        id: "introduction",
        order: 1,
        author: "Ben Lignel and Namita Gupta Wiggers",
        authorShort: "Lignel & Wiggers",
        title: "Introduction",
      },
      {
        pubId: "tom",
        id: "archive",
        order: 5,
        author: "Namita Gupta Wiggers",
        authorShort: "Wiggers",
        title: "Archive",
      },
    ],
  },
  {
    id: "apprenticeship",
    name: "apprenticeship",
    articles: [
      {
        pubId: "notaretreat",
        id: "meissner",
        order: 2,
        author: "Amy Meissner",
        authorShort: "Meissner",
        title: "But First, Learn This (A List)",
      },
      {
        pubId: "perron-feller",
        id: "buckert",
        order: 4,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Constructing an Interdisciplinary Life: An Oral History with Morgan Buckert",
      },
    ],
  },
  {
    id: "archives",
    name: "archives",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "editornote",
        order: 1,
        author: "Jill DiMassimo & Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Making Room, Making Connections, Making History: Editors' Note",
      },
      {
        pubId: "dimassimo_weiss",
        id: "absence",
        order: 3,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Absence",
      },
      {
        pubId: "dimassimo_weiss",
        id: "hatch",
        order: 3.1,
        author: "Michael Hatch",
        authorShort: "Hatch",
        title: "Redacted",
      },
      {
        pubId: "notaretreat",
        id: "levine",
        order: 10,
        author: "Faythe Levine",
        authorShort: "Levine",
        title: "The Dotted Line (Desktop Version)",
      },
      {
        pubId: "tom",
        id: "grave",
        order: 13,
        author: "Jarrett M. Drake",
        authorShort: "Drake",
        title: "Grave",
      },
    ],
  },
  {
    id: "askingquestions",
    name: "asking questions",
    articles: [
      {
        pubId: "notaretreat",
        id: "jarrett_wiggers",
        order: 3,
        author: "Lisa Jarrett and Namita Gupta Wiggers",
        authorShort: "Jarrett & Wiggers",
        title:
          "Teaching Questions: Lisa Jarrett and Namita Gupta Wiggers in Conversation",
      },
      {
        pubId: "notaretreat",
        id: "acierto",
        order: 4,
        author: "alejandro t. acierto",
        authorShort: "acierto",
        title:
          "In the After-Thought: Teaching Transparency and the Ongoing Practice of Freedom",
      },
      {
        pubId: "notaretreat",
        id: "leemann",
        order: 5,
        author: "Judith Leemann",
        authorShort: "Leemann",
        title: "towards tending not knowing",
      },
      {
        pubId: "notaretreat",
        id: "lignel_wiggers",
        order: 11,
        author: "Ben Lignel and Namita Gupta Wiggers",
        authorShort: "Lignel & Wiggers",
        title:
          "Designing the Plane as We Flew It—A Discussion on the MACR Program Publications",
      },
    ],
  },
  {
    id: "basketweaving",
    name: "basket weaving",
    articles: [
      {
        pubId: "devlin",
        id: "wallkimmerer",
        order: 2,
        author: "Robin Wall Kimmerer",
        authorShort: "Kimmerer",
        title: "Wisgaak Gokpenagen: A Black Ash Basket",
      },
    ],
  },
  {
    id: "bias",
    name: "bias",
    articles: [
      {
        pubId: "hand",
        id: "foreword",
        order: 1,
        author: "Jennifer Hand",
        authorShort: "Hand",
        title: "Foreword",
      },
      {
        pubId: "hand",
        id: "hall",
        order: 2,
        author: "Stuart Hall",
        authorShort: "Hall",
        title: "Minimal Selves",
      },
      {
        pubId: "notaretreat",
        id: "jarrett_wiggers",
        order: 3,
        author: "Lisa Jarrett and Namita Gupta Wiggers",
        authorShort: "Jarrett & Wiggers",
        title:
          "Teaching Questions: Lisa Jarrett and Namita Gupta Wiggers in Conversation",
      },
      {
        pubId: "notaretreat",
        id: "acierto",
        order: 4,
        author: "alejandro t. acierto",
        authorShort: "acierto",
        title:
          "In the After-Thought: Teaching Transparency and the Ongoing Practice of Freedom",
      },
      {
        pubId: "notaretreat",
        id: "clugage",
        order: 7,
        author: "Sara Clugage",
        authorShort: "Clugage",
        title: "This Is Not a Food Studies Program",
      },
      {
        pubId: "notaretreat",
        id: "lignel",
        order: 9,
        author: "Ben Lignel",
        authorShort: "Lignel",
        title:
          "Teaching and Unlearning—Or How to Write Wall Texts: Lessons of Decolonial Thinking",
      },
      {
        pubId: "notaretreat",
        id: "murray",
        order: 18,
        author: "Kevin Murray",
        authorShort: "Murray",
        title: "Why the Question?",
      },
      {
        pubId: "notaretreat",
        id: "delosreyes",
        order: 19,
        author: "Jen Delos Reyes",
        authorShort: "Delos Reyes",
        title:
          "Future Desire Lines: Cultivating Paths of Resistance, Support, and Joy",
      },
      {
        pubId: "notaretreat",
        id: "fisher",
        order: 21,
        author: "Michelle Millar Fisher",
        authorShort: "Fisher",
        title: "Who writes craft histories?",
      },
    ],
  },
  {
    id: "bibliography",
    name: "bibliography",
    articles: [
      {
        pubId: "wiltsie",
        id: "bibliography",
        order: 5,
        author: "Tina Wiltsie",
        authorShort: "Wiltsie",
        title: "Gathering Raw Materials: A Bibliography of Suggested Readings",
      },
    ],
  },
  {
    id: "biography",
    name: "biography",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "presence",
        order: 4,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Presence",
      },
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo",
        order: 4.1,
        author: "Jill DiMassimo",
        authorShort: "DiMassimo",
        title: "Mapping Commeraw’s Jar",
      },
      {
        pubId: "dimassimo_weiss",
        id: "hand",
        order: 4.2,
        author: "Jennifer Hand",
        authorShort: "Hand",
        title:
          "Made to Order: Edmonia Lewis and Repercussions of the Tourist Trade on a Sculptural Practice",
      },
      {
        pubId: "dimassimo_weiss",
        id: "devlin",
        order: 6.1,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Exceptional, Typical, Chairs by Dick Poynor",
      },
      {
        pubId: "notaretreat",
        id: "delosreyes",
        order: 19,
        author: "Jen Delos Reyes",
        authorShort: "Delos Reyes",
        title:
          "Future Desire Lines: Cultivating Paths of Resistance, Support, and Joy",
      },
    ],
  },
  {
    id: "blackcraftspeople",
    name: "black craftspeople",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "editornote",
        order: 1,
        author: "Jill DiMassimo & Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Making Room, Making Connections, Making History: Editors' Note",
      },
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo_momon_weiss",
        order: 2.2,
        author: "Tiffany Momon, Jill DiMassimo, and Joanna Weiss",
        authorShort: "Momon, DiMassimo & Weiss",
        title:
          "Interview with Dr. Tiffany Momon: Public Historian, BCDA Founder, and MACR Teaching Fellow",
      },
      {
        pubId: "dimassimo_weiss",
        id: "goodman",
        order: 3.2,
        author: "Mellanee Goodman",
        authorShort: "Goodman",
        title: "Reflections on “In the Fray”",
      },
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo",
        order: 4.1,
        author: "Jill DiMassimo",
        authorShort: "DiMassimo",
        title: "Mapping Commeraw’s Jar",
      },
      {
        pubId: "dimassimo_weiss",
        id: "hand",
        order: 4.2,
        author: "Jennifer Hand",
        authorShort: "Hand",
        title:
          "Made to Order: Edmonia Lewis and Repercussions of the Tourist Trade on a Sculptural Practice",
      },
      {
        pubId: "dimassimo_weiss",
        id: "weiss",
        order: 5.1,
        author: "Joanna Weiss",
        authorShort: "Weiss",
        title:
          "Cowrie Shells: Movement and Connections through African American Material Culture",
      },
      {
        pubId: "dimassimo_weiss",
        id: "devlin",
        order: 6.1,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Exceptional, Typical, Chairs by Dick Poynor",
      },
      {
        pubId: "dimassimo_weiss",
        id: "awake_ryland",
        order: 6.2,
        author: "Robell Awake and Charlie Ryland",
        authorShort: "Awake & Ryland",
        title: "Combatting Erasure",
      },
      {
        pubId: "hand",
        id: "joyce",
        order: 3,
        author: "Jennifer Hand",
        authorShort: "Hand",
        title: "Joyce Scott: Hard Truth in Beauty",
      },
      {
        pubId: "notaretreat",
        id: "momon",
        order: 15,
        author: "Tiffany Momon",
        authorShort: "Momon",
        title: "People, Get Ready: Building New Worlds",
      },
    ],
  },
  {
    id: "blackfeminism",
    name: "black feminism",
    articles: [
      {
        pubId: "hand",
        id: "taylor",
        order: 6,
        author: "Keeanga-Yamahtta Taylor",
        authorShort: "Taylor",
        title: "Until Black Women Are Free, None of Us Will Be Free",
      },
      {
        pubId: "notaretreat",
        id: "jarrett_wiggers",
        order: 3,
        author: "Lisa Jarrett and Namita Gupta Wiggers",
        authorShort: "Jarrett & Wiggers",
        title:
          "Teaching Questions: Lisa Jarrett and Namita Gupta Wiggers in Conversation",
      },
      {
        pubId: "notaretreat",
        id: "keith",
        order: 13,
        author: "Jeffrey A. Keith",
        authorShort: "Keith",
        title: "Emplaced Remembrance—Thinking about Gone Worlds Always Nearby",
      },
    ],
  },
  {
    id: "blackgirlhood",
    name: "black girlhood",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "weiss",
        order: 5.1,
        author: "Joanna Weiss",
        authorShort: "Weiss",
        title:
          "Cowrie Shells: Movement and Connections through African American Material Culture",
      },
    ],
  },
  {
    id: "blackhistory",
    name: "black history",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "editornote",
        order: 1,
        author: "Jill DiMassimo & Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Making Room, Making Connections, Making History: Editors' Note",
      },
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo_momon_weiss",
        order: 2.2,
        author: "Tiffany Momon, Jill DiMassimo, and Joanna Weiss",
        authorShort: "Momon, DiMassimo & Weiss",
        title:
          "Interview with Dr. Tiffany Momon: Public Historian, BCDA Founder, and MACR Teaching Fellow",
      },
      {
        pubId: "dimassimo_weiss",
        id: "hatch",
        order: 3.1,
        author: "Michael Hatch",
        authorShort: "Hatch",
        title: "Redacted",
      },
      {
        pubId: "dimassimo_weiss",
        id: "goodman",
        order: 3.2,
        author: "Mellanee Goodman",
        authorShort: "Goodman",
        title: "Reflections on “In the Fray”",
      },
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo",
        order: 4.1,
        author: "Jill DiMassimo",
        authorShort: "DiMassimo",
        title: "Mapping Commeraw’s Jar",
      },
      {
        pubId: "dimassimo_weiss",
        id: "weiss",
        order: 5.1,
        author: "Joanna Weiss",
        authorShort: "Weiss",
        title:
          "Cowrie Shells: Movement and Connections through African American Material Culture",
      },
      {
        pubId: "dimassimo_weiss",
        id: "perron-feller",
        order: 5.2,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Material Encounters: A Moment of Bringing Black Craft History into the Present through Fieldwork",
      },
      {
        pubId: "dimassimo_weiss",
        id: "devlin",
        order: 6.1,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Exceptional, Typical, Chairs by Dick Poynor",
      },
      {
        pubId: "dimassimo_weiss",
        id: "awake_ryland",
        order: 6.2,
        author: "Robell Awake and Charlie Ryland",
        authorShort: "Awake & Ryland",
        title: "Combatting Erasure",
      },
      {
        pubId: "hand",
        id: "hall",
        order: 2,
        author: "Stuart Hall",
        authorShort: "Hall",
        title: "Minimal Selves",
      },
      {
        pubId: "tom",
        id: "archive",
        order: 5,
        author: "Namita Gupta Wiggers",
        authorShort: "Wiggers",
        title: "Archive",
      },
    ],
  },
  {
    id: "blackwomen",
    name: "black women",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "goodman",
        order: 3.2,
        author: "Mellanee Goodman",
        authorShort: "Goodman",
        title: "Reflections on “In the Fray”",
      },
      {
        pubId: "dimassimo_weiss",
        id: "hand",
        order: 4.2,
        author: "Jennifer Hand",
        authorShort: "Hand",
        title:
          "Made to Order: Edmonia Lewis and Repercussions of the Tourist Trade on a Sculptural Practice",
      },
      {
        pubId: "dimassimo_weiss",
        id: "weiss",
        order: 5.1,
        author: "Joanna Weiss",
        authorShort: "Weiss",
        title:
          "Cowrie Shells: Movement and Connections through African American Material Culture",
      },
    ],
  },
  {
    id: "ceramic",
    name: "ceramic",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo",
        order: 4.1,
        author: "Jill DiMassimo",
        authorShort: "DiMassimo",
        title: "Mapping Commeraw’s Jar",
      },
      {
        pubId: "dimassimo_weiss",
        id: "perron-feller",
        order: 5.2,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Material Encounters: A Moment of Bringing Black Craft History into the Present through Fieldwork",
      },
      {
        pubId: "tom",
        id: "aufhebung",
        order: 6,
        author: "Lucia Ilene Wallace",
        authorShort: "Wallace",
        title: "Aufhebung",
      },
      {
        pubId: "wiltsie",
        id: "gallery",
        order: 3,
        author: "Tina Wiltsie",
        authorShort: "Wiltsie",
        title: "Enduring Form: Objects Depicting Ceramics across Time",
      },
      {
        pubId: "wiltsie",
        id: "artistpanel",
        order: 4,
        author:
          "Molly Hatch, Kevin McNamee-Tweed, Mariko Paterson & Tina Wiltsie",
        authorShort: "Hatch, McNamee-Tweed, Paterson, & Wiltsie",
        title:
          "History Surrounds Us: A Conversation with Mariko Paterson, Molly Hatch, and Kevin McNamee-Tweed",
      },
    ],
  },
  {
    id: "chairs",
    name: "chairs",
    articles: [
      {
        pubId: "devlin",
        id: "calderoanddevlin",
        order: 6,
        author: "Alexis Rosa Caldero & Miriam Devlin",
        authorShort: "Caldero & Devlin",
        title:
          "Conversation with Alexis Rosa Caldero: Wooden Chairs for Healing",
      },
      {
        pubId: "dimassimo_weiss",
        id: "devlin",
        order: 6.1,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Exceptional, Typical, Chairs by Dick Poynor",
      },
      {
        pubId: "dimassimo_weiss",
        id: "awake_ryland",
        order: 6.2,
        author: "Robell Awake and Charlie Ryland",
        authorShort: "Awake & Ryland",
        title: "Combatting Erasure",
      },
    ],
  },
  {
    id: "chance",
    name: "chance",
    articles: [
      {
        pubId: "notaretreat",
        id: "leemann",
        order: 5,
        author: "Judith Leemann",
        authorShort: "Leemann",
        title: "towards tending not knowing",
      },
      {
        pubId: "notaretreat",
        id: "levine",
        order: 10,
        author: "Faythe Levine",
        authorShort: "Levine",
        title: "The Dotted Line (Desktop Version)",
      },
    ],
  },
  {
    id: "chapter",
    name: "chapter",
    articles: [
      {
        pubId: "devlin",
        id: "wallkimmerer",
        order: 2,
        author: "Robin Wall Kimmerer",
        authorShort: "Kimmerer",
        title: "Wisgaak Gokpenagen: A Black Ash Basket",
      },
      {
        pubId: "hand",
        id: "josselson",
        order: 5,
        author: "Ruthellen Josselson",
        authorShort: "Josselson",
        title:
          "On Writing Other People’s Lives: Self-analytic Reflections of a Narrative Researcher",
      },
      {
        pubId: "perron-feller",
        id: "wallkimmerer",
        order: 2,
        author: "Robin Wall Kimmerer",
        authorShort: "Kimmerer",
        title: '"Asters and Goldenrod," from Braiding Sweetgrass',
      },
    ],
  },
  {
    id: "commentary",
    name: "commentary",
    articles: [
      {
        pubId: "devlin",
        id: "devlin",
        order: 4,
        author: "Trieste Devlin",
        authorShort: "Devlin",
        title: "On Frames",
      },
      {
        pubId: "devlin",
        id: "bernsteinanddevlin",
        order: 7,
        author: "Blake Bernstein & Trieste Devlin",
        authorShort: "Bernstein & Devlin",
        title: "On Manga",
      },
      {
        pubId: "hand",
        id: "leeandhand",
        order: 7,
        author: "Helen Lee and Jennifer Hand",
        authorShort: "Lee & Hand",
        title:
          "Practice Makes Progress. A Comment Conversation on the Urban Glass Fall 2022 Feature, “Multilingualism: The Intersecting Art and Advocacy of Helen Lee” ",
      },
      {
        pubId: "notaretreat",
        id: "potter",
        order: 6,
        author: "Melissa Potter",
        authorShort: "Potter",
        title: "Pandemic Papermaking: Critical Craft",
      },
    ],
  },
  {
    id: "community",
    name: "community",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo_weiss_wiggers",
        order: 2.1,
        author: "Namita Gupta Wiggers, Jill DiMassimo, and Joanna Weiss",
        authorShort: "Wiggers, DiMassimo & Weiss",
        title:
          "Interview with Namita Gupta Wiggers: A Focused Oral History on the MACR Creation",
      },
      {
        pubId: "notaretreat",
        id: "introduction",
        order: 1,
        author: "Ben Lignel and Namita Gupta Wiggers",
        authorShort: "Lignel & Wiggers",
        title: "Introduction",
      },
      {
        pubId: "notaretreat",
        id: "jarrett_wiggers",
        order: 3,
        author: "Lisa Jarrett and Namita Gupta Wiggers",
        authorShort: "Jarrett & Wiggers",
        title:
          "Teaching Questions: Lisa Jarrett and Namita Gupta Wiggers in Conversation",
      },
      {
        pubId: "notaretreat",
        id: "clugage",
        order: 7,
        author: "Sara Clugage",
        authorShort: "Clugage",
        title: "This Is Not a Food Studies Program",
      },
      {
        pubId: "notaretreat",
        id: "wilder",
        order: 12,
        author: "Melanie Wilder",
        authorShort: "wilder",
        title: "Weaving Community Together",
      },
      {
        pubId: "notaretreat",
        id: "helgeson",
        order: 16,
        author: "Anna Helgeson",
        authorShort: "Helgeson",
        title: "Craft, Nothing, and Nowhere",
      },
      {
        pubId: "notaretreat",
        id: "delosreyes",
        order: 19,
        author: "Jen Delos Reyes",
        authorShort: "Delos Reyes",
        title:
          "Future Desire Lines: Cultivating Paths of Resistance, Support, and Joy",
      },
      {
        pubId: "perron-feller",
        id: "denherder",
        order: 3,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Ecology of Built Environments: An Interview with Structural Engineer Justin Den Herder",
      },
      {
        pubId: "perron-feller",
        id: "buckert",
        order: 4,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Constructing an Interdisciplinary Life: An Oral History with Morgan Buckert",
      },
      {
        pubId: "perron-feller",
        id: "johnson",
        order: 5,
        author: "Emily Johnson",
        authorShort: "Johnson",
        title: "Surgent Love ",
      },
      {
        pubId: "tom",
        id: "acknowledgments",
        order: 2,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Acknowledgments",
      },
      {
        pubId: "tom",
        id: "ancestry",
        order: 4,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Ancestry",
      },
      {
        pubId: "tom",
        id: "archive",
        order: 5,
        author: "Namita Gupta Wiggers",
        authorShort: "Wiggers",
        title: "Archive",
      },
      {
        pubId: "tom",
        id: "coalesce",
        order: 7,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Coalesce",
      },
      {
        pubId: "tom",
        id: "educate",
        order: 9,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Educate",
      },
      {
        pubId: "tom",
        id: "grave",
        order: 13,
        author: "Jarrett M. Drake",
        authorShort: "Drake",
        title: "Grave",
      },
      {
        pubId: "tom",
        id: "map",
        order: 18,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Map",
      },
      {
        pubId: "tom",
        id: "offering",
        order: 20,
        author: "Phoebe Cheng",
        authorShort: "Cheng",
        title: "Offering",
      },
      {
        pubId: "tom",
        id: "portrait",
        order: 22,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Portrait",
      },
      {
        pubId: "tom",
        id: "publication",
        order: 24,
        author: "Ben Lignel",
        authorShort: "Lignel",
        title: "Publication",
      },
      {
        pubId: "tom",
        id: "queer",
        order: 25,
        author: "matt lambert",
        authorShort: "lambert",
        title: "Queer",
      },
      {
        pubId: "tom",
        id: "souvenir",
        order: 30,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Souvenir",
      },
      {
        pubId: "wiltsie",
        id: "artistpanel",
        order: 4,
        author:
          "Molly Hatch, Kevin McNamee-Tweed, Mariko Paterson & Tina Wiltsie",
        authorShort: "Hatch, McNamee-Tweed, Paterson, & Wiltsie",
        title:
          "History Surrounds Us: A Conversation with Mariko Paterson, Molly Hatch, and Kevin McNamee-Tweed",
      },
    ],
  },
  {
    id: "conversation",
    name: "conversation",
    articles: [
      {
        pubId: "devlin",
        id: "editorsletter",
        order: 1,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Editor's Letter",
      },
      {
        pubId: "devlin",
        id: "devlin",
        order: 4,
        author: "Trieste Devlin",
        authorShort: "Devlin",
        title: "On Frames",
      },
      {
        pubId: "devlin",
        id: "bernsteinanddevlin",
        order: 7,
        author: "Blake Bernstein & Trieste Devlin",
        authorShort: "Bernstein & Devlin",
        title: "On Manga",
      },
      {
        pubId: "wiltsie",
        id: "artistpanel",
        order: 4,
        author:
          "Molly Hatch, Kevin McNamee-Tweed, Mariko Paterson & Tina Wiltsie",
        authorShort: "Hatch, McNamee-Tweed, Paterson, & Wiltsie",
        title:
          "History Surrounds Us: A Conversation with Mariko Paterson, Molly Hatch, and Kevin McNamee-Tweed",
      },
    ],
  },
  {
    id: "coursework",
    name: "coursework",
    articles: [
      {
        pubId: "devlin",
        id: "kuo",
        order: 5,
        author: "Phoebe Kuo",
        authorShort: "Kuo",
        title: "Swimming Ethnography",
      },
      {
        pubId: "devlin",
        id: "hawes",
        order: 9,
        author: "Kate Hawes",
        authorShort: "Hawes",
        title: "Mouth to Test Spoons",
      },
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo",
        order: 4.1,
        author: "Jill DiMassimo",
        authorShort: "DiMassimo",
        title: "Mapping Commeraw’s Jar",
      },
      {
        pubId: "dimassimo_weiss",
        id: "hand",
        order: 4.2,
        author: "Jennifer Hand",
        authorShort: "Hand",
        title:
          "Made to Order: Edmonia Lewis and Repercussions of the Tourist Trade on a Sculptural Practice",
      },
      {
        pubId: "dimassimo_weiss",
        id: "weiss",
        order: 5.1,
        author: "Joanna Weiss",
        authorShort: "Weiss",
        title:
          "Cowrie Shells: Movement and Connections through African American Material Culture",
      },
      {
        pubId: "dimassimo_weiss",
        id: "devlin",
        order: 6.1,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Exceptional, Typical, Chairs by Dick Poynor",
      },
      {
        pubId: "tom",
        id: "legacy",
        order: 14,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Legacy",
      },
      {
        pubId: "tom",
        id: "map",
        order: 18,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Map",
      },
      {
        pubId: "tom",
        id: "reframe",
        order: 26,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Reframe",
      },
    ],
  },
  {
    id: "craftpractices",
    name: "craft practices",
    articles: [
      {
        pubId: "devlin",
        id: "wallkimmerer",
        order: 2,
        author: "Robin Wall Kimmerer",
        authorShort: "Kimmerer",
        title: "Wisgaak Gokpenagen: A Black Ash Basket",
      },
      {
        pubId: "devlin",
        id: "devlin",
        order: 4,
        author: "Trieste Devlin",
        authorShort: "Devlin",
        title: "On Frames",
      },
      {
        pubId: "devlin",
        id: "calderoanddevlin",
        order: 6,
        author: "Alexis Rosa Caldero & Miriam Devlin",
        authorShort: "Caldero & Devlin",
        title:
          "Conversation with Alexis Rosa Caldero: Wooden Chairs for Healing",
      },
      {
        pubId: "devlin",
        id: "hawes",
        order: 9,
        author: "Kate Hawes",
        authorShort: "Hawes",
        title: "Mouth to Test Spoons",
      },
      {
        pubId: "dimassimo_weiss",
        id: "editornote",
        order: 1,
        author: "Jill DiMassimo & Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Making Room, Making Connections, Making History: Editors' Note",
      },
      {
        pubId: "dimassimo_weiss",
        id: "goodman",
        order: 3.2,
        author: "Mellanee Goodman",
        authorShort: "Goodman",
        title: "Reflections on “In the Fray”",
      },
      {
        pubId: "dimassimo_weiss",
        id: "making",
        order: 6,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Making",
      },
      {
        pubId: "dimassimo_weiss",
        id: "awake_ryland",
        order: 6.2,
        author: "Robell Awake and Charlie Ryland",
        authorShort: "Awake & Ryland",
        title: "Combatting Erasure",
      },
      {
        pubId: "hand",
        id: "joyce",
        order: 3,
        author: "Jennifer Hand",
        authorShort: "Hand",
        title: "Joyce Scott: Hard Truth in Beauty",
      },
      {
        pubId: "notaretreat",
        id: "potter",
        order: 6,
        author: "Melissa Potter",
        authorShort: "Potter",
        title: "Pandemic Papermaking: Critical Craft",
      },
      {
        pubId: "notaretreat",
        id: "wilder",
        order: 12,
        author: "Melanie Wilder",
        authorShort: "wilder",
        title: "Weaving Community Together",
      },
      {
        pubId: "notaretreat",
        id: "helgeson",
        order: 16,
        author: "Anna Helgeson",
        authorShort: "Helgeson",
        title: "Craft, Nothing, and Nowhere",
      },
      {
        pubId: "perron-feller",
        id: "foreword",
        order: 1,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title: "Foreword",
      },
      {
        pubId: "perron-feller",
        id: "buckert",
        order: 4,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Constructing an Interdisciplinary Life: An Oral History with Morgan Buckert",
      },
      {
        pubId: "tom",
        id: "legacy",
        order: 14,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Legacy",
      },
      {
        pubId: "tom",
        id: "predict",
        order: 23,
        author: "Andres Payan Estrada",
        authorShort: "Estrada",
        title: "Predict",
      },
      {
        pubId: "tom",
        id: "talisman",
        order: 33,
        author: "Kasey Smith",
        authorShort: "Smith",
        title: "Talisman",
      },
      {
        pubId: "wiltsie",
        id: "artistpanel",
        order: 4,
        author:
          "Molly Hatch, Kevin McNamee-Tweed, Mariko Paterson & Tina Wiltsie",
        authorShort: "Hatch, McNamee-Tweed, Paterson, & Wiltsie",
        title:
          "History Surrounds Us: A Conversation with Mariko Paterson, Molly Hatch, and Kevin McNamee-Tweed",
      },
    ],
  },
  {
    id: "craftstudies",
    name: "craft studies",
    articles: [
      {
        pubId: "devlin",
        id: "editorsletter",
        order: 1,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Editor's Letter",
      },
      {
        pubId: "devlin",
        id: "adamson",
        order: 3,
        author: "Glenn Adamson",
        authorShort: "Adamson",
        title: "Material Intelligence",
      },
      {
        pubId: "devlin",
        id: "hawes",
        order: 9,
        author: "Kate Hawes",
        authorShort: "Hawes",
        title: "Mouth to Test Spoons",
      },
      {
        pubId: "dimassimo_weiss",
        id: "editornote",
        order: 1,
        author: "Jill DiMassimo & Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Making Room, Making Connections, Making History: Editors' Note",
      },
      {
        pubId: "dimassimo_weiss",
        id: "approach",
        order: 2,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Approach",
      },
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo_weiss_wiggers",
        order: 2.1,
        author: "Namita Gupta Wiggers, Jill DiMassimo, and Joanna Weiss",
        authorShort: "Wiggers, DiMassimo & Weiss",
        title:
          "Interview with Namita Gupta Wiggers: A Focused Oral History on the MACR Creation",
      },
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo_momon_weiss",
        order: 2.2,
        author: "Tiffany Momon, Jill DiMassimo, and Joanna Weiss",
        authorShort: "Momon, DiMassimo & Weiss",
        title:
          "Interview with Dr. Tiffany Momon: Public Historian, BCDA Founder, and MACR Teaching Fellow",
      },
      {
        pubId: "dimassimo_weiss",
        id: "absence",
        order: 3,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Absence",
      },
      {
        pubId: "dimassimo_weiss",
        id: "hatch",
        order: 3.1,
        author: "Michael Hatch",
        authorShort: "Hatch",
        title: "Redacted",
      },
      {
        pubId: "dimassimo_weiss",
        id: "goodman",
        order: 3.2,
        author: "Mellanee Goodman",
        authorShort: "Goodman",
        title: "Reflections on “In the Fray”",
      },
      {
        pubId: "dimassimo_weiss",
        id: "presence",
        order: 4,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Presence",
      },
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo",
        order: 4.1,
        author: "Jill DiMassimo",
        authorShort: "DiMassimo",
        title: "Mapping Commeraw’s Jar",
      },
      {
        pubId: "dimassimo_weiss",
        id: "hand",
        order: 4.2,
        author: "Jennifer Hand",
        authorShort: "Hand",
        title:
          "Made to Order: Edmonia Lewis and Repercussions of the Tourist Trade on a Sculptural Practice",
      },
      {
        pubId: "dimassimo_weiss",
        id: "materials",
        order: 5,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Materials",
      },
      {
        pubId: "dimassimo_weiss",
        id: "weiss",
        order: 5.1,
        author: "Joanna Weiss",
        authorShort: "Weiss",
        title:
          "Cowrie Shells: Movement and Connections through African American Material Culture",
      },
      {
        pubId: "dimassimo_weiss",
        id: "perron-feller",
        order: 5.2,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Material Encounters: A Moment of Bringing Black Craft History into the Present through Fieldwork",
      },
      {
        pubId: "dimassimo_weiss",
        id: "making",
        order: 6,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Making",
      },
      {
        pubId: "dimassimo_weiss",
        id: "devlin",
        order: 6.1,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Exceptional, Typical, Chairs by Dick Poynor",
      },
      {
        pubId: "dimassimo_weiss",
        id: "awake_ryland",
        order: 6.2,
        author: "Robell Awake and Charlie Ryland",
        authorShort: "Awake & Ryland",
        title: "Combatting Erasure",
      },
      {
        pubId: "hand",
        id: "leeandhand",
        order: 7,
        author: "Helen Lee and Jennifer Hand",
        authorShort: "Lee & Hand",
        title:
          "Practice Makes Progress. A Comment Conversation on the Urban Glass Fall 2022 Feature, “Multilingualism: The Intersecting Art and Advocacy of Helen Lee” ",
      },
      {
        pubId: "notaretreat",
        id: "introduction",
        order: 1,
        author: "Ben Lignel and Namita Gupta Wiggers",
        authorShort: "Lignel & Wiggers",
        title: "Introduction",
      },
      {
        pubId: "notaretreat",
        id: "skov",
        order: 8,
        author: "Mara Holt Skov",
        authorShort: "Skov",
        title: "Reflections on Teaching and Learning with the Bowl",
      },
      {
        pubId: "notaretreat",
        id: "razdan",
        order: 14,
        author: "Anjula Razdan",
        authorShort: "Razdan",
        title: "Unearthing the Craftscape",
      },
      {
        pubId: "notaretreat",
        id: "murray",
        order: 18,
        author: "Kevin Murray",
        authorShort: "Murray",
        title: "Why the Question?",
      },
      {
        pubId: "notaretreat",
        id: "fisher",
        order: 21,
        author: "Michelle Millar Fisher",
        authorShort: "Fisher",
        title: "Who writes craft histories?",
      },
      {
        pubId: "perron-feller",
        id: "foreword",
        order: 1,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title: "Foreword",
      },
      {
        pubId: "tom",
        id: "archive",
        order: 5,
        author: "Namita Gupta Wiggers",
        authorShort: "Wiggers",
        title: "Archive",
      },
      {
        pubId: "tom",
        id: "educate",
        order: 9,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Educate",
      },
      {
        pubId: "tom",
        id: "ghost",
        order: 12,
        author: "Sara Clugage",
        authorShort: "Clugage",
        title: "Ghost",
      },
      {
        pubId: "tom",
        id: "map",
        order: 18,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Map",
      },
      {
        pubId: "tom",
        id: "renewal",
        order: 27,
        author: "Brian Potter",
        authorShort: "Potter",
        title: "Renewal",
      },
      {
        pubId: "tom",
        id: "subvert",
        order: 32,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Subvert",
      },
      {
        pubId: "wiltsie",
        id: "foreword",
        order: 1,
        author: "Tina Wiltsie",
        authorShort: "Wiltsie",
        title: "Foreword",
      },
      {
        pubId: "wiltsie",
        id: "bibliography",
        order: 5,
        author: "Tina Wiltsie",
        authorShort: "Wiltsie",
        title: "Gathering Raw Materials: A Bibliography of Suggested Readings",
      },
    ],
  },
  {
    id: "craftwayspresenters",
    name: "craft ways (presenters)",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo_weiss_wiggers",
        order: 2.1,
        author: "Namita Gupta Wiggers, Jill DiMassimo, and Joanna Weiss",
        authorShort: "Wiggers, DiMassimo & Weiss",
        title:
          "Interview with Namita Gupta Wiggers: A Focused Oral History on the MACR Creation",
      },
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo_momon_weiss",
        order: 2.2,
        author: "Tiffany Momon, Jill DiMassimo, and Joanna Weiss",
        authorShort: "Momon, DiMassimo & Weiss",
        title:
          "Interview with Dr. Tiffany Momon: Public Historian, BCDA Founder, and MACR Teaching Fellow",
      },
      {
        pubId: "dimassimo_weiss",
        id: "hatch",
        order: 3.1,
        author: "Michael Hatch",
        authorShort: "Hatch",
        title: "Redacted",
      },
      {
        pubId: "dimassimo_weiss",
        id: "goodman",
        order: 3.2,
        author: "Mellanee Goodman",
        authorShort: "Goodman",
        title: "Reflections on “In the Fray”",
      },
      {
        pubId: "notaretreat",
        id: "introduction",
        order: 1,
        author: "Ben Lignel and Namita Gupta Wiggers",
        authorShort: "Lignel & Wiggers",
        title: "Introduction",
      },
      {
        pubId: "notaretreat",
        id: "meissner",
        order: 2,
        author: "Amy Meissner",
        authorShort: "Meissner",
        title: "But First, Learn This (A List)",
      },
      {
        pubId: "notaretreat",
        id: "jarrett_wiggers",
        order: 3,
        author: "Lisa Jarrett and Namita Gupta Wiggers",
        authorShort: "Jarrett & Wiggers",
        title:
          "Teaching Questions: Lisa Jarrett and Namita Gupta Wiggers in Conversation",
      },
      {
        pubId: "notaretreat",
        id: "momon",
        order: 15,
        author: "Tiffany Momon",
        authorShort: "Momon",
        title: "People, Get Ready: Building New Worlds",
      },
      {
        pubId: "perron-feller",
        id: "johnson",
        order: 5,
        author: "Emily Johnson",
        authorShort: "Johnson",
        title: "Surgent Love ",
      },
      {
        pubId: "tom",
        id: "predict",
        order: 23,
        author: "Andres Payan Estrada",
        authorShort: "Estrada",
        title: "Predict",
      },
      {
        pubId: "tom",
        id: "queer",
        order: 25,
        author: "matt lambert",
        authorShort: "lambert",
        title: "Queer",
      },
    ],
  },
  {
    id: "craftscape",
    name: "craftscape",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo_weiss_wiggers",
        order: 2.1,
        author: "Namita Gupta Wiggers, Jill DiMassimo, and Joanna Weiss",
        authorShort: "Wiggers, DiMassimo & Weiss",
        title:
          "Interview with Namita Gupta Wiggers: A Focused Oral History on the MACR Creation",
      },
      {
        pubId: "hand",
        id: "foreword",
        order: 1,
        author: "Jennifer Hand",
        authorShort: "Hand",
        title: "Foreword",
      },
      {
        pubId: "notaretreat",
        id: "introduction",
        order: 1,
        author: "Ben Lignel and Namita Gupta Wiggers",
        authorShort: "Lignel & Wiggers",
        title: "Introduction",
      },
      {
        pubId: "notaretreat",
        id: "jarrett_wiggers",
        order: 3,
        author: "Lisa Jarrett and Namita Gupta Wiggers",
        authorShort: "Jarrett & Wiggers",
        title:
          "Teaching Questions: Lisa Jarrett and Namita Gupta Wiggers in Conversation",
      },
      {
        pubId: "notaretreat",
        id: "razdan",
        order: 14,
        author: "Anjula Razdan",
        authorShort: "Razdan",
        title: "Unearthing the Craftscape",
      },
      {
        pubId: "notaretreat",
        id: "momon",
        order: 15,
        author: "Tiffany Momon",
        authorShort: "Momon",
        title: "People, Get Ready: Building New Worlds",
      },
      {
        pubId: "tom",
        id: "archive",
        order: 5,
        author: "Namita Gupta Wiggers",
        authorShort: "Wiggers",
        title: "Archive",
      },
    ],
  },
  {
    id: "criticalracetheory",
    name: "critical race theory",
    articles: [
      {
        pubId: "hand",
        id: "ahmed",
        order: 4,
        author: "Sara Ahmed",
        authorShort: "Ahmed",
        title: "A Phenomenology of Whiteness",
      },
      {
        pubId: "hand",
        id: "taylor",
        order: 6,
        author: "Keeanga-Yamahtta Taylor",
        authorShort: "Taylor",
        title: "Until Black Women Are Free, None of Us Will Be Free",
      },
    ],
  },
  {
    id: "criticism",
    name: "criticism",
    articles: [
      {
        pubId: "hand",
        id: "joyce",
        order: 3,
        author: "Jennifer Hand",
        authorShort: "Hand",
        title: "Joyce Scott: Hard Truth in Beauty",
      },
      {
        pubId: "hand",
        id: "leeandhand",
        order: 7,
        author: "Helen Lee and Jennifer Hand",
        authorShort: "Lee & Hand",
        title:
          "Practice Makes Progress. A Comment Conversation on the Urban Glass Fall 2022 Feature, “Multilingualism: The Intersecting Art and Advocacy of Helen Lee” ",
      },
    ],
  },
  {
    id: "curation",
    name: "curation",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo_weiss_wiggers",
        order: 2.1,
        author: "Namita Gupta Wiggers, Jill DiMassimo, and Joanna Weiss",
        authorShort: "Wiggers, DiMassimo & Weiss",
        title:
          "Interview with Namita Gupta Wiggers: A Focused Oral History on the MACR Creation",
      },
      {
        pubId: "dimassimo_weiss",
        id: "hatch",
        order: 3.1,
        author: "Michael Hatch",
        authorShort: "Hatch",
        title: "Redacted",
      },
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo",
        order: 4.1,
        author: "Jill DiMassimo",
        authorShort: "DiMassimo",
        title: "Mapping Commeraw’s Jar",
      },
      {
        pubId: "hand",
        id: "joyce",
        order: 3,
        author: "Jennifer Hand",
        authorShort: "Hand",
        title: "Joyce Scott: Hard Truth in Beauty",
      },
      {
        pubId: "notaretreat",
        id: "skov",
        order: 8,
        author: "Mara Holt Skov",
        authorShort: "Skov",
        title: "Reflections on Teaching and Learning with the Bowl",
      },
      {
        pubId: "tom",
        id: "evolve",
        order: 11,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Evolve",
      },
    ],
  },
  {
    id: "dance",
    name: "dance",
    articles: [
      {
        pubId: "devlin",
        id: "boden",
        order: 8,
        author: "Margaret Boden",
        authorShort: "Boden",
        title: "Crafts, Perception, and the Possibilities of the Body",
      },
      {
        pubId: "perron-feller",
        id: "johnson",
        order: 5,
        author: "Emily Johnson",
        authorShort: "Johnson",
        title: "Surgent Love ",
      },
    ],
  },
  {
    id: "death",
    name: "death",
    articles: [
      {
        pubId: "notaretreat",
        id: "martin",
        order: 17,
        author: "Tom Martin",
        authorShort: "Martin",
        title: "What to Do When You Are Dead: Craft and Existential Anxiety ",
      },
      {
        pubId: "tom",
        id: "ecology",
        order: 8,
        author: "Anna Lowenhaupt Tsing",
        authorShort: "Tsing",
        title: "Ecology",
      },
      {
        pubId: "tom",
        id: "grave",
        order: 13,
        author: "Jarrett M. Drake",
        authorShort: "Drake",
        title: "Grave",
      },
    ],
  },
  {
    id: "decolonization",
    name: "decolonization",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo_weiss_wiggers",
        order: 2.1,
        author: "Namita Gupta Wiggers, Jill DiMassimo, and Joanna Weiss",
        authorShort: "Wiggers, DiMassimo & Weiss",
        title:
          "Interview with Namita Gupta Wiggers: A Focused Oral History on the MACR Creation",
      },
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo",
        order: 4.1,
        author: "Jill DiMassimo",
        authorShort: "DiMassimo",
        title: "Mapping Commeraw’s Jar",
      },
      {
        pubId: "hand",
        id: "ahmed",
        order: 4,
        author: "Sara Ahmed",
        authorShort: "Ahmed",
        title: "A Phenomenology of Whiteness",
      },
      {
        pubId: "notaretreat",
        id: "lignel_wiggers",
        order: 11,
        author: "Ben Lignel and Namita Gupta Wiggers",
        authorShort: "Lignel & Wiggers",
        title:
          "Designing the Plane as We Flew It—A Discussion on the MACR Program Publications",
      },
      {
        pubId: "notaretreat",
        id: "razdan",
        order: 14,
        author: "Anjula Razdan",
        authorShort: "Razdan",
        title: "Unearthing the Craftscape",
      },
    ],
  },
  {
    id: "destruction",
    name: "destruction",
    articles: [
      {
        pubId: "tom",
        id: "aufhebung",
        order: 6,
        author: "Lucia Ilene Wallace",
        authorShort: "Wallace",
        title: "Aufhebung",
      },
      {
        pubId: "tom",
        id: "ecology",
        order: 8,
        author: "Anna Lowenhaupt Tsing",
        authorShort: "Tsing",
        title: "Ecology",
      },
      {
        pubId: "tom",
        id: "renewal",
        order: 27,
        author: "Brian Potter",
        authorShort: "Potter",
        title: "Renewal",
      },
    ],
  },
  {
    id: "diaspora",
    name: "diaspora",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "weiss",
        order: 5.1,
        author: "Joanna Weiss",
        authorShort: "Weiss",
        title:
          "Cowrie Shells: Movement and Connections through African American Material Culture",
      },
      {
        pubId: "hand",
        id: "hall",
        order: 2,
        author: "Stuart Hall",
        authorShort: "Hall",
        title: "Minimal Selves",
      },
      {
        pubId: "notaretreat",
        id: "acierto",
        order: 4,
        author: "alejandro t. acierto",
        authorShort: "acierto",
        title:
          "In the After-Thought: Teaching Transparency and the Ongoing Practice of Freedom",
      },
    ],
  },
  {
    id: "digitalcommunication",
    name: "digital communication",
    articles: [
      {
        pubId: "notaretreat",
        id: "acierto",
        order: 4,
        author: "alejandro t. acierto",
        authorShort: "acierto",
        title:
          "In the After-Thought: Teaching Transparency and the Ongoing Practice of Freedom",
      },
      {
        pubId: "notaretreat",
        id: "potter",
        order: 6,
        author: "Melissa Potter",
        authorShort: "Potter",
        title: "Pandemic Papermaking: Critical Craft",
      },
    ],
  },
  {
    id: "dye",
    name: "dye",
    articles: [
      {
        pubId: "devlin",
        id: "devlin",
        order: 4,
        author: "Trieste Devlin",
        authorShort: "Devlin",
        title: "On Frames",
      },
      {
        pubId: "notaretreat",
        id: "wilder",
        order: 12,
        author: "Melanie Wilder",
        authorShort: "wilder",
        title: "Weaving Community Together",
      },
    ],
  },
  {
    id: "ecology",
    name: "ecology",
    articles: [
      {
        pubId: "devlin",
        id: "wallkimmerer",
        order: 2,
        author: "Robin Wall Kimmerer",
        authorShort: "Kimmerer",
        title: "Wisgaak Gokpenagen: A Black Ash Basket",
      },
      {
        pubId: "perron-feller",
        id: "foreword",
        order: 1,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title: "Foreword",
      },
      {
        pubId: "perron-feller",
        id: "wallkimmerer",
        order: 2,
        author: "Robin Wall Kimmerer",
        authorShort: "Kimmerer",
        title: '"Asters and Goldenrod," from Braiding Sweetgrass',
      },
      {
        pubId: "perron-feller",
        id: "denherder",
        order: 3,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Ecology of Built Environments: An Interview with Structural Engineer Justin Den Herder",
      },
      {
        pubId: "perron-feller",
        id: "buckert",
        order: 4,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Constructing an Interdisciplinary Life: An Oral History with Morgan Buckert",
      },
      {
        pubId: "tom",
        id: "ecology",
        order: 8,
        author: "Anna Lowenhaupt Tsing",
        authorShort: "Tsing",
        title: "Ecology",
      },
      {
        pubId: "tom",
        id: "lifespan",
        order: 15,
        author: "Paul Benzon and Future Library",
        authorShort: "Benzon & Future Library",
        title: "Lifespan",
      },
      {
        pubId: "tom",
        id: "memorial",
        order: 19,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Memorial",
      },
      {
        pubId: "tom",
        id: "talisman",
        order: 33,
        author: "Kasey Smith",
        authorShort: "Smith",
        title: "Talisman",
      },
    ],
  },
  {
    id: "education",
    name: "education",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "approach",
        order: 2,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Approach",
      },
      {
        pubId: "dimassimo_weiss",
        id: "perron-feller",
        order: 5.2,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Material Encounters: A Moment of Bringing Black Craft History into the Present through Fieldwork",
      },
      {
        pubId: "hand",
        id: "leeandhand",
        order: 7,
        author: "Helen Lee and Jennifer Hand",
        authorShort: "Lee & Hand",
        title:
          "Practice Makes Progress. A Comment Conversation on the Urban Glass Fall 2022 Feature, “Multilingualism: The Intersecting Art and Advocacy of Helen Lee” ",
      },
      {
        pubId: "notaretreat",
        id: "jarrett_wiggers",
        order: 3,
        author: "Lisa Jarrett and Namita Gupta Wiggers",
        authorShort: "Jarrett & Wiggers",
        title:
          "Teaching Questions: Lisa Jarrett and Namita Gupta Wiggers in Conversation",
      },
    ],
  },
  {
    id: "embodiedknowledge",
    name: "embodied knowledge",
    articles: [
      {
        pubId: "devlin",
        id: "editorsletter",
        order: 1,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Editor's Letter",
      },
      {
        pubId: "notaretreat",
        id: "clugage",
        order: 7,
        author: "Sara Clugage",
        authorShort: "Clugage",
        title: "This Is Not a Food Studies Program",
      },
      {
        pubId: "tom",
        id: "magic",
        order: 17,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Magic",
      },
    ],
  },
  {
    id: "encounters",
    name: "encounters",
    articles: [
      {
        pubId: "notaretreat",
        id: "leemann",
        order: 5,
        author: "Judith Leemann",
        authorShort: "Leemann",
        title: "towards tending not knowing",
      },
      {
        pubId: "notaretreat",
        id: "lignel_wiggers",
        order: 11,
        author: "Ben Lignel and Namita Gupta Wiggers",
        authorShort: "Lignel & Wiggers",
        title:
          "Designing the Plane as We Flew It—A Discussion on the MACR Program Publications",
      },
      {
        pubId: "notaretreat",
        id: "wilder",
        order: 12,
        author: "Melanie Wilder",
        authorShort: "wilder",
        title: "Weaving Community Together",
      },
      {
        pubId: "notaretreat",
        id: "fisher",
        order: 21,
        author: "Michelle Millar Fisher",
        authorShort: "Fisher",
        title: "Who writes craft histories?",
      },
    ],
  },
  {
    id: "enslavement",
    name: "enslavement",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "goodman",
        order: 3.2,
        author: "Mellanee Goodman",
        authorShort: "Goodman",
        title: "Reflections on “In the Fray”",
      },
    ],
  },
  {
    id: "erasure",
    name: "erasure",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "editornote",
        order: 1,
        author: "Jill DiMassimo & Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Making Room, Making Connections, Making History: Editors' Note",
      },
      {
        pubId: "dimassimo_weiss",
        id: "absence",
        order: 3,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Absence",
      },
      {
        pubId: "dimassimo_weiss",
        id: "hatch",
        order: 3.1,
        author: "Michael Hatch",
        authorShort: "Hatch",
        title: "Redacted",
      },
      {
        pubId: "dimassimo_weiss",
        id: "goodman",
        order: 3.2,
        author: "Mellanee Goodman",
        authorShort: "Goodman",
        title: "Reflections on “In the Fray”",
      },
      {
        pubId: "dimassimo_weiss",
        id: "devlin",
        order: 6.1,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Exceptional, Typical, Chairs by Dick Poynor",
      },
      {
        pubId: "dimassimo_weiss",
        id: "awake_ryland",
        order: 6.2,
        author: "Robell Awake and Charlie Ryland",
        authorShort: "Awake & Ryland",
        title: "Combatting Erasure",
      },
      {
        pubId: "tom",
        id: "archive",
        order: 5,
        author: "Namita Gupta Wiggers",
        authorShort: "Wiggers",
        title: "Archive",
      },
    ],
  },
  {
    id: "essay",
    name: "essay",
    articles: [
      {
        pubId: "devlin",
        id: "adamson",
        order: 3,
        author: "Glenn Adamson",
        authorShort: "Adamson",
        title: "Material Intelligence",
      },
      {
        pubId: "devlin",
        id: "kuo",
        order: 5,
        author: "Phoebe Kuo",
        authorShort: "Kuo",
        title: "Swimming Ethnography",
      },
      {
        pubId: "devlin",
        id: "boden",
        order: 8,
        author: "Margaret Boden",
        authorShort: "Boden",
        title: "Crafts, Perception, and the Possibilities of the Body",
      },
      {
        pubId: "devlin",
        id: "hawes",
        order: 9,
        author: "Kate Hawes",
        authorShort: "Hawes",
        title: "Mouth to Test Spoons",
      },
      {
        pubId: "dimassimo_weiss",
        id: "hatch",
        order: 3.1,
        author: "Michael Hatch",
        authorShort: "Hatch",
        title: "Redacted",
      },
      {
        pubId: "dimassimo_weiss",
        id: "goodman",
        order: 3.2,
        author: "Mellanee Goodman",
        authorShort: "Goodman",
        title: "Reflections on “In the Fray”",
      },
      {
        pubId: "dimassimo_weiss",
        id: "hand",
        order: 4.2,
        author: "Jennifer Hand",
        authorShort: "Hand",
        title:
          "Made to Order: Edmonia Lewis and Repercussions of the Tourist Trade on a Sculptural Practice",
      },
      {
        pubId: "dimassimo_weiss",
        id: "devlin",
        order: 6.1,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Exceptional, Typical, Chairs by Dick Poynor",
      },
      {
        pubId: "hand",
        id: "hall",
        order: 2,
        author: "Stuart Hall",
        authorShort: "Hall",
        title: "Minimal Selves",
      },
      {
        pubId: "hand",
        id: "ahmed",
        order: 4,
        author: "Sara Ahmed",
        authorShort: "Ahmed",
        title: "A Phenomenology of Whiteness",
      },
      {
        pubId: "hand",
        id: "taylor",
        order: 6,
        author: "Keeanga-Yamahtta Taylor",
        authorShort: "Taylor",
        title: "Until Black Women Are Free, None of Us Will Be Free",
      },
      {
        pubId: "hand",
        id: "vowel",
        order: 8,
        author: "Chelsea Vowel",
        authorShort: "Vowel",
        title: "Writing Toward a Definition of Indigenous Futurism",
      },
      {
        pubId: "notaretreat",
        id: "meissner",
        order: 2,
        author: "Amy Meissner",
        authorShort: "Meissner",
        title: "But First, Learn This (A List)",
      },
      {
        pubId: "notaretreat",
        id: "acierto",
        order: 4,
        author: "alejandro t. acierto",
        authorShort: "acierto",
        title:
          "In the After-Thought: Teaching Transparency and the Ongoing Practice of Freedom",
      },
      {
        pubId: "notaretreat",
        id: "leemann",
        order: 5,
        author: "Judith Leemann",
        authorShort: "Leemann",
        title: "towards tending not knowing",
      },
      {
        pubId: "notaretreat",
        id: "clugage",
        order: 7,
        author: "Sara Clugage",
        authorShort: "Clugage",
        title: "This Is Not a Food Studies Program",
      },
      {
        pubId: "notaretreat",
        id: "skov",
        order: 8,
        author: "Mara Holt Skov",
        authorShort: "Skov",
        title: "Reflections on Teaching and Learning with the Bowl",
      },
      {
        pubId: "notaretreat",
        id: "lignel",
        order: 9,
        author: "Ben Lignel",
        authorShort: "Lignel",
        title:
          "Teaching and Unlearning—Or How to Write Wall Texts: Lessons of Decolonial Thinking",
      },
      {
        pubId: "notaretreat",
        id: "wilder",
        order: 12,
        author: "Melanie Wilder",
        authorShort: "wilder",
        title: "Weaving Community Together",
      },
      {
        pubId: "notaretreat",
        id: "keith",
        order: 13,
        author: "Jeffrey A. Keith",
        authorShort: "Keith",
        title: "Emplaced Remembrance—Thinking about Gone Worlds Always Nearby",
      },
      {
        pubId: "notaretreat",
        id: "momon",
        order: 15,
        author: "Tiffany Momon",
        authorShort: "Momon",
        title: "People, Get Ready: Building New Worlds",
      },
      {
        pubId: "notaretreat",
        id: "helgeson",
        order: 16,
        author: "Anna Helgeson",
        authorShort: "Helgeson",
        title: "Craft, Nothing, and Nowhere",
      },
      {
        pubId: "notaretreat",
        id: "martin",
        order: 17,
        author: "Tom Martin",
        authorShort: "Martin",
        title: "What to Do When You Are Dead: Craft and Existential Anxiety ",
      },
      {
        pubId: "notaretreat",
        id: "murray",
        order: 18,
        author: "Kevin Murray",
        authorShort: "Murray",
        title: "Why the Question?",
      },
      {
        pubId: "notaretreat",
        id: "patel",
        order: 20,
        author: "Alpesh Kantilal Patel",
        authorShort: "Patel",
        title: "Worldmaking with Colleagues",
      },
      {
        pubId: "perron-feller",
        id: "johnson",
        order: 5,
        author: "Emily Johnson",
        authorShort: "Johnson",
        title: "Surgent Love ",
      },
      {
        pubId: "tom",
        id: "archive",
        order: 5,
        author: "Namita Gupta Wiggers",
        authorShort: "Wiggers",
        title: "Archive",
      },
      {
        pubId: "tom",
        id: "ghost",
        order: 12,
        author: "Sara Clugage",
        authorShort: "Clugage",
        title: "Ghost",
      },
      {
        pubId: "tom",
        id: "offering",
        order: 20,
        author: "Phoebe Cheng",
        authorShort: "Cheng",
        title: "Offering",
      },
      {
        pubId: "tom",
        id: "palimpsest",
        order: 21,
        author: "Indira Allegra",
        authorShort: "Allegra",
        title: "Palimpsest",
      },
      {
        pubId: "tom",
        id: "publication",
        order: 24,
        author: "Ben Lignel",
        authorShort: "Lignel",
        title: "Publication",
      },
      {
        pubId: "tom",
        id: "queer",
        order: 25,
        author: "matt lambert",
        authorShort: "lambert",
        title: "Queer",
      },
      {
        pubId: "tom",
        id: "reframe",
        order: 26,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Reframe",
      },
      {
        pubId: "tom",
        id: "renewal",
        order: 27,
        author: "Brian Potter",
        authorShort: "Potter",
        title: "Renewal",
      },
      {
        pubId: "tom",
        id: "repair",
        order: 28,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Repair",
      },
      {
        pubId: "tom",
        id: "resolve",
        order: 29,
        author: "Jill DiMassimo",
        authorShort: "DiMassimo",
        title: "Resolve",
      },
      {
        pubId: "tom",
        id: "souvenir",
        order: 30,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Souvenir",
      },
      {
        pubId: "tom",
        id: "speculate",
        order: 31,
        author: "Tom Martin",
        authorShort: "Martin",
        title: "Speculate",
      },
      {
        pubId: "tom",
        id: "subvert",
        order: 32,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Subvert",
      },
      {
        pubId: "tom",
        id: "talisman",
        order: 33,
        author: "Kasey Smith",
        authorShort: "Smith",
        title: "Talisman",
      },
      {
        pubId: "tom",
        id: "eulogy",
        order: 35,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Eulogy",
      },
    ],
  },
  {
    id: "ethnography",
    name: "ethnography",
    articles: [
      {
        pubId: "devlin",
        id: "kuo",
        order: 5,
        author: "Phoebe Kuo",
        authorShort: "Kuo",
        title: "Swimming Ethnography",
      },
      {
        pubId: "tom",
        id: "reframe",
        order: 26,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Reframe",
      },
    ],
  },
  {
    id: "excerpt",
    name: "excerpt",
    articles: [
      {
        pubId: "notaretreat",
        id: "levine",
        order: 10,
        author: "Faythe Levine",
        authorShort: "Levine",
        title: "The Dotted Line (Desktop Version)",
      },
      {
        pubId: "tom",
        id: "epigraph",
        order: 3,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Epigraph",
      },
      {
        pubId: "tom",
        id: "aufhebung",
        order: 6,
        author: "Lucia Ilene Wallace",
        authorShort: "Wallace",
        title: "Aufhebung",
      },
      {
        pubId: "tom",
        id: "ecology",
        order: 8,
        author: "Anna Lowenhaupt Tsing",
        authorShort: "Tsing",
        title: "Ecology",
      },
      {
        pubId: "tom",
        id: "enchant",
        order: 10,
        author: "Karen Wilson Baptist",
        authorShort: "Baptist",
        title: "Enchant",
      },
      {
        pubId: "tom",
        id: "grave",
        order: 13,
        author: "Jarrett M. Drake",
        authorShort: "Drake",
        title: "Grave",
      },
      {
        pubId: "tom",
        id: "lifespan",
        order: 15,
        author: "Paul Benzon and Future Library",
        authorShort: "Benzon & Future Library",
        title: "Lifespan",
      },
      {
        pubId: "tom",
        id: "liminal",
        order: 16,
        author: "Alex Singh",
        authorShort: "Singh",
        title: "Liminal",
      },
      {
        pubId: "tom",
        id: "zombie",
        order: 34,
        author: "Greg Garrett",
        authorShort: "Garrett",
        title: "Zombie",
      },
    ],
  },
  {
    id: "faith",
    name: "faith",
    articles: [
      {
        pubId: "notaretreat",
        id: "keith",
        order: 13,
        author: "Jeffrey A. Keith",
        authorShort: "Keith",
        title: "Emplaced Remembrance—Thinking about Gone Worlds Always Nearby",
      },
      {
        pubId: "notaretreat",
        id: "momon",
        order: 15,
        author: "Tiffany Momon",
        authorShort: "Momon",
        title: "People, Get Ready: Building New Worlds",
      },
    ],
  },
  {
    id: "fieldwork",
    name: "fieldwork",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo_momon_weiss",
        order: 2.2,
        author: "Tiffany Momon, Jill DiMassimo, and Joanna Weiss",
        authorShort: "Momon, DiMassimo & Weiss",
        title:
          "Interview with Dr. Tiffany Momon: Public Historian, BCDA Founder, and MACR Teaching Fellow",
      },
      {
        pubId: "dimassimo_weiss",
        id: "perron-feller",
        order: 5.2,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Material Encounters: A Moment of Bringing Black Craft History into the Present through Fieldwork",
      },
    ],
  },
  {
    id: "future",
    name: "future",
    articles: [
      {
        pubId: "tom",
        id: "epigraph",
        order: 3,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Epigraph",
      },
      {
        pubId: "tom",
        id: "coalesce",
        order: 7,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Coalesce",
      },
      {
        pubId: "tom",
        id: "ecology",
        order: 8,
        author: "Anna Lowenhaupt Tsing",
        authorShort: "Tsing",
        title: "Ecology",
      },
      {
        pubId: "tom",
        id: "ghost",
        order: 12,
        author: "Sara Clugage",
        authorShort: "Clugage",
        title: "Ghost",
      },
      {
        pubId: "tom",
        id: "grave",
        order: 13,
        author: "Jarrett M. Drake",
        authorShort: "Drake",
        title: "Grave",
      },
      {
        pubId: "tom",
        id: "lifespan",
        order: 15,
        author: "Paul Benzon and Future Library",
        authorShort: "Benzon & Future Library",
        title: "Lifespan",
      },
      {
        pubId: "tom",
        id: "predict",
        order: 23,
        author: "Andres Payan Estrada",
        authorShort: "Estrada",
        title: "Predict",
      },
      {
        pubId: "tom",
        id: "publication",
        order: 24,
        author: "Ben Lignel",
        authorShort: "Lignel",
        title: "Publication",
      },
      {
        pubId: "tom",
        id: "queer",
        order: 25,
        author: "matt lambert",
        authorShort: "lambert",
        title: "Queer",
      },
      {
        pubId: "tom",
        id: "repair",
        order: 28,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Repair",
      },
      {
        pubId: "tom",
        id: "souvenir",
        order: 30,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Souvenir",
      },
      {
        pubId: "tom",
        id: "speculate",
        order: 31,
        author: "Tom Martin",
        authorShort: "Martin",
        title: "Speculate",
      },
      {
        pubId: "tom",
        id: "talisman",
        order: 33,
        author: "Kasey Smith",
        authorShort: "Smith",
        title: "Talisman",
      },
      {
        pubId: "tom",
        id: "eulogy",
        order: 35,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Eulogy",
      },
    ],
  },
  {
    id: "healing",
    name: "healing",
    articles: [
      {
        pubId: "devlin",
        id: "calderoanddevlin",
        order: 6,
        author: "Alexis Rosa Caldero & Miriam Devlin",
        authorShort: "Caldero & Devlin",
        title:
          "Conversation with Alexis Rosa Caldero: Wooden Chairs for Healing",
      },
      {
        pubId: "notaretreat",
        id: "keith",
        order: 13,
        author: "Jeffrey A. Keith",
        authorShort: "Keith",
        title: "Emplaced Remembrance—Thinking about Gone Worlds Always Nearby",
      },
    ],
  },
  {
    id: "heritage",
    name: "heritage",
    articles: [
      {
        pubId: "perron-feller",
        id: "johnson",
        order: 5,
        author: "Emily Johnson",
        authorShort: "Johnson",
        title: "Surgent Love ",
      },
      {
        pubId: "tom",
        id: "ancestry",
        order: 4,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Ancestry",
      },
      {
        pubId: "tom",
        id: "archive",
        order: 5,
        author: "Namita Gupta Wiggers",
        authorShort: "Wiggers",
        title: "Archive",
      },
      {
        pubId: "tom",
        id: "legacy",
        order: 14,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Legacy",
      },
      {
        pubId: "tom",
        id: "lifespan",
        order: 15,
        author: "Paul Benzon and Future Library",
        authorShort: "Benzon & Future Library",
        title: "Lifespan",
      },
      {
        pubId: "tom",
        id: "offering",
        order: 20,
        author: "Phoebe Cheng",
        authorShort: "Cheng",
        title: "Offering",
      },
      {
        pubId: "tom",
        id: "renewal",
        order: 27,
        author: "Brian Potter",
        authorShort: "Potter",
        title: "Renewal",
      },
      {
        pubId: "tom",
        id: "repair",
        order: 28,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Repair",
      },
      {
        pubId: "tom",
        id: "resolve",
        order: 29,
        author: "Jill DiMassimo",
        authorShort: "DiMassimo",
        title: "Resolve",
      },
      {
        pubId: "tom",
        id: "talisman",
        order: 33,
        author: "Kasey Smith",
        authorShort: "Smith",
        title: "Talisman",
      },
    ],
  },
  {
    id: "identity",
    name: "identity",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo",
        order: 4.1,
        author: "Jill DiMassimo",
        authorShort: "DiMassimo",
        title: "Mapping Commeraw’s Jar",
      },
      {
        pubId: "dimassimo_weiss",
        id: "hand",
        order: 4.2,
        author: "Jennifer Hand",
        authorShort: "Hand",
        title:
          "Made to Order: Edmonia Lewis and Repercussions of the Tourist Trade on a Sculptural Practice",
      },
      {
        pubId: "hand",
        id: "hall",
        order: 2,
        author: "Stuart Hall",
        authorShort: "Hall",
        title: "Minimal Selves",
      },
      {
        pubId: "hand",
        id: "josselson",
        order: 5,
        author: "Ruthellen Josselson",
        authorShort: "Josselson",
        title:
          "On Writing Other People’s Lives: Self-analytic Reflections of a Narrative Researcher",
      },
    ],
  },
  {
    id: "imaginedfutures",
    name: "imagined futures",
    articles: [
      {
        pubId: "hand",
        id: "vowel",
        order: 8,
        author: "Chelsea Vowel",
        authorShort: "Vowel",
        title: "Writing Toward a Definition of Indigenous Futurism",
      },
      {
        pubId: "notaretreat",
        id: "introduction",
        order: 1,
        author: "Ben Lignel and Namita Gupta Wiggers",
        authorShort: "Lignel & Wiggers",
        title: "Introduction",
      },
      {
        pubId: "notaretreat",
        id: "momon",
        order: 15,
        author: "Tiffany Momon",
        authorShort: "Momon",
        title: "People, Get Ready: Building New Worlds",
      },
      {
        pubId: "notaretreat",
        id: "helgeson",
        order: 16,
        author: "Anna Helgeson",
        authorShort: "Helgeson",
        title: "Craft, Nothing, and Nowhere",
      },
      {
        pubId: "notaretreat",
        id: "martin",
        order: 17,
        author: "Tom Martin",
        authorShort: "Martin",
        title: "What to Do When You Are Dead: Craft and Existential Anxiety ",
      },
      {
        pubId: "notaretreat",
        id: "murray",
        order: 18,
        author: "Kevin Murray",
        authorShort: "Murray",
        title: "Why the Question?",
      },
      {
        pubId: "notaretreat",
        id: "delosreyes",
        order: 19,
        author: "Jen Delos Reyes",
        authorShort: "Delos Reyes",
        title:
          "Future Desire Lines: Cultivating Paths of Resistance, Support, and Joy",
      },
      {
        pubId: "perron-feller",
        id: "foreword",
        order: 1,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title: "Foreword",
      },
    ],
  },
  {
    id: "indigenousknowledge",
    name: "indigenous knowledge",
    articles: [
      {
        pubId: "devlin",
        id: "wallkimmerer",
        order: 2,
        author: "Robin Wall Kimmerer",
        authorShort: "Kimmerer",
        title: "Wisgaak Gokpenagen: A Black Ash Basket",
      },
      {
        pubId: "dimassimo_weiss",
        id: "hand",
        order: 4.2,
        author: "Jennifer Hand",
        authorShort: "Hand",
        title:
          "Made to Order: Edmonia Lewis and Repercussions of the Tourist Trade on a Sculptural Practice",
      },
      {
        pubId: "hand",
        id: "vowel",
        order: 8,
        author: "Chelsea Vowel",
        authorShort: "Vowel",
        title: "Writing Toward a Definition of Indigenous Futurism",
      },
      {
        pubId: "notaretreat",
        id: "lignel",
        order: 9,
        author: "Ben Lignel",
        authorShort: "Lignel",
        title:
          "Teaching and Unlearning—Or How to Write Wall Texts: Lessons of Decolonial Thinking",
      },
      {
        pubId: "notaretreat",
        id: "fisher",
        order: 21,
        author: "Michelle Millar Fisher",
        authorShort: "Fisher",
        title: "Who writes craft histories?",
      },
      {
        pubId: "perron-feller",
        id: "wallkimmerer",
        order: 2,
        author: "Robin Wall Kimmerer",
        authorShort: "Kimmerer",
        title: '"Asters and Goldenrod," from Braiding Sweetgrass',
      },
      {
        pubId: "perron-feller",
        id: "johnson",
        order: 5,
        author: "Emily Johnson",
        authorShort: "Johnson",
        title: "Surgent Love ",
      },
      {
        pubId: "tom",
        id: "archive",
        order: 5,
        author: "Namita Gupta Wiggers",
        authorShort: "Wiggers",
        title: "Archive",
      },
    ],
  },
  {
    id: "indigenousstudies",
    name: "indigenous studies",
    articles: [
      {
        pubId: "hand",
        id: "vowel",
        order: 8,
        author: "Chelsea Vowel",
        authorShort: "Vowel",
        title: "Writing Toward a Definition of Indigenous Futurism",
      },
      {
        pubId: "notaretreat",
        id: "keith",
        order: 13,
        author: "Jeffrey A. Keith",
        authorShort: "Keith",
        title: "Emplaced Remembrance—Thinking about Gone Worlds Always Nearby",
      },
    ],
  },
  {
    id: "interdisciplinarity",
    name: "interdisciplinarity",
    articles: [
      {
        pubId: "notaretreat",
        id: "razdan",
        order: 14,
        author: "Anjula Razdan",
        authorShort: "Razdan",
        title: "Unearthing the Craftscape",
      },
      {
        pubId: "perron-feller",
        id: "foreword",
        order: 1,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title: "Foreword",
      },
      {
        pubId: "tom",
        id: "foreword",
        order: 1,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Foreword",
      },
      {
        pubId: "tom",
        id: "liminal",
        order: 16,
        author: "Alex Singh",
        authorShort: "Singh",
        title: "Liminal",
      },
      {
        pubId: "wiltsie",
        id: "foreword",
        order: 1,
        author: "Tina Wiltsie",
        authorShort: "Wiltsie",
        title: "Foreword",
      },
      {
        pubId: "wiltsie",
        id: "artistpanel",
        order: 4,
        author:
          "Molly Hatch, Kevin McNamee-Tweed, Mariko Paterson & Tina Wiltsie",
        authorShort: "Hatch, McNamee-Tweed, Paterson, & Wiltsie",
        title:
          "History Surrounds Us: A Conversation with Mariko Paterson, Molly Hatch, and Kevin McNamee-Tweed",
      },
      {
        pubId: "wiltsie",
        id: "bibliography",
        order: 5,
        author: "Tina Wiltsie",
        authorShort: "Wiltsie",
        title: "Gathering Raw Materials: A Bibliography of Suggested Readings",
      },
    ],
  },
  {
    id: "interview",
    name: "interview",
    articles: [
      {
        pubId: "devlin",
        id: "calderoanddevlin",
        order: 6,
        author: "Alexis Rosa Caldero & Miriam Devlin",
        authorShort: "Caldero & Devlin",
        title:
          "Conversation with Alexis Rosa Caldero: Wooden Chairs for Healing",
      },
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo_weiss_wiggers",
        order: 2.1,
        author: "Namita Gupta Wiggers, Jill DiMassimo, and Joanna Weiss",
        authorShort: "Wiggers, DiMassimo & Weiss",
        title:
          "Interview with Namita Gupta Wiggers: A Focused Oral History on the MACR Creation",
      },
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo_momon_weiss",
        order: 2.2,
        author: "Tiffany Momon, Jill DiMassimo, and Joanna Weiss",
        authorShort: "Momon, DiMassimo & Weiss",
        title:
          "Interview with Dr. Tiffany Momon: Public Historian, BCDA Founder, and MACR Teaching Fellow",
      },
      {
        pubId: "hand",
        id: "joyce",
        order: 3,
        author: "Jennifer Hand",
        authorShort: "Hand",
        title: "Joyce Scott: Hard Truth in Beauty",
      },
      {
        pubId: "notaretreat",
        id: "jarrett_wiggers",
        order: 3,
        author: "Lisa Jarrett and Namita Gupta Wiggers",
        authorShort: "Jarrett & Wiggers",
        title:
          "Teaching Questions: Lisa Jarrett and Namita Gupta Wiggers in Conversation",
      },
      {
        pubId: "notaretreat",
        id: "lignel_wiggers",
        order: 11,
        author: "Ben Lignel and Namita Gupta Wiggers",
        authorShort: "Lignel & Wiggers",
        title:
          "Designing the Plane as We Flew It—A Discussion on the MACR Program Publications",
      },
      {
        pubId: "perron-feller",
        id: "denherder",
        order: 3,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Ecology of Built Environments: An Interview with Structural Engineer Justin Den Herder",
      },
      {
        pubId: "perron-feller",
        id: "buckert",
        order: 4,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Constructing an Interdisciplinary Life: An Oral History with Morgan Buckert",
      },
      {
        pubId: "wiltsie",
        id: "mitchell_wiltsie",
        order: 2,
        author: "W. J. T. Mitchell & Tina Wiltsie",
        authorShort: "Mitchell & Wiltsie",
        title:
          "From Metapictures to Metacraft? An Interview with W. J. T. Mitchell",
      },
      {
        pubId: "wiltsie",
        id: "artistpanel",
        order: 4,
        author:
          "Molly Hatch, Kevin McNamee-Tweed, Mariko Paterson & Tina Wiltsie",
        authorShort: "Hatch, McNamee-Tweed, Paterson, & Wiltsie",
        title:
          "History Surrounds Us: A Conversation with Mariko Paterson, Molly Hatch, and Kevin McNamee-Tweed",
      },
    ],
  },
  {
    id: "introduction",
    name: "introduction",
    articles: [
      {
        pubId: "devlin",
        id: "editorsletter",
        order: 1,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Editor's Letter",
      },
      {
        pubId: "dimassimo_weiss",
        id: "editornote",
        order: 1,
        author: "Jill DiMassimo & Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Making Room, Making Connections, Making History: Editors' Note",
      },
      {
        pubId: "dimassimo_weiss",
        id: "approach",
        order: 2,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Approach",
      },
      {
        pubId: "dimassimo_weiss",
        id: "absence",
        order: 3,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Absence",
      },
      {
        pubId: "dimassimo_weiss",
        id: "presence",
        order: 4,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Presence",
      },
      {
        pubId: "dimassimo_weiss",
        id: "materials",
        order: 5,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Materials",
      },
      {
        pubId: "dimassimo_weiss",
        id: "making",
        order: 6,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Making",
      },
      {
        pubId: "hand",
        id: "foreword",
        order: 1,
        author: "Jennifer Hand",
        authorShort: "Hand",
        title: "Foreword",
      },
      {
        pubId: "notaretreat",
        id: "introduction",
        order: 1,
        author: "Ben Lignel and Namita Gupta Wiggers",
        authorShort: "Lignel & Wiggers",
        title: "Introduction",
      },
      {
        pubId: "perron-feller",
        id: "foreword",
        order: 1,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title: "Foreword",
      },
      {
        pubId: "tom",
        id: "foreword",
        order: 1,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Foreword",
      },
      {
        pubId: "wiltsie",
        id: "foreword",
        order: 1,
        author: "Tina Wiltsie",
        authorShort: "Wiltsie",
        title: "Foreword",
      },
    ],
  },
  {
    id: "invitation",
    name: "invitation",
    articles: [
      {
        pubId: "notaretreat",
        id: "delosreyes",
        order: 19,
        author: "Jen Delos Reyes",
        authorShort: "Delos Reyes",
        title:
          "Future Desire Lines: Cultivating Paths of Resistance, Support, and Joy",
      },
      {
        pubId: "notaretreat",
        id: "fisher",
        order: 21,
        author: "Michelle Millar Fisher",
        authorShort: "Fisher",
        title: "Who writes craft histories?",
      },
    ],
  },
  {
    id: "knowledgeclassification",
    name: "knowledge classification",
    articles: [
      {
        pubId: "notaretreat",
        id: "lignel",
        order: 9,
        author: "Ben Lignel",
        authorShort: "Lignel",
        title:
          "Teaching and Unlearning—Or How to Write Wall Texts: Lessons of Decolonial Thinking",
      },
      {
        pubId: "notaretreat",
        id: "levine",
        order: 10,
        author: "Faythe Levine",
        authorShort: "Levine",
        title: "The Dotted Line (Desktop Version)",
      },
      {
        pubId: "tom",
        id: "foreword",
        order: 1,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Foreword",
      },
      {
        pubId: "tom",
        id: "evolve",
        order: 11,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Evolve",
      },
      {
        pubId: "tom",
        id: "map",
        order: 18,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Map",
      },
    ],
  },
  {
    id: "labor",
    name: "labor",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "editornote",
        order: 1,
        author: "Jill DiMassimo & Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Making Room, Making Connections, Making History: Editors' Note",
      },
      {
        pubId: "dimassimo_weiss",
        id: "goodman",
        order: 3.2,
        author: "Mellanee Goodman",
        authorShort: "Goodman",
        title: "Reflections on “In the Fray”",
      },
      {
        pubId: "notaretreat",
        id: "meissner",
        order: 2,
        author: "Amy Meissner",
        authorShort: "Meissner",
        title: "But First, Learn This (A List)",
      },
      {
        pubId: "notaretreat",
        id: "keith",
        order: 13,
        author: "Jeffrey A. Keith",
        authorShort: "Keith",
        title: "Emplaced Remembrance—Thinking about Gone Worlds Always Nearby",
      },
      {
        pubId: "tom",
        id: "palimpsest",
        order: 21,
        author: "Indira Allegra",
        authorShort: "Allegra",
        title: "Palimpsest",
      },
    ],
  },
  {
    id: "land",
    name: "land",
    articles: [
      {
        pubId: "notaretreat",
        id: "keith",
        order: 13,
        author: "Jeffrey A. Keith",
        authorShort: "Keith",
        title: "Emplaced Remembrance—Thinking about Gone Worlds Always Nearby",
      },
      {
        pubId: "notaretreat",
        id: "razdan",
        order: 14,
        author: "Anjula Razdan",
        authorShort: "Razdan",
        title: "Unearthing the Craftscape",
      },
      {
        pubId: "perron-feller",
        id: "denherder",
        order: 3,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Ecology of Built Environments: An Interview with Structural Engineer Justin Den Herder",
      },
      {
        pubId: "perron-feller",
        id: "johnson",
        order: 5,
        author: "Emily Johnson",
        authorShort: "Johnson",
        title: "Surgent Love ",
      },
    ],
  },
  {
    id: "learningwithobjects",
    name: "learning with objects",
    articles: [
      {
        pubId: "notaretreat",
        id: "leemann",
        order: 5,
        author: "Judith Leemann",
        authorShort: "Leemann",
        title: "towards tending not knowing",
      },
      {
        pubId: "notaretreat",
        id: "skov",
        order: 8,
        author: "Mara Holt Skov",
        authorShort: "Skov",
        title: "Reflections on Teaching and Learning with the Bowl",
      },
      {
        pubId: "notaretreat",
        id: "lignel",
        order: 9,
        author: "Ben Lignel",
        authorShort: "Lignel",
        title:
          "Teaching and Unlearning—Or How to Write Wall Texts: Lessons of Decolonial Thinking",
      },
      {
        pubId: "wiltsie",
        id: "gallery",
        order: 3,
        author: "Tina Wiltsie",
        authorShort: "Wiltsie",
        title: "Enduring Form: Objects Depicting Ceramics across Time",
      },
    ],
  },
  {
    id: "macrprogram",
    name: "macr program",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "editornote",
        order: 1,
        author: "Jill DiMassimo & Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Making Room, Making Connections, Making History: Editors' Note",
      },
      {
        pubId: "dimassimo_weiss",
        id: "approach",
        order: 2,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Approach",
      },
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo_weiss_wiggers",
        order: 2.1,
        author: "Namita Gupta Wiggers, Jill DiMassimo, and Joanna Weiss",
        authorShort: "Wiggers, DiMassimo & Weiss",
        title:
          "Interview with Namita Gupta Wiggers: A Focused Oral History on the MACR Creation",
      },
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo_momon_weiss",
        order: 2.2,
        author: "Tiffany Momon, Jill DiMassimo, and Joanna Weiss",
        authorShort: "Momon, DiMassimo & Weiss",
        title:
          "Interview with Dr. Tiffany Momon: Public Historian, BCDA Founder, and MACR Teaching Fellow",
      },
      {
        pubId: "dimassimo_weiss",
        id: "perron-feller",
        order: 5.2,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Material Encounters: A Moment of Bringing Black Craft History into the Present through Fieldwork",
      },
      {
        pubId: "notaretreat",
        id: "jarrett_wiggers",
        order: 3,
        author: "Lisa Jarrett and Namita Gupta Wiggers",
        authorShort: "Jarrett & Wiggers",
        title:
          "Teaching Questions: Lisa Jarrett and Namita Gupta Wiggers in Conversation",
      },
      {
        pubId: "notaretreat",
        id: "lignel_wiggers",
        order: 11,
        author: "Ben Lignel and Namita Gupta Wiggers",
        authorShort: "Lignel & Wiggers",
        title:
          "Designing the Plane as We Flew It—A Discussion on the MACR Program Publications",
      },
      {
        pubId: "tom",
        id: "acknowledgments",
        order: 2,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Acknowledgments",
      },
      {
        pubId: "wiltsie",
        id: "foreword",
        order: 1,
        author: "Tina Wiltsie",
        authorShort: "Wiltsie",
        title: "Foreword",
      },
      {
        pubId: "wiltsie",
        id: "bibliography",
        order: 5,
        author: "Tina Wiltsie",
        authorShort: "Wiltsie",
        title: "Gathering Raw Materials: A Bibliography of Suggested Readings",
      },
    ],
  },
  {
    id: "makingspace",
    name: "making space",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo_weiss_wiggers",
        order: 2.1,
        author: "Namita Gupta Wiggers, Jill DiMassimo, and Joanna Weiss",
        authorShort: "Wiggers, DiMassimo & Weiss",
        title:
          "Interview with Namita Gupta Wiggers: A Focused Oral History on the MACR Creation",
      },
      {
        pubId: "notaretreat",
        id: "jarrett_wiggers",
        order: 3,
        author: "Lisa Jarrett and Namita Gupta Wiggers",
        authorShort: "Jarrett & Wiggers",
        title:
          "Teaching Questions: Lisa Jarrett and Namita Gupta Wiggers in Conversation",
      },
      {
        pubId: "notaretreat",
        id: "acierto",
        order: 4,
        author: "alejandro t. acierto",
        authorShort: "acierto",
        title:
          "In the After-Thought: Teaching Transparency and the Ongoing Practice of Freedom",
      },
      {
        pubId: "notaretreat",
        id: "potter",
        order: 6,
        author: "Melissa Potter",
        authorShort: "Potter",
        title: "Pandemic Papermaking: Critical Craft",
      },
      {
        pubId: "notaretreat",
        id: "razdan",
        order: 14,
        author: "Anjula Razdan",
        authorShort: "Razdan",
        title: "Unearthing the Craftscape",
      },
      {
        pubId: "notaretreat",
        id: "helgeson",
        order: 16,
        author: "Anna Helgeson",
        authorShort: "Helgeson",
        title: "Craft, Nothing, and Nowhere",
      },
      {
        pubId: "notaretreat",
        id: "delosreyes",
        order: 19,
        author: "Jen Delos Reyes",
        authorShort: "Delos Reyes",
        title:
          "Future Desire Lines: Cultivating Paths of Resistance, Support, and Joy",
      },
      {
        pubId: "tom",
        id: "archive",
        order: 5,
        author: "Namita Gupta Wiggers",
        authorShort: "Wiggers",
        title: "Archive",
      },
    ],
  },
  {
    id: "mapping",
    name: "mapping",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo",
        order: 4.1,
        author: "Jill DiMassimo",
        authorShort: "DiMassimo",
        title: "Mapping Commeraw’s Jar",
      },
      {
        pubId: "notaretreat",
        id: "lignel_wiggers",
        order: 11,
        author: "Ben Lignel and Namita Gupta Wiggers",
        authorShort: "Lignel & Wiggers",
        title:
          "Designing the Plane as We Flew It—A Discussion on the MACR Program Publications",
      },
      {
        pubId: "notaretreat",
        id: "wilder",
        order: 12,
        author: "Melanie Wilder",
        authorShort: "wilder",
        title: "Weaving Community Together",
      },
      {
        pubId: "tom",
        id: "legacy",
        order: 14,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Legacy",
      },
      {
        pubId: "tom",
        id: "map",
        order: 18,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Map",
      },
      {
        pubId: "wiltsie",
        id: "gallery",
        order: 3,
        author: "Tina Wiltsie",
        authorShort: "Wiltsie",
        title: "Enduring Form: Objects Depicting Ceramics across Time",
      },
    ],
  },
  {
    id: "materialknowledge",
    name: "material knowledge",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "perron-feller",
        order: 5.2,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Material Encounters: A Moment of Bringing Black Craft History into the Present through Fieldwork",
      },
      {
        pubId: "dimassimo_weiss",
        id: "making",
        order: 6,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Making",
      },
      {
        pubId: "dimassimo_weiss",
        id: "devlin",
        order: 6.1,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Exceptional, Typical, Chairs by Dick Poynor",
      },
      {
        pubId: "dimassimo_weiss",
        id: "awake_ryland",
        order: 6.2,
        author: "Robell Awake and Charlie Ryland",
        authorShort: "Awake & Ryland",
        title: "Combatting Erasure",
      },
      {
        pubId: "wiltsie",
        id: "artistpanel",
        order: 4,
        author:
          "Molly Hatch, Kevin McNamee-Tweed, Mariko Paterson & Tina Wiltsie",
        authorShort: "Hatch, McNamee-Tweed, Paterson, & Wiltsie",
        title:
          "History Surrounds Us: A Conversation with Mariko Paterson, Molly Hatch, and Kevin McNamee-Tweed",
      },
    ],
  },
  {
    id: "materiality",
    name: "materiality",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "materials",
        order: 5,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Materials",
      },
      {
        pubId: "dimassimo_weiss",
        id: "weiss",
        order: 5.1,
        author: "Joanna Weiss",
        authorShort: "Weiss",
        title:
          "Cowrie Shells: Movement and Connections through African American Material Culture",
      },
      {
        pubId: "dimassimo_weiss",
        id: "perron-feller",
        order: 5.2,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Material Encounters: A Moment of Bringing Black Craft History into the Present through Fieldwork",
      },
      {
        pubId: "perron-feller",
        id: "buckert",
        order: 4,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Constructing an Interdisciplinary Life: An Oral History with Morgan Buckert",
      },
      {
        pubId: "tom",
        id: "ghost",
        order: 12,
        author: "Sara Clugage",
        authorShort: "Clugage",
        title: "Ghost",
      },
      {
        pubId: "wiltsie",
        id: "artistpanel",
        order: 4,
        author:
          "Molly Hatch, Kevin McNamee-Tweed, Mariko Paterson & Tina Wiltsie",
        authorShort: "Hatch, McNamee-Tweed, Paterson, & Wiltsie",
        title:
          "History Surrounds Us: A Conversation with Mariko Paterson, Molly Hatch, and Kevin McNamee-Tweed",
      },
    ],
  },
  {
    id: "memory",
    name: "memory",
    articles: [
      {
        pubId: "notaretreat",
        id: "keith",
        order: 13,
        author: "Jeffrey A. Keith",
        authorShort: "Keith",
        title: "Emplaced Remembrance—Thinking about Gone Worlds Always Nearby",
      },
      {
        pubId: "tom",
        id: "ancestry",
        order: 4,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Ancestry",
      },
      {
        pubId: "tom",
        id: "archive",
        order: 5,
        author: "Namita Gupta Wiggers",
        authorShort: "Wiggers",
        title: "Archive",
      },
      {
        pubId: "tom",
        id: "enchant",
        order: 10,
        author: "Karen Wilson Baptist",
        authorShort: "Baptist",
        title: "Enchant",
      },
      {
        pubId: "tom",
        id: "memorial",
        order: 19,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Memorial",
      },
      {
        pubId: "tom",
        id: "queer",
        order: 25,
        author: "matt lambert",
        authorShort: "lambert",
        title: "Queer",
      },
      {
        pubId: "tom",
        id: "souvenir",
        order: 30,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Souvenir",
      },
      {
        pubId: "tom",
        id: "eulogy",
        order: 35,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Eulogy",
      },
    ],
  },
  {
    id: "methodology",
    name: "methodology",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "editornote",
        order: 1,
        author: "Jill DiMassimo & Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Making Room, Making Connections, Making History: Editors' Note",
      },
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo_momon_weiss",
        order: 2.2,
        author: "Tiffany Momon, Jill DiMassimo, and Joanna Weiss",
        authorShort: "Momon, DiMassimo & Weiss",
        title:
          "Interview with Dr. Tiffany Momon: Public Historian, BCDA Founder, and MACR Teaching Fellow",
      },
      {
        pubId: "hand",
        id: "foreword",
        order: 1,
        author: "Jennifer Hand",
        authorShort: "Hand",
        title: "Foreword",
      },
      {
        pubId: "notaretreat",
        id: "razdan",
        order: 14,
        author: "Anjula Razdan",
        authorShort: "Razdan",
        title: "Unearthing the Craftscape",
      },
      {
        pubId: "notaretreat",
        id: "murray",
        order: 18,
        author: "Kevin Murray",
        authorShort: "Murray",
        title: "Why the Question?",
      },
      {
        pubId: "tom",
        id: "ancestry",
        order: 4,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Ancestry",
      },
      {
        pubId: "tom",
        id: "archive",
        order: 5,
        author: "Namita Gupta Wiggers",
        authorShort: "Wiggers",
        title: "Archive",
      },
      {
        pubId: "wiltsie",
        id: "foreword",
        order: 1,
        author: "Tina Wiltsie",
        authorShort: "Wiltsie",
        title: "Foreword",
      },
      {
        pubId: "wiltsie",
        id: "mitchell_wiltsie",
        order: 2,
        author: "W. J. T. Mitchell & Tina Wiltsie",
        authorShort: "Mitchell & Wiltsie",
        title:
          "From Metapictures to Metacraft? An Interview with W. J. T. Mitchell",
      },
      {
        pubId: "wiltsie",
        id: "bibliography",
        order: 5,
        author: "Tina Wiltsie",
        authorShort: "Wiltsie",
        title: "Gathering Raw Materials: A Bibliography of Suggested Readings",
      },
    ],
  },
  {
    id: "movement",
    name: "movement",
    articles: [
      {
        pubId: "devlin",
        id: "kuo",
        order: 5,
        author: "Phoebe Kuo",
        authorShort: "Kuo",
        title: "Swimming Ethnography",
      },
      {
        pubId: "devlin",
        id: "bernsteinanddevlin",
        order: 7,
        author: "Blake Bernstein & Trieste Devlin",
        authorShort: "Bernstein & Devlin",
        title: "On Manga",
      },
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo",
        order: 4.1,
        author: "Jill DiMassimo",
        authorShort: "DiMassimo",
        title: "Mapping Commeraw’s Jar",
      },
      {
        pubId: "wiltsie",
        id: "gallery",
        order: 3,
        author: "Tina Wiltsie",
        authorShort: "Wiltsie",
        title: "Enduring Form: Objects Depicting Ceramics across Time",
      },
    ],
  },
  {
    id: "museumlabels",
    name: "museum labels",
    articles: [
      {
        pubId: "notaretreat",
        id: "skov",
        order: 8,
        author: "Mara Holt Skov",
        authorShort: "Skov",
        title: "Reflections on Teaching and Learning with the Bowl",
      },
      {
        pubId: "notaretreat",
        id: "lignel",
        order: 9,
        author: "Ben Lignel",
        authorShort: "Lignel",
        title:
          "Teaching and Unlearning—Or How to Write Wall Texts: Lessons of Decolonial Thinking",
      },
      {
        pubId: "wiltsie",
        id: "gallery",
        order: 3,
        author: "Tina Wiltsie",
        authorShort: "Wiltsie",
        title: "Enduring Form: Objects Depicting Ceramics across Time",
      },
    ],
  },
  {
    id: "newwriting",
    name: "new writing",
    articles: [
      {
        pubId: "devlin",
        id: "editorsletter",
        order: 1,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Editor's Letter",
      },
      {
        pubId: "devlin",
        id: "devlin",
        order: 4,
        author: "Trieste Devlin",
        authorShort: "Devlin",
        title: "On Frames",
      },
      {
        pubId: "devlin",
        id: "calderoanddevlin",
        order: 6,
        author: "Alexis Rosa Caldero & Miriam Devlin",
        authorShort: "Caldero & Devlin",
        title:
          "Conversation with Alexis Rosa Caldero: Wooden Chairs for Healing",
      },
      {
        pubId: "devlin",
        id: "bernsteinanddevlin",
        order: 7,
        author: "Blake Bernstein & Trieste Devlin",
        authorShort: "Bernstein & Devlin",
        title: "On Manga",
      },
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo_weiss_wiggers",
        order: 2.1,
        author: "Namita Gupta Wiggers, Jill DiMassimo, and Joanna Weiss",
        authorShort: "Wiggers, DiMassimo & Weiss",
        title:
          "Interview with Namita Gupta Wiggers: A Focused Oral History on the MACR Creation",
      },
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo_momon_weiss",
        order: 2.2,
        author: "Tiffany Momon, Jill DiMassimo, and Joanna Weiss",
        authorShort: "Momon, DiMassimo & Weiss",
        title:
          "Interview with Dr. Tiffany Momon: Public Historian, BCDA Founder, and MACR Teaching Fellow",
      },
      {
        pubId: "hand",
        id: "foreword",
        order: 1,
        author: "Jennifer Hand",
        authorShort: "Hand",
        title: "Foreword",
      },
    ],
  },
  {
    id: "newwritingalumni",
    name: "new writing (alumni)",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "hatch",
        order: 3.1,
        author: "Michael Hatch",
        authorShort: "Hatch",
        title: "Redacted",
      },
      {
        pubId: "dimassimo_weiss",
        id: "goodman",
        order: 3.2,
        author: "Mellanee Goodman",
        authorShort: "Goodman",
        title: "Reflections on “In the Fray”",
      },
      {
        pubId: "notaretreat",
        id: "meissner",
        order: 2,
        author: "Amy Meissner",
        authorShort: "Meissner",
        title: "But First, Learn This (A List)",
      },
      {
        pubId: "tom",
        id: "queer",
        order: 25,
        author: "matt lambert",
        authorShort: "lambert",
        title: "Queer",
      },
    ],
  },
  {
    id: "newwritingcontributor",
    name: "new writing (contributor)",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "awake_ryland",
        order: 6.2,
        author: "Robell Awake and Charlie Ryland",
        authorShort: "Awake & Ryland",
        title: "Combatting Erasure",
      },
      {
        pubId: "tom",
        id: "palimpsest",
        order: 21,
        author: "Indira Allegra",
        authorShort: "Allegra",
        title: "Palimpsest",
      },
      {
        pubId: "tom",
        id: "talisman",
        order: 33,
        author: "Kasey Smith",
        authorShort: "Smith",
        title: "Talisman",
      },
    ],
  },
  {
    id: "newwritingfaculty",
    name: "new writing (faculty)",
    articles: [
      {
        pubId: "notaretreat",
        id: "introduction",
        order: 1,
        author: "Ben Lignel and Namita Gupta Wiggers",
        authorShort: "Lignel & Wiggers",
        title: "Introduction",
      },
      {
        pubId: "notaretreat",
        id: "jarrett_wiggers",
        order: 3,
        author: "Lisa Jarrett and Namita Gupta Wiggers",
        authorShort: "Jarrett & Wiggers",
        title:
          "Teaching Questions: Lisa Jarrett and Namita Gupta Wiggers in Conversation",
      },
      {
        pubId: "notaretreat",
        id: "acierto",
        order: 4,
        author: "alejandro t. acierto",
        authorShort: "acierto",
        title:
          "In the After-Thought: Teaching Transparency and the Ongoing Practice of Freedom",
      },
      {
        pubId: "notaretreat",
        id: "leemann",
        order: 5,
        author: "Judith Leemann",
        authorShort: "Leemann",
        title: "towards tending not knowing",
      },
      {
        pubId: "notaretreat",
        id: "potter",
        order: 6,
        author: "Melissa Potter",
        authorShort: "Potter",
        title: "Pandemic Papermaking: Critical Craft",
      },
      {
        pubId: "notaretreat",
        id: "clugage",
        order: 7,
        author: "Sara Clugage",
        authorShort: "Clugage",
        title: "This Is Not a Food Studies Program",
      },
      {
        pubId: "notaretreat",
        id: "skov",
        order: 8,
        author: "Mara Holt Skov",
        authorShort: "Skov",
        title: "Reflections on Teaching and Learning with the Bowl",
      },
      {
        pubId: "notaretreat",
        id: "lignel",
        order: 9,
        author: "Ben Lignel",
        authorShort: "Lignel",
        title:
          "Teaching and Unlearning—Or How to Write Wall Texts: Lessons of Decolonial Thinking",
      },
      {
        pubId: "notaretreat",
        id: "levine",
        order: 10,
        author: "Faythe Levine",
        authorShort: "Levine",
        title: "The Dotted Line (Desktop Version)",
      },
      {
        pubId: "notaretreat",
        id: "lignel_wiggers",
        order: 11,
        author: "Ben Lignel and Namita Gupta Wiggers",
        authorShort: "Lignel & Wiggers",
        title:
          "Designing the Plane as We Flew It—A Discussion on the MACR Program Publications",
      },
      {
        pubId: "notaretreat",
        id: "wilder",
        order: 12,
        author: "Melanie Wilder",
        authorShort: "wilder",
        title: "Weaving Community Together",
      },
      {
        pubId: "notaretreat",
        id: "keith",
        order: 13,
        author: "Jeffrey A. Keith",
        authorShort: "Keith",
        title: "Emplaced Remembrance—Thinking about Gone Worlds Always Nearby",
      },
      {
        pubId: "notaretreat",
        id: "momon",
        order: 15,
        author: "Tiffany Momon",
        authorShort: "Momon",
        title: "People, Get Ready: Building New Worlds",
      },
      {
        pubId: "notaretreat",
        id: "helgeson",
        order: 16,
        author: "Anna Helgeson",
        authorShort: "Helgeson",
        title: "Craft, Nothing, and Nowhere",
      },
      {
        pubId: "notaretreat",
        id: "martin",
        order: 17,
        author: "Tom Martin",
        authorShort: "Martin",
        title: "What to Do When You Are Dead: Craft and Existential Anxiety ",
      },
      {
        pubId: "notaretreat",
        id: "murray",
        order: 18,
        author: "Kevin Murray",
        authorShort: "Murray",
        title: "Why the Question?",
      },
      {
        pubId: "notaretreat",
        id: "delosreyes",
        order: 19,
        author: "Jen Delos Reyes",
        authorShort: "Delos Reyes",
        title:
          "Future Desire Lines: Cultivating Paths of Resistance, Support, and Joy",
      },
      {
        pubId: "notaretreat",
        id: "patel",
        order: 20,
        author: "Alpesh Kantilal Patel",
        authorShort: "Patel",
        title: "Worldmaking with Colleagues",
      },
      {
        pubId: "notaretreat",
        id: "fisher",
        order: 21,
        author: "Michelle Millar Fisher",
        authorShort: "Fisher",
        title: "Who writes craft histories?",
      },
      {
        pubId: "tom",
        id: "archive",
        order: 5,
        author: "Namita Gupta Wiggers",
        authorShort: "Wiggers",
        title: "Archive",
      },
      {
        pubId: "tom",
        id: "ghost",
        order: 12,
        author: "Sara Clugage",
        authorShort: "Clugage",
        title: "Ghost",
      },
      {
        pubId: "tom",
        id: "predict",
        order: 23,
        author: "Andres Payan Estrada",
        authorShort: "Estrada",
        title: "Predict",
      },
      {
        pubId: "tom",
        id: "publication",
        order: 24,
        author: "Ben Lignel",
        authorShort: "Lignel",
        title: "Publication",
      },
      {
        pubId: "tom",
        id: "speculate",
        order: 31,
        author: "Tom Martin",
        authorShort: "Martin",
        title: "Speculate",
      },
    ],
  },
  {
    id: "newwritingstudent",
    name: "new writing (student)",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "editornote",
        order: 1,
        author: "Jill DiMassimo & Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Making Room, Making Connections, Making History: Editors' Note",
      },
      {
        pubId: "dimassimo_weiss",
        id: "approach",
        order: 2,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Approach",
      },
      {
        pubId: "dimassimo_weiss",
        id: "absence",
        order: 3,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Absence",
      },
      {
        pubId: "dimassimo_weiss",
        id: "presence",
        order: 4,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Presence",
      },
      {
        pubId: "dimassimo_weiss",
        id: "materials",
        order: 5,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Materials",
      },
      {
        pubId: "dimassimo_weiss",
        id: "perron-feller",
        order: 5.2,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Material Encounters: A Moment of Bringing Black Craft History into the Present through Fieldwork",
      },
      {
        pubId: "dimassimo_weiss",
        id: "making",
        order: 6,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Making",
      },
      {
        pubId: "perron-feller",
        id: "foreword",
        order: 1,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title: "Foreword",
      },
      {
        pubId: "perron-feller",
        id: "denherder",
        order: 3,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Ecology of Built Environments: An Interview with Structural Engineer Justin Den Herder",
      },
      {
        pubId: "perron-feller",
        id: "buckert",
        order: 4,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Constructing an Interdisciplinary Life: An Oral History with Morgan Buckert",
      },
      {
        pubId: "tom",
        id: "foreword",
        order: 1,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Foreword",
      },
      {
        pubId: "tom",
        id: "coalesce",
        order: 7,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Coalesce",
      },
      {
        pubId: "tom",
        id: "educate",
        order: 9,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Educate",
      },
      {
        pubId: "tom",
        id: "evolve",
        order: 11,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Evolve",
      },
      {
        pubId: "tom",
        id: "magic",
        order: 17,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Magic",
      },
      {
        pubId: "tom",
        id: "memorial",
        order: 19,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Memorial",
      },
      {
        pubId: "tom",
        id: "portrait",
        order: 22,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Portrait",
      },
      {
        pubId: "tom",
        id: "repair",
        order: 28,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Repair",
      },
      {
        pubId: "tom",
        id: "resolve",
        order: 29,
        author: "Jill DiMassimo",
        authorShort: "DiMassimo",
        title: "Resolve",
      },
      {
        pubId: "tom",
        id: "souvenir",
        order: 30,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Souvenir",
      },
      {
        pubId: "tom",
        id: "subvert",
        order: 32,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Subvert",
      },
      {
        pubId: "tom",
        id: "eulogy",
        order: 35,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Eulogy",
      },
      {
        pubId: "wiltsie",
        id: "foreword",
        order: 1,
        author: "Tina Wiltsie",
        authorShort: "Wiltsie",
        title: "Foreword",
      },
      {
        pubId: "wiltsie",
        id: "mitchell_wiltsie",
        order: 2,
        author: "W. J. T. Mitchell & Tina Wiltsie",
        authorShort: "Mitchell & Wiltsie",
        title:
          "From Metapictures to Metacraft? An Interview with W. J. T. Mitchell",
      },
      {
        pubId: "wiltsie",
        id: "gallery",
        order: 3,
        author: "Tina Wiltsie",
        authorShort: "Wiltsie",
        title: "Enduring Form: Objects Depicting Ceramics across Time",
      },
      {
        pubId: "wiltsie",
        id: "artistpanel",
        order: 4,
        author:
          "Molly Hatch, Kevin McNamee-Tweed, Mariko Paterson & Tina Wiltsie",
        authorShort: "Hatch, McNamee-Tweed, Paterson, & Wiltsie",
        title:
          "History Surrounds Us: A Conversation with Mariko Paterson, Molly Hatch, and Kevin McNamee-Tweed",
      },
      {
        pubId: "wiltsie",
        id: "bibliography",
        order: 5,
        author: "Tina Wiltsie",
        authorShort: "Wiltsie",
        title: "Gathering Raw Materials: A Bibliography of Suggested Readings",
      },
    ],
  },
  {
    id: "objectlessness",
    name: "objectlessness",
    articles: [
      {
        pubId: "notaretreat",
        id: "helgeson",
        order: 16,
        author: "Anna Helgeson",
        authorShort: "Helgeson",
        title: "Craft, Nothing, and Nowhere",
      },
      {
        pubId: "tom",
        id: "aufhebung",
        order: 6,
        author: "Lucia Ilene Wallace",
        authorShort: "Wallace",
        title: "Aufhebung",
      },
    ],
  },
  {
    id: "oralhistory",
    name: "oral history",
    articles: [
      {
        pubId: "hand",
        id: "josselson",
        order: 5,
        author: "Ruthellen Josselson",
        authorShort: "Josselson",
        title:
          "On Writing Other People’s Lives: Self-analytic Reflections of a Narrative Researcher",
      },
      {
        pubId: "notaretreat",
        id: "keith",
        order: 13,
        author: "Jeffrey A. Keith",
        authorShort: "Keith",
        title: "Emplaced Remembrance—Thinking about Gone Worlds Always Nearby",
      },
    ],
  },
  {
    id: "pedagogy",
    name: "pedagogy",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "approach",
        order: 2,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Approach",
      },
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo_weiss_wiggers",
        order: 2.1,
        author: "Namita Gupta Wiggers, Jill DiMassimo, and Joanna Weiss",
        authorShort: "Wiggers, DiMassimo & Weiss",
        title:
          "Interview with Namita Gupta Wiggers: A Focused Oral History on the MACR Creation",
      },
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo_momon_weiss",
        order: 2.2,
        author: "Tiffany Momon, Jill DiMassimo, and Joanna Weiss",
        authorShort: "Momon, DiMassimo & Weiss",
        title:
          "Interview with Dr. Tiffany Momon: Public Historian, BCDA Founder, and MACR Teaching Fellow",
      },
      {
        pubId: "notaretreat",
        id: "jarrett_wiggers",
        order: 3,
        author: "Lisa Jarrett and Namita Gupta Wiggers",
        authorShort: "Jarrett & Wiggers",
        title:
          "Teaching Questions: Lisa Jarrett and Namita Gupta Wiggers in Conversation",
      },
      {
        pubId: "notaretreat",
        id: "acierto",
        order: 4,
        author: "alejandro t. acierto",
        authorShort: "acierto",
        title:
          "In the After-Thought: Teaching Transparency and the Ongoing Practice of Freedom",
      },
      {
        pubId: "notaretreat",
        id: "lignel_wiggers",
        order: 11,
        author: "Ben Lignel and Namita Gupta Wiggers",
        authorShort: "Lignel & Wiggers",
        title:
          "Designing the Plane as We Flew It—A Discussion on the MACR Program Publications",
      },
      {
        pubId: "notaretreat",
        id: "momon",
        order: 15,
        author: "Tiffany Momon",
        authorShort: "Momon",
        title: "People, Get Ready: Building New Worlds",
      },
      {
        pubId: "perron-feller",
        id: "foreword",
        order: 1,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title: "Foreword",
      },
      {
        pubId: "perron-feller",
        id: "wallkimmerer",
        order: 2,
        author: "Robin Wall Kimmerer",
        authorShort: "Kimmerer",
        title: '"Asters and Goldenrod," from Braiding Sweetgrass',
      },
      {
        pubId: "perron-feller",
        id: "denherder",
        order: 3,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Ecology of Built Environments: An Interview with Structural Engineer Justin Den Herder",
      },
      {
        pubId: "perron-feller",
        id: "buckert",
        order: 4,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Constructing an Interdisciplinary Life: An Oral History with Morgan Buckert",
      },
      {
        pubId: "tom",
        id: "educate",
        order: 9,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Educate",
      },
      {
        pubId: "tom",
        id: "eulogy",
        order: 35,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Eulogy",
      },
    ],
  },
  {
    id: "performance",
    name: "performance",
    articles: [
      {
        pubId: "notaretreat",
        id: "helgeson",
        order: 16,
        author: "Anna Helgeson",
        authorShort: "Helgeson",
        title: "Craft, Nothing, and Nowhere",
      },
      {
        pubId: "perron-feller",
        id: "johnson",
        order: 5,
        author: "Emily Johnson",
        authorShort: "Johnson",
        title: "Surgent Love ",
      },
    ],
  },
  {
    id: "phenomenology",
    name: "phenomenology",
    articles: [
      {
        pubId: "devlin",
        id: "kuo",
        order: 5,
        author: "Phoebe Kuo",
        authorShort: "Kuo",
        title: "Swimming Ethnography",
      },
      {
        pubId: "devlin",
        id: "boden",
        order: 8,
        author: "Margaret Boden",
        authorShort: "Boden",
        title: "Crafts, Perception, and the Possibilities of the Body",
      },
      {
        pubId: "devlin",
        id: "hawes",
        order: 9,
        author: "Kate Hawes",
        authorShort: "Hawes",
        title: "Mouth to Test Spoons",
      },
      {
        pubId: "notaretreat",
        id: "martin",
        order: 17,
        author: "Tom Martin",
        authorShort: "Martin",
        title: "What to Do When You Are Dead: Craft and Existential Anxiety ",
      },
      {
        pubId: "tom",
        id: "speculate",
        order: 31,
        author: "Tom Martin",
        authorShort: "Martin",
        title: "Speculate",
      },
    ],
  },
  {
    id: "philosophy",
    name: "philosophy",
    articles: [
      {
        pubId: "notaretreat",
        id: "martin",
        order: 17,
        author: "Tom Martin",
        authorShort: "Martin",
        title: "What to Do When You Are Dead: Craft and Existential Anxiety ",
      },
      {
        pubId: "tom",
        id: "enchant",
        order: 10,
        author: "Karen Wilson Baptist",
        authorShort: "Baptist",
        title: "Enchant",
      },
      {
        pubId: "tom",
        id: "liminal",
        order: 16,
        author: "Alex Singh",
        authorShort: "Singh",
        title: "Liminal",
      },
      {
        pubId: "tom",
        id: "magic",
        order: 17,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Magic",
      },
      {
        pubId: "tom",
        id: "publication",
        order: 24,
        author: "Ben Lignel",
        authorShort: "Lignel",
        title: "Publication",
      },
      {
        pubId: "tom",
        id: "speculate",
        order: 31,
        author: "Tom Martin",
        authorShort: "Martin",
        title: "Speculate",
      },
      {
        pubId: "tom",
        id: "subvert",
        order: 32,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Subvert",
      },
      {
        pubId: "wiltsie",
        id: "mitchell_wiltsie",
        order: 2,
        author: "W. J. T. Mitchell & Tina Wiltsie",
        authorShort: "Mitchell & Wiltsie",
        title:
          "From Metapictures to Metacraft? An Interview with W. J. T. Mitchell",
      },
    ],
  },
  {
    id: "place",
    name: "place",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo",
        order: 4.1,
        author: "Jill DiMassimo",
        authorShort: "DiMassimo",
        title: "Mapping Commeraw’s Jar",
      },
      {
        pubId: "dimassimo_weiss",
        id: "materials",
        order: 5,
        author: "Jill DiMassimo and Joanna Weiss",
        authorShort: "DiMassimo & Weiss",
        title: "Materials",
      },
      {
        pubId: "dimassimo_weiss",
        id: "weiss",
        order: 5.1,
        author: "Joanna Weiss",
        authorShort: "Weiss",
        title:
          "Cowrie Shells: Movement and Connections through African American Material Culture",
      },
      {
        pubId: "dimassimo_weiss",
        id: "perron-feller",
        order: 5.2,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Material Encounters: A Moment of Bringing Black Craft History into the Present through Fieldwork",
      },
      {
        pubId: "notaretreat",
        id: "introduction",
        order: 1,
        author: "Ben Lignel and Namita Gupta Wiggers",
        authorShort: "Lignel & Wiggers",
        title: "Introduction",
      },
    ],
  },
  {
    id: "poetry",
    name: "poetry",
    articles: [
      {
        pubId: "devlin",
        id: "kuo",
        order: 5,
        author: "Phoebe Kuo",
        authorShort: "Kuo",
        title: "Swimming Ethnography",
      },
      {
        pubId: "notaretreat",
        id: "keith",
        order: 13,
        author: "Jeffrey A. Keith",
        authorShort: "Keith",
        title: "Emplaced Remembrance—Thinking about Gone Worlds Always Nearby",
      },
      {
        pubId: "perron-feller",
        id: "denherder",
        order: 3,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Ecology of Built Environments: An Interview with Structural Engineer Justin Den Herder",
      },
    ],
  },
  {
    id: "publichistory",
    name: "public history",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo_momon_weiss",
        order: 2.2,
        author: "Tiffany Momon, Jill DiMassimo, and Joanna Weiss",
        authorShort: "Momon, DiMassimo & Weiss",
        title:
          "Interview with Dr. Tiffany Momon: Public Historian, BCDA Founder, and MACR Teaching Fellow",
      },
      {
        pubId: "dimassimo_weiss",
        id: "perron-feller",
        order: 5.2,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Material Encounters: A Moment of Bringing Black Craft History into the Present through Fieldwork",
      },
      {
        pubId: "notaretreat",
        id: "momon",
        order: 15,
        author: "Tiffany Momon",
        authorShort: "Momon",
        title: "People, Get Ready: Building New Worlds",
      },
    ],
  },
  {
    id: "queerlife",
    name: "queer life",
    articles: [
      {
        pubId: "notaretreat",
        id: "helgeson",
        order: 16,
        author: "Anna Helgeson",
        authorShort: "Helgeson",
        title: "Craft, Nothing, and Nowhere",
      },
      {
        pubId: "notaretreat",
        id: "patel",
        order: 20,
        author: "Alpesh Kantilal Patel",
        authorShort: "Patel",
        title: "Worldmaking with Colleagues",
      },
      {
        pubId: "tom",
        id: "queer",
        order: 25,
        author: "matt lambert",
        authorShort: "lambert",
        title: "Queer",
      },
    ],
  },
  {
    id: "questions",
    name: "questions",
    articles: [
      {
        pubId: "notaretreat",
        id: "razdan",
        order: 14,
        author: "Anjula Razdan",
        authorShort: "Razdan",
        title: "Unearthing the Craftscape",
      },
    ],
  },
  {
    id: "renewal",
    name: "renewal",
    articles: [
      {
        pubId: "tom",
        id: "epigraph",
        order: 3,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Epigraph",
      },
      {
        pubId: "tom",
        id: "aufhebung",
        order: 6,
        author: "Lucia Ilene Wallace",
        authorShort: "Wallace",
        title: "Aufhebung",
      },
      {
        pubId: "tom",
        id: "repair",
        order: 28,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Repair",
      },
      {
        pubId: "tom",
        id: "talisman",
        order: 33,
        author: "Kasey Smith",
        authorShort: "Smith",
        title: "Talisman",
      },
      {
        pubId: "tom",
        id: "zombie",
        order: 34,
        author: "Greg Garrett",
        authorShort: "Garrett",
        title: "Zombie",
      },
      {
        pubId: "tom",
        id: "eulogy",
        order: 35,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Eulogy",
      },
    ],
  },
  {
    id: "repair",
    name: "repair",
    articles: [
      {
        pubId: "tom",
        id: "renewal",
        order: 27,
        author: "Brian Potter",
        authorShort: "Potter",
        title: "Renewal",
      },
      {
        pubId: "tom",
        id: "repair",
        order: 28,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Repair",
      },
    ],
  },
  {
    id: "reprint",
    name: "reprint",
    articles: [
      {
        pubId: "devlin",
        id: "wallkimmerer",
        order: 2,
        author: "Robin Wall Kimmerer",
        authorShort: "Kimmerer",
        title: "Wisgaak Gokpenagen: A Black Ash Basket",
      },
      {
        pubId: "devlin",
        id: "adamson",
        order: 3,
        author: "Glenn Adamson",
        authorShort: "Adamson",
        title: "Material Intelligence",
      },
      {
        pubId: "devlin",
        id: "boden",
        order: 8,
        author: "Margaret Boden",
        authorShort: "Boden",
        title: "Crafts, Perception, and the Possibilities of the Body",
      },
      {
        pubId: "hand",
        id: "hall",
        order: 2,
        author: "Stuart Hall",
        authorShort: "Hall",
        title: "Minimal Selves",
      },
      {
        pubId: "hand",
        id: "joyce",
        order: 3,
        author: "Jennifer Hand",
        authorShort: "Hand",
        title: "Joyce Scott: Hard Truth in Beauty",
      },
      {
        pubId: "hand",
        id: "ahmed",
        order: 4,
        author: "Sara Ahmed",
        authorShort: "Ahmed",
        title: "A Phenomenology of Whiteness",
      },
      {
        pubId: "hand",
        id: "josselson",
        order: 5,
        author: "Ruthellen Josselson",
        authorShort: "Josselson",
        title:
          "On Writing Other People’s Lives: Self-analytic Reflections of a Narrative Researcher",
      },
      {
        pubId: "hand",
        id: "taylor",
        order: 6,
        author: "Keeanga-Yamahtta Taylor",
        authorShort: "Taylor",
        title: "Until Black Women Are Free, None of Us Will Be Free",
      },
      {
        pubId: "hand",
        id: "leeandhand",
        order: 7,
        author: "Helen Lee and Jennifer Hand",
        authorShort: "Lee & Hand",
        title:
          "Practice Makes Progress. A Comment Conversation on the Urban Glass Fall 2022 Feature, “Multilingualism: The Intersecting Art and Advocacy of Helen Lee” ",
      },
      {
        pubId: "hand",
        id: "vowel",
        order: 8,
        author: "Chelsea Vowel",
        authorShort: "Vowel",
        title: "Writing Toward a Definition of Indigenous Futurism",
      },
      {
        pubId: "perron-feller",
        id: "wallkimmerer",
        order: 2,
        author: "Robin Wall Kimmerer",
        authorShort: "Kimmerer",
        title: '"Asters and Goldenrod," from Braiding Sweetgrass',
      },
      {
        pubId: "perron-feller",
        id: "johnson",
        order: 5,
        author: "Emily Johnson",
        authorShort: "Johnson",
        title: "Surgent Love ",
      },
      {
        pubId: "tom",
        id: "epigraph",
        order: 3,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Epigraph",
      },
      {
        pubId: "tom",
        id: "ancestry",
        order: 4,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Ancestry",
      },
      {
        pubId: "tom",
        id: "aufhebung",
        order: 6,
        author: "Lucia Ilene Wallace",
        authorShort: "Wallace",
        title: "Aufhebung",
      },
      {
        pubId: "tom",
        id: "ecology",
        order: 8,
        author: "Anna Lowenhaupt Tsing",
        authorShort: "Tsing",
        title: "Ecology",
      },
      {
        pubId: "tom",
        id: "enchant",
        order: 10,
        author: "Karen Wilson Baptist",
        authorShort: "Baptist",
        title: "Enchant",
      },
      {
        pubId: "tom",
        id: "grave",
        order: 13,
        author: "Jarrett M. Drake",
        authorShort: "Drake",
        title: "Grave",
      },
      {
        pubId: "tom",
        id: "lifespan",
        order: 15,
        author: "Paul Benzon and Future Library",
        authorShort: "Benzon & Future Library",
        title: "Lifespan",
      },
      {
        pubId: "tom",
        id: "liminal",
        order: 16,
        author: "Alex Singh",
        authorShort: "Singh",
        title: "Liminal",
      },
      {
        pubId: "tom",
        id: "offering",
        order: 20,
        author: "Phoebe Cheng",
        authorShort: "Cheng",
        title: "Offering",
      },
      {
        pubId: "tom",
        id: "renewal",
        order: 27,
        author: "Brian Potter",
        authorShort: "Potter",
        title: "Renewal",
      },
      {
        pubId: "tom",
        id: "zombie",
        order: 34,
        author: "Greg Garrett",
        authorShort: "Garrett",
        title: "Zombie",
      },
    ],
  },
  {
    id: "resistance",
    name: "resistance",
    articles: [
      {
        pubId: "perron-feller",
        id: "johnson",
        order: 5,
        author: "Emily Johnson",
        authorShort: "Johnson",
        title: "Surgent Love ",
      },
      {
        pubId: "tom",
        id: "map",
        order: 18,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Map",
      },
      {
        pubId: "tom",
        id: "subvert",
        order: 32,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Subvert",
      },
    ],
  },
  {
    id: "selfknowledge",
    name: "self-knowledge",
    articles: [
      {
        pubId: "hand",
        id: "foreword",
        order: 1,
        author: "Jennifer Hand",
        authorShort: "Hand",
        title: "Foreword",
      },
      {
        pubId: "hand",
        id: "hall",
        order: 2,
        author: "Stuart Hall",
        authorShort: "Hall",
        title: "Minimal Selves",
      },
      {
        pubId: "notaretreat",
        id: "meissner",
        order: 2,
        author: "Amy Meissner",
        authorShort: "Meissner",
        title: "But First, Learn This (A List)",
      },
      {
        pubId: "notaretreat",
        id: "martin",
        order: 17,
        author: "Tom Martin",
        authorShort: "Martin",
        title: "What to Do When You Are Dead: Craft and Existential Anxiety ",
      },
    ],
  },
  {
    id: "selfpublishing",
    name: "self-publishing",
    articles: [
      {
        pubId: "notaretreat",
        id: "levine",
        order: 10,
        author: "Faythe Levine",
        authorShort: "Levine",
        title: "The Dotted Line (Desktop Version)",
      },
      {
        pubId: "notaretreat",
        id: "lignel_wiggers",
        order: 11,
        author: "Ben Lignel and Namita Gupta Wiggers",
        authorShort: "Lignel & Wiggers",
        title:
          "Designing the Plane as We Flew It—A Discussion on the MACR Program Publications",
      },
      {
        pubId: "tom",
        id: "lifespan",
        order: 15,
        author: "Paul Benzon and Future Library",
        authorShort: "Benzon & Future Library",
        title: "Lifespan",
      },
    ],
  },
  {
    id: "skillacquisition",
    name: "skill acquisition",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "awake_ryland",
        order: 6.2,
        author: "Robell Awake and Charlie Ryland",
        authorShort: "Awake & Ryland",
        title: "Combatting Erasure",
      },
      {
        pubId: "notaretreat",
        id: "meissner",
        order: 2,
        author: "Amy Meissner",
        authorShort: "Meissner",
        title: "But First, Learn This (A List)",
      },
      {
        pubId: "notaretreat",
        id: "clugage",
        order: 7,
        author: "Sara Clugage",
        authorShort: "Clugage",
        title: "This Is Not a Food Studies Program",
      },
    ],
  },
  {
    id: "socialjustice",
    name: "social justice",
    articles: [
      {
        pubId: "hand",
        id: "joyce",
        order: 3,
        author: "Jennifer Hand",
        authorShort: "Hand",
        title: "Joyce Scott: Hard Truth in Beauty",
      },
      {
        pubId: "notaretreat",
        id: "momon",
        order: 15,
        author: "Tiffany Momon",
        authorShort: "Momon",
        title: "People, Get Ready: Building New Worlds",
      },
      {
        pubId: "notaretreat",
        id: "patel",
        order: 20,
        author: "Alpesh Kantilal Patel",
        authorShort: "Patel",
        title: "Worldmaking with Colleagues",
      },
    ],
  },
  {
    id: "somatics",
    name: "somatics",
    articles: [
      {
        pubId: "devlin",
        id: "editorsletter",
        order: 1,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Editor's Letter",
      },
      {
        pubId: "devlin",
        id: "calderoanddevlin",
        order: 6,
        author: "Alexis Rosa Caldero & Miriam Devlin",
        authorShort: "Caldero & Devlin",
        title:
          "Conversation with Alexis Rosa Caldero: Wooden Chairs for Healing",
      },
      {
        pubId: "devlin",
        id: "boden",
        order: 8,
        author: "Margaret Boden",
        authorShort: "Boden",
        title: "Crafts, Perception, and the Possibilities of the Body",
      },
    ],
  },
  {
    id: "supportnetworks",
    name: "support networks",
    articles: [
      {
        pubId: "notaretreat",
        id: "acierto",
        order: 4,
        author: "alejandro t. acierto",
        authorShort: "acierto",
        title:
          "In the After-Thought: Teaching Transparency and the Ongoing Practice of Freedom",
      },
      {
        pubId: "notaretreat",
        id: "patel",
        order: 20,
        author: "Alpesh Kantilal Patel",
        authorShort: "Patel",
        title: "Worldmaking with Colleagues",
      },
      {
        pubId: "tom",
        id: "acknowledgments",
        order: 2,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Acknowledgments",
      },
      {
        pubId: "tom",
        id: "coalesce",
        order: 7,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Coalesce",
      },
      {
        pubId: "tom",
        id: "educate",
        order: 9,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Educate",
      },
      {
        pubId: "tom",
        id: "portrait",
        order: 22,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Portrait",
      },
    ],
  },
  {
    id: "teachingpractice",
    name: "teaching practice",
    articles: [
      {
        pubId: "notaretreat",
        id: "introduction",
        order: 1,
        author: "Ben Lignel and Namita Gupta Wiggers",
        authorShort: "Lignel & Wiggers",
        title: "Introduction",
      },
      {
        pubId: "notaretreat",
        id: "leemann",
        order: 5,
        author: "Judith Leemann",
        authorShort: "Leemann",
        title: "towards tending not knowing",
      },
      {
        pubId: "notaretreat",
        id: "potter",
        order: 6,
        author: "Melissa Potter",
        authorShort: "Potter",
        title: "Pandemic Papermaking: Critical Craft",
      },
    ],
  },
  {
    id: "transcience",
    name: "transcience",
    articles: [
      {
        pubId: "tom",
        id: "enchant",
        order: 10,
        author: "Karen Wilson Baptist",
        authorShort: "Baptist",
        title: "Enchant",
      },
      {
        pubId: "tom",
        id: "magic",
        order: 17,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Magic",
      },
      {
        pubId: "tom",
        id: "memorial",
        order: 19,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Memorial",
      },
      {
        pubId: "tom",
        id: "offering",
        order: 20,
        author: "Phoebe Cheng",
        authorShort: "Cheng",
        title: "Offering",
      },
      {
        pubId: "tom",
        id: "palimpsest",
        order: 21,
        author: "Indira Allegra",
        authorShort: "Allegra",
        title: "Palimpsest",
      },
    ],
  },
  {
    id: "translation",
    name: "translation",
    articles: [
      {
        pubId: "devlin",
        id: "editorsletter",
        order: 1,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Editor's Letter",
      },
      {
        pubId: "devlin",
        id: "devlin",
        order: 4,
        author: "Trieste Devlin",
        authorShort: "Devlin",
        title: "On Frames",
      },
      {
        pubId: "devlin",
        id: "bernsteinanddevlin",
        order: 7,
        author: "Blake Bernstein & Trieste Devlin",
        authorShort: "Bernstein & Devlin",
        title: "On Manga",
      },
    ],
  },
  {
    id: "visualessay",
    name: "visual essay",
    articles: [
      {
        pubId: "dimassimo_weiss",
        id: "dimassimo",
        order: 4.1,
        author: "Jill DiMassimo",
        authorShort: "DiMassimo",
        title: "Mapping Commeraw’s Jar",
      },
      {
        pubId: "dimassimo_weiss",
        id: "weiss",
        order: 5.1,
        author: "Joanna Weiss",
        authorShort: "Weiss",
        title:
          "Cowrie Shells: Movement and Connections through African American Material Culture",
      },
      {
        pubId: "dimassimo_weiss",
        id: "perron-feller",
        order: 5.2,
        author: "Beryl Perron-Feller",
        authorShort: "Perron-Feller",
        title:
          "Material Encounters: A Moment of Bringing Black Craft History into the Present through Fieldwork",
      },
      {
        pubId: "dimassimo_weiss",
        id: "awake_ryland",
        order: 6.2,
        author: "Robell Awake and Charlie Ryland",
        authorShort: "Awake & Ryland",
        title: "Combatting Erasure",
      },
      {
        pubId: "tom",
        id: "ancestry",
        order: 4,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Ancestry",
      },
      {
        pubId: "tom",
        id: "coalesce",
        order: 7,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Coalesce",
      },
      {
        pubId: "tom",
        id: "educate",
        order: 9,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Educate",
      },
      {
        pubId: "tom",
        id: "evolve",
        order: 11,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Evolve",
      },
      {
        pubId: "tom",
        id: "legacy",
        order: 14,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Legacy",
      },
      {
        pubId: "tom",
        id: "magic",
        order: 17,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Magic",
      },
      {
        pubId: "tom",
        id: "map",
        order: 18,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Map",
      },
      {
        pubId: "tom",
        id: "memorial",
        order: 19,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Memorial",
      },
      {
        pubId: "tom",
        id: "portrait",
        order: 22,
        author: "Rena Tom",
        authorShort: "Tom",
        title: "Portrait",
      },
      {
        pubId: "tom",
        id: "predict",
        order: 23,
        author: "Andres Payan Estrada",
        authorShort: "Estrada",
        title: "Predict",
      },
      {
        pubId: "wiltsie",
        id: "gallery",
        order: 3,
        author: "Tina Wiltsie",
        authorShort: "Wiltsie",
        title: "Enduring Form: Objects Depicting Ceramics across Time",
      },
    ],
  },
  {
    id: "weaving",
    name: "weaving",
    articles: [
      {
        pubId: "tom",
        id: "liminal",
        order: 16,
        author: "Alex Singh",
        authorShort: "Singh",
        title: "Liminal",
      },
      {
        pubId: "tom",
        id: "palimpsest",
        order: 21,
        author: "Indira Allegra",
        authorShort: "Allegra",
        title: "Palimpsest",
      },
    ],
  },
  {
    id: "woodworking",
    name: "woodworking",
    articles: [
      {
        pubId: "devlin",
        id: "calderoanddevlin",
        order: 6,
        author: "Alexis Rosa Caldero & Miriam Devlin",
        authorShort: "Caldero & Devlin",
        title:
          "Conversation with Alexis Rosa Caldero: Wooden Chairs for Healing",
      },
      {
        pubId: "devlin",
        id: "hawes",
        order: 9,
        author: "Kate Hawes",
        authorShort: "Hawes",
        title: "Mouth to Test Spoons",
      },
      {
        pubId: "dimassimo_weiss",
        id: "devlin",
        order: 6.1,
        author: "Miriam Devlin",
        authorShort: "Devlin",
        title: "Exceptional, Typical, Chairs by Dick Poynor",
      },
      {
        pubId: "dimassimo_weiss",
        id: "awake_ryland",
        order: 6.2,
        author: "Robell Awake and Charlie Ryland",
        authorShort: "Awake & Ryland",
        title: "Combatting Erasure",
      },
    ],
  },
  {
    id: "worldmaking",
    name: "world making",
    articles: [
      {
        pubId: "notaretreat",
        id: "momon",
        order: 15,
        author: "Tiffany Momon",
        authorShort: "Momon",
        title: "People, Get Ready: Building New Worlds",
      },
      {
        pubId: "notaretreat",
        id: "patel",
        order: 20,
        author: "Alpesh Kantilal Patel",
        authorShort: "Patel",
        title: "Worldmaking with Colleagues",
      },
    ],
  },
  {
    id: "writing",
    name: "writing",
    articles: [
      {
        pubId: "hand",
        id: "foreword",
        order: 1,
        author: "Jennifer Hand",
        authorShort: "Hand",
        title: "Foreword",
      },
      {
        pubId: "notaretreat",
        id: "levine",
        order: 10,
        author: "Faythe Levine",
        authorShort: "Levine",
        title: "The Dotted Line (Desktop Version)",
      },
      {
        pubId: "notaretreat",
        id: "delosreyes",
        order: 19,
        author: "Jen Delos Reyes",
        authorShort: "Delos Reyes",
        title:
          "Future Desire Lines: Cultivating Paths of Resistance, Support, and Joy",
      },
      {
        pubId: "notaretreat",
        id: "fisher",
        order: 21,
        author: "Michelle Millar Fisher",
        authorShort: "Fisher",
        title: "Who writes craft histories?",
      },
      {
        pubId: "tom",
        id: "lifespan",
        order: 15,
        author: "Paul Benzon and Future Library",
        authorShort: "Benzon & Future Library",
        title: "Lifespan",
      },
      {
        pubId: "wiltsie",
        id: "mitchell_wiltsie",
        order: 2,
        author: "W. J. T. Mitchell & Tina Wiltsie",
        authorShort: "Mitchell & Wiltsie",
        title:
          "From Metapictures to Metacraft? An Interview with W. J. T. Mitchell",
      },
    ],
  },
];
