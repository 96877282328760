import { NavLink } from "react-router-dom";

const Razdan = () => {
  return (
    <div>
      <p>
        For some time now, I’ve been working through a framework I call{" "}
        <em>craftscape</em>.
        <sup>
          <a href="#annotation-1">1</a>
        </sup>{" "}
        In my first use of the term, my thinking focused on connecting
        craft-based artists and performance. I was and continue to be interested
        in the cultural dimensions of craft. By working through this spatial
        model that engages cultural and historical flow while being
        simultaneously grounded in specific acts of making, I could bring makers
        and what they do into view in a way that prioritizes craft as a subject.
        An essay I wrote in 2010, “Craft Performs,” connects multiple kinds of
        spaces, mapping a complex and interconnected craftscape: arts and craft
        fairs, street performers, action as a critical turn in contemporary art
        practices, living history sites, nationalism, Mingei and Americanized
        Zen, Black craft history, and craft performed in a way that dislocates
        histories to make space for a more layered and expansive understanding
        of craft. The MACR community will hear echoes here in the kind of work
        we’ve been doing for the past five years.{" "}
      </p>

      <p>
        Working at Warren Wilson College, and especially with Dean of Land Dave
        Ellum, who is responsible for the school’s farm, forest, and garden as
        part of its liberal arts curriculum, impacted my focus in recent years
        on craft and material flows. Being connected to a college that stewards
        1,100 acres of land gives a different perspective on history and time;
        Ellum explains that he sees what was done 100 years ago to the forest
        and takes action keeping in mind what will happen 100 years from now. He
        and his work crews are responsible for tending the land, and the
        connection to craft is foundational: wood is used to make objects,
        repair campus buildings, and heat homes; a farm and garden provide
        significant amounts of produce and meat; raw wool shorn from sheep is
        spun into usable fiber; and dyes are extracted from plants cultivated
        and harvested from a dye garden. Here, wood, for example, is both matter
        and material in a highly visible way. After I delivered an online
        webinar for Warren Wilson College in November 2020,{" "}
        <em>American Craft</em> magazine reached out to me to write an article
        about craftscape, specifically to examine what I mean by the term, what
        thinking contributes to the framework, and how it applied through the MA
        in Critical Craft Studies program curriculum.
        <sup>
          <a href="#annotation-2">2</a>
        </sup>{" "}
        The resulting article, “Unearthing the Craftscape,” is shared here with
        permission from the American Craft Council. During our first of many
        conversations, writer Anjula Razdan and I realized we’d been in a class
        taught by Homi Bhabha together at the University of Chicago. I felt this
        shared background in time, place, and critical thinking in the kinds of
        questions Razdan asked, and see it in how clearly and succinctly the
        article describes how I am working through craftscape.
      </p>

      <p>
        The use of “scape” draws heavily on the work of Arjun Appadurai. When
        combined with methods, questions, and discourse from art history,
        anthropology, and material culture, craftscape is a framework for
        makers, objects, and place to expand beyond systemic discursive forces
        of colonialism and disciplinary biases that center discourse around
        binaries like art versus craft and a transatlantic perspective on craft
        history.
        <sup>
          <a href="#annotation-3">3</a>
        </sup>{" "}
        A quick review of the students’ publications in <em>The MACR Papers</em>{" "}
        reveals craftscape in action.{" "}
        <NavLink to="/notaretreat/momon">Dr. Tiffany Momon’s essay</NavLink> in{" "}
        <em>This Is Not a Retreat</em> describes craftscape as “the word that I
        knew I needed, yet did not know existed,” and the object essay matt
        lambert (Class of 2020) published in conjunction with “Unearthing the
        Craftscape” demonstrates the application of the framework.
        <sup>
          <a href="#annotation-4">4</a>
        </sup>{" "}
        In conjunction with Anjula Razdan’s article, the American Craft Council
        and I worked to create an online Summer Craft Forum called "Object
        Stories from the Craftscape".
        <sup>
          <a href="#annotation-5">5</a>
        </sup>{" "}
        We invited a group of artists and scholars with whom the article and a
        set of questions was shared. The resulting conversation reveals how
        craftscape coalesces and conjoins interest in a particular kind of
        human-material-object relationship known as craft. Participants shared
        specifics about their own work and, in the discussion that followed,
        discussed how craftscape offers a flexible, fluid, and nonhierarchical
        approach that creates space for each of them to bring more of their
        personal histories and identities into view. For me, this conversation
        exemplifies what I want and work toward: to be in a craftscape that is
        complicated, messy, multi-narrative, and collective. Toni Morrison says
        if you cannot find the book you want to read, write that book. I don’t
        want to work or live alone, nor do I want to live and work in spaces
        that fence me in. The craftscape works because it is predicated on
        dialogue and exchange, on listening and learning with and from one
        another—on being in community.{" "}
      </p>

      <p>—Namita Gupta Wiggers</p>
      <ul className="bibliography">
        <li>
          Razdan, Anjula. “Unearthing the Craftscape.” <em>American Craft</em>,
          May 27, 2021.{" "}
          <a
            href="https://www.craftcouncil.org/magazine/article/unearthing-craftscape"
            target="_blank"
            rel="noreferrer"
          >
            https://www.craftcouncil.org/magazine/article/unearthing-craftscape
          </a>
          .{" "}
        </li>
      </ul>
      <p>
        This article is reprinted with the kind permission of the American Craft
        council.
      </p>
      <p>
        <a
          href={
            process.env.PUBLIC_URL +
            `/img/notaretreat/ACC_unearthing_the_craftscape.pdf`
          }
          download
        >
          Download PDF
        </a>
      </p>
      <p>*</p>
      <h2>Bibliography</h2>
      <ul className="bibliography">
        <li>
          lambert, matt. “The Good China.” <em>American Craft</em>, May 27,
          2021.{" "}
          <a
            href="https://www.craftcouncil.org/magazine/article/unearthing-craftscape"
            target="_blank"
            rel="noreferrer"
          >
            https://www.craftcouncil.org/magazine/article/unearthing-craftscape
          </a>
          .
        </li>
        <li>
          Razdan, Anjula. “Unearthing the Craftscape.” <em>American Craft</em>,
          May 27, 2021.{" "}
          <a
            href="https://www.craftcouncil.org/magazine/article/unearthing-craftscape"
            target="_blank"
            rel="noreferrer"
          >
            https://www.craftcouncil.org/magazine/article/unearthing-craftscape
          </a>
          .
        </li>

        <li>
          Wiggers, Namita Gupta. “Building a Craftscape at Warren Wilson
          College: What Is a Field and Who Does It Include?” Online webinar.
          Warren Wilson College, November 12, 2020.{" "}
          <a
            href="https://www.youtube.com/watch?v=IX_2IG7hAdM"
            target="_blank"
            rel="noreferrer"
          >
            https://www.youtube.com/watch?v=IX_2IG7hAdM
          </a>
          <sup>
            <a href="#annotation-6">6</a>
          </sup>{" "}
        </li>

        <li>
          Wiggers, Namita Gupta, host with American Craft Council, Summer Craft
          Forum. “Object Stories from the Craftscape.” American Craft Council,
          online, June 17, 2021.{" "}
          <a
            href="https://youtu.be/jsMpRZkhNEA"
            target="_blank"
            rel="noreferrer"
          >
            https://youtu.be/jsMpRZkhNEA
          </a>
          , retrieved May 20, 2023. Introduction by Rachel Messerich, the
          American Craft Council’s legacy and editorial programs manager.
          Participants: Jovencio de la Paz, Andres Payan Estrada, Courtney M.
          Leonard, Amy Meissner (Class of 2021), and Dr. Tiffany Momon.
          <sup>
            <a href="#annotation-7">7</a>
          </sup>{" "}
        </li>
      </ul>
      <hr />

      <cite>
        <sup id="annotation-1">1.</sup> I first used the term in the essay
        “Craft Performs,” in Valerie Cassel Oliver,{" "}
        <em>Hand+Made: The Performative Impulse in Art and Craft</em> (Houston:
        Contemporary Arts Museum, 2010),{" "}
        <a
          href="https://issuu.com/thecamh/docs/2010_hand_plus_made_edited"
          target="_blank"
          rel="noreferrer"
        >
          https://issuu.com/thecamh/docs/2010_hand_plus_made_edited
        </a>
        , retrieved May 25, 2023.{" "}
      </cite>

      <cite>
        <sup id="annotation-2">2.</sup> Anjula Razdan, “Unearthing the
        Craftscape,” <em>American Craft</em>, May 27, 2021,{" "}
        <a
          href="https://www.craftcouncil.org/magazine/article/unearthing-craftscape"
          target="_blank"
          rel="noreferrer"
        >
          https://www.craftcouncil.org/magazine/article/unearthing-craftscape
        </a>
        . See Namita Gupta Wiggers, “Matter and Material, Matter <em>as</em>{" "}
        Material,” in Holly Jerger, ed.,{" "}
        <em>RAW: Craft, Commodity, and Capitalism</em> (Los Angeles: Craft
        Contemporary, 2018),{" "}
        <a
          href="https://www.craftcontemporary.org/wp-content/uploads/2020/05/RAW-Catalog.pdf"
          target="_blank"
          rel="noreferrer"
        >
          https://www.craftcontemporary.org/wp-content/uploads/2020/05/RAW-Catalog.pdf
        </a>
        , retrieved May 25, 2023. The exhibition was on view September 29,
        2019¬–January 5, 2020. Students in the MACR program read Sven Beckert’s{" "}
        <em>The Empire of Cotton</em> (New York: Knopf Doubleday, 2015) as
        preparation before their first residency to begin considering systems
        through which craft circulates. See also Kathryn Yusoff,{" "}
        <em>A Billion Black Anthropocenes or None</em> (Minneapolis: University
        of Minnesota Press, 2019). Special thanks to matt lambert, Class of
        2020, for introducing me to Yusoff’s work.
      </cite>

      <cite>
        <sup id="annotation-3">3.</sup> See Arjun Appadurai, “Disjuncture and
        Difference in the Global Cultural Economy,”{" "}
        <em>Theory Culture Society</em> 7, no. 2–3,{" "}
        <a
          href="https://www.arjunappadurai.org/articles/Appadurai_Disjuncture_and_Difference_in_the_Global_Cultural_Economy.pdf"
          target="_blank"
          rel="noreferrer"
        >
          https://www.arjunappadurai.org/articles/Appadurai_Disjuncture_and_Difference_in_the_Global_Cultural_Economy.pdf
        </a>
        , retrieved May 24, 2023. See also Arjun Appadurai, ed.,{" "}
        <em>The Social Life of Things: Commodities in Cultural Perspective</em>,
        (Cambridge and New York: Cambridge University Press, 1986); Arjun
        Appadurai, “The Thing Itself,” <em>Public Culture</em> 18, no. 1 (2006),{" "}
        <a
          href="http://www.arjunappadurai.org/articles/Appadurai_The_Thing_Itself.pdf"
          target="_blank"
          rel="noreferrer"
        >
          http://www.arjunappadurai.org/articles/Appadurai_The_Thing_Itself.pdf
        </a>
        , retrieved May 26, 2023; and Arjun Appadurai, “How Histories Make
        Geographies: Circulation and Context in a Global Perspective,”{" "}
        <em>The Journal of Transcultural Studies</em> 1, no. 1 (2010),{" "}
        <a
          href="https://heiup.uni-heidelberg.de/journals/index.php/transcultural/article/view/6129"
          target="_blank"
          rel="noreferrer"
        >
          https://heiup.uni-heidelberg.de/journals/index.php/transcultural/article/view/6129
        </a>
        , retrieved May 24, 2023. I worked for two years as a research assistant
        to Appadurai, and, for part of the time, to both Appadurai and his late
        wife, Carol Breckenridge, when they taught at the University of Chicago.
        I was enrolled in the Art History Department, and worked with Appadurai
        and Breckenridge to expand the interdisciplinary possibilities connected
        to my research and studies.
      </cite>

      <cite>
        <sup id="annotation-4">4.</sup> lambert, matt, “The Good China,”
        American Craft, May 27, 2021,{" "}
        <a
          href="https://www.craftcouncil.org/magazine/article/unearthing-craftscape"
          target="_blank"
          rel="noreferrer"
        >
          https://www.craftcouncil.org/magazine/article/unearthing-craftscape
        </a>
        , retrieved May 22, 2023.
      </cite>

      <cite>
        <sup id="annotation-5">5.</sup> Namita Gupta Wiggers, host with American
        Craft Council, Summer Craft Forum, “Object Stories from the Craftscape,”
        American Craft Council, online, June 17, 2021,{" "}
        <a href="https://youtu.be/jsMpRZkhNEA" target="_blank" rel="noreferrer">
          https://youtu.be/jsMpRZkhNEA
        </a>
        , retrieved May 20, 2023.
      </cite>

      <cite>
        <sup id="annotation-6">6.</sup> Namita Gupta Wiggers, “Building a
        Craftscape at Warren Wilson College: What Is a Field and Who Does It
        Include?,” online webinar, Warren Wilson College, November 12, 2020,{" "}
        <a
          href="https://www.youtube.com/watch?v=IX_2IG7hAdM"
          target="_blank"
          rel="noreferrer"
        >
          https://www.youtube.com/watch?v=IX_2IG7hAdM
        </a>
        .
      </cite>

      <cite>
        <sup id="annotation-7">7.</sup> Ibid, “Object Stories.” The online
        program description states: “In this American Craft Forum, we presented
        a series of short talks, or ‘object stories,’ by craftspeople, craft
        artists, and scholars. In choosing their own stories to tell, they
        revealed the possibilities of thinking about objects through the lens of
        what writer, curator, and educator Namita Gupta Wiggers refers to as the{" "}
        <em>Craftscape</em>. For the last 30 years, most recently as the
        founding director of the MA in Critical Craft Studies program at Warren
        Wilson College, Wiggers has been chipping away at traditional
        Western-based approaches to viewing, appreciating, and talking about the
        objects we admire in museums—and those we live with at home. Turning
        that monolithic art–historical approach on its head, Wiggers encourages
        us to think about the object not as the endpoint, but as a catalyst for
        conversations on process, cultural and material histories, the
        importance of the land, biography, and narrative. Welcome to the
        Craftscape.”
      </cite>
    </div>
  );
};

export default Razdan;
