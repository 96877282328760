import React from "react";
import { useParams } from "react-router-dom";
import { COLORS } from "../../publications";
import { KEYWORD_ARTICLES } from "../../data/keywordarticles";
import ArticleMenuItem from "../ArticleMenuItem";

const Keyword = () => {
  const { keyId } = useParams();
  const keyword = KEYWORD_ARTICLES.find((k) => k.id === keyId);

  return (
    <div className="pane scrollable">
      <h2 className="page-title">{keyword.name}</h2>
      <ul className="keyword-articles">
        {keyword.articles.map((a, i) => (
          <li key={i}>
            <ArticleMenuItem
              article={a}
              to={`/${a.pubId}/${a.id}`}
              highlight={COLORS[a.pubId]}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Keyword;
