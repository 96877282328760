import React from "react";
import { Helmet } from "react-helmet";
import { KEYWORD_MAP } from "../../data/keywordmap";
import { NavLink } from "react-router-dom";

const Keywords = () => {
  return (
    <>
      <Helmet>
        <title>The MACR Papers | Keywords</title>
        <link rel="canonical" href="https://www.themacrpapers.com/keywords" />
      </Helmet>
      <div className="pane scrollable">
        <h2 className="page-title">Keywords</h2>
        <ul className="keyword-list">
          {KEYWORD_MAP.map((k, i) =>
            !!k[1].length ? (
              <li key={i}>
                <div className="keyword-list__letter">{k[0]}</div>
                <ul>
                  {k[1].map((keyword, i) => (
                    <li key={i}>
                      <NavLink to={`/keywords/${keyword.id}`}>
                        {keyword.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>
            ) : null
          )}
        </ul>
      </div>
    </>
  );
};

export default Keywords;
