const Patel = () => {
  return (
    <div>
      <p>
        The opportunity to work closely with graduate students interested in
        communicating through academic writing only presented itself to me when
        I joined the Warren Wilson MA program to mentor students one-on-one on
        their thesis projects. Before this, my mentees had been exclusively MFA
        in visual arts candidates enrolled at Florida International University
        (FIU), in Miami, Florida, where I taught for a decade (2011–2022). While
        I did work with MFA candidates on written/oral language, this was never
        the focus as it was in the craft studies MA program.{" "}
      </p>

      <p>
        I worked with matt lambert, Phoebe Kuo, and Maru Lopez. During my
        initial meeting with matt, I said, “I am delighted to work with a future
        colleague,” or something similar. I said this because matt’s—indeed, all
        these students’—interests overlapped with my interest in anti-racist,
        queer, and feminist worldmaking. It is in my interest to make this
        happen.
      </p>

      <p>
        As a doctoral student, I had a wonderful experience with my adviser,
        Amelia Jones. I had always intended to maintain a relationship with her
        after getting my degree. However, after I graduated it was clear she
        considered me her colleague. For example, she went out of her way (and
        still does) to help me build my CV and my career. For instance, she
        invited me to co-chair a panel at the 2012 Performance Studies
        international annual conference. She extended this invitation after my
        panel for the annual College Art Association (CAA) conference was turned
        down during the first year I started a tenure-track job. She also
        invited me to write a chapter for an anthology she was writing on queer
        feminism. I was surprised that someone whose scholarship I still admire
        might feel this way.
      </p>

      <p>
        Amelia provided tangible opportunities at crucial moments in my career,
        and I hope I can do the same for matt, Phoebe, and Maru. I recently
        invited matt to co-jury an open call for an exhibition of recent MFA
        graduates I am organizing at UrbanGlass, in Brooklyn. As part of the
        public programming for the show, we plan to explore “cruising,” a
        concept I mobilize in my first monograph,{" "}
        <em>
          Productive Failure: Writing Queer Transnational South Asian Art
          Histories
        </em>
        . Moreover, matt and I have had conversations over the last few years
        about putting together a panel at CAA’s annual conference to explore
        what cruising as a concept might open for us as a methodology. Phoebe
        and I spoke a lot about being Asian Americans in the art world, and I
        have been meaning to connect with her about continuing our discussion.
        Finally, I am interested in considering the role of practice-led
        research in academic writing more seriously. Maru is engaging in some
        amazing work in this regard, and it would be great to figure out a way
        to continue that conversation with her.{" "}
      </p>

      <p>
        At the same time, I do not mean to imply that conference panels and
        essays are the only way to nurture relationships. For instance, I have
        had many exchanges with Amelia via email, phone calls, and Zoom chats
        over the last fifteen years. This kind of improvisational ongoing
        communication is not measurable in clear-cut tangible outputs and is
        infinitely more powerful to a certain degree. They might even be more
        powerful in the worldmaking I invoke.{" "}
      </p>

      <h2>Further Readings</h2>
      <p>
        The following short essays of mine connected to worldmaking and/or
        former mentees can be found on my website:{" "}
        <a
          href="https://www.alpeshkpatel.com/exhibition-catalog-short-essays"
          target="_blank"
          rel="noreferrer"
        >
          https://www.alpeshkpatel.com/exhibition-catalog-short-essays
        </a>
      </p>

      <ul className="bibliography">
        <li>
          Patel, Alpesh Kantilal. ”Disorderly Encounters and Irregular
          Connections: Towards Cruising,” brochure essay accompanying the
          exhibition <em>Self</em>, Contemporary Craft, Pittsburgh,
          Pennsylvania, April–August 2023.
        </li>

        <li>
          ———. “Multiple and One,” brochure essay accompanying the exhibition{" "}
          <em>Multiple and One: Sebastian Duncan-Poruond</em>o, UrbanGlass,
          Brooklyn.{" "}
        </li>

        <li>
          ———. “Queer and Trans/Joy Worlding,” brochure essay accompanying the
          exhibition <em>Self</em>, Contemporary Craft, Pittsburgh,
          Pennsylvania, April–August 2023.
        </li>

        <li>
          ———. “Wonder and Worldmaking,” brochure essay accompanying the
          exhibition <em>Sharyn O’Mara: At the Edge of Rain</em>, Fleisher Art
          Memorial, Philadelphia, permanent installation.
        </li>
      </ul>
      <hr />
    </div>
  );
};

export default Patel;
