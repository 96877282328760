const Lignel = () => {
  return (
    <div>
      <p>
        The fourth semester of the MACR program tended to focus on the theme of
        “learning.” In the Spring of 2020, I chose to dedicate the Materials Lab
        course to learning and teaching with objects, and invited the students
        to reflect on a pot very kindly lent to me by two French photographers,
        Eléonore Lubna and Louis Matton. They had recently returned to France
        with it, after several visits to the Awajún community in the northern
        Peruvian village of Wawaim, where it had been made, and were in the
        middle of an extensive documentation project on the community’s landmark
        fight against German filmmaker Werner Herzog. What follows are my
        reflections on the movement of this pot across the Atlantic—which
        mirrors the movement of white image-makers toward South America—and the
        exciting questions it raises about <em>naming things</em>.
      </p>

      <p>
        The text was originally written for Eléonore and Louis’s forthcoming
        publication. I thank them for letting me publish my thoughts in English
        before they come out in French.
      </p>

      <p>*</p>

      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_9_Yukun_pot.jpg"}
        />
        <figcaption>
          Eléonore Lubna, Louis Matton, and Wawaim community members, Pot, 2019.
          <br />
          Hand-fired clay. <br />
          Photo: Eléonore Lubna and Louis Matton
        </figcaption>
      </figure>
      <h2>Teaching</h2>
      <p>
        What can we learn from an object? What theoretical framework can we use
        to grasp it? What senses? What can be said about an object, so that
        words (and things) may reconstruct not only the practices and uses that
        give the object form and meaning, but also the conditions of its making,
        and of its existence? What lessons can be drawn from this? How can those
        lessons then be taught?
      </p>

      <p>
        I posed this series of questions first in 2019 to the graduate students
        in the Critical Craft Studies program at Warren Wilson College,
        <sup>
          <a href="#annotation-1">1</a>
        </sup>{" "}
        and then in October 2020 to a handful of researchers and craftspeople
        participating in a virtual seminar on reciprocal action between observer
        and object.
        <sup>
          <a href="#annotation-2">2</a>
        </sup>{" "}
      </p>

      <p>
        When I asked the graduate students to design a lesson plan around one of
        the pots in a series of functional ceramic pieces co-produced by the
        workshop of Amalia Wisum Chimpa and the researcher-photographers
        Eléonore Lubna and Louis Matton, I was in reality asking them to
        consider the ethical questions raised by this project, especially
        concerning the display of those pots to a non-Awajún spectatorship.
        <sup>
          <a href="#annotation-3">3</a>
        </sup>{" "}
      </p>

      <p>
        As a prompt, I brought the students the <em>Yukùn</em>-style pot,
        <sup>
          <a href="#annotation-4">4</a>
        </sup>{" "}
        whose interior is decorated with a sgraffito
        <sup>
          <a href="#annotation-5">5</a>
        </sup>{" "}
        depicting German filmmaker Nina Gladitz camera in hand, and I share with
        them a trove of documents compiled by Eléonore and Louis: interview
        transcriptions, press articles, documentary and fiction films,
        photographs, testimonies, and essays. It was now up to the students to
        create a lesson plan based on these rich, multilingual materials.
      </p>

      <p>This inquiry is driven by three considerations.</p>

      <p>
        First: the imperative to research that craftspeople and artists face
        with increasing frequency encourages us to think about the relationship
        that may exist between an argument and an object. What kind of{" "}
        <em>proof</em> can an object offer? Can the object itself articulate
        something resembling a series of ideas? These questions concern the
        relationship between object and knowledge, knowledge and text.
      </p>

      <p>
        The second consideration relates, more broadly, to our sensitivity to
        objects. How can we hone our sensitivity to objects? What questions can
        we ask of an object that we know nothing about? What clues does it give
        us about itself? These questions pertain to our relationship with
        things, to the hold that objects have on us, and the hold we have on
        them.
      </p>

      <p>
        Finally, the last consideration involves the complicity between
        knowledge and power, bringing us closer to the project of historiated
        pottery led by Eléonore and Louis and their Awajún interlocutors. Their
        research demonstrates the enormous narrative pressures that bear on the
        history of the Awajún struggle, and clearly documents the conflicting
        interpretations offered by a gaggle of anthropologists, agronomists,
        filmmakers, and journalists grappling with the events that occurred
        between the end of 1978 and the release of Werner Herzog’s film{" "}
        <em>Fitzcarraldo</em> in March 1982. The question of power is at the
        heart of this essay, in which I will attempt both to grasp an object on
        the move between different systems of meaning and to draft its “label.”
        <sup>
          <a href="#annotation-6">6</a>
        </sup>{" "}
      </p>

      <p>
        The task at hand is to make legible the power dynamics between the
        Awajún and French makers of a pot, whose decoration depicts the violent
        outcome of a struggle for the territorial sovereignty and
        self-determination of an indigenous community of the Peruvian northwest,
        waged through images, justice, and force. My hope is to bring the
        consequences of this struggle to bear on the words that will be
        associated with its possible representations, 40 years later. I propose
        to do this by pursuing reflections that were stirred up in a classroom,
        and that I’ll fuel with students’ questions, and with my own repeated
        attempts at captioning.
      </p>
      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_9_Yukun_pot.jpg"}
        />
        <figcaption>
          Adman Samekash Ampam, Eléonore Lubna, and Louis Matton, Untitled (Nina
          Gladitz), 2019.
          <br />
          Pot in the <em>Yukùn</em> style, hand-fired coiled earth, sgraffitoed
          and black-glazed on the inside. <br />
          Photo: Eléonore Lubna and Louis Matton
        </figcaption>
      </figure>

      <h2>Wall Text</h2>

      <p>
        Borrowing from decolonial and restorative thinking, I intend to bring
        these epistemological and ethical questions to bear upon the “small
        rectangular boxes offering [the] least resistance”
        <sup>
          <a href="#annotation-7">7</a>
        </sup>{" "}
        that accompany works presented in public exhibition spaces. Object
        captions (also known as museum labels) are brief compilations of
        information associated with exhibited objects. Labels emerged in the
        nineteenth century as part of a nascent pedagogical project undertaken
        by public museums to educate visitors without obliging them to purchase
        a catalog.
        <sup>
          <a href="#annotation-8">8</a>
        </sup>{" "}
      </p>

      <p>
        The nomenclature of labels follows the taxonomic priorities of the
        institution or the department that produces them, according to a fairly
        rigid code: name of creator, title, date, medium, provenance (for works
        of art), type, [cultural zone], era, fabrication process, and use, for
        ritual or everyday objects. “Extended” labels also include a very short
        text whose historical, analytical, biographical, or otherwise critical
        content either adheres to, or challenges, current interpretative trends.
        In other words, labels are part of the mediation apparatus that guides
        museum visitors’ interpretations, at the same time that it classifies
        the work. This double project, of informing and classifying, is largely
        led by experts in the field. The wall text does not deepen or digress;
        it doesn’t have time. Its brevity and affirmative tone endow it with the
        authority of the obvious. And in fact, labels and their longer wall text
        companions rarely reveal the epistemological positions (including the
        blind spots) that underpin their conception. Thus, though we’re
        accustomed to seeing a painting associated with the name of its last
        owner on a label, it’s rare for wall text to call the principle of
        private property <em>itself</em> into question, or to reveal the stories
        of conquest, theft, and massacre that often precede or govern the
        acquisition of a work of art.
      </p>

      <blockquote className="right-justify">
        <p>
          What is a description?
          <br />
          What are we looking at? Where does an object start?
          <br />
          What presuppositions about the act of “making,” or about its meaning,
          inform our reading?
          <br />
          <strong>
            Class discussion (Warren Wilson College, January 2019)
          </strong>
        </p>
      </blockquote>

      <h2>Conflicts</h2>

      <p>
        The pot belongs to a series of more than 30 ceramic vessels made in
        Wawaim by half a dozen women and two men, under the supervision of
        Amalia Wisum Chimpa, then decorated with sgraffito by the two French
        photographers during their second, long sojourn in Peru, in the spring
        of 2019. Deriving from the traditional repertory of utilitarian Awajún
        pottery, the pots depict key episodes of the struggle between the Awajún
        people and German filmmaker Werner Herzog, who arrived in their
        territory on July 5, 1979, to shoot his film <em>Fitzcarraldo</em>.
        Taken as a whole, they constitute a kind of mythical retelling of the
        Herzog affair. Their narrative thread stretches back into the past, by
        emphasizing the historical event that inspired Herzog’s fictionalized
        reconstitution of the life of rubber trader Carlos Fitzcarrald, and then
        extends into the future, to encompass events subsequent to the
        filmmaker’s appearance.
      </p>

      <p>
        The fight against Herzog crowned a long series of struggles for
        self-determination and the control of image-making undertaken by the
        Jivaro Indians against Spanish explorers, Jesuit and evangelical
        missionaries, rubber barons, gold miners, botanists, traders, and, most
        recently, pharmaceutical and agrochemical companies. At the end of the
        1970s, in particular, the newly formed indigenous organization Consejo
        Aguaruna y Huambisa (CAH) expelled Spanish technicians, Jesuit and
        evangelical linguists, and several teams of anthropologists from their
        territory.
      </p>

      <p>
        Herzog’s work methods and those of his acolyte and producer Walter Saxer
        echoed the coercive forms of exploitation employed by Fitzcarrald. These
        methods of forced work both constitute the central motif of the film,
        and were re-enacted and updated during the film’s production: massive
        employment of indigenous workers under the domination of non-indigenous
        overseers, territorial conquest through technological domination, active
        corruption of community members, threats, blackmail, false accusations,
        recourse to military force …{" "}
      </p>

      <p>
        According to French agronomist Eric Sabourin,
        <sup>
          <a href="#annotation-9">9</a>
        </sup>{" "}
        the success of the Awajún community in this conflict was probably due to
        its three-pronged approach: first, engaging in battle on the ground;
        second, appealing (for the first time) to the Peruvian justice system;
        and, lastly, carrying out a marketing campaign through white
        representatives (such as Sabourin himself) on the international scene.
        In the Consejo’s opinion, the struggle itself, and its judicial
        resolution in favor of the Awajún people, represented a major victory,
        and a crucial stage in the life of the newly formed CAH. The event
        marked a transition from physical fighting toward judicial and media
        solutions, and from informal collective decisions toward formal ones.
        The collaboration of several Westerners gave the CAH a chance to
        establish its own authority while staking, among other things, a new
        position as image commissioner, as we will see later.
      </p>

      <h2>Strangers with a camera</h2>

      <p>
        The question of who gets to write history is central to the conflict
        between Herzog and the CAH. The same question was simultaneously
        stirring trouble in the French countryside, in the mountain ranges of
        North America, and in Sub-Saharan Africa, where the arrival of
        “strangers with a camera”
        <sup>
          <a href="#annotation-10">10</a>
        </sup>{" "}
        polarized relationships and created unbridgeable technological,
        cultural, and economic gaps between those filming and those being
        filmed. Of course the problems that arise from these compilations,
        reports, and romanticized narratives can vary according to the
        intentions of the image-makers, and of the eventual use of the images.
        Nevertheless, the risk of voyeurism, lack of respect for the right over
        one’s image, and stereotyped or univocal representations, for example,
        are hallmarks of reportages on the war against poverty, as critiques of
        these reportages have noted.
      </p>

      <p>
        Eléonore and Louis tell me that the Awajún people keep their distance
        from images and films, perceiving their production as a white person’s
        pursuit.
        <sup>
          <a href="#annotation-11">11</a>
        </sup>{" "}
        By 1978, an already substantial number of white photographers and
        filmmakers had “come through” their territory. All had made promises
        about a reciprocal restitution of images—promises that had not been
        honored. Thus, the Awajún people were deeply mistrustful of Herzog,
        their preemptive resistance inflamed by past abuses.
      </p>

      <p>
        To outmaneuver, to evade, to resist the colonial gaze: these strategies
        (or tricks, as Michel de Certeau
        <sup>
          <a href="#annotation-12">12</a>
        </sup>{" "}
        would call them) go hand in hand with past and current reflections on
        the power dynamics between dominant and subaltern, between hegemonic
        culture and marginalized knowledges. They serve decolonial and feminist
        fights against objectification, otherization, and exoticization of
        photographed bodies. Among other things, they encourage the
        reappropriation of modes of image capture and image diffusion by, and
        the activist transmission of techniques and expertise to, groups and
        communities who have historically been constituted as the subjects of
        photographs, and never as photographing subjects. Examples of such
        activism are numerous, in many projects that continue to exist today: in
        the US, the <em>Feminist Photography Ovulars</em> (1979–1981), part of
        the lesbian land collective Rootworks (Oregon); the lending and training
        program for documentary filmmaking led by Appalshop, a center for media
        arts training and production located in Whitesburg, Kentucky (1969);
        Wendy Ewald’s photography workshops for rural North American, Mexican,
        Indian, and Dutch children (1975);{" "}
        <em>No Olho da Rua (In the Eye of the Street)</em>, a project helmed by
        the street kids of Bello Horizonte, in Brazil (1995); InsightShare’s
        platform of participatory videos (1999); and, most recently,{" "}
        <em>Now You See Me Moria</em>, documenting the lives of residents of the
        Moria refugee camp in Greece (2020–2021).
      </p>

      <p>
        Although the Awajún people did not themselves assume the position of
        image-makers, the CAH began commissioning documentary chronicles of the
        conflict as soon as Herzog set up camp: a reportage by German
        photographer-anthropologist Manfred Schäfer in 1979 and, from his
        compatriot Nina Gladitz, a 1982 filmed reenactment of the 1979 attack of
        Herzog’s camp. This film is itself a “replay” of the 1981 restaging of
        the incident in Napuruka, which had been photographed by Käthe Reentzen.
        <sup>
          <a href="#annotation-13">13</a>
        </sup>{" "}
        The CAH is credited as co-director of Gladitz’s documentary film, and
        contractually owns the intellectual rights. Indeed, both the capture and
        the production of images seems to have been a key element of the Awajún
        “response” to Herzog, as well as a way to commemorate their victory, a
        memorialization that continued in 2019 when the decision was made to
        recount the story through illustrations on a series of traditional pots.
      </p>

      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_9_Yukun_pot.jpg"}
        />
        <figcaption>
          An Awajún ceramic pot decorated with a sgraffito representing a
          reenactment of the December 1979 arson of Werner Herzog’s encampment
          being filmed by documentary filmmaker Nina Gladitz in 1982. It was
          created by Adman Samekash Ampam for the workshop of Amalia Wisum
          Chimpa (modeling, firing, glazing) and Eléonore Lubna and Louis Matton
          (drawing), and collectively produced in 2019. Photo: Eléonore Lubna
          and Louis Matton
        </figcaption>
      </figure>
      <h2>Alliances</h2>
      <p>
        Various dynamics explain the presence of Westerners in Wawaim, including
        philosopher Dominique Temple, agronomist Eric Sabourin, filmmakers
        Werner Herzog and Nina Gladitz, and, later, photographers Eléonore Lubna
        and Louis Matton. The movement of information, objects, and people
        toward and from western Peru raises questions of power, networks, and
        legitimacy. Western visitors are mobile and responsive—see for example
        the surprising speed with which Sabourin is able to relay Awajún news to
        the international press, or how quickly Nina Gladitz is able to arrive
        on the scene in Wawaim. Westerners’ role, in the eyes of the council, is
        to convey indigenous claims and make visible the faces of its members to
        their respective countries. Thus, in 1978, two information
        machines—Herzog’s and the CAH’s—co-existed, each supported by their
        respective cultural intermediaries. Communicating through Westerners, in
        addition to leveling the technical and linguistic-legal “battlefield,”
        assures an extra-regional and international audience for the CAH.
      </p>
      <blockquote className="indented">
        <ol>
          <li>
            Show students a seven-minute video of an Awajún woman making a pot.
            Discussion: Were their assumptions about the pot’s creation correct?
            What part of the pot’s creation was not shown in the video?
            (sgraffito)
          </li>
          <li>
            Discuss Eléonore’s and Louis’s visits with the Awajún. How did the
            pot get to France? Did Eléonore and Louis buy it, or was it a gift?
            How do the Awajún circulate their ceramics?
          </li>
          <li>
            Discuss the multi-authored creation of the pot. What does this do to
            the pot? Who is considered the author/creator? What objects do
            students own that have been created in this way? Have they
            collaborated with people to make something?
          </li>
        </ol>
        <p className="right-justify">
          <strong>Lesson plan by Sam Rastatter (June 2019)</strong>
        </p>
      </blockquote>

      <p>
        The disparities between two judicial-social systems—one founded on
        reciprocal obligations and collective redistribution, and the other on
        private property and contractual agreements—explains the repeated
        recourse to Western intermediaries, consultants, and technical
        assessors. When Eléonore and Louis arrived in Wawaim, the Awajún people
        challenged their desire to stay. A form of contribution on their part
        was expected, and mutually agreed upon in the form of a series of
        English courses and photography workshops. In addition, the
        photographers proposed a ceramics project, centered on the idea of
        (re-)telling the Herzog story: “It isn’t simply a matter of ‘passing
        through’ as a photographer, but of sitting down, of sitting down and
        working with people” and of finding “common ground where we can meet,
        and talk, that is not based on Western technologies.”
        <sup>
          <a href="#annotation-14">14</a>
        </sup>{" "}
      </p>

      <p>
        Louis’s words demonstrate his desire to avoid previous forms of
        predation and to elaborate, together with the Awajún people, more
        equitable forms of exchange—understood both as <em>communication</em>{" "}
        and as <em>transaction</em>. To do so requires explicitly asking: “What
        is given and what is sold, from one side to the other?” The
        photographers give workshops and buy pots at a price determined by their
        makers. On those pots, they illustrate a history that is not their own,
        but of which they are now the new chroniclers. The pots, which were
        already exhibited once in France, will probably continue to tour in
        Europe over the next few years. If the pots are sold (at the time of
        writing, this decision had not yet been made), the profits will be
        reinvested in the Wawaim community, according to the principle of
        dual-price exchange elaborated by Dominique Temple.
        <sup>
          <a href="#annotation-15">15</a>
        </sup>{" "}
      </p>

      <h2>Relationships</h2>

      <p>
        Above and beyond its transactional aspect, this set of dishes and
        recipients seals a relationship and implicates both parties in
        reciprocal obligations, according to well-defined roles: the Awajún
        people are recognized as “possessors of knowledge” and “producers of
        narratives,” and the photographers as guardians of an object that
        embodies that narrative. This emphasis on relationship, rather than on
        the object, favors an indigenous perspective, where relationships are
        woven together through repeated practice, over Western ideas of culture
        as an accumulation of objects. As philosopher Jean-François Lyotard
        writes:
      </p>

      <blockquote className="indented">
        <p>
          “Tradition is that which concerns time, not content. Whereas what the
          West wants from autonomy, invention, novelty, self-determination, is
          the opposite—to forget time and to preserve, and accumulate contents.
          To turn them into what we call history and to think that it progresses
          because it accumulates. On the contrary, in the case of popular
          traditions […] nothing gets accumulated, that is the narratives must
          be repeated all the time because they are forgotten all the time. But
          what does not get forgotten is the temporal beat that does not stop
          sending the narratives to oblivion […] This is a situation of
          continuous embedding, which makes it impossible to find a first
          utterer.”
          <sup>
            <a href="#annotation-16">16</a>
          </sup>{" "}
        </p>
      </blockquote>

      <p>
        I’d like to link this thought—from object to the social tie—to Black
        artist and writer Anthony Romero’s injunctions to US cultural
        institutions:
      </p>

      <blockquote className="indented">
        <p>
          “Colonialism imported much of what lies at the core of your
          institution including the twin ideas of property and ownership.
          Museums have always been about the care of property, material and
          intellectual, but what if your institution were to break from this
          tradition? What if your institution committed itself to building
          social relations over property relations?”
          <sup>
            <a href="#annotation-17">17</a>
          </sup>{" "}
        </p>
      </blockquote>

      <p>
        Let us imagine, along with Temple, Lyotard, and Romero, an economy of
        exchanges and representations centered on reciprocal obligations, the
        durability of relationships constituted by those obligations, and the
        craft practices that shape those relationships. This economy is not
        brought to an end after the first transaction—money for a pot. On the
        contrary, the first transaction merely inaugurates further opportunities
        for working and storytelling, infinitely repeated, that invite Amalia
        and her colleagues to continue their practice
        <sup>
          <a href="#annotation-18">18</a>
        </sup>{" "}
        of making, and Eléonore and Louis to continue their efforts of
        preservation and raising awareness. A wall text that echoes this economy
        might look like this:
      </p>

      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_9_Yukun_pot.jpg"}
        />
        <figcaption>
          Contractual object connecting French chronicler-guardians to a
          community of Awajún maker-possessors of knowledge around a project of
          collaborative fabrication, memorialization, and diffusion outside of
          the community (2019 to present-day). Photo: Eléonore Lubna and Louis
          Matton
        </figcaption>
      </figure>

      <h2>Contradictions</h2>

      <p>
        The image labels proposed so far in this essay distance themselves
        progressively from the author-object, in order to emphasize community
        and relation. But talking about the pot in terms of contract and
        reciprocal obligations cannot eradicate the friction between the economy
        of trade and exchange that exists all along the Marañon River, and the
        economy of the subsidized art world in which the pots will circulate,
        until further notice. Thus, I can’t preserve the contradictions present
        at the heart of each label, any more than I can link them together with
        a “yes, and,” as in improvisational theater, in order to maintain two
        different intentions side by side, rather than replacing one with
        another.
        <sup>
          <a href="#annotation-19">19</a>
        </sup>{" "}
      </p>

      <p>
        For example, I ask myself how a label might “integrate indigenous
        knowledge” while dismantling Western inventory categories and
        descriptive nomenclatures, in order to let collective authors connect a
        work to genealogies of knowledge that feel appropriate to them. The
        project “Traditional Knowledge (TK) Labels” has begun to do this work:
      </p>

      <blockquote className="indented">
        <p>
          “The TK Labels are a tool for Indigenous communities to add existing
          local protocols for access and use to recorded cultural heritage that
          is digitally circulating outside community contexts. The TK Labels
          offer an educative and informational strategy to help non-community
          users of this cultural heritage understand its importance and
          significance to the communities from where it derives and continues to
          have meaning. TK Labeling is designed to identify and clarify which
          material has community-specific restrictions regarding access and use.
          […] The TK Labels also can be used to add information that might be
          considered ‘missing,’ including the name of the community who remains
          the creator or cultural custodian of the material, and how to contact
          the relevant family, clan, or community to arrange appropriate
          permissions. […] The TK Labels also make it clear that Indigenous
          communities are stewards of these materials, regardless of Western
          legal structures.”
          <sup>
            <a href="#annotation-20">20</a>
          </sup>{" "}
        </p>
      </blockquote>

      <p>
        Furthermore, I ask myself how to let the project’s affiliation with
        existing know-hows (a repertory of generic forms that are rarely
        attributed) live side by side with the irruption of formal innovation:
        in our case, the figurative drawings that illustrate the Herzog affair,
        for example, are quite removed from the traditional Awajún repertory of
        forms. <em>They</em> are attributed: to Eléonore and Louis, and also, in
        conversation, to Edith, Amalia’s daughter.
      </p>

      <p>
        Must we envision multiplying labels so that one that celebrates
        tradition and continuity can be put side by side with another that
        emphasizes authorial gestures? And by the way, how would Amalia herself
        describe this pot? This work of attribution and of description is
        unthinkable without her voice. Why did she agree to make these pots?
        What does she expect from this transaction, and how would she describe
        these objects and their provenance?
      </p>

      <p>
        Above all, how can we even ask her this question, without predetermining
        her response in accordance to codes that are foreign to her?
      </p>

      <h2>Chains</h2>

      <p>
        The idea of consulting Amalia brings up several new elements in my
        reflections on knowledge and power. Eulogio and Liza, the two teachers
        in Wawaim, have internet access. Louis Matton can send them an email—but
        diplomatically speaking, he informs me, it’s counterproductive to have
        them carry a message to Amalia, the ceramicist. It will therefore be up
        to her cousin Timoteo to do it: he knows Amalia well, and he can
        translate from Spanish to Awajún, and vice-versa. This chain—that
        connects the question that Eléonore, Louis, and I are asking, to
        Eulogio’s and Liza’s computer, to Timoteo’s house, and finally to
        Amalia’s workshop—is full of semantic pitfalls, or possible
        translations, where each party will probably use their ingenuity to find
        the best form of communication for their interlocutor, according to his
        or her ideas, a little like I’ve done in this essay.
      </p>

      <p>
        Timoteo responds in a recorded audio message. His words, mixed up with
        Amalia’s words, return the pots to their place of origin, underlining
        the proximity of the clay to place and to community, and pointing to a
        cycle of birth and shape-giving that the book Louis and Eléonore are
        working on will reveal:
      </p>

      <blockquote className="indented">
        <p>
          “It’s very important [to say] in this book how the ceramicists began,
          what for and who [they] are. You must start there, where it begins:
          what you get, where you get [the earth] to make this kind of pot. It’s
          not easy, there are processes: beginning, middle, and end. You should
          tell all this and do it in the name of the Wawaim community. Because
          this is the origin, the birthplace, this is where they find the clay,
          here where they fabricate, you saw for yourself …”{" "}
        </p>
      </blockquote>

      <p>
        If the book provides a framework to make sense of the ceramicists’
        practice, almost acting as an <em>a posteriori</em> justification, it
        also clearly creates reciprocal obligations: “You should name the
        authors so that on an international or national level people can see in
        this book [who are we? what are we doing?] we would be grateful” and
        later: “Amalia continues to work, to give, and they are waiting, for
        when you will look for a market for the pots and begin to sell them:
        that’s what they say.”
        <sup>
          <a href="#annotation-21">21</a>
        </sup>{" "}
      </p>

      <p>
        The shift from “she” to “them” echoes the multiplicity of “institutions”
        to which Amalia connects the work she’s done. She talks about “her name,
        her family, the authority” and later mentions “the founder of this
        community.”
      </p>

      <figure>
        <img
          src={process.env.PUBLIC_URL + "/img/notaretreat/B_N_9_Yukun_pot.jpg"}
        />
        <figcaption>
          Clay in motion: extracted and shaped in Wawaim (2019) by
          hands/people/families connected to the studio of Amalia Wisum Chimpa,
          moved and reinterpreted by temporary visitors, reproduced here in a
          book that articulates its significance in 245 pages. For new orders,
          contact the workshop. Photograph: Eléonore Lubna and Louis Matton
        </figcaption>
      </figure>

      <p>
        The pots’ migration from the northwest of Peru to France influences
        their status and meaning and informs their demonstrative power alongside
        the numerous documents mobilized by Eléonore and Louis, first presented
        at the Centre Photographique d’Ile de France, and, soon, in their
        forthcoming book.
      </p>

      <p>
        Arjun Appadurai’s concept of an object as an “accidental refugee”
        invites us to always take the long “cultural biography”
        <sup>
          <a href="#annotation-22">22</a>
        </sup>{" "}
        of displaced objects into consideration, to go ceaselessly to battle,
        questions in hand, on the minuscule semantic territory that is the
        label. It is also within wall texts that the colonial project is defined
        and unraveled, and that the destiny of objects in movement gets locked
        in. This fight will be worthwhile for as long as the questions of who is
        the master
        <sup>
          <a href="#annotation-23">23</a>
        </sup>{" "}
        and who speaks continue to intersect.
        <sup>
          <a href="#annotation-24">24</a>
        </sup>{" "}
      </p>

      <h2>Acknowledgements</h2>
      <p>
        This writing was informed by multiple conversations with Eléonore and
        Louis, and the rich trove of documents they shared with me, between
        November 2019 and February 2020, then from April to December 2021. I am
        often unable to distinguish between their ideas and mine in the above
        writing, and I thank them for having agreed to submit their project to
        this relentless interrogation. I would also like to thank Clotilde
        Lebas, to whom I owe the Michel de Certeau reference, for reading and
        rereading this essay. My gratitude to Youna Kwak, finally, who so
        elegantly translated this text during <em>les vacances scolaires</em>,
        and to Namita Gupta Wiggers, for making that translation possible.
      </p>
      <hr />

      <cite>
        <sup id="annotation-1">1.</sup> The Masters in Critical Craft Studies,
        directed by Namita Gupta Wiggers, is a multidisciplinary and
        non-canonical program that borrows investigative and analytic
        methodologies from anthropology (especially material culture studies),
        regional history, cultural mediation, and philosophy. The “Material Lab”
        course has a strong pragmatic and experimental dimension. In the course,
        students are invited to put both their theoretical and practical
        knowledge to use, as they take on the role of producers. Warren Wilson’s
        campus is located in Appalachia, along the border of North Carolina.
        Originally a farm school established in proximity to Cherokee territory,
        to Black Mountain College, and to the Penland School of Craft, the
        College has proved fertile ground for exploring the multicultural
        affiliations that craft objects can claim for themselves.
      </cite>

      <cite>
        <sup id="annotation-2">2.</sup> This day-long seminar hosted by the
        jewelry collective La Garantie, Association pour le bijou consisted of a
        series of live-broadcast conversations with five scholars and artists
        from the world of jewelry-making, who were all asked the same question:
        how do you learn with objects? Recordings of the conversations, in
        French and English, are available{" "}
        <a
          href="https://lagarantie.org/faire-avec-les-objets-regarder-discuter-apprendre-making-do-with-objects-looking-conversing-learning/"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        .{" "}
      </cite>

      <cite>
        <sup id="annotation-3">3.</sup> The exhibition was held in January 2022
        at the Centre Photographique d’Ile de France, as one of three
        exhibitions on documentary photography practices the Centre mounts each
        year. The Centre’s site design conforms to the sanitized white space and
        uninterrupted natural light of “white cube” display principles. The
        “white cube” environment encourages viewers to perceive objects on
        display as “contemporary art” rather than as ethnographic or artisanal
        objects. At the time of this writing (December 2021), Eléonore and Louis
        had yet to address several questions about the exhibition of the pots,
        in particular about how they would be labeled. This essay dwells in the
        indeterminate space opened up by these possibilities.
      </cite>

      <cite>
        <sup id="annotation-4">4.</sup> <em>Yukùn</em> pots are small, black,
        flared bowls with a cylindrical base a few centimeters in height and
        diameter, widened by coiled sections into a cone about 15 centimeters in
        diameter, and generally used for drinking Wais, an emetic and laxative
        beverage. <em>Yukùn</em> represents one of the six traditional Awajún
        forms recorded in the catalog <em>Cerámica Tradicional Awajún</em>{" "}
        (Lima: Ministerio de Cultura del Perú, 2015).
      </cite>

      <cite>
        <sup id="annotation-5">5.</sup> Sgraffito refers to incisions made on
        the surface of dry clay before it’s fired in an oven. The resulting
        motifs and the colors applied by dip-dying or by paintbrush comprise the
        pot’s “decoration.”
      </cite>

      <cite>
        <sup id="annotation-6">6.</sup> The French alternatively translate
        “label” as the words “cartel” and “légende.” The latter excitingly
        contaminates the project of being authoritative with connotation of
        unverifiability.
      </cite>

      <cite>
        <sup id="annotation-7">7.</sup> Leone Samu Tui, “Teu Le Vā—Evolving
        Relationships and Practice in the Wake of the Pacific Collection Access
        Project,” in <em>Uneven Bodies</em> (Reader) (New Plymouth:
        Govett-Brewster Art Gallery, 2021). This symposium accompanied the
        re-installation of the Govett-Brewster collections by guest artist Ruth
        Buchanan to mark the New Zealand gallery’s 50-year anniversary.
        Buchanan’s project aimed to interrogate the inequality and power
        dynamics that are inscribed in public cultural institutions. Some of the
        contributions—like Tui’s—focus specifically on the language acts the
        institution uses to express itself: nomination, changing definitions,
        de-anonymization, etc. This essay is inspired by current theory emerging
        from New Zealand about biculturalism and, more broadly, the dynamic of
        “re-indigenization” of knowledge that is strongly emerging at the dawn
        of the 21st century. See, for example, recent efforts to better
        integrate nomad, indigenous, and/or traditional knowledge in cultural or
        academic institutions. I’m thinking here of the new glossary created
        jointly by museums in Trondheim, Malmö, and Helskini (
        <a href="http://museumwhy.com/" target="_blank" rel="noreferrer">
          here
        </a>
        ), or of Lorisia MacLeod’s project of reimagining the citation templates
        for oral knowledge, published in the University of Victoria Libraries
        journal KULA (Knowledge Creation, Dissemination, and Preservation
        Studies),{" "}
        <a
          href="https://kula.uvic.ca/index.php/kula/article/view/135"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        .{" "}
      </cite>

      <cite>
        <sup id="annotation-8">8.</sup> “In 1857, the British House of Commons
        passed a rule that, in National Museums, objects of art, science, and
        historical interest would thenceforth be accompanied by ‘a brief
        Description thereof, with a view of conveying useful Information to the
        Public, and of sparing them the expense of a Catalogue.’” F. J. North,{" "}
        <em>Museum Labels: Handbook for Museum Curators</em>, Part B, Section 3
        (London: Museum Association, 1957), 4. Cited in Ingrid Schaffner, “Wall
        Text, 2003/6, Ink on Paper, Courtesy of the Author,” in Paula Marincola
        (ed.), <em>What Makes a Great Exhibition</em> (London: Reaktion Books,
        2007), 157.
      </cite>

      <cite>
        <sup id="annotation-9">9.</sup> Eric Sabourin arrived in Peru on March
        11, 1979, and became the CAH’s most visible Western ally. Originally
        employed as an agricultural assessor by the Awajún and Huambisa
        communities, Sabourin quickly became a privileged witness, then a
        chronicler, of the predatory actions of Herzog’s Wildlife Film Company
        (see Sabourin, “Réponse des communautés indiennes aux agressions
        occidentales,” <em>Journal de la Société des Américanistes</em> 67
        [1980]: 441–460). Sabourin continues to serve as a resource for the
        preservation and transmission of the Herzog affair papers. He shared
        them with Eléonore Lubna and Louis Matton, among others.
      </cite>

      <cite>
        <sup id="annotation-10">10.</sup> See the film-manifesto{" "}
        <em>Stranger with a Camera</em> (1999), in which director Elizabeth
        Barret interviews residents of a small town in Kentucky about the death
        of Canadian filmmaker Hugh O’Connor, who was killed in 1967 while
        shooting a documentary on marginalized, rural white American poverty.
        Barret’s film insists on the media’s responsibility in fabricating one
        of the most tenacious stereotypes of North American culture: the figure
        of the indigent, illiterate, parochial redneck—a stereotype that is
        subsequently picked up in many nondocumentary films. Many thanks to my
        colleague Jeff Keith for introducing me both to the film and to the
        critical conversation it is part of.
      </cite>

      <cite>
        <sup id="annotation-11">11.</sup> For a detailed report on proposals for
        UNESCO representatives to make audiovisual materials available to the
        Awajún people, and to train them to capture images, and on the CAH’s
        response to those proposals, see Éric Sabourin,{" "}
        <em>
          Indiens et médias: le cas d'Aguarunas (Pérou) face à l'audio-visuel
        </em>{" "}
        (typewritten, undated [1979?] and unpublished). Among the most important
        gains that technological autonomy for the Awajún people would provide,
        writes Tabourin, would be the use of the image as a propaganda tool for
        the CAH, firstly, and the “autonomous, cultural economic and social
        development of the ethnic group,” secondly (Tabourin, 14). These two
        goals are very different from those of UNESCO and the technicians sent
        on location.
      </cite>

      <cite>
        <sup id="annotation-12">12.</sup> See the passages on “tactics of
        practice” in Michel de Certeau, <em>The Practice of Everyday Life</em>,
        translated by Stephen Randell (Berkeley: University of California Press,
        2011).
      </cite>

      <cite>
        <sup id="annotation-13">13.</sup> According to Louis Matton, the arson
        of Herzog’s camp was a symbolic way to destroy the “making of [exotic]
        images”—<em>the Casa de Imagenes</em>—of which the Western world never
        tires. The fire that burns in Amalia’s ceramics workshop responds to
        that fire, thus substituting itself for the <em>Casa de Imagenes</em> as
        a site of production and diffusion of knowledge. Author’s conversation
        with Louis Matton, May 25, 2022. See Nina Gladitz’s film{" "}
        <em>Land der Bitterkeit und des Stolzes</em> (1981).
      </cite>

      <cite>
        <sup id="annotation-14">14.</sup> Author’s conversation with Louis
        Matton, May 25, 2022.
      </cite>

      <cite>
        <sup id="annotation-15">15.</sup> This system of compensation was
        developed by the Belgian economist Bernard Lietaer, and formalized by
        French philosopher Dominique Temple, another comrade-in-arms of the
        Awajún struggle. Temple proposes that two different compensations should
        structure the transaction between craftsperson and Western buyer, with
        the first compensation occurring at the time of purchase in the location
        the object was made, and the second as a commission indexed to the value
        that the artisanal object will later have in a Western commercial
        circuit. See “What is the Shipibo contract?” (1992),{" "}
        <a
          href="http://dominique.temple.free.fr/reciprocite.php?page=reciprocite_2&id_article=20"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        . See also, in the same vein,{" "}
        <em>The Artist’s Reserved Rights Transfer and Sale Agreement</em>{" "}
        (1971), by American art dealer Seth Siegelaub and lawyer Bob Projansky,
        in favor of “ongoing rights” for artists “over the use of their work and
        participation in its economics after they no longer own it,” i.e., as
        the work accrues value through future sales (
        <a
          href="https://en.wikipedia.org/wiki/The_Artist%27s_Reserved_Rights_Transfer_and_Sale_Agreement"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        ).
      </cite>

      <cite>
        <sup id="annotation-16">16.</sup> Jean-François Lyotard in Homi Bhabha,{" "}
        <em>The Location of Culture</em> (London: Routledge, 1993), 81.
      </cite>

      <cite>
        <sup id="annotation-17">17.</sup> Anthony Romero, in response to the
        Walker Art Center’s call for 2019 New Year’s resolutions for American
        art museums,{" "}
        <a
          href="https://walkerart.org/magazine/soundboard-museum-resolutions-anthony-romero"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        . Challenging the practice of tokenism by institutions in their search
        for inclusive images, Romero proposes supporting communities and
        nourishing relationships rather than collecting objects and knowledge. I
        think I was introduced to this text by MACR colleague Judith Leemann.
      </cite>

      <cite>
        <sup id="annotation-18">18.</sup> Between the time that this essay was
        commissioned and the time of writing, Awajún ceramics received the
        UNESCO label of Intangible Cultural Heritage (ICH),{" "}
        <a
          href="https://ich.unesco.org/en/RL/pottery-related-values-knowledge-lore-and-practices-of-the-awajn-people-01557"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        . This convention, voted by UNESCO in 2003, focuses precisely on
        practices and their importance within a community, rather than
        protecting material objects, independently of those who make them.
        Inscription on the ICH list thus emphasizes, among other things, the
        role of pottery practices in the empowerment of Awajún women. The ICH
        label guardrails the maintenance of intangible patrimony, but does not
        fix it into rigid forms.
      </cite>

      <cite>
        <sup id="annotation-19">19.</sup> The “yes, and” protocol originates in
        improvisational theater practices, and inspired the work of American
        artist Lauren Sinner, who taught a workshop at the MACR program. Each
        new actor joining an improvisation that is underway is asked to use
        “yes, and” in order to acknowledge what has already been said, and to
        build continuity on the basis of this acknowledgement. MACR program
        director Namita Wiggers, in turn, has framed “yes, and” as an
        alternative to “and/or,” i.e., as a way of building continuity while
        questioning binary models.
      </cite>

      <cite>
        <sup id="annotation-20">20.</sup> The project of “Traditional Knowledge
        (TK)” and “Biocultural (BC)” labels is part of a platform for management
        of indigenous cultural heritage,{" "}
        <a href="https://mukurtu.org/about/" target="_blank" rel="noreferrer">
          here
        </a>{" "}
        and{" "}
        <a
          href="https://localcontexts.org/labels/traditional-knowledge-labels/"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        . Initially launched in New Zealand, this protocol is currently being
        used in the United States, Canada, and Australia. It echoes intra- and
        extra-institutional discussions on modes of archiving, accessing, and
        categorizing objects linked to indigenous communities.
      </cite>

      <cite>
        <sup id="annotation-21">21.</sup> From Louis Matton’s transcription of a
        Facebook Messenger audio message recorded by Timoteo, December 2022,
        translated from the Spanish by Louis Matton.
      </cite>

      <cite>
        <sup id="annotation-22">22.</sup> Arjun Appadurai, “Museum Objects as
        Accidental Refugees,” in Johan Deurell (ed.),{" "}
        <em>Migration: The Journey of Objects</em> (Gothenburg: Röhsska Museum
        of Design and Craft, 2021).
      </cite>

      <cite>
        <sup id="annotation-23">23.</sup> Here, an attentive reader might recall
        an exchange between Alice and Humpty Dumpty that clearly draws a thread
        between power and knowledge: “‘When I use a word,’ Humpty Dumpty said in
        rather a scornful tone, ‘it means just what I choose it to mean—neither
        more nor less.’ ‘The question is,’ said Alice, ‘whether you can make
        words mean different things—that’s all.’ ‘The question is,’ said Humpty
        Dumpty, ‘which is to be master—that’s all.’” Lewis Carroll,{" "}
        <em>Through the Looking Glass</em> (London: Macmillan, 1971).
      </cite>

      <cite>
        <sup id="annotation-24">24.</sup> The role of labels in preserving—or
        challenging—a Western-centric, and colonial, epistemic order is getting
        more attention. Of particular note, in the context of this publication,
        are Professor Tiffany Momon’s work on extended captions for the
        exhibition <em>Troubled Like the Restless Sea</em>, organized by the
        Chipstone Foundation at the Milwaukee Art Museum (2022), in which the
        objects are framed as bearing witness to the colonial order in which
        they were first deployed. In France, curator Anne Dressen has taken a
        leave from her position as senior curator for the Musée d’Art Moderne to
        pursue a PhD dedicated to re-activating dormant museum collections, and
        expending both the timeline, and the perspectives, that labels account
        for.
      </cite>
    </div>
  );
};

export default Lignel;
