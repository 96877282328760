const Levine = () => {
  return (
    <div>
      <p>
        In January 2022 I had the pleasure of leading a remote zine-making
        workshop and discussion with the students and faculty in the MA in
        Critical Craft program. During the hands-on workshop we discussed a
        passion of mine: independent publishing and zine-making. Our discussions
        were shaped around three core readings.
        <sup>
          <a href="#annotation-1">1</a>
        </sup>{" "}
        We also used resources by Kate Bingaman-Burt, various volumes of{" "}
        <em>Written Names</em>, and the recording of{" "}
        <em>Towards a Self-Sustaining Publishing Model</em>.
        <sup>
          <a href="#annotation-2">2</a>
        </sup>{" "}
        I also shared personal favorites from my extensive collection, pulling a
        wide range of materials from 1995–2018.{" "}
      </p>
      <p>
        Similar to my workshop, this contribution to this publication highlights
        my passion for printed matter of all sorts. Below is an archive of my
        physical desktop in February 2023, culled from ephemera held between
        three file organizers that sit in front of me daily. This is the place
        where I tuck postcards, articles, handouts, essays, zines, catalogs,
        letters, and books—items I intend to circle back to, work with, haven’t
        fully engaged with yet, or want to send to someone. These collections of
        piles and stacks are a part of my building process for creating
        exhibitions, building programming, brainstorming syllabi for information
        sharing. As a visual learner, these stacks help me make connections
        between ideas and thoughts.
      </p>
      <figure>
        <img
          src={
            process.env.PUBLIC_URL +
            "/img/notaretreat/B_N_10_Levine_Figure1.jpg"
          }
        />
        <figcaption>
          <em>Figure 1</em>. Faythe Levine, Instagram screenshot “Kicking off
          the new year with facilitating a 2 day virtual workshop with
          @macraftstudieswwc on zines & independent publishing. Pulled out some
          gems from my collection to share tomorrow, 1995-2018,” January 7,
          2022.
        </figcaption>
      </figure>

      <p>
        I went through them in the order things were tucked away, from left to
        right. I proceeded to pull a sentence or two from nearly every item, not
        overthinking my decisions, an exercise of revisiting. The following list
        can be an experimental syllabus, a poem, a train of thought, or—my
        personal favorite—bait for going down deep worm-hole research spirals. I
        can see many themes and overlaps of worlds that may not be as obvious to
        a reader not intimately familiar with the authors or communities
        represented here. But with this exercise I also discovered so many
        dotted lines I hadn’t seen before. For example, I can picture{" "}
        <em>The Floating Foundation of Photography</em> docked for a visit and
        right at home in the Wolf Spider Island boat house community. I imagined
        a conversation between Indira Allegra and Richard Blanco discussing the
        overlaps between their respective works{" "}
        <em>Grief-threads and Grief-portals</em> and{" "}
        <em>On the Craft of My Loneliness</em>. I got excited after rereading
        Allie Wist’s <em>Tendency to Collapse (Anthropocene Anarchive)</em>,
        realizing it’s the perfect thing to send to Sto Len, a friend who is the
        first artist-in-residence at the New York Department of Sanitation.
        <sup>
          <a href="#annotation-3">3</a>
        </sup>{" "}
        My hope is that if you look closely, you likely will discover and
        connect them to your own, expanding our webs further. Connecting and
        sharing is how we build community and networks.{" "}
      </p>

      <p>Faythe Levine</p>

      <p>
        <strong>Proofreader note:</strong> The style and punctuation of the
        original publications have been preserved, even when they clashed with
        this publication’s own.
      </p>

      <p>*</p>

      <h2>File 1</h2>
      <p>
        All sorts of things influence me. I let things influence me. If they
        catch my interests I let them take hold.
        <sup>
          <a href="#annotation-4">4</a>
        </sup>{" "}
      </p>

      <p>
        In the field situation, the interviewer is usually the ignorant person,
        at least in terms of the culture being investigated. The theme of
        fieldwork as a learning experience is discussed later in this interview.
        <sup>
          <a href="#annotation-5">5</a>
        </sup>{" "}
      </p>

      <p>
        At that time, I saw television as a one-way communication system, coming
        directly at the viewer without the viewer’s ability to “talk back.” I
        wanted to disrupt the flow of imagery in programs such as the top-rated
        show <em>Wonder Woman</em>.
        <sup>
          <a href="#annotation-6">6</a>
        </sup>{" "}
      </p>

      <p>
        I couldn’t help but think about the time I spent preparing my own
        sketchbooks for what may happen to them. Not many 20 year olds have a
        will. Illness forces you to think about death, but in the end everything
        left behind is only for the living.
        <sup>
          <a href="#annotation-7">7</a>
        </sup>{" "}
      </p>

      <p>
        Landfills are archives in that they are sites of accumulation, but ones
        that permit decay, in contrast to preservation. They also similarly rest
        on colonial power in that they do not function without continued access
        to unceded Indigenous lands.
        <sup>
          <a href="#annotation-8">8</a>
        </sup>{" "}
      </p>

      <p>
        In service of greater literacy, we need to consider what the space
        between the threads can offer us when engaged in the study of cloth
        grammar.
        <sup>
          <a href="#annotation-9">9</a>
        </sup>{" "}
      </p>

      <p>
        In the early years the only access was by boat. Since Sing Sing has
        become a reception center, you now arrive to be processed, sorted, and
        finally “boated” to where you will spend your term. Appropriately
        enough, we too would come by boat at one point, journeying up the river
        in the purple houseboat that serves as the headquarters for the Floating
        Foundation of Photography. But we came first by land. It was March of
        1971, and we had arranged a photography exhibit for the Sing Sing
        inmates.
        <sup>
          <a href="#annotation-10">10</a>
        </sup>{" "}
      </p>

      <p>
        The Pause Apothecary acknowledges that it is not just women who
        experience menopause, but a diverse group of people including those who
        identify as nonbinary, transgender, gender nonconforming, and more.
        <sup>
          <a href="#annotation-11">11</a>
        </sup>{" "}
      </p>

      <p>
        In 1909, Philadelphia merchant-prince John Wanamaker bought the
        instrument for his new Philadelphia emporium. Thirteen freight cars were
        required to ship the entire organ from St. Louis, and installation took
        two years.
        <sup>
          <a href="#annotation-12">12</a>
        </sup>{" "}
      </p>

      <p>
        One doesn’t have to be a librarian or an artist to help improve the
        Chicago Artist Files. Anyone could hand over material about an artist in
        the files. The person that attends exhibitions and events all over the
        city and picks up ephemera along the way could gift these things to the
        library.
        <sup>
          <a href="#annotation-13">13</a>
        </sup>{" "}
      </p>

      <p>
        Loneliness is my only craft since you’ve gone: my waiting is my chisel
        cleaving through time, this silence a new skill I’ve reluctantly had to
        master, my memories the medium that binds up while apart.
        <sup>
          <a href="#annotation-14">14</a>
        </sup>{" "}
      </p>

      <p>
        The zine, the softest form of any published material, is tender. There
        are cultural codes built into its form, which it signifies long before
        you turn its first page.
        <sup>
          <a href="#annotation-15">15</a>
        </sup>{" "}
      </p>

      <p>
        Long before the music festival in 1969 made Woodstock, New York, famous,
        it was home to what is considered America’s first intentionally created,
        year round arts colony—founded in 1902 and still thriving more than 100
        years later.
        <sup>
          <a href="#annotation-16">16</a>
        </sup>{" "}
      </p>

      <p>
        We are learning. We are trying. Say it again. Say it together.
        <br />
        Progress not perfection.
        <br />
        Progress not perfection.
        <br />
        Nothing changes if nothing changes.
        <br />
        Nothing changes if nothing changes.
        <sup>
          <a href="#annotation-17">17</a>
        </sup>{" "}
      </p>

      <p>
        Are any of my five watches still ticking in the locker? no sooner did I
        have the thought to buy another than my phone was showing me Swatch ads.
        last year in the office I had at least uttered “Swedish Fish” before
        they swam into my feed.
        <sup>
          <a href="#annotation-18">18</a>
        </sup>{" "}
      </p>

      <h2>File 2</h2>

      <p>
        Scaling something down—taking it from actual size to mini—is a form of
        abstraction.
        <sup>
          <a href="#annotation-19">19</a>
        </sup>{" "}
      </p>

      <p>
        What can I do for you—This is the password—What can I do.
        <sup>
          <a href="#annotation-20">20</a>
        </sup>{" "}
      </p>

      <p>
        Communication is a tricky thing here. You may sit down to draft a letter
        only to find the response has been in your mailbox for a week.
        <sup>
          <a href="#annotation-21">21</a>
        </sup>{" "}
      </p>

      <p>
        If I look backward, I am a weekender. If I look forward, I am a local.
        From where I stand at this moment in time I feel like I am the ecotone
        between the two.
        <sup>
          <a href="#annotation-22">22</a>
        </sup>{" "}
      </p>

      <p>
        I didn’t have kids. I don’t eat animals or use animal products. I drive
        a plug-in hybrid (and feel guilty for driving at all.) I barely water my
        yard, but water gets out there anyhow through the greywater system.
        <sup>
          <a href="#annotation-23">23</a>
        </sup>{" "}
      </p>

      <p>
        Kangaroos don’t condone cops.
        <sup>
          <a href="#annotation-24">24</a>
        </sup>{" "}
      </p>

      <p>
        Naomi told me British geologists often send each other rocks in the
        mail, with their universities picking up the postage bill. The
        university archives the letters and rocks separately, frustratingly, the
        letters often mention “see rock.”
        <sup>
          <a href="#annotation-25">25</a>
        </sup>{" "}
      </p>

      <p>
        Setting aside time for leisure is an act of rebellion in a world that
        dictates we must always be hustling in a society that has shown no care
        for us. Today (and everyday!) we burn it all down. We give ourselves
        permission to imagine a world we want to live in.
        <sup>
          <a href="#annotation-26">26</a>
        </sup>{" "}
      </p>

      <p>
        This is an asynchronous site-specific audio walk performance and
        accompanying zine. Go do the audio walk! Go to: The Maine Motel, 606
        Main Street, South Portland, ME 04106.
        <sup>
          <a href="#annotation-27">27</a>
        </sup>{" "}
      </p>

      <p>
        In this presentation, editor of{" "}
        <em>Marilyn Nance: Last Day in Lagos</em> Oluremi C. Onabanjo will
        unpack the various stages of her close collaboration with Nance that
        yielded this distinctive photobook.
        <sup>
          <a href="#annotation-28">28</a>
        </sup>{" "}
      </p>

      <p>
        [Marjorie] Kreilick’s proposal separated the state’s geography into nine
        ecological divisions as the subject matter for the murals, and she was
        willing to walk away from the commission in order to stay true to her
        vision. As she observed, “Industries come and go … I wanted to show the
        state before man got here, to show the ecological areas and recognize
        some of the contributions that came from the indigenous Indians, the
        landscape before white man came. I wanted to do something that would be
        the essence of Wisconsin.”
        <sup>
          <a href="#annotation-29">29</a>
        </sup>{" "}
      </p>

      <p>
        I call it the <em>river</em>, Saidiya Hartman calls it the{" "}
        <em>chorus</em>, Lukaza Branfman-Verissimo calls it the <em>web</em>.
        <sup>
          <a href="#annotation-30">30</a>
        </sup>{" "}
      </p>

      <p>
        I am of the belief that outdated ideals and practices carry useful
        information that can be utilized to help us understand the context in
        time and place. They can help us in thinking about the social and
        cultural shifts that have brought us to the understandings we have now.
        <sup>
          <a href="#annotation-31">31</a>
        </sup>{" "}
      </p>

      <p>
        One after another, administration after administration proposed
        different commercial, cultural, and commercial-cultural plans,
        twenty-seven in total; automobile center, performance center, museum of
        art, tourism center, modern cemetery, center of radio and television,
        multi-cinema, national library, museum of anthropology, and
        environmental center, to name a few.
        <sup>
          <a href="#annotation-32">32</a>
        </sup>{" "}
      </p>

      <p>
        Others are utopian feminists, perhaps anarchists: for them, it’s ALL
        ABOUT care, collectivity, and cooperation. They believe that a more
        caring, liberatory, and queer-embracing world will emerge from bodies in
        motion, resisting, resting when necessary, practicing mutual aid.
        Because they know that establishment culture will never nourish them,
        people in this category often orient toward creative and artistic
        practices.
        <sup>
          <a href="#annotation-33">33</a>
        </sup>{" "}
      </p>

      <h2>File 3</h2>

      <p>
        Haystack’s 1968 culinary session is a rare moment in which a craft
        school consciously approached cooking as a conceptual practice.
        <sup>
          <a href="#annotation-34">34</a>
        </sup>{" "}
      </p>

      <p>
        And I also want to know what’s gonna be the hottest witch hat of the
        season?
        <sup>
          <a href="#annotation-35">35</a>
        </sup>{" "}
      </p>

      <p>
        During the 1980s, for a series of performances and public interventions,
        Böttner cast herself as a classical Greek sculpture. She remarked, “I
        saw that many Greek statues without arms were admired for their beauty.
        I wanted to show the beauty of the crip body.”
        <sup>
          <a href="#annotation-36">36</a>
        </sup>{" "}
      </p>

      <p>
        Once you realize you can be anything why would you ever let anyone tell
        you what you are?
        <sup>
          <a href="#annotation-37">37</a>
        </sup>{" "}
      </p>

      <p>
        Several people have politely asked about the artwork on display in my
        truck, but mostly my neighbors talk to me about parking.
        <sup>
          <a href="#annotation-38">38</a>
        </sup>{" "}
      </p>

      <p>
        Every Major League baseball includes 300-odd yards of wool yarn (most of
        it spun at a mill in West Millbury, MA) wound systematically around a
        cork center, providing the characteristic spring and resilience{" "}
        <em>and</em>, it’s been speculated, is partially responsible for the end
        of the Dead-ball era and the commencement of the post WWI Live-ball era,
        due to the shift to imported Australian merino necessitated by
        war-induced domestic wool price inflation.
        <sup>
          <a href="#annotation-39">39</a>
        </sup>{" "}
      </p>

      <p>
        Kim: Being romantic partners with your business co-owner is challenging
        and we both left semi-stable freelance careers in the city, moved
        rurally, and scrambled to discover in our early 40s what was going to be
        a viable career path. Like, “Can we pay the bill, babe, like, making sea
        salt?” <em>[All laugh.]</em>
        <sup>
          <a href="#annotation-40">40</a>
        </sup>{" "}
      </p>

      <p>
        Ulbricht believed that craft production was a noble undertaking and
        “that a thing worth doing is a thing worth doing beautifully.”
        <sup>
          <a href="#annotation-41">41</a>
        </sup>{" "}
      </p>
      <p>
        Let me pause here to say that “instructional photographs” is a term that
        I have made up; there is no pre-existing dedicated category for this
        kind of picture, such as this one from a book on mask-making. Words like
        educational, vernacular, journalistic, and illustrative do not serve;
        “demonstrative” comes a little closer.
        <sup>
          <a href="#annotation-42">42</a>
        </sup>{" "}
      </p>

      <p>
        Last week the Women’s Studio Workshop, Faythe Levine, and Erin Zona
        hosted an amazing talk, featuring Sage Dawson, Alex Lukas, Lukaza
        Branfman-Verissimo, and Imin Yeh, all of whom have crafted and cultivate
        contemporary alternative exhibition spaces.
        <sup>
          <a href="#annotation-43">43</a>
        </sup>{" "}
      </p>
      <figure>
        <img
          src={
            process.env.PUBLIC_URL +
            "/img/notaretreat/B_N_10_Levine_Figure2.jpg"
          }
        />
        <figcaption>
          <em>Figure 2</em>. Faythe Levine, desktop archive, Mellenville, New
          York, February 18, 2023. Reader, take note of the{" "}
          <em>Back Channel Radio</em> patch clipped to the center file, as it is
          intended to be included as the grand finale audio bonus addition to
          this archive.
          <sup>
            <a href="#annotation-44">44</a>
          </sup>{" "}
        </figcaption>
      </figure>
      <hr />

      <cite>
        <sup id="annotation-1">1.</sup> Kate Eichhorn,{" "}
        <em>
          Adjusted Margin: Xerography, Art, and Activism in the Late Twentieth
          Century
        </em>
        , (Cambridge, MA: MIT Press, 2016); Alison Piepmeier, “Why Zines Matter:
        Materiality and the Creation of Embodied Community,”{" "}
        <em>American Periodicals</em> (Ohio State University Press) 18, no. 2
        (2008): 213–238; and Paul Soulellis, <em>What Is Queer Typography?</em>,
        2021.
      </cite>
      <cite>
        <sup id="annotation-2">2.</sup> Kate Bingaman-Burt,{" "}
        <em>
          The Zine of Zines: Your Introductory Guide to Zine History, Formats,
          Subject Matter, Artists, Folds, Resources and More
        </em>
        , n.d.,{" "}
        <a
          href="http://www.katebingamanburt.com"
          target="_blank"
          rel="noreferrer"
        >
          http://www.katebingamanburt.com
        </a>
        ; Alex Lukas,{" "}
        <em>
          Written Names Fanzine: Dedicated to Occurrences of Hyper-localized,
          Unsanctioned Public Name Writing
        </em>
        , issues 1, 5, 6, 8 (2018–2020); and{" "}
        <em>Towards a Self-Sustaining Publishing Model</em>, part of The
        Classroom, a series of programs organized by David Senior and produced
        for Printed Matter’s Virtual Art Book Fair, February 2021,{" "}
        <a
          href="https://www.youtube.com/watch?v=Sxup9Cl6R5I"
          target="_blank"
          rel="noreferrer"
        >
          https://www.youtube.com/watch?v=Sxup9Cl6R5I
        </a>
        .
      </cite>
      <cite>
        <sup id="annotation-3">3.</sup> Corey Kilgannon, “Trash Is His Muse:
        Meet the Sanitation Department’s Resident Artist,”{" "}
        <em>New York Times</em>, May 30, 2022.
      </cite>
      <cite>
        <sup id="annotation-4">4.</sup> Octavia E. Butler, quoted in Randall
        Kenan, “An Interview with Octavia E. Butler,” <em>Callaloo</em> 14.2
        (1991): 502.
      </cite>
      <cite>
        <sup id="annotation-5">5.</sup> This text appears as a footnote in a
        handout I received during a workshop I attended at the Oral History
        Summer School in October 2022. It's a printout of the transcript of an
        interview annotated by an unknown writer; the handout doesn't bear the
        name of either the workshop or its instructor. The interview in the
        handout was conducted for the Alessandro Portelli Oral History Project.
        Mildred Shackleford, interview by Alessandro Portelli, November 2, 1990,
        Appalachia: They Say in Harlan County, The Alessandro Portelli Oral
        History Project, Louie B. Nunn Center for Oral History, University of
        Kentucky Libraries,{" "}
        <a
          href="https://kentuckyoralhistory.org/ark:/16417/xt737p8tdm44"
          target="_blank"
          rel="noreferrer"
        >
          https://kentuckyoralhistory.org/ark:/16417/xt737p8tdm44
        </a>
        .
      </cite>
      <cite>
        <sup id="annotation-6">6.</sup> Dara Birnbaum, quoted in Eli Anapur,
        “Emphasizing and Strengthening the Female Voice in Video Art—In
        Conversation with Dara Birnbaum.” Gallery handout, <em>Widewalls</em>,
        August 17, 2022.
      </cite>
      <cite>
        <sup id="annotation-7">7.</sup> Rosario Parker Gordon,{" "}
        <em>August Approaching</em> (Minneapolis, self-pub., 2022).
      </cite>
      <cite>
        <sup id="annotation-8">8.</sup> Allie Wist, “Tendency to Collapse
        (Anthropocene Anarchive).” Exhibition handout, Collar Works, Troy, NY,
        2022.
      </cite>
      <cite>
        <sup id="annotation-9">9.</sup> Indira Allegra, “Unspooling die
        Gramgewinde: Writing with Gunta Stölzl on Grief-threads and
        Grief-portals,” <em>TEXTILE</em> vol 20, no. 4 (2022): 506-523.
      </cite>
      <cite>
        <sup id="annotation-10">10.</sup> Steven Schoen,{" "}
        <em>Sing Sing: The View from Within. Photographs by the Prisoners</em>{" "}
        (New York: The Floating Foundation of Photography, 1972).
      </cite>
      <cite>
        <sup id="annotation-11">11.</sup> Macon Reed, “The Pause Apothecary.”
        Exhibition handout Spring/Break Art Show, 2022.
      </cite>
      <cite>
        <sup id="annotation-12">12.</sup> The Friends of the Wanamaker Organ,
        Inc., An Invitation to Become a Friend of the Wanamaker Organ at Macy’s.
        In-store circular, n.d.
      </cite>
      <cite>
        <sup id="annotation-13">13.</sup> Marc Fischer,{" "}
        <em>Library Excavations</em> #9: Chicago’s Filed Artists (Chicago:
        Public Collectors, 2018), 4.
      </cite>
      <cite>
        <sup id="annotation-14">14.</sup> Richard Blanco,{" "}
        <em>On the Craft of My Loneliness</em> (Deer Isle, ME: Haystack Mountain
        School of Crafts, Monograph Series #36, 2020).
      </cite>
      <cite>
        <sup id="annotation-15">15.</sup> Be Oakley,{" "}
        <em>
          Imperfect Archiving, Archiving as Practice: For a Love of Softness
        </em>{" "}
        (Brooklyn: GenderFail, 2021), 19.
      </cite>
      <cite>
        <sup id="annotation-16">16.</sup> “The Historic Woodstock Art Colony:
        The Arthur A. Anderson Collection.” Exhibition handout, Samuel Dorsky
        Museum of Art, 2023.
      </cite>
      <cite>
        <sup id="annotation-17">17.</sup> Jen Delos Reyes,{" "}
        <em>Defiantly Optimistic: Turning Up in a World on Fire</em> (Chicago:
        Flatland, 2022), 439.
      </cite>
      <cite>
        <sup id="annotation-18">18.</sup> Stacy Szymaszek,{" "}
        <em>Famous Hermits</em> (Brooklyn, Archway Editions, 2022), 55.
      </cite>
      <cite>
        <sup id="annotation-19">19.</sup> Adam Milner, “Build Yourself a Tiny
        Cabin,” in Imin Yeh, <em>Dreamcabin</em> (Pittsburgh: E.L. Hymns, 2022),
        2.
      </cite>
      <cite>
        <sup id="annotation-20">20.</sup> Louise Bourgeois,{" "}
        <em>The Spider and the Tapestries</em> (Berlin: Hauser & Wirth, 2019),
        45.
      </cite>
      <cite>
        <sup id="annotation-21">21.</sup> Mark, <em>Other Cities</em>, a
        collaborative project between Making Things Strange(r) with the
        Minnesota Prison Writing Workshop, taught by Abbey Mei Otis, and Zines,
        Comics and Books at the Art Department of the University of Minnesota,
        taught by Corrine Teed (Minneapolis: 2022), 12.
      </cite>
      <cite>
        <sup id="annotation-22">22.</sup> Nicole Lavelle, “Codes and Questions
        for Anyone Who Goes Anywhere: Towards a Community Framework for Visiting
        Citizens,” <em>The Changing Times</em> 4 (February 2018): Unincorporated
        Marin County.
      </cite>
      <cite>
        <sup id="annotation-23">23.</sup> Lisa Anne Auerbach,{" "}
        <em>Artist Publishers Reflect on Book Waste</em> (Chicago: Temporary
        Services, 2022), 17.
      </cite>
      <cite>
        <sup id="annotation-24">24.</sup> Julia Epifania Basnage,{" "}
        <em>Worms Don’t Work for Wages: An Anticapitalist ABC’s</em> (Portland,
        ME: Childish Books, 2022).
      </cite>
      <cite>
        <sup id="annotation-25">25.</sup> Lieven Lahaye, “Eighty Percent of
        Success is Showing up,” <em>Catalog</em> #10, Rock, rock, rock, rock,
        rock, Amsterdam, 2019.
      </cite>
      <cite>
        <sup id="annotation-26">26.</sup> Adriana Monsalve,{" "}
        <em>Even the Score: A Love Letter from the Editor</em> (Portland, ME:
        Homie House Press, 2022).
      </cite>
      <cite>
        <sup id="annotation-27">27.</sup> Jan Bindas-Tenney,{" "}
        <em>Where the Wastewater Pools</em>, (Portland, ME: printing by Pickwick
        Independent Press for programing affiliated with SPEEDWELL Contemporary
        Fall 2022 exhibition <em>Can’t Take My Eyes Off You</em>, curated by
        Faythe Levine).
      </cite>
      <cite>
        <sup id="annotation-28">28.</sup> Oluremi C. Onabanjo, “The Conditions
        of the Archive—Marilyn Nance: Last Day in Lagos.” New York Art Book Fair
        2022 program, 22.
      </cite>
      <cite>
        <sup id="annotation-29">29.</sup> Lilian Sizemore and Eric Vogel,
        “Milwaukee’s Monumental Modernist Mosaics,”{" "}
        <em>Wisconsin People & Ideas</em> 68, no. 3 (Summer 2022): 24.
      </cite>
      <cite>
        <sup id="annotation-30">30.</sup> Ayana Zaire Cotton, “We Web As
        Rehearsal, Every Web Needs A Window,” in Lukaza Branfman-Verissimo,{" "}
        <em>We Web Keepers</em>, (New York: Printed Matter, 2022). Newspaper
        publication.
      </cite>
      <cite>
        <sup id="annotation-31">31.</sup> Siddisse Negero,{" "}
        <em>KYKY Archives: Etymology of Black Lesbian Gender</em> (KYKY, 2022).
      </cite>
      <cite>
        <sup id="annotation-32">32.</sup> Celeste Olalquiaga,{" "}
        <em>Tropical Babel</em> (La Plata, Argentina: Arquitectura y Fantasía,
        2022).
      </cite>
      <cite>
        <sup id="annotation-33">33.</sup> Erin Segal and Julie Cho,{" "}
        <em>Inquiry Into Care (Ongoing)</em> Thick Press, Spring 2022
        Installment, 7.
      </cite>
      <cite>
        <sup id="annotation-34">34.</sup> Sara Clugage,{" "}
        <em>Cooking, Craft and Performance</em> (Deer Isle, ME: Haystack
        Mountain School of Crafts, Monograph Series #37, 2021), 5.
      </cite>
      <cite>
        <sup id="annotation-35">35.</sup> Frank Haines, <em>Witch Hotline</em>{" "}
        (n.p., FL: Heinzfeller Nileisist, 2022).
      </cite>
      <cite>
        <sup id="annotation-36">36.</sup> Paul B. Preciado, “Lorenza Böttner:
        Requiem for the Norm.” Exhibition handout, Leslie-Lohman Museum of Art,
        2022.
      </cite>
      <cite>
        <sup id="annotation-37">37.</sup> Joseph Fidel Roberts,{" "}
        <em>Clowns of Hyperspace: Choose Your Own Adventure</em> #15 (Los
        Angeles: Unpiano Books, 2021), 106–107.
      </cite>
      <cite>
        <sup id="annotation-38">38.</sup> Alex Lukas, “CA53776V2.gallery
        catalog,” Santa Barbara, CA, Fall 2022, 4.
      </cite>
      <cite>
        <sup id="annotation-39">39.</sup> <em>The Weaving Mail</em> 1, issue 2
        (December 2020). Print newsletter published by The Weaving Mill,
        Chicago.
      </cite>
      <cite>
        <sup id="annotation-40">40.</sup> Kim Kelly, “The Salty Dykes of Bush
        Island,” in Clare Lagomarsino, <em>Queer Earth Food</em> (self-pub.,
        2022), 17.
      </cite>
      <cite>
        <sup id="annotation-41">41.</sup> James A Findlay,{" "}
        <em>
          WPA Handicraft Projects, 1935–1943: Hand Bound Books, Block Printed
          Textiles, Wall Hangings, Prints, Toys, and Other Crafts from the
          Collections of the Bienes Museum of the Modern Book
        </em>{" "}
        (Fort Lauderdale, FL: Bienes Museum of the Modern Book, 2010), 5.
      </cite>
      <cite>
        <sup id="annotation-42">42.</sup> Carmen Winant,{" "}
        <em>Instructional Photography: Learning How to Live Now</em> (New York:
        SPBH Editions, 2021), 10.
      </cite>
      <cite>
        <sup id="annotation-43">43.</sup> Juliette Walker, “The Office of
        Possible Projects’ Exhibition of Alternative Exhibition Spaces.”
        Exhibition handout, Fayetteville, AR, 2022.
      </cite>
      <cite>
        <sup id="annotation-44">44.</sup> <em>Back Channel Radio</em> is an oral
        documentary project by Gina Favano, which she describes as “focusing on
        the preservation of undertold narratives.{" "}
        <em>A Wolf Spider Island Story</em> is an ongoing digital and document
        archive, and podcast, based on oral history interviews about a small
        boathouse community on the Mississippi River in Minnesota that pulled
        together to legally sanction their floating homes. It is told mostly
        through the rememberings of longtime resident and unofficial island
        historian John Rupkey.”
      </cite>
    </div>
  );
};

export default Levine;
