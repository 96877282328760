import React, { useEffect } from "react";
import { Outlet, useParams } from "react-router";
import { NavLink, Navigate } from "react-router-dom";
import { PUBLICATIONS } from "../../publications";
import SimpleBar from "simplebar-react";
import { useMediaQuery } from "react-responsive";

const Publication = () => {
  const { pubId, articleId } = useParams();
  const publication = PUBLICATIONS?.find((pub) => pub.id === pubId);
  const articles = publication?.articles;
  const isMobile = useMediaQuery({ query: "(max-width: 1023px)" });

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--highlight", publication?.accent);
  }, [publication]);

  // Mobile version excludes the side navigation, we just display the outlet.
  if (isMobile) {
    return (
      <>
        {pubId && !articleId ? (
          <Navigate to={`${publication.firstArticle}`} replace />
        ) : null}
        <Outlet />
      </>
    );
  }

  return (
    <>
      {/* If we only see a publication ID, send user to the first article */}
      {pubId && !articleId ? (
        <Navigate to={`${publication.firstArticle}`} replace />
      ) : null}

      <div className="pubgrid">
        <SimpleBar className="publication-menu-wrap">
          <h2 className="publication-title">{publication?.name}</h2>
          <ul className="publication-menu">
            {articles?.map((a, i) => {
              return (
                <li className="publication-menu__item" key={i}>
                  <NavLink to={`${a.id}`}>
                    <div className="publication-order">
                      <span>{a.order}</span>
                    </div>{" "}
                    <div>
                      {a.authorShort && (
                        <div className="publication-article-author">
                          {a.authorShort}
                        </div>
                      )}
                      <div className="publication-article-title">{a.title}</div>
                    </div>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </SimpleBar>
        <div>
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Publication;
