// Pubs
import DimassimoWeiss from "./dimassimo-weiss";
import Hand from "./hand";
import Devlin from "./devlin";
import Tom from "./tom";
import Wiltsie from "./wiltsie";
import PerronFeller from "./perron-feller";
import LignelWiggers from "./lignel-wiggers";

export const COLORS = {
  devlin: "#863315",
  dimassimo_weiss: "#d1222a",
  hand: "#ee00a0",
  notaretreat: "#7830a6",
  "perron-feller": "#0056e3",
  tom: "#009129",
  wiltsie: "#f26010",
};

export const PUBLICATIONS = [
  Devlin,
  DimassimoWeiss,
  Hand,
  LignelWiggers,
  PerronFeller,
  Tom,
  Wiltsie,
];

const ARTICLES = [
  ...Devlin.articles,
  ...DimassimoWeiss.articles,
  ...Hand.articles,
  ...LignelWiggers.articles,
  ...PerronFeller.articles,
  ...Tom.articles,
  ...Wiltsie.articles,
];

// Helper function to get all unique tags
export const getAllTags = () => {
  let tags = [];

  ARTICLES.forEach((article) => {
    article.tags.forEach((tag) => tags.push(tag));
  });

  const allTags = Array.from(new Set(tags)).sort();

  return allTags;
};

// Helper function to make "safe" tag urls
export const renderSafeTag = (tag) => {
  return tag.toLowerCase().replace(/[^\w]/gi, "");
};

// Helper function to get articles for a particular tag
const getArticlesForTags = () => {
  const tags = getAllTags();

  const kwMap = tags.map((t) => {
    return {
      id: renderSafeTag(t),
      name: t,
      articles: [],
    };
  });

  ARTICLES.map((article) => {
    const { pubId, id, order, author, authorShort, title } = article;

    article.tags.forEach((tag) => {
      const kw = kwMap.find((k) => k.id === renderSafeTag(tag));

      kw.articles.push({
        pubId,
        id,
        order,
        author,
        authorShort,
        title,
      });
    });
  });

  return kwMap;
};

const ALPHABETICAL = {
  a: [],
  b: [],
  c: [],
  d: [],
  e: [],
  f: [],
  g: [],
  h: [],
  i: [],
  j: [],
  k: [],
  l: [],
  m: [],
  n: [],
  o: [],
  p: [],
  q: [],
  r: [],
  s: [],
  t: [],
  u: [],
  v: [],
  w: [],
  x: [],
  y: [],
  z: [],
};

const sortByLetter = () => {
  const keywords = getAllTags();

  const kwMap = keywords.map((t) => {
    return {
      id: renderSafeTag(t),
      name: t,
    };
  });

  kwMap.forEach((keyword) => {
    const letter = keyword.id.charAt(0);
    ALPHABETICAL[letter].push(keyword);
  });

  return Object.entries(ALPHABETICAL);
};

// Uncomment if we need to regenerate the keyword map
// console.log(sortByLetter());

// Uncomment the below if we need to regenerate the article map.
export const ARTICLE_MAP = getArticlesForTags();
const data = JSON.stringify(ARTICLE_MAP);
console.log(data);
