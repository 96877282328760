const Keith = () => {
  return (
    <div>
      <p>
        <em>
          For bell hooks, 1952–2021
          <br />
          (And in Honor of MACR)
        </em>
      </p>
      <p>
        COVID-anxious yet giddy to be together, the participants in the winter
        residency in the critical craft studies program (MACR) gathered around
        an outdoor table on January 11, 2023, and they invited me to join them
        for a celebratory meal. There was too much of everything to go around
        that night—an abundance of gratitude, a few shared frustrations, plates
        of escargot, infinite amounts of curiosity, and overlapping
        conversations about everyone’s latest projects. My mind was present in
        conversation, but my words tumbled here and there as I remembered
        various pieces of the past to share with students working on projects
        that spanned the globe.{" "}
      </p>

      <p>
        As usual, I worked with the group, through conversation, to go deeper
        into our understanding of the place that joined us, the Swannanoa Valley
        of the Blue Ridge Mountains. The little nook of land where we were
        gathered was Haw Creek, and that place name references the nearest
        tributary of the Swannanoa River. This sent my mind wandering across the
        landscape and then down toward the river as I talked about some
        implications of the river’s name.
      </p>

      <p>
        Swannanoa is a corruption of a Cherokee word that translates as “the
        trail to the Ani-Suwa’li.” The Cherokee people have an Iroquoian
        language, but Ani-Suwa’li refers to a people with a Siouan language.
        This signifies that the area along the Swannanoa River has long been a
        place of connection between people from different cultures. Cherokee
        people describe the larger area, their ancestral homeland, with many
        words, including <em>Shaconage</em>, or “the land of blue smoke.” I
        recall discussing these things over dinner.
      </p>

      <p>*</p>

      <p>
        I moved my seat to eat dessert with Namita Wiggers and Ben Lignel. We
        caught up a bit before Namita ignited my storytelling with questions
        about whether I had found any lost craft narratives through my recent
        work in the Warren Wilson College archives. Ben listened intently,
        wineglass in hand.{" "}
      </p>

      <p>
        I talked about a friendly exchange of letters between the college’s
        second president and Anni Albers, and then mentioned my fascination with
        Faris Ashkar, a Lebanese alumnus who lived out his life as an Arabic
        letter carver based in East Tennessee. At one point I alluded to the
        complicated implications of a connection between the college’s first
        archeology professor, William “Bill” Klein, and James Goingback “G.B.”
        Chiltoskey, the Cherokee jeweler and blowgun champion who founded the
        Qualla Arts and Crafts Mutual and was the first active tribal member of
        the Southern Highland Craft Guild. As usual, the Appalachian past was
        proving much more complicated than its stereotypes allow it to seem.{" "}
      </p>

      <p>
        I kept turning to Namita to say, “I haven’t even gotten to the wildest
        part yet.” Then another good question would send me down a different
        path.
      </p>

      <p>
        Recognizing the limits of time but unaware of the weight of his
        question, Ben cut to the chase. “What about your interest in mica?”{" "}
      </p>

      <p>Ben knew enough to anticipate where I was headed. </p>

      <p>“It’s been buried,” I said. </p>

      <p>
        I meant this literally. And that was only the second piece of
        information Ben knew about the topic. I had told him a few months
        earlier that I was thinking about an object made from mica as I
        contemplated this essay. Now he knew I was interested in a piece of it
        that had been put back into the land. Already editing, Ben’s mind
        focused on the latter point.
      </p>

      <p>
        His voice lifted up a few notes before he let out his question. “Have
        you ever thought about exploring your interest in buried things?”{" "}
      </p>

      <p>*</p>

      <p>
        July 17, 2020, was the first day of my first residency as a core faculty
        member in MACR. I was grappling to accept that this new horizon for my
        teaching was happening on a screen, and I was rattled to my core by the
        state of the world.{" "}
      </p>

      <p>
        Searching for meaning in the poetry of bell hooks, I had spent the night
        before reading <em>Appalachian Elegy: Poetry and Place</em>. I’d come
        around to the text after Namita had led the faculty through a
        pre-residency exercise to compare the relationship between diversity as
        it existed on our bookshelves and in our course syllabi. Looking back on
        it, the book found me at the exact time I needed it.{" "}
      </p>

      <p>
        “Like a slow solemn musical refrain played again and again,” hooks
        writes in her introduction to <em>Appalachian Elegy</em>, “[poems of
        lamentation] call us to remember and mourn, to know again that as we
        work for change our struggle is also a struggle of memory against
        forgetting.”
        <sup>
          <a href="#annotation-1">1</a>
        </sup>{" "}
        A dogeared page suggests to me now that I read poem number ten to our
        virtual gathering.{" "}
      </p>

      <blockquote className="indented">
        <p>
          10. <br />
          here and there <br />
          across and down <br />
          treasure uncovered <br />
          remnants of ancient ways <br />
          not buried deep enough <br />
          excavated they surface <br />
          objects that say <br />
          some part of me <br />
          lived here before <br />
          reincarnated ancestors <br />
          give me breath <br />
          urge me—live again <br />
          return to familiar ground <br />
          hear our lost people speak
        </p>
      </blockquote>

      <p>
        The writing of hooks wove throughout my classes across the subsequent
        two years. And the words of this poem still echo in my mind as I work to
        uncover histories hidden in the broad valleys of the Blue Ridge
        Mountains.{" "}
      </p>

      <p>
        At the time I was reading these poems of lamentation in preparation for
        my first day of virtual teaching, I was working with my old friend Kevin
        Kehrberg to put the final touches on our cowritten manuscript about a
        song called “
        <a
          href="https://bittersoutherner.com/2020/somebody-died-babe-a-musical-coverup-of-racism-violence-and-greed"
          target="_blank"
          rel="noreferrer"
        >
          Swannanoa Tunnel
        </a>
        .” As we had learned, this popular folk song had been sung by
        incarcerated railroad workers, almost all of whom were Black and some of
        whom were worked to death in the Swannanoa Valley during the late
        nineteenth century. In the Duke University archives we had found the
        only known recording of the song by a Black man, and we had repatriated
        the recording by sharing it with his family just before the first COVID
        lockdown.{" "}
      </p>

      <p>
        Thinking about that work and MACR, I recall that I approached my role as
        a faculty member with the hope that our students might learn to listen
        more closely, to escape the confines of their minds and to engage in
        education, in the spirit of bell hooks, “as the practice of freedom.”
        <sup>
          <a href="#annotation-2">2</a>
        </sup>{" "}
        And across my two years as a MACR faculty member, I witnessed a full
        embrace of this spirit, this “struggle of memory against forgetting.” We
        all examined craft with criticality, looking for “treasure uncovered”
        and listening for “objects that say.” hooks’s words echoed through it
        all, and still do.{" "}
      </p>

      <p>*</p>

      <p>
        “The land remembers,” said Brian Giemza, a Cormac McCarthy scholar at
        the University of the South. Brian was discussing the ecological
        consequences of suburban development in the swamps around Houston,
        Texas. He was responding to a story being told by the Texas-based
        educator and community organizer Chassidy Olainu-Alade. Kevin and I met
        them as we prepared to present our work on Swannanoa to an East
        Tennessee interdisciplinary colloquium exploring the complexity and
        necessity of researching unfree labor in the post-Civil War US. We
        sipped our coffees as we listened in on this conversation, both of us
        eager to learn about Chassidy’s work and intrigued by Brian’s comment.{" "}
      </p>

      <p>
        Chassidy is the Coordinator of Community and Civic Engagement with the
        Sugarland 95 Memorialization Project, an effort to identify and honor
        the remains of 95 people found in an improvised and unmarked graveyard
        for incarcerated laborers who died while being forced to work at the
        Bullhead Convict Labor Camp, near Houston. A community elder who lived
        near the graves had told everyone who would listen that people were
        buried there. He remembered the truth, and he had told the truth for
        years. Developers rejected his claims, insisting all the remains on the
        site belonged to animals—not people. Earthmovers arrived on the scene,
        and a backhoe revealed human skeletons. Then came the archeologists, who
        uncovered traces of a story that was locked into place. The land had
        remembered. And Chassidy’s work took root in that place.{" "}
      </p>

      <p>
        The story of unfree labor in the post-Civil War US is almost entirely a
        story about the impact of white supremacy on Black men, but many Black
        women and some non-Black people were swept into this exploitative system
        of labor. White-owned corporations and state governments colluded with
        one another, using this practice to generate profits by zeroing out
        labor costs for infrastructure projects.{" "}
      </p>

      <p>
        When remembered at all, this practice is referred to in archival
        documents as “convict leasing.” An astonishing amount of Southern
        infrastructure was built under the heading of that paltry euphemism. The
        book titles of two colloquium attendees better capture the cruelty of
        convict leasing: <em>Slavery by Another Name</em> and{" "}
        <em>One Dies, Get Another</em>.
        <sup>
          <a href="#annotation-3">3</a>
        </sup>{" "}
      </p>

      <p>
        “The land remembers” is my newfound mantra. It sends my thoughts “here
        and there / across and down.” It also allows me to escape my thoughts
        through critical engagement with place. It urges me to dig for lost
        memories and to search for buried histories. It reminds me to continue
        work that sometimes provides me with little joy. Its truths offer me
        strength to never look away and to listen until the end.{" "}
      </p>

      <p>*</p>

      <p>
        As I have repeated this mantra I have begun to consider what is buried
        underneath my own understanding of Warren Wilson College, the place
        where I live and the place where I will be buried. I often visit my
        burial plot to consider my inevitable rendezvous with eternity; these
        visits startle to life a keen self-awareness. With that in mind, I have
        begun to indulge how remembering works at different levels as well as
        how it operates in different directions when one leads an emplaced life,
        mine having taken root in the Swannanoa Valley of Shaconage.{" "}
      </p>

      <p>
        I am growing to think critically about the physical traces of the past
        that surround me, especially those that lie underneath the surface of my
        known, visible surroundings or those that evade my understanding due to
        seeming so commonplace. These deeper considerations relate to how the
        land itself does the work of remembering and, over time, how it makes
        everything whole again.{" "}
      </p>

      <p>
        There’s an instructive difference between the formal and folk
        etymologies of the word <em>remember</em>. Formal etymology relies on
        root words, and those describe remembering in the language of the mind.
        The Latin prefix re- (“again, back”) and the term <em>memor</em>{" "}
        (“mindful”) combined long ago to make the late Latin term{" "}
        <em>rememorari</em> (“call to mind”). Folk etymology, however, relies on
        the context of how people actually understand the words they use, and
        this places the meaning of remembrance in the language of the body.
        Remembering, in other words, becomes the action of bringing together a
        person or thing that has been dismembered or separated from itself.{" "}
      </p>

      <p>
        The land’s capacity for remembrance moves my thinking toward mysteries
        of the spirit. In the sense of pulling together what has been taken
        apart, the Earth will remember all of us someday. This strikes me as
        both a profound material and spiritual truth. Formal burial practices,
        such as coffins or vaults, resist this final remembrance by the Earth,
        but their futility is often spoken about in burial rites. “Dust to
        dust,” people say in my faith tradition. Put another way, the Earth will
        remember us until we ourselves become part of it.{" "}
      </p>

      <p>*</p>

      <p>
        In a field called Back Bottom, next to the Swannanoa River, at an
        unmarked yet precisely known location, lies a buried disc of mica that a
        First American craftsperson cut into a shape resembling a Cherokee
        symbol to honor the seven sacred directions. In the fall of 1966, Warren
        Wilson College’s first archeology professor, Bill Klein, found this disc
        when he and his students uncovered and exhumed a grave. In all, various
        archeologists and students removed more than four dozen graves from this
        field, sending bones and grave goods such as this mica disc to a
        laboratory at the University of North Carolina at Chapel Hill.{" "}
      </p>

      <p>
        “Grave good” is one of many archeological terms of art that I have
        learned as I have worked to dig deeper into where my understanding of
        place comes from. The existence of this disc in the field calls to mind
        “ground truth”—another term, one meaning what is learned through direct
        observation through archeological work.
      </p>

      <p>
        In the fall of 1999, Walker Calhoun, a Cherokee elder, repatriated all
        of these graves, placing the bones and the grave goods precisely where
        they had been taken out of the ground by Klein, his fellow
        archeologists, and several Warren Wilson College students. Calhoun did
        the work of blessing the graves alongside his son and grandson. The
        Warren Wilson College archeology crew had dug to the exact location of
        the original graves, and then they left before the reconsecrating
        ceremonies began.{" "}
      </p>

      <p>
        James Bird, the executive director of cultural resources for the Eastern
        Band of Cherokee Indians, oversaw the effort. In the local press he
        spoke about its meaning. “Because they were removed from the ground,
        their trip to the earth, to decompose and become part of the ground, was
        disturbed. Now we’re trying to put them back in order.”{" "}
      </p>

      <p>
        As I read this quotation, my mind lands on the final lines of bell
        hooks’s “10.” I imagine the words bouncing throughout the Swannanoa
        Valley and encouraging me to see Back Bottom anew:{" "}
      </p>

      <blockquote className="indented">
        <p>
          return to familiar ground
          <br />
          hear our lost people speak
        </p>
      </blockquote>

      <p>*</p>
      <p>
        In a 2012 lecture titled “Reclaiming Place,” hooks reflects on her poems
        in <em>Appalachian Elegy</em> as well as a book of essays she
        subsequently wrote titled <em>Belonging</em>. She uses the lecture to
        trace a relationship between critical thinking about white supremacy and
        the need for agrarianism, localism, and love.
      </p>

      <p>
        “If you want to engage in philanthropy,” hooks offers, “you can engage
        in what I call everyday philanthropy, helping in the maintenance and
        stability of your community. And that is what a lot of local engagement
        allows.”
        <sup>
          <a href="#annotation-4">4</a>
        </sup>{" "}
        This kind of work returned her to a place she once had fled. Raised in a
        Kentucky homeplace just 60 miles west of where I was raised, hooks found
        rich land for self-discovery and engagement by finding a sense of
        belonging in her home state. She remembered through the land before
        dying on December 15, 2021.
      </p>

      <p>
        Through small acts of everyday philanthropy, I find hope as I remember
        what has been dismembered. I search for ways to put things back in
        order. I try to make things whole by way of a more complete truth. This
        work is too much for anyone, and so we must all do it together—around
        dinner tables, in conversations, and through continual acts of freedom.
        As I continue to contemplate hooks’s poems, I know this ground truth to
        my bones: The land remembers. It is up to us to look, listen, and learn
        as we “return to familiar ground” and imagine it anew.{" "}
      </p>

      <hr />

      <cite>
        <sup id="annotation-1">1.</sup> bell hooks,{" "}
        <em>Appalachian Elegy: Poetry and Place</em> (Lexington: University
        Press of Kentucky, 2012), 5.
      </cite>
      <cite>
        <sup id="annotation-2">2.</sup> bell hooks,{" "}
        <em>Teaching to Transgress: Education as the Practice of Freedom</em>{" "}
        (New York: Routledge, 1994), 6–12.
      </cite>
      <cite>
        <sup id="annotation-3">3.</sup> Douglas A. Blackmon,{" "}
        <em>
          Slavery by Another Name: The Re-Enslavement of Black Americans from
          the Civil War to World War II
        </em>{" "}
        (New York: Doubleday, 2008); Matthew J. Mancini,{" "}
        <em>One Dies, Get Another: Convict Leasing in the American South</em>,
        1866–1928 (Columbia: University of North Carolina Press, 1996).
      </cite>
      <cite>
        <sup id="annotation-4">4.</sup> bell hooks, “Reclaiming Place,” in{" "}
        <em>Appalachia in Regional Context: Place Matters</em>, eds. Dwight B.
        Billings and Ann E. Kingsolver (Lexington: University Press of Kentucky,
        2018), 187.
      </cite>
    </div>
  );
};

export default Keith;
