import Adamson from "./adamson";
import BernsteinAndDevlin from "./bernsteinanddevlin";
import CalderoAndDevlin from "./calderoanddevlin";
import Devlin from "./devlin";
import EditorsNote from "./editorsnote";
import Kuo from "./kuo";
import WallKimmerer from "./wallkimmerer";
import Boden from "./boden";
import Hawes from "./hawes";
import { COLORS } from "..";

const publication = {
  pubId: "devlin",
  id: "devlin",
  author: "Devlin",
  authorFull: "Miriam Devlin",
  name: "Craft Practiced, a reader",
  articles: [
    {
      pubId: "devlin",
      id: "editorsletter",
      order: 1,
      title: "Editor's Letter",
      author: "Miriam Devlin",
      authorShort: "Devlin",
      tags: [
        "new writing",
        "introduction",
        "conversation",
        "craft studies",
        "embodied knowledge",
        "somatics",
        "translation",
      ],
      citation: `Devlin, Miriam. “Editor’s Letter,” Craft Practiced, a reader, edited by Miriam Devlin. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-7-0  `,
      url: "https://www.theMACRPapers.com/devlin/editorsletter",
      downloadUrl: `devlin/Miriam_1_Editor.pdf`,
      component: <EditorsNote />,
    },
    {
      pubId: "devlin",
      id: "wallkimmerer",
      order: 2,
      title: "Wisgaak Gokpenagen: A Black Ash Basket",
      author: "Robin Wall Kimmerer",
      authorShort: "Kimmerer",
      tags: [
        "reprint",
        "chapter",
        "basket weaving",
        "craft practices",
        "ecology",
        "indigenous knowledge",
      ],
      citation: `Devlin, Miriam. “Introduction: Robin Wall Kimmerer,” Craft Practiced, a reader, edited by Miriam Devlin. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-7-0  `,
      url: "https://www.theMACRPapers.com/devlin/wallkimmerer",
      downloadUrl: `devlin/Miriam_2_Kimmerer.pdf`,
      component: <WallKimmerer />,
    },
    {
      pubId: "devlin",
      id: "adamson",
      order: 3,
      title: "Material Intelligence",
      author: "Glenn Adamson",
      authorShort: "Adamson",
      tags: ["reprint", "essay", "agency", "anthropology", "craft studies"],
      citation: `Devlin, Miriam. “Introduction: Glenn Adamson,” Craft Practiced, a reader, edited by Miriam Devlin. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-7-0  `,
      url: "https://www.theMACRPapers.com/devlin/adamson",
      downloadUrl: `devlin/Miriam_3_Adamson.pdf`,
      component: <Adamson />,
    },
    {
      pubId: "devlin",
      id: "devlin",
      order: 4,
      title: "On Frames",
      author: "Trieste Devlin",
      authorShort: "Devlin",
      tags: [
        "new writing",
        "commentary",
        "agency",
        "conversation",
        "craft practices",
        "dye",
        "translation",
      ],
      citation: `Devlin, Trieste. “On Frames,” Craft Practiced, a reader, edited by Miriam Devlin. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-7-0  `,
      url: "https://www.theMACRPapers.com/devlin/devlin",
      downloadUrl: "devlin/Miriam_4_Devlin.pdf",
      component: <Devlin />,
    },
    {
      pubId: "devlin",
      id: "kuo",
      order: 5,
      title: "Swimming Ethnography",
      author: "Phoebe Kuo",
      authorShort: "Kuo",
      tags: [
        "coursework",
        "essay",
        "ethnography",
        "movement",
        "phenomenology",
        "poetry",
      ],
      citation: `Kuo, Phoebe. “Swimming Ethnography,” Craft Practiced, a reader, edited by Miriam Devlin. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-7-0  `,
      url: "https://www.theMACRPapers.com/devlin/kuo",
      downloadUrl: "devlin/Miriam_5_Kuo.pdf",
      component: <Kuo />,
    },
    {
      pubId: "devlin",
      id: "calderoanddevlin",
      order: 6,
      title: "Conversation with Alexis Rosa Caldero: Wooden Chairs for Healing",
      author: "Alexis Rosa Caldero & Miriam Devlin",
      authorShort: "Caldero & Devlin",
      tags: [
        "new writing",
        "interview",
        "chairs",
        "craft practices",
        "healing",
        "somatics",
        "woodworking",
      ],
      citation: `Caldero, Alexis Rosa and Miriam Devlin. “Conversation with Alexis Rosa Caldero: Wooden Chairs for Healing,” Craft Practiced, a reader, edited by Miriam Devlin. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-7-0  `,
      url: "https://www.theMACRPapers.com/devlin/calderoanddevlin",
      downloadUrl: "devlin/Miriam_6_Caldero.pdf",
      component: <CalderoAndDevlin />,
    },
    {
      pubId: "devlin",
      id: "bernsteinanddevlin",
      order: 7,
      title: "On Manga",
      author: "Blake Bernstein & Trieste Devlin",
      authorShort: "Bernstein & Devlin",
      tags: [
        "new writing",
        "commentary",
        "conversation",
        "movement",
        "translation",
      ],
      citation: `Bernstein, Blake and Trieste Devlin. “On Manga,” Craft Practiced, a reader, edited by Miriam Devlin. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-7-0  `,
      url: "https://www.theMACRPapers.com/devlin/bernsteinanddevlin",
      downloadUrl: "devlin/Miriam_7_Bernstein_Devlin.pdf",
      component: <BernsteinAndDevlin />,
    },
    {
      pubId: "devlin",
      id: "boden",
      order: 8,
      title: "Crafts, Perception, and the Possibilities of the Body",
      author: "Margaret Boden",
      authorShort: "Boden",
      tags: ["reprint", "essay", "dance", "phenomenology", "somatics"],
      citation: `Devlin, Miriam. “Introduction: Margaret Boden,” Craft Practiced, a reader, edited by Miriam Devlin. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-7-0 `,
      url: "https://www.theMACRPapers.com/devlin/boden",
      downloadUrl: "devlin/Miriam_8_Boden.pdf",
      component: <Boden />,
    },
    {
      pubId: "devlin",
      id: "hawes",
      order: 9,
      title: "Mouth to Test Spoons",
      author: "Kate Hawes",
      authorShort: "Hawes",
      tags: [
        "coursework",
        "essay",
        "craft practices",
        "craft studies",
        "phenomenology",
        "woodworking",
      ],
      citation: `Hawes, Kate. “Mouth to Test Spoons,” Craft Practiced, a reader, edited by Miriam Devlin. The MACR Papers, Swannanoa, NC: MA in Critical Craft Studies, Warren Wilson College, 2023. Copyright remains with the author. ISBN 978-1-7351592-7-0  `,
      url: "https://www.theMACRPapers.com/devlin/hawes",
      downloadUrl: "devlin/Miriam_9_Hawes.pdf",
      component: <Hawes />,
    },
  ],
  accent: "#863315",
  firstArticle: "editorsletter",
};

export default publication;
