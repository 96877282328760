const DeLosReyes = () => {
  return (
    <div>
      <p>
        I am currently working with a friend and curator on the first survey
        show of my practice, with the working title <em>Make Space</em>. He
        called me up recently because he had an idea that maybe the title could
        be better. He suggested <em>Desire Paths</em> as the title for the show,
        a term I introduced him to that he felt was reflective of what I do.
        Desire lines, or desire paths, are unsanctioned paths that are made
        through the repeated act of taking the route you wish was there in order
        to get to where you want to go, a route more desirable to the traveler.
        My response to his suggestion of the name change was that while I loved
        the idea and the concept, I didn’t feel like it was right for me yet,
        and that I aspire to have the kind of practice that, by the time I am
        ready for a retrospective show, <em>Desire Paths</em> would be the only
        logical choice.{" "}
      </p>

      <p>
        While I haven’t quite gotten where I want to be in my practice, it
        hasn’t stopped me from integrating this end goal into my pedagogy toward
        supporting others in finding and making their own paths. Part of this
        work is resisting current systems and structures, such as the expected
        forms of professional practice—how a CV should be structured, or what is
        shared in a bio—in order to let other models and possibilities emerge.
        Another is creating room for imagining other ways our lives can be. My
        offering to this publication is an invitation for you to answer for
        yourself some of the questions I ask of myself and students (I currently
        teach in the Art Department at Cornell University, but engage with
        students at many different institutions when invited to give talks or
        lead workshops), to rethink some of the professional practices we
        routinely engage in, and to imagine new forms our lives could take to
        better support our work, and ultimately one another.
      </p>

      <p>
        What follows is a combination of two workshops, Minimizing Professional
        Practice: Making Room for Creative Practice on a Human Level and I’m
        Going to Live the Life I Sing About in My Song: How Artists Make and
        Live Lives of Meaning. The latter was first conducted with the students
        of the MA in Critical Craft Studies program at Warren Wilson College in
        January of 2020. In the session it became clear by the end that all the
        students had a clear desire to merge their everyday lives with the
        meaning they find in their studies, and to not only support themselves
        in a way that brings fulfillment, but to also support their communities
        of makers and thinkers. Many, when thinking of the ideal containers to
        hold their lives and practices, proposed residencies that emerged from
        their homes, or collectively buying property or land.{" "}
      </p>

      <p>
        I have had multiple interactions with the MA in Critical Craft Studies
        program over the years and one of the things I have valued most about
        the time that we shared was being in a learning environment in which not
        only knowledge was exchanged, but life was lived. In addition to deep
        critical inquiry and commitment to intellectual pursuits, we lived
        together, steeped tea, and crafted elaborate meals. This created an
        intimacy and vulnerability that made this group particularly open to my
        often-unorthodox approaches to learning and being together. As an
        introduction to one another, I asked that everyone wear an outfit of
        significance and tell us the story of the outfit, and then to swap
        outfits with someone else as a form of really embodied knowledge. I
        asked that people bring yoga mats to my workshops so that we could be
        led in group stretching by the program coordinator, who was training to
        be a yoga instructor. They were always willing and trusting to go down
        unfamiliar pedagogical paths alongside me.{" "}
      </p>

      <p>
        In this spirit I ask that you not skip ahead of this prepared{" "}
        <a
          href="https://drive.google.com/drive/folders/17BU9Hvi-zBTJ8R7mb8zfLQTcl4wqiibY"
          download
        >
          downloadable PDF
        </a>
        , but that you print it out and follow along and that you use the
        provided space in this book to write your responses. Trust the process.
        Find the path that makes sense for you, and keep going. I hope we all
        find our ways to futures full of meaning, life, and joy.
      </p>

      <hr />
    </div>
  );
};

export default DeLosReyes;
