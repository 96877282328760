// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed&family=Kalam:wght@400;700&family=Lora:ital,wght@0,500;0,700;1,500;1,700&family=Rubik:ital,wght@0,400;0,700;1,400&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* \nfont-family: 'Barlow Semi Condensed', sans-serif;\nfont-family: 'Lora', serif;\nfont-family: 'Rubik', sans-serif;\nfont-family: 'Kalam', cursive;\n*/\n", "",{"version":3,"sources":["webpack://./src/styles/typography.css"],"names":[],"mappings":"AAAA;;;;;CAKC","sourcesContent":["/* \nfont-family: 'Barlow Semi Condensed', sans-serif;\nfont-family: 'Lora', serif;\nfont-family: 'Rubik', sans-serif;\nfont-family: 'Kalam', cursive;\n*/\n\n@import url(\"https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed&family=Kalam:wght@400;700&family=Lora:ital,wght@0,500;0,700;1,500;1,700&family=Rubik:ital,wght@0,400;0,700;1,400&display=swap\");\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
