const Acierto = () => {
  return (
    <div>
      <blockquote>
        <p>
          “Meaningful collaboration both relies on and deepens relationship—the
          stronger the bond between the people or groups in collaboration, the
          more possibility you can hold.”
          <sup>
            <a href="#annotation-1">1</a>
          </sup>
        </p>
        <p>—adrienne maree brown</p>
      </blockquote>

      <blockquote>
        <p>
          “The academy is not paradise. But learning is a place where paradise
          can be created. The classroom, with all its limitations, remains a
          location of possibility. In that field of possibility we have the
          opportunity to labor for freedom, to demand of ourselves and our
          comrades, an openness of mind and heart that allows us to face reality
          even as we collectively imagine ways to move beyond boundaries, to
          transgress. This is education as the practice of freedom.”
          <sup>
            <a href="#annotation-2">2</a>
          </sup>
        </p>
        <p>—bell hooks</p>
      </blockquote>

      <p>
        <em>
          Usually in the mornings—Arizona time—Summer 2021 in the midst of
          COVID. I log in from Akimel O’odham and Pee Posh land to be with a
          group of smiling faces arranged as tiles on a screen in a Zoom call.
          We gather to share, workshop, and refine syllabi for the upcoming
          semester of classes in the graduate program for Critical Craft Studies
          at Warren Wilson College. Our conversations take shape in real time
          during convenings while others linger in the after-thought.
          <sup>
            <a href="#annotation-3">3</a>
          </sup>
        </em>
      </p>

      <p>
        I felt anxious and nervous before the first full faculty meeting. I was
        new and joined the core faculty as Faculty Fellow after several
        meandering and generous conversations with Namita some weeks before. At
        the time of our first meetings over Zoom, I was in the midst of
        conducting research and follow-up studio visits in preparation for an
        essay about a group of contemporary diasporic textile artists in the
        Southeastern US. Some were artists I had worked with on a small
        exhibition, at COOP Gallery, in Nashville, called <em>Take Refuge</em>,
        while others were simply based in the Southeast.
        <sup>
          <a href="#annotation-4">4</a>
        </sup>{" "}
        My curiosity in their projects (as well as the work of others that I
        hadn’t immediately engaged with as a curator) emerged as a necessary
        reflection in the wake of then-President Trump’s slew of executive
        orders such as 13769, commonly referred to as the “Muslim ban,” or the
        campaign to “build the wall” across the US-Mexico border. In an era of
        increased militarized state formation, I was interested in how artists
        deployed strategies that deflated or subverted arbitrary constructions
        of difference predicated on national borders. I wanted to understand how
        diasporic artists situated in the Southeastern US navigated the tensions
        of institutional/governmental/structural racism as non-white inhabitants
        (despite holding citizenship or green cards) coupled with their status
        as, or as descendants of, refugees in a country nearly 7,000 miles away
        from their homelands. It was this simultaneous condition of working with
        those artists and the MACR faculty cohort that accelerated my curiosity
        about the entanglements of craft, the internet, and a relationship to
        place. Oscillating between the two enabled a number of continual
        returns, where ideas developed with one group enlivened my approach to
        the other.{" "}
      </p>

      <p>
        What emerged from engagements with those SWANA
        <sup>
          <a href="#annotation-5">5</a>
        </sup>{" "}
        artists was an understanding of their reliance on digital communication
        strategies (Skype, WhatsApp, Instagram, even Twitter) to keep in touch
        with their families, loved ones, and communities in their homelands.
        Textile production, processes, and art-making were frequently the
        catalyst for gathering that invited artists and their kin to cultivate
        and sustain their relationships despite their physical and geographical
        distances. In most situations, women and girls would gather in front of
        their devices to share the activities of textile work while catching up,
        sharing news, and joking amongst aunties and cousins. The engagement of
        craft was conditioned upon the shared trust that blossomed from the
        informality of conversation. I was fascinated with how these exchanges
        over the internet—despite the complications of bandwidth, network speed,
        and time zones—offered a reason and impetus for artistic production
        alongside the traditions embodied by generations of practice.
      </p>

      <p>
        Related to my interest in collaborative co-production, I observed that
        these artists used media socially to engage and sustain conversations
        that proliferated across platforms beyond bedtimes. Texts, emails, and
        other forms of exchange could linger beyond the platform—not to mention
        other kinds of thoughts that trailed in the shadows of other meandering
        conversations in real time.
      </p>

      <p>
        These lingering thoughts—the ones that continue to take shape after
        animated exclamations and sensitive revelations—remind me of the virtual
        encounters over Zoom with my faculty colleagues in the MA program.
        Spread across time zones, continents, and connection speeds, my
        colleagues offered similar kinds of commitments to shared knowledge
        where we engaged in generous discourse about our overlapping values in
        respect to critical craft studies. Each of our regular syllabi meetings
        underscored our respective dedication to pedagogy conditioned by a deep
        affinity toward engaging rigorous interdisciplinary questions. It was
        clear I had joined a cohort of brilliant minds invested in building a
        field from critical vantage points. Yet my investment in this group of
        humans went beyond their contributions to critical craft studies and was
        instead located in the ways they were able to share stories or enable
        connections that made them feel like old friends. These moments of
        vulnerability and reflection were not unlike the digital encounters I
        was studying in the Southeast and the way those artists had sustained
        relationships with their loved ones despite their physical distance.{" "}
      </p>
      <p>
        In the Zoom room, syllabi were the things that kept our hands busy, that
        gave us the focus to develop and strengthen connections or adjust our
        materials in the ways that best served our students. But it should also
        be stated that these discursive activities—the ideas that kept our hands
        busy typing—also felt like strategies to maintain connections with other
        dedicated scholars and thinkers. In the midst of COVID, isolation was
        real. I never physically met any of my colleagues during my short period
        as a Faculty Fellow, and I was only introduced to Namita in passing,
        years before, at a conference. These moments of intellectual camaraderie
        enabled an ability to forge intellectual connections and relationships
        with peers that remains parallel, if not critical, to this reflection.{" "}
      </p>

      <p>
        Between these intense, rigorous moments of connection with MA program
        colleagues, I found myself continuing to think through our
        conversations, working hard to make assignments, readings, or activities
        more intentional than I had done before. I was compelled, following the
        shared experience of workshopping syllabi amongst colleagues, to not
        only adapt the ways I could connect with students, but to be more
        deliberate about establishing thicker connective threads between classes
        and course content. Without that bird’s-eye view of the curricular shape
        of the program, I wouldn’t have been able to properly locate my own
        projects with students or share how my assignments would best suit their
        needs as contemporary scholars. This of course was structural. To be
        able to understand and engage other faculty members’ framing of their
        course materials was a deliberate way to establish patterns of
        transparency that decolonized the educational space altogether. There
        was no need to withhold or gatekeep one’s pedagogical approach to
        material because we all shared similar values and trusted that our
        colleagues would provide students with the necessary tools, analytic
        frameworks, and critical perspectives that could enliven the discourse
        overall. As I continued to shape the projects and readings for my class,
        it was important to understand what my second-year MA students had
        completed and read in their first year so that I could continue to build
        on those foundations within my class. Similarly, it was incredibly
        helpful to understand what my other faculty cohort teaching second-year
        students were doing so that we could build off and ricochet ideas and
        approaches with each other. Though we each had our disciplinary
        orientations and ways of presenting pedagogical content, there was no
        need to co-opt or steal intellectual approaches to craft studies, in
        part because no one could “own” craft. An open discursive space, our
        ways of approaching material and content dismantled a singular approach
        toward mastery.
        <sup>
          <a href="#annotation-6">6</a>
        </sup>{" "}
      </p>
      <p>
        Craft, like teaching, is a community project rooted in shared effort,
        responsibility, and accountability. We needed to support each other, to
        help ensure students were able to connect intellectual threads between
        materials and classes, and to develop meaningful relationships with
        course content to encourage nuanced forms of intellectual care. Together
        in the syllabi meetings, we understood that faculty cohorts (first-year
        faculty, or second-year faculty) would remain present in the classroom
        during residency, not as co-teachers or co-facilitators, but as
        participants engaged in the same materials our students were tasked to
        prepare. This kind of pedagogical transparency enabled us to bring our
        own intellectual, experiential, and educational approaches to the
        broader curriculum in a way that opened new creative conditions and
        possibilities. As hooks writes, a “commitment to engaged pedagogy
        carries with it the willingness to be responsible, not to pretend that
        professors do not have the power to change the direction of our
        students’ lives.”
        <sup>
          <a href="#annotation-7">7</a>
        </sup>{" "}
        As we assembled, revised, and reconceptualized critical pedagogical
        pathways to craft studies as a collective cohort, I could feel a
        palpable, though occasionally awkward, shift in our approaches. Sitting
        with this discomfort was, at least for me, part of the work in the
        practice toward freedom.
      </p>
      <p>
        Unlike other institutional spaces with designated areas of study that I
        have been affiliated with, this MA program allowed for (or maybe more
        accurately, necessitated) a different kind of intellectual flexibility
        that invited this kind of transparency.
        <sup>
          <a href="#annotation-8">8</a>
        </sup>{" "}
        This gesture to share knowledge creation gave us the capacity to dive
        into the shit that actually mattered, to weed out superfluous academic
        formalism and instead provide resources that leveled the playing field.{" "}
      </p>
      <p>
        These moments of sharing propelled several after-thoughts as I continued
        to linger on and ruminate over someone’s feedback. They offered a way to
        focus, to stay with and challenge my own pedagogical approaches that
        would otherwise be conditioned by habit. In the times I spent working on
        my syllabi outside of these meetings, I might have recalled a comment or
        a reading a colleague had mentioned (sometimes both!) and be struck by
        how those ideas could inform my own pedagogical approach to materials
        that might encourage students to stay with the broader themes in the
        class. It was through these after-thoughts that I felt encouraged to
        make adjustments, to continue refining my approach to content, and to
        develop strategies that would serve students better. Relatedly, what
        also seemed critical to the program was a dedication to develop a
        culture of curiosity rooted in accessibility. We wanted our students to
        feel welcome to explore the complexities of rigorous academic content
        without the pressure of producing inaccessible content. We wanted them
        to have the tools to share the specificities of their studies with wider
        publics that were rooted in decolonial, feminist, queer, and crip
        positionalities yet materialized in ways that were legible to a broad
        readership. This ultimately changed the scope and approach to my
        pedagogy in that it demanded more individualized and flexible approaches
        to how I presented and engaged learning materials. I wanted to provide
        students with resources to get to the substantial questions that allowed
        other non-craft scholars in.
      </p>
      <p>
        The fact that our syllabi were living documents, susceptible to (yet
        inviting of) critical feedback, marked a significant political
        commitment that felt refreshing and subversive. The ability of my
        colleagues to show up and share their works in progress without
        hesitation allowed a different kind of pedagogical focus that I hadn’t
        seen in other academic appointments. While other programs I’ve been part
        of had broad curricular goals, many faculty often had differing or even
        contested approaches to presenting material, and they rarely shared the
        inner workings of their pedagogical materials or processes, let alone
        invited critique that might ensure courses across the curriculum be
        aligned with political intent. These moments of visceral intellectual
        exchange with my MACR faculty cohort, all of which have been nothing shy
        of invigorating (but also nerve-racking!), continue to compel me to
        consider how those meetings impact my own pedagogical practice outside
        of the program. They allow the materials and my approach to remain in
        dialogue, to invite me to <em>stay with the thoughts</em> (as
        after-thoughts) compelled by prior discussions of the material. It is
        the continual return to my thoughts (in the practice of after-thoughts)
        that might ensure that ideas remain fluid.{" "}
      </p>
      <p>
        In these interim periods between Zoom meetings, it felt important for me
        to reflect on how I might show up for my students by becoming more
        transparent about the syllabus or by adapting the needs of the class to
        suit the trajectory of the larger curricular structure.
        <sup>
          <a href="#annotation-9">9</a>
        </sup>{" "}
        To do so has made me ask questions about the ways my own
        interdisciplinary arts and performance program (situated inside a small
        humanities college at the largest public research university in the US
        in one of the worst-performing public school systems in the nation) has
        adequately prepared students to remain curious about the world around
        them. It has compelled me to ask, in what ways do my own intellectual,
        academic, and creative biases limit how my students continue to learn?
        How might these orientations shape the methods students adopt in their
        engagement with scholarship or material culture? And do my own
        pedagogical patterns allow or disable my students’ ability to devise
        expansive and capacious pathways toward new possible academic models?{" "}
      </p>
      <p>
        I have been encouraged by colleagues and graduate students in the MA for
        Critical Craft Studies to remain critical of the methodological,
        philosophical, and intellectual traditions we have been tethered to
        because it enables us the necessary flexibility in the development of
        new approaches to craft. I have been energized by the ability of this
        simple activity of sharing syllabi to allow me the opportunity to
        meander in and out of disciplinary confines. It encourages me to engage
        in a deep curiosity that refuses methodologies and analyses rooted in
        legacies of violence that have historically shaped the field of craft
        studies.
        <sup>
          <a href="#annotation-10">10</a>
        </sup>{" "}
        In many ways, this act of radical transparency has reminded me of the
        ability for the classroom to be, as hooks remarks “a location of
        possibility.”
        <sup>
          <a href="#annotation-11">11</a>
        </sup>{" "}
        As an ongoing activity, teaching with transparency allows for a kind of
        unfixedness to the processes, objects, and subjects that make up the
        field.{" "}
      </p>
      <p>
        It is through these experiences and thought-provoking exchanges that I
        have learned to see how the process of doing—the active participation of
        shaping in collaboration—has allowed me to feel situated and grounded.
        They have enabled me to be intellectually entangled and woven into
        parallel thought similar to the diasporic artists I have been in
        conversation with. I don’t know if I’ll be fortunate enough to share
        with others the same kind of experience that I had as part of this
        incredible cohort, but I am indebted to my colleagues for offering one
        of many nuanced strategies toward a practice of freedom. I know we
        haven’t met in a while, but I look forward to our next Zoom meeting, if
        only to return to a location of possibility.{" "}
      </p>

      <h2>Further Readings</h2>
      <ul className="bibliography">
        <li>
          brown, adrienne maree.{" "}
          <em>Emergent Strategy: Shaping Change, Changing Worlds</em>. Chico,
          CA: AK Press, 2017.
        </li>
        <li>
          hooks, bell.{" "}
          <em>Teaching to Transgress: Education as the Practice of Freedom</em>.
          CITY: Routledge, 1994.
        </li>
        <li>
          Singh, Julietta.{" "}
          <em>Unthinking Mastery: Dehumanism and Decolonial Entanglements</em>.
          Durham, NC: Duke University Press, 2017.
        </li>
        <li>
          Smith, Linda Tuhiwai.{" "}
          <em>Decolonizing Methodologies: Research and Indigenous Peoples</em>.
          London: Zed Books, 1999.
        </li>
        <li>
          Wilkinson-Weber, Clare M., and Alicia Ory DeNicola, eds.{" "}
          <em>Critical Craft: Technology, Globalization, and Capitalism</em>.
          London: Routledge, 2016.
        </li>
      </ul>
      <hr />
      <cite>
        <sup id="annotation-1">1.</sup> adrienne maree brown,{" "}
        <em>Emergent Strategy: Shaping Change, Changing Worlds</em> (Chico, CA:
        AK Press, 2017), 159.
      </cite>
      <cite>
        <sup id="annotation-2">2.</sup> bell hooks,{" "}
        <em>Teaching to Transgress: Education as the Practice of Freedom</em>{" "}
        (New York, NY: Routledge, 1994), 207.
      </cite>
      <cite>
        <sup id="annotation-3">3.</sup> I imagine the after-thought, or
        after-thoughts, as a series of unfolding, non-linear mental connections
        that become strengthened following rigorous concentration. Similar to
        the ways muscles need rest as a way to repair and grow after enduring
        micro-tears that happen during exercise, processing information as an
        after-thought allows our brains the pause necessary to build further on
        the ideas we encounter in (previous) real time. Distinct from an{" "}
        <em>afterthought</em>, or the thought that happens without intention or
        without consideration, the after-thought is a way of returning to an
        initial prompt that might expand or fortify the idea. It is a thought
        filled with intention built on a network of related or conceptually
        informed ideas.
      </cite>
      <cite>
        <sup id="annotation-4">4.</sup> Joe Nolan, “Four Women Artists Reflect
        on Peoples and Places at Coop,” Nashville Scene, October 19, 2019,{" "}
        <a
          href="https://www.nashvillescene.com/arts_culture/four-women-artists-reflect-on-peoples-and-places-at-coop/article_93565876-b3da-5a05-983c-bb68d4e11fec.html"
          target="_blank"
          rel="noreferrer"
        >
          https://www.nashvillescene.com/arts_culture/four-women-artists-reflect-on-peoples-and-places-at-coop/article_93565876-b3da-5a05-983c-bb68d4e11fec.html
        </a>
        .
      </cite>
      <cite>
        <sup id="annotation-5">5.</sup> I use the contested term SWANA, which
        refers to people from Southwest Asia and North Africa, though I
        recognize this as merely a geographical shorthand and not a cultural or
        political identity.
      </cite>
      <cite>
        <sup id="annotation-6">6.</sup> Julietta Singh offers a critical
        examination of this notion of mastery in her book{" "}
        <em>Unthinking Mastery</em>, in which she writes, “we must begin to
        exile ourselves from feeling comfortable at home [in our disciplines]
        (which so often involves opaque forms of mastery), turning instead
        toward forms of queer dispossession that reach for different ways of
        inhabiting our scholarly domains—and more primordially, of inhabiting
        ourselves” (page 8). To engage her further discussion, see Julietta
        Singh, “Introduction: Reading against Mastery,” in{" "}
        <em>Unthinking Mastery: Dehumanism and Decolonial Entanglements</em>{" "}
        (Durham, NC: Duke University Press, 2017).
      </cite>
      <cite>
        <sup id="annotation-7">7.</sup> hooks, <em>Teaching to Transgress</em>,
        206.
      </cite>
      <cite>
        <sup id="annotation-8">8.</sup> Many of the previous institutions and
        departments that I worked for relied on constraining their disciplines
        to ensure a kind of consistency and methodological approach toward new
        and emerging scholarship. While these approaches helped to establish
        measured rubrics for evaluative purposes, there remain several instances
        where these methodological deployments continue to be uncritical of the
        ways they reinforce colonial, racist, or ableist tropes that had
        substantial impact within and outside of the discipline. This
        unwillingness to adapt or shift the methodological approach to
        disciplinary thinking reminds me of Linda Tuhiwai Smith’s writing when
        she says, “the concept of discipline is even more interesting when we
        think about it not simply as a way of organizing systems of knowledge
        but also as a way of organizing people or bodies” (page 71). For a more
        sustained conversation about the relationships between colonialism and
        disciplines, see Linda Tuhiwai Smith, “Colonizing Knowledges,” in{" "}
        <em>Decolonizing Methodologies: Research and Indigenous Peoples</em>{" "}
        (London: Zed Books, 1999).
      </cite>
      <cite>
        <sup id="annotation-9">9.</sup> To clarify, I use the phrase “show up”
        as a way to signal a kind of present (in-the-now) vulnerability that is
        distinct from mere visibility. In this way, “to show up” is not about
        the fact of being seen or arriving at a place, but instead marks a
        person’s ability to be fully present in the moment and to have the
        capacity to share that space with others so that they might feel whole
        or recognized as another complex human.
      </cite>

      <cite>
        <sup id="annotation-10">10.</sup> I am primarily thinking about
        foundational studies of craft that were situated within and as part of
        colonial anthropology. Entangled with anthropological collections and
        expeditions, early studies of craft were frequently tethered to cultural
        “civility.” As Alicia Ory DeNicola and Clare M. Wilkinson-Weber remark:
        “In early anthropology, craft objects and activities were of interest as
        putative cognates of the older practices of ‘civilized’ societies, whose
        traces might still be captured by field techniques of collection and
        description, and scientific procedures of classification and analysis.”
        For further discussion, see Clare M. Wilkinson-Weber and Alicia Ory
        DeNicola, eds.,{" "}
        <em>Critical Craft: Technology, Globalization, and Capitalism</em>{" "}
        (London: Taylor & Francis Group, 2016).{" "}
      </cite>

      <cite>
        <sup id="annotation-11">11.</sup> hooks, <em>Teaching to Transgress</em>
        , 207.
      </cite>
    </div>
  );
};

export default Acierto;
