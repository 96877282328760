import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { Helmet } from "react-helmet";

const Home = () => {
  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--highlight", "#de5a28");
  }, []);

  return (
    <SimpleBar className="home">
      <Helmet>
        <title>The MACR Papers | Being Here</title>
        <link rel="canonical" href={"https://www.themacrpapers.com"} />
      </Helmet>
      <article>
        <div className="article-header">
          <h4 className="author">BEN LIGNEL AND NAMITA GUPTA WIGGERS</h4>
          <h2 className="title center">Being Here</h2>
        </div>
        <blockquote className="indented">
          <p>
            <em>
              You have just entered the publication headquarters: in a heap near
              the entrance, a binge of bulldog clips and notepads, of see-thru
              sleeves and colored pens. School desks and a copy machine stand to
              attention in the room, and eye the whiteboard with suspicion (why
              does it always get all the exclamation marks?). You ask yourself:
              where do we go from here? And: Will people come?
            </em>{" "}
            (MatLab IV Syllabus, Spring 2023, closing semester)
          </p>
        </blockquote>
        <p>
          The final MACR program publication is a closing and a beginning. MACR
          is how the MA in Critical Craft Studies, the first low-residency
          graduate program focused on critical craft studies, was known at
          Warren Wilson College, Swannanoa, NC. Taking our cue from MoMA
          Librarian David Senior, we are thinking of “publications [as] archival
          documents of actual spaces or happenings that become […] emblematic of
          a certain hospitality to new forms of art and expression.”
          <sup>
            <a href="#annotation-1">1</a>
          </sup>{" "}
          The “new forms” are, in this case, the learning, researching, and
          intermittent living together that took place over the five years of
          the program’s first iteration—the “Warren Wilson College years,” you
          might say—from the fall of 2017 to the spring of 2023.
        </p>
        <blockquote className="indented">
          <p>
            <em>
              —What if, this time around, each graduating student created their
              own publication? <br />
              —Yes, and …
            </em>
          </p>
        </blockquote>
        <p>
          At the helm of this collection of seven publications is the 2023
          graduating cohort—Miriam Devlin, Jill DiMassimo, Jennifer Hand, Beryl
          Perron-Feller, Rena Tom, Joanna Weiss, and Tina Wiltsie—joined by
          program director Namita Gupta Wiggers and core faculty Ben Lignel.
          Individually, or in pairs, we engaged in the complex gymnastics of
          looking in the rearview mirror to describe some things learned, while
          looking ahead to frame them for future readerships. In Belgian
          feminist philosopher Françoise Colin’s words, this is a “heritage
          without a testament.”
          <sup>
            <a href="#annotation-2">2</a>
          </sup>{" "}
        </p>
        <blockquote className="indented">
          <p>
            <em>
              “This conference presentation outlines plans in progress to
              develop a new approach to craft history and theory, one which
              emphasizes craft’s longstanding global importance, and which
              leaves room for shifts and complexity, moving towards canons and
              away from a single canonical approach.”
              <sup>
                <a href="#annotation-3">3</a>
              </sup>{" "}
            </em>
          </p>
        </blockquote>
        <p>
          We have referred internally to <em>The MACR Papers</em> as an
          “archive,” a “library,” and landed finally on “papers,” to center the
          rich and messy nature of this multi-authored reflection on, and with,
          the program’s actual archive.
          <sup>
            <a href="#annotation-4">4</a>
          </sup>{" "}
          “Messy” points toward the editors’ discussions on the limits of siloed
          content organization. The students’ line-ups—of commissioned as well
          as reprinted texts—were conceived in full awareness of the knowledge
          that arises from the juxtaposition of texts that were not meant to be
          juxtaposed: their work lies as much in those connections as it does in
          the heavy lifting of producing their publications. But we asked
          ourselves: could we tend to the connections that exist both within,
          and across, the seven editorial projects? We understood that the
          adhesive nature of each publication could benefit from{" "}
          <em>another</em> miscellaneous sorting system, one that would echo the
          multiple disciplinary allegiances of craft studies. With this in mind,
          we created a keyword navigation system for you, dear reader, in order
          to generate fruitful contiguity,
          <sup>
            <a href="#annotation-5">5</a>
          </sup>{" "}
          while not pretending that all the “gaps in the shelves” are filled.
          <sup>
            <a href="#annotation-6">6</a>
          </sup>{" "}
          The resulting interface highlights keyword navigation and lets each
          one of the 99 downloadable articles carry its own unique set of
          faceted coordinates within <em>The MACR Papers</em>’ wider semantic
          constellation. As you browse—through tables of content or keywords—we
          invite you to choose, save, and print the articles that meet with your
          interest, and thus to assemble your own “MACR Papers.”
        </p>
        <blockquote className="indented">
          <p>
            <em>
              “Many hands, many minds all the time.”
              <br />
              —Ulrike Müller
              <sup>
                <a href="#annotation-7">7</a>
              </sup>{" "}
            </em>
          </p>
        </blockquote>
        <p>
          This is our most ambitious publishing project to date, by far. The
          students pulled it off while working on Practicum—no small feat—and
          produced editorial projects that very much reflect the thinkers they
          are and the conversations they have chosen to be part of. Thinking
          about the intellectual communities and support networks we all relied
          on makes us want to thank the world and their cousin, or so it might
          seem. Alongside the knowledge holders who are cited in the{" "}
          <em>Papers</em>, we want to name you—friend, reader, educator, mentor,
          and collaborator. You are important to us, and if we missed you in
          this list, we beg forgiveness. You belong in a roll call of the field
          of craft studies, which this program served. Thank you for being here.
        </p>
        <p>*</p>
        <p>
          To consult previous publication projects, go to{" "}
          <a
            href="https://www.macraftstudieswwc.com"
            target="_blank"
            rel="noreferrer"
          >
            www.macraftstudieswwc.com
          </a>
          .<br />
          The entire program, from 2017 to 2023, is “archived” on Instagram{" "}
          <a
            href="https://www.instagram.com/macraftstudieswwc/"
            target="_blank"
            rel="noreferrer"
          >
            @macraftstudieswwc
          </a>
          .
        </p>
        <p>*</p>
        <h2>Colophon</h2>
        <p>
          This series of publications crowns the relentless efforts of the
          program’s last graduating cohort to respond to a slightly crazy “what
          if?” The seven students have worked tirelessly to bring their material
          to publishing-ready state, done the mammoth work of tracking images
          and copyright holders, and rose to the challenge of each becoming, for
          the first time, the editor of a publication of their own. Conceived as
          part of the Materials Lab course, this editorial project was developed
          by the graduating cohort during semesters III and IV, and complemented
          by a faculty publication led by Ben Lignel and Namita Gupta Wiggers.
        </p>
        <p>
          The masthead below reflects this imbricated organization, with some
          “general” titles and positions, and individual ones.
        </p>
        <ul className="masthead">
          <li>
            <h6>The MACR Papers are published by</h6>
            MA in Critical Craft Studies, Swannanoa, North Carolina
          </li>
          <li>
            <h6>Founding program director</h6>
            Namita Gupta Wiggers
          </li>
          <li>
            <h6>Individual editors and editorial teams</h6>
            Miriam Devlin, editor for Craft Practiced: A Reader (ISBN
            978-1-7351592-7-0)
          </li>
          <li>
            Jill DiMassimo and Joanna Weiss, editors for Reclaiming the Center:
            Making Way for Black Craft (ISBN 978-1-7351592-4-9)
          </li>
          <li>
            Jennifer Hand, editor for The Space between Us: Writing across
            Difference in the Crafts (ISBN 978-1-7351592-5-6)
          </li>
          <li>
            Ben Lignel and Namita Gupta Wiggers, editors for This Is Not a
            Retreat (ISBN 978-1-7351592-6-3)
          </li>
          <li>
            Beryl Perron-Feller, editor for Finding Common Ground through Craft
            (ISBN 978-1-7351592-3-2)
          </li>
          <li>
            Rena Tom, editor for Encyclopedia of Craft Studies (Abridged) (ISBN
            978-1-7351592-8-7)
          </li>
          <li>
            Tina Wiltsie, editor for Pots on Pots (ISBN 978-1-7351592-9-4)
          </li>
          <li>
            <h6>General edition supervisor</h6>
            Ben Lignel
          </li>
          <li>
            <h6>Proofreader for all publications</h6>
            Nathalie Mornu
          </li>
          <li>
            <h6>Website developer</h6>
            Mike Katsufrakis
          </li>
          <li>
            <h6>Copyright and contribution agreement manager</h6>
            Jessie Shires
          </li>
          <li>
            <h6>Designer (website and PDF)</h6>
            Ben Lignel
          </li>
        </ul>
        <p>*</p>
        <p>
          We thank the publishing bodies, historical institutions, commercial
          galleries, and private copyright holders who let us share images
          and/or texts, etc. Every effort has been made to contact copyright
          owners. To the best of our knowledge, all images reproduced in the
          publications are either free of copyright, or we have been granted
          written permission to use them. The home page image is from the island
          of Serifos, Greece, 2020, shot by Madeleine Caillard. It is used with
          the photographer’s very kind permission.
        </p>
        <p>Our very special thanks to:</p>
        <p>
          <strong>Jessie Shires</strong>, who managed the logistical end of
          permission and memorandums of agreement: hats off to your phlegmatic
          and ever-chilled-out composure, may you never see Formstacks again.
        </p>
        <p>
          <strong>Mike Katsufrakis</strong>, who performed extraordinary coding
          within an absurdly tight timeline: thank you for the Sunday marathons
          and daily updates—you rock.
        </p>
        <p>
          <strong>Olivier Vespignani</strong>, who accepted to draw and redraw a
          GIF animation with only one image for inspiration—while on holiday.{" "}
          <a href="https://www.ovespi.com" target="_blank" rel="noreferrer">
            www.ovespi.com
          </a>
        </p>
        <p>
          <strong>Lucy Conticello</strong>, for her day-in, day-out advice on
          image use, and deep knowledge of the fact that you will never know
          exactly who <em>else</em> owns a picture.
        </p>
        <p>
          The following is a small list of individuals engaged in the MACR
          community; they might be advisors, alumni, mentors, Warren Wilson
          College staff or faculty, teaching fellows, contributors,
          illustrators, donors, or a combination of these roles. Thank you for
          being in community in the craftscape.{" "}
        </p>
        <ul className="thankyous">
          <li>Glenn Adamson</li>
          <li>Aeon.co</li>
          <li>Indira Allegra</li>
          <li>
            <em>American Craft</em>
          </li>
          <li>Sarah Archer</li>
          <li>Jeff Arnal</li>
          <li>Robell Awake</li>
          <li>Chenoa Baker</li>
          <li>Zaida Goveo Balmaseda</li>
          <li>Paul Bartels</li>
          <li>Juliana Barton</li>
          <li>Karen Bell</li>
          <li>Blake Bernstein</li>
          <li>Ben Blackmar</li>
          <li>Phoenix Booth</li>
          <li>Darrah Bowden</li>
          <li>Jacob Brault</li>
          <li>Scott Braun</li>
          <li>Morgan Buckert</li>
          <li>Christina Burke</li>
          <li>Dani Burke</li>
          <li>Maria Elena Buszek, PhD</li>
          <li>Alexis Rosa Caldero</li>
          <li>Cargill Foundation</li>
          <li>Julie Caro</li>
          <li>The Chrysler Museum</li>
          <li>Lola Clairmont</li>
          <li>Sonya Clark</li>
          <li>Sara Clugage</li>
          <li>Brian Conlon</li>
          <li>Lucy Conticello</li>
          <li>Amanda Hollomon Cook</li>
          <li>Sonja Dahl</li>
          <li>Sara Darro</li>
          <li>Mary Ellen Davis</li>
          <li>Julie Decker, PhD</li>
          <li>Jen Delos Reyes</li>
          <li>Justin Den Herder</li>
          <li>Alicia Ory DeNicola</li>
          <li>Miriam Devlin</li>
          <li>Trieste Devlin</li>
          <li>Jill DiMassimo</li>
          <li>David Ellum</li>
          <li>Pete Erb</li>
          <li>Mark Essig</li>
          <li>Andres Payan Estrada</li>
          <li>Nick Falduto</li>
          <li>Anna Fariello</li>
          <li>Fabio Fernandez</li>
          <li>Michelle Millar Fisher</li>
          <li>Sam Ford</li>
          <li>Michael-Jamar Jean Francois</li>
          <li>Eric Franklin</li>
          <li>Paula Garrett</li>
          <li>Bean Gilsdorf</li>
          <li>Andrew Glasgow</li>
          <li>Louise Goings</li>
          <li>Kat Gordon</li>
          <li>Mellanee Gordon</li>
          <li>Katherine Gray</li>
          <li>Josh Green</li>
          <li>Laurin Guthrie</li>
          <li>Catherine Hall</li>
          <li>Mia Hall</li>
          <li>Jennifer Hand</li>
          <li>Heather Harvey</li>
          <li>Lexie Harvey</li>
          <li>Michael Hatch</li>
          <li>Molly Hatch</li>
          <li>Matt Haugh</li>
          <li>Kate Hawes</li>
          <li>Gary Hawkins</li>
          <li>Erin Hawley</li>
          <li>Jay Haynes</li>
          <li>Anna Helgeson</li>
          <li>Pablo Helguera</li>
          <li>Julie Hollenbach</li>
          <li>Graham House</li>
          <li>Alisa Hove</li>
          <li>Carol Howard</li>
          <li>Teresa Imfeld</li>
          <li>Lisa Jarrett</li>
          <li>Emily Johnson</li>
          <li>Meghen Jones</li>
          <li>Nikki Josheff</li>
          <li>Faye Junaluska</li>
          <li>Mike Katsufrakis</li>
          <li>Jeffrey A. Keith</li>
          <li>Sarah Kelly</li>
          <li>Sarah K. Khan</li>
          <li>Kareem Khubchandani</li>
          <li>Laura Kina</li>
          <li>Stephen Knott</li>
          <li>Ben Krakauer</li>
          <li>Phoebe Kuo</li>
          <li>Stoney Lamar</li>
          <li>matt lambert</li>
          <li>Anne Lawson</li>
          <li>Helen Lee</li>
          <li>JeeYeun Lee</li>
          <li>Judith Leemann</li>
          <li>Faythe Levine</li>
          <li>Ben Lignel</li>
          <li>Rosemary Logan, PhD</li>
          <li>Maru Lopez</li>
          <li>Kae Lorentz</li>
          <li>Sharon Louden</li>
          <li>Caitrin Lynch</li>
          <li>Sarah Margolis-Pineo</li>
          <li>Tom Martin</li>
          <li>Alex Matisse</li>
          <li>Connie Matisse</li>
          <li>Tim May</li>
          <li>Kevin McIlvoy</li>
          <li>Aaron McIntosh</li>
          <li>Kevin McNamee-Tweed</li>
          <li>Amy Meissner</li>
          <li>Cyle Metzger</li>
          <li>Jay Miller</li>
          <li>Nick Mirzoeff</li>
          <li>W. J. T. Mitchell</li>
          <li>Tiffany Momon</li>
          <li>Stephanie Moore</li>
          <li>Nathalie Mornu</li>
          <li>Lynn Morton</li>
          <li>Mike Murawski</li>
          <li>Kevin Murray</li>
          <li>Pam Myers</li>
          <li>The New Yorker</li>
          <li>Karen Olson</li>
          <li>Alpesh Kantilal Patel</li>
          <li>Mariko Paterson</li>
          <li>Beryl Perron-Feller</li>
          <li>PJ Gubatina Policarpio</li>
          <li>Elizabeth Porter</li>
          <li>Melissa Potter</li>
          <li>Heather Powers</li>
          <li>Sam Ratsatter</li>
          <li>Anjula Razdan</li>
          <li>Whitney Richardson</li>
          <li>Jay Roberts</li>
          <li>Leslie Carol Roberts</li>
          <li>Annabelle Ruiz</li>
          <li>Charlie Ryland</li>
          <li>Paul Sacaridez</li>
          <li>Linda Sandino</li>
          <li>Sara Schultz</li>
          <li>Ezra Shales</li>
          <li>Jessie Shires</li>
          <li>Yasmeen Siddiqui</li>
          <li>Lauren Sinner</li>
          <li>Mara Holt Skov</li>
          <li>Kasey Smith</li>
          <li>T’ai Smith</li>
          <li>Anthony Sonnenberg</li>
          <li>Jenni Sorkin</li>
          <li>Heather South</li>
          <li>Jeffrey Spahn</li>
          <li>Jonathan Michael Square</li>
          <li>Kat St. Aubin</li>
          <li>Molly Steele</li>
          <li>Shannon Rae Stratton</li>
          <li>The Stuart Hall Foundation</li>
          <li>Sara Tabbert</li>
          <li>Lehua M. Taitano</li>
          <li>Savneet Talwar</li>
          <li>Tara Leigh Tappert</li>
          <li>Keeanga-Yamahtta Taylor</li>
          <li>Ed Thomas</li>
          <li>Reggie Tidwell</li>
          <li>Rena Tom</li>
          <li>Urban Glass</li>
          <li>Deborah Valoma</li>
          <li>Joni Van Bockel</li>
          <li>Olivier Vespignani</li>
          <li>John Vigeland</li>
          <li>Anuradha Vikram</li>
          <li>L. Vinebaum</li>
          <li>Chelsea Vowel</li>
          <li>Anna Walker</li>
          <li>Joanna Weiss</li>
          <li>Namita Gupta Wiggers</li>
          <li>MC Wilder</li>
          <li>Melanie Wilder</li>
          <li>Tina Wiltsie</li>
          <li>Windgate Foundation</li>
          <li>Emily Winter</li>
          <li>Nathan Wyrick</li>
          <li>Marilyn Zapf</li>
        </ul>
        <hr />
        <cite>
          <sup id="annotation-1">1.</sup> David Senior,{" "}
          <em>Access to Tools: Publications from the Whole Earth Catalog</em>{" "}
          (New York: MoMA, 2011).
        </cite>
        <cite>
          <sup id="annotation-2">2.</sup> Françoise Colin,{" "}
          <em>Un Héritage sans Testament</em> (Montréal: Éditions du
          Remue-Ménage, 2020).
        </cite>
        <cite>
          <sup id="annotation-3">3.</sup> Namita G. Wiggers, “Unsettling as
          Action: Developing a Craft Studies Curriculum,” in the session
          “Unsettling Coloniality: Resources and Strategies for New Teaching
          Models and Pedagogies” (presentation, Shared Ground symposium,
          organized by Bard Graduate Center, the Center for Craft, and the
          Museum of Arts and Design, September 20–22, 2018),{" "}
          <a
            href="https://www.youtube.com/watch?v=hdy1bIxL9yw"
            target="_blank"
            rel="noreferrer"
          >
            https://www.youtube.com/watch?v=hdy1bIxL9yw
          </a>
          , retrieved May 19, 2023.
        </cite>
        <cite>
          <sup id="annotation-4">4.</sup> We have documented the program from
          its inception: notes, schedules, syllabi, recordings of programs,
          classes and debrief sessions, and more with future researchers in
          mind. Through a memorandum of understanding between former President
          Lynn Morton, Provost Jay Roberts, and former CFO Belinda Burke in
          Spring 2021, all program materials are the property and responsibility
          of Founding Program Director Namita Gupta Wiggers as of July 1, 2023.
          The public-facing elements of the archive are available at{" "}
          <a
            href="https://www.criticalcraftforum.com"
            target="_blank"
            rel="noreferrer"
          >
            www.criticalcraftforum.com
          </a>
          .{" "}
        </cite>
        <cite>
          <sup id="annotation-5">5.</sup> In-class discussions on the limitation
          of the Dewey Decimal Classification system encouraged us to consider
          not only alternatives to it, like Ranganathan’s faceted classification
          system, but also strategies for courting bookshelf surprises: Aby
          Warburg, Walter Benjamin, and Georges Perec provide good models of
          productive book (dis)organization.
        </cite>
        <cite>
          <sup id="annotation-6">6.</sup> A reflection of who—and what—is
          missing from the archives we work in, and by extension, the one we are
          building, is central to this program’s vision. For more on the
          subject, go to, in particular,{" "}
          <NavLink to="/dimassimo_weiss/editornote">
            Making Room, Making Connections, Making History: Editor's Note
          </NavLink>
          ,{" "}
          <NavLink to="/notaretreat/keith">
            Emplaced Remembrance—Thinking about Gone Worlds Always Nearby
          </NavLink>{" "}
          and{" "}
          <NavLink to="/notaretreat/fisher">
            Who writes craft histories?
          </NavLink>
          .
        </cite>
        <cite>
          <sup id="annotation-7">7.</sup> Ulrike Müller,{" "}
          <em>Notes on Herstory Inventory: A Collaborative Work in Process</em>{" "}
          (citation needed, 2011).
        </cite>
      </article>
    </SimpleBar>
  );
};

export default Home;
